import axiosInstance from "../helpers/axiosInstance";

export const fetchOperators = async (searchParams) => {
    const {warehouse_id, ...params} = searchParams
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/warehouses/${warehouse_id}/operators`, { params })
    return res.data
}

export const fetchOperator = async (operatorId) => {
    if(operatorId===undefined){
        return null
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/claims/${operatorId}`)
    return res.data
}

export const createOperator = async (data) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/user/sign-up`, data)
    return res.data
}


export const updateOperatorPwd = async (email, newPassword) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/user/update-password`, {"email": email, "new_password": newPassword})
    return res.data
}

export const updateOperatorStatus = async (operatorId, status) => {
    if(operatorId===undefined || status===undefined){
        return null
    }
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/users/${operatorId}/update_enabled_status`, {"is_enabled": status})
    return res.data
}

export const updateRoles = async (operatorId, roles) => {
    if(operatorId===undefined || roles===undefined){
        return null
    }
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/users/${operatorId}/update_roles`, {"roles": roles})
    return res
}

export const fetchOperatorsAccount = async (searchParams) => {
    const {warehouse_id} = searchParams
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/warehouses/${warehouse_id}/operators-account`)
    return res.data
}
