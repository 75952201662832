import { ExclamationCircleIcon } from "@heroicons/react/solid"

const BANNER_TYPES = {
  error: {
    textColor: "text-white",
    backgroundColor: "bg-red-600",
    borderColor: "",
    icon: <ExclamationCircleIcon className="h-5 w-5 mr-2" />,
  },
}

export const NoticeBanner = ({
  text = "",
  icon = null,
  className = "",
  type = "error",
}) => {
  let classes = ["rounded-full", "px-2", "py-1", "mt-3", "flex", "items-center"]
  if (BANNER_TYPES[type]) {
    classes.push(BANNER_TYPES[type].textColor)
    classes.push(BANNER_TYPES[type].backgroundColor)
    classes.push(BANNER_TYPES[type].borderColor)
  }
  classes = classes.join(" ")
  classes = classes + " " + className

  return (
    <div className={classes}>
      {BANNER_TYPES[type].icon}
      <div className="">{text}</div>
    </div>
  )
}
