import { ExclamationCircleIcon } from "@heroicons/react/outline";

const PageViewFooter = ({bottomMenu, errorMessage=null, bottomMenuStyles=null}) => {
    if(!bottomMenu && !errorMessage){
        return <></>
    }

    return (
        <div>
            {errorMessage && (
                <div>
                    <div className="bg-red-700 text-white text-3xl py-6 px-16 font-medium flex gap-4 items-center text-justify ">
                        <ExclamationCircleIcon className="h-10 flex-shrink-0" />
                        {errorMessage}
                    </div>
                </div>
            )}

            {bottomMenu && (
                <div className={`min-h-17 flex-shrink-0 bg-gray-100 ${bottomMenuStyles ? bottomMenuStyles : ""}`}>
                    {bottomMenu}
                </div>
            )}
        </div>
    )
}

export default PageViewFooter