/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { QuestionMarkCircleIcon } from '@heroicons/react/outline'
import {DialogView} from "./DialogView";
import {InputField} from "./InputField";
import {SearchInput} from "./SearchInput";

const wrongImeiLabel = 'IMEI incorrecto'

export const ImeiDialog = ({
    open,
    setOpen,
    title='Ingresa IMEI',
    onSubmit,
    onCancel,
    cancelLabel = 'Cancelar',
    imeiValidationRegex,
}) => {
    const cancelButtonRef = useRef()
    const imeiInputRef = useRef()
    const [lastImeiInputError, setLastImeiInputError] = useState(null);

    const validateImei = (newImei) => {
        if(!newImei.length) {
            return;
        }

        const imeiRegex = new RegExp(imeiValidationRegex)
        const isValid = imeiRegex.test(newImei)

        if(!isValid) {
            setLastImeiInputError(newImei)
        } else {
            onSubmit(newImei)
            setLastImeiInputError(null)
        }

        imeiInputRef.current?.resetInput()
    }

    useEffect(() => {
        if(!open) {
            setLastImeiInputError(null)
            imeiInputRef.current?.resetInput()
        }
    }, [open])

    return (
        <DialogView setOpen={setOpen} open={open} className="w-auto">
            <div>
                <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        {title}
                    </Dialog.Title>
                </div>
            </div>
            <div className="mt-5 sm:mt-6">
                <SearchInput
                    placeholder="IMEI"
                    onChange={(value) => { validateImei(value) }}
                    numericOnly={false}
                    hideIcons={true}
                    debounce={250}
                    ref={imeiInputRef}
                />
                {
                    lastImeiInputError &&
                        <p className="mt-1 text-sm text-red-600 text-center">{`${wrongImeiLabel}: ${lastImeiInputError}`}</p>
                }
            </div>
            <div className="mt-5 sm:mt-6">
                <button
                    type="button"
                    className="mt-5 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={onCancel}
                    ref={cancelButtonRef}
                >
                    {cancelLabel}
                </button>
            </div>
        </DialogView>
    )
}