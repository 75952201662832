import { SearchInput } from "../../SearchInput"
import {useState} from 'react';
// import { SelectInput } from "../../SelectInput"
import { Select } from 'antd'
import { DatePicker, InputNumber} from 'antd'

export function TextColumnFilter({
   column: { filterValue, setFilter, numericOnlyFilter = false, placeholder="Buscar", inputHoverDescription=null }
}) {
   return (
       <SearchInput
           inputValue = {filterValue}
           onChange = { value => { setFilter(value || undefined) } }
           debounce = {250}
           numericOnly = {numericOnlyFilter}
           placeholder={placeholder}
           inputHoverDescription={inputHoverDescription}
       ></SearchInput>
   )
}

export function SelectColumnFilter({
   column: { filterValue, setFilter, selectFilterOptions },
   onSubmit = () => {}
}) {
   return (
      <div className="w-full">
         <select
            value={filterValue}
            className="block w-full bg-white py-2 border border-gray-300 rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm"
            onChange={e => {
                  setFilter(e.target.value || undefined)
                  onSubmit(e.target.value || undefined)
            }}
         >
            <option value="">Todos</option>
            {
               selectFilterOptions.map((option, index) => {
                  return <option key={index} value={option.id}>{option.label}</option>
               })
            }
         </select>
      </div>
   )
}


export function SelectColumnMultiFilter({
   column: { filterValue, setFilter, selectFilterOptions },
   
}) {
   const children = [];
   const { Option } = Select;

   function handleChange(value) {
      setFilter(value || undefined)
   }
   return (
      <div className="w-full ">
          <Select
               mode="multiple"
               allowClear
               //className="block w-full bg-white py-2 border border-gray-300 rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm"
               size="small"
               className=""
               style={{ width: '100%', fontSize:"x-small"}}
               placeholder="Seleccionar"
               defaultValue={filterValue}
               onChange={handleChange}
               >
                  {
                     selectFilterOptions.map((option, index) => {
                        return <Option key={index} value={option.id}>{option.label}</Option>
                     })
                  }
            </Select>
      </div>
   )
}

const { RangePicker } = DatePicker;
const defaultDateTimeRangeFilterOptions = {
   size: "default",
   placeholder: ['Inicio', 'Fin'],
   showTime: { format: 'HH:mm' },
   format: "YYYY-MM-DD HH:mm"
}
export const DateTimeRangeFilter = ({ column: { filterValue, setFilter, filterOptions = {...defaultDateTimeRangeFilterOptions}}}) => {
   const options = {...defaultDateTimeRangeFilterOptions, ...filterOptions}

   const handleChange = (value) => {
      console.log("datepickerValue", value)
      if (value === null) {
         setFilter(null)
      }
      else {
         let filterValue = {from: value[0].toISOString(), to: value[1].toISOString()}
         setFilter(filterValue)
      }
   }
   return (
      <div className="bg-white py-1 border border-gray-300 rounded-md ">
         <RangePicker bordered={false} format={options.format} size={options.size} placeholder={options.placeholder} showTime={{ format: 'HH:mm' }} onChange={(v) => handleChange(v)}/>
      </div>
   )
   
}

const defaultDateTimeFilterOptions = {
   size: "default",
   placeholder: 'Fecha',
   showTime: { format: 'HH:mm' },
   format: "YYYY-MM-DD HH:mm"
}
export const DateTimeFilter = ({ column: { filterValue, setFilter, filterOptions = {...defaultDateTimeFilterOptions}}}) => {
   const options = {...defaultDateTimeFilterOptions, ...filterOptions}
   const handleChange = (value) => {
      if (value === null) {
         setFilter(null)
      }
      else {
         setFilter(value.toISOString())
      }
   }
   return (
      <div className="bg-white py-1 border border-gray-300 rounded-md ">
         <DatePicker bordered={false} format={options.format} size={options.size} placeholder={options.placeholder} showTime={{ format: 'HH:mm' }} onChange={(v) => handleChange(v)}/>
      </div>
   )
}

const defaultNumericRangeFilterOptions = {
   placeholder: ['Desde', 'Hasta'],
   minValue: 1,
   maxValue: null,
   className: '',
   containerClassName: 'w-32 flex',
   showControls: false,
   size: 'large',
   overrideInputStyles: [
      {borderRight: 0, borderColor: "#d1d5db", borderRadius: "0.375rem", borderTopRightRadius: 0, borderBottomRightRadius:0},
      {borderLeft: 0, borderColor: "#d1d5db", borderRadius: "0.375rem", borderTopLeftRadius: 0, borderBottomLeftRadius:0, paddingLeft: 0}
   ]
}
export const NumericRangeFilter = ({ column: {setFilter, filterOptions = {...defaultNumericRangeFilterOptions}}}) => {
   const options = {...defaultNumericRangeFilterOptions, ...filterOptions}

   const [valInputInit, setValInputInit] = useState(null)
   const [valInputFinal, setValInputFinal] = useState(null)


   const handleChangeInit = (value) => {
      setValInputInit(value)
      if(!value && !valInputFinal){
         setFilter(null)
      }
      if(valInputFinal != null) {
         const filterValue = {from: value, to: valInputFinal}
         setFilter(filterValue)
      }  else  {
         const filterValue = {from: value, to: null}
         setFilter(filterValue)
      }
   }

   const handleChangeFinal = (value) => {
      setValInputFinal(value)
      if(!value && !valInputInit){
         setFilter(null)
      }
      if(valInputInit != null) {
         const filterValue = {from: valInputInit, to: value}
         setFilter(filterValue)
      }  else  {
         const filterValue = {from: null, to: value}
         setFilter(filterValue)
      }
   }

   return (
      <>
         <div className={options.containerClassName}>
            <InputNumber 
               min={options.minValue} 
               max= {options.maxValue}
               className={options.className}
               size={options.size}
               style={options.overrideInputStyles[0]}
               placeholder={options.placeholder[0]} 
               onChange={(v) => handleChangeInit(v)}
               controls={options.showControls}
            />
            <InputNumber 
               min={options.minValue} 
               max= {options.maxValue}
               size={options.size}
               className={options.className}
               style={options.overrideInputStyles[1]}
               placeholder={options.placeholder[1]} 
               onChange={(v) => handleChangeFinal(v)}
               controls={options.showControls}
            />
         </div>
      </>
   )
   
}
