import {useState,useRef} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {
  fetchInventoryHistory,
} from "../../services/inventoryHistoryServices"
import {
    FormattedDate,
    PageTopBar,
    PageView
} from "../../components"
import { BasicTable } from "./../../components/BasicTable/BasicTable"
import { Loader } from '../../components/Loader';
import { DateTimeRangeFilter, SelectColumnFilter } from '../../components/BasicTable/modules/ColumnFiltersUI';
import { setInventoryHistoryListPageSize } from '../../redux/pageSizeSlice';
import { useDispatch, useSelector } from "react-redux";

const columns = [
  {
    Header: 'Ejecutado',
    accessor: 'executed_at', // accessor is the "key" in the data
    Filter: DateTimeRangeFilter,
    disableFilters: false,
    disableSortBy: false,
    shrinkToContent: true
  },
  {
    Header: 'Tienda',
    accessor: 'store_name',
    disableFilters: false,
    disableSortBy: true
  },
  {
      Header: 'SKU',
      accessor: 'sku',
      disableFilters: false,
      disableSortBy: true
  },
  {
      Header: 'UPC',
      accessor: 'bar_code',
      disableFilters: false,
      disableSortBy: true
  },
  {
    Header: 'Producto',
    accessor: 'product_name',
    disableFilters: false,
    disableSortBy: true
  },
  {
    Header: 'Almacén',
    accessor: 'warehouse_id',
    disableFilters: true,
    disableSortBy: true
  },
  {
    Header: 'Ubicación',
    accessor: 'location',
    disableFilters: false,
    disableSortBy: true
  },
  {
    Header: 'Stock Previo',
    accessor: 'previous_stock_quantity',
    disableFilters: true,
    disableSortBy: true
  },
  {
    Header: 'Nuevo Stock',
    accessor: 'new_stock_quantity',
    disableFilters: true,
    disableSortBy: true
  },
  {
    Header: 'Id',
    accessor: 'referenceable_id',
    disableFilters: true,
    disableSortBy: true
  },
  {
    Header: 'Acción',
    accessor: 'referenceable_action',
    Filter: SelectColumnFilter,
    selectFilterOptions: 
    ['Transfer', 
    'Damaged Check-in', 
    'Return Put Away',
    'Shipped', 
    'Return Check-in', 
    'Check-in', 
    'Put Away'].map((referenceable_action)=>{
            
      return {
        id: referenceable_action,
        label: referenceable_action
      }
    }),
    disableFilters: false,
    disableSortBy: true
  },
  {
    Header: 'Hecho por',
    accessor: 'created_by',
    disableFilters: false,
    disableSortBy: true
  }
]

export const ListInventoryHistoryContainer = () => {

  const history = useHistory()
    const {warehouse_id} = useParams()
    const dispatch = useDispatch()
    const pageSize = useSelector((state) => state.pageSize.inventoryHistoryList)
    const defaultSearchParams = {
      page: 1,
      per_page: pageSize,
      order_by: 'executed_at',
      order: 'DESC',
      warehouse_id: isNaN(parseInt(warehouse_id)) ? null : parseInt(warehouse_id),
    }
    const [searchParams, setSearchParams] = useState({...defaultSearchParams})
    const tableRef = useRef()
    const {
      isLoading,
      isError,
      error,
      data,
      isFetching,
      isPreviousData,
  } = useQuery(['inventory_history', searchParams], () => fetchInventoryHistory(searchParams), { keepPreviousData : true })

  const onSortChange = (orderBy) => {
    let newSearchParams = {
      ...searchParams
    }
    if (orderBy.length > 0) {
      newSearchParams.order_by = orderBy[0].id
      newSearchParams.order = orderBy[0].desc ? "DESC" : "ASC"
      setSearchParams(newSearchParams)
    } else {
      if ("order_by" in searchParams) {
        newSearchParams.order_by = defaultSearchParams.order_by
        newSearchParams.order = defaultSearchParams.order
        setSearchParams(newSearchParams)
      }
    }
  }

  const onFilterChange = (activeFilters) => {
    let newSearchParams = {
      ...searchParams
    }
    columns.forEach((col) => {
      if (newSearchParams[col.accessor]) {
        delete newSearchParams[col.accessor]
      }
    })
    let filters = []
    activeFilters.forEach((filter) => {
      filters[filter.id] = filter.value
    })
    setSearchParams({...newSearchParams, ...filters, page: 1, warehouse_id: warehouse_id})
  }

  const getReferenceIdOrOrderNumber = (inv_history) => {
    if(inv_history?.referenceable_type === "Order") {
      return "#" + inv_history.referenceable?.order_number
    } 
    if(inv_history.referenceable_id === null) {
      return "Sin registro"
    }
    return inv_history?.referenceable_id
  }

  const getTableData = () => {
    return data.inventory_histories.map((inv_history) => {
      return {
        entity: {...inv_history},
        executed_at:<FormattedDate date={inv_history.executed_at} shortDate />,
        sku: <div className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-blue-100 text-blue-800"}>{(inv_history.product.product_codes[0].sku)}</div>,
        bar_code: <div className="">{String(inv_history.product.bar_code)}</div>,
        product_name: <div className="">{String(inv_history.product.name)}</div>,
        warehouse_id: <div className="">{String(inv_history.warehouse_location?.warehouse_id === 1 ? 'Polanco' : 'Repremundo')}</div>,
        location: <div className="">{String(inv_history.warehouse_location?.unique_code)}</div>,
        previous_stock_quantity: <div className="text-xl">{String(inv_history.previous_stock_quantity)}</div>,
        new_stock_quantity: <div className="text-xl">{String(inv_history.new_stock_quantity)}</div>,
        referenceable_id:<div className=""><div>{getReferenceIdOrOrderNumber(inv_history)}</div></div>,
        referenceable_action: <div className="uppercase">{String(inv_history.action == 'NULL' ? '-' : inv_history.action)}</div>,
        created_by: <div className="">{String(inv_history?.created_by.first_name + '-' + inv_history?.created_by.last_name)}</div>,
        store_name: <div className="">{String(inv_history?.product?.store.name)}</div>,
      }
    })
  }

  return (
    <>
           <PageView
                topMenu={<PageTopBar>
                  <div className="text-lg font-semibold">
                    Inventario{" "}
                    <span className="text-sm text-gray-400">
                      Historial de inventario - 
                    </span>
                    <span className="text-sm text-gray-400">
                      Almacén {warehouse_id}
                    </span>
                  </div>
                  
                    
                </PageTopBar>}
                childrenFullWidth={true}
                topMenuFullWidth={true}
            >
                
                {!isPreviousData && isLoading ? (
                    <><Loader show={true}></Loader></>
                ) : isError ? (
                    <>Error: {error.message}</>
                ) : (
                    <BasicTable
                        showHeader
                        showLoader={isFetching && isPreviousData}
                        columns={columns}
                        filterable
                        sortable
                        showPaginator
                        data = {getTableData()}
                        onFilterChange={(activeFilters) => onFilterChange(activeFilters)}
                        onSortChange={(activeSort) => onSortChange(activeSort)}
                        paginationMeta={data?.meta}
                        labelToPaginator="Registros"
                        pagesSize = {searchParams.per_page}
                        onPaginationChange= { (requestedPage) => { setSearchParams({...searchParams, page: requestedPage}) } }
                        onPageSizeChange= { (pageSize) => {
                            setSearchParams({...searchParams, per_page: pageSize, page: 1})
                            dispatch(setInventoryHistoryListPageSize(pageSize))
                            tableRef.current.resetPagination()
                        }}
                        showDataStatus
                        isFetching={isFetching}
                        emptyTableText="No hay pedidos que mostrar."
                        ref={tableRef}
                        />
                    )
                }
            </PageView>
        </>
  )    

}