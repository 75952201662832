import { CurrencyDollarIcon } from "@heroicons/react/outline"
import React, { useEffect, useState } from "react"

export const CurrencyInputField = React.forwardRef(
  (
    {
      value = "",
      onChange = () => {},
      onError = (message) => {},
      title = null,
      labelElement = null,
      placeholder,
      required = false,
      readOnly = false,
      inputClassName = "",
      help = null,
      hideIcon = false,
      customIcon = null,
      precision = 11,
      scale = 4,
      showErrors = true,
      isDisabled= false,
      ...params
    },
    ref
  ) => {
    const { className } = params

    const [localValue, setLocalValue] = useState(value)
    const [errorMessage, setErrorMessage] = useState(null)

    useEffect(() => {
      setLocalValue(value)
    }, [value])

    const handleChange = (e) => {
      let val = e.target.value

      if(scale === 0){
        const decimals = val.split(".")
        val = decimals[0]
      }

      val = val.replace(/[^\d.]/g, "")
      let decimals = val.split(".")
      while (decimals.length > 2) {
        decimals.pop()
      }
      
      if (decimals[1]?.length > scale) {
        setErrorMessage(`Máximo ${scale} decimales.`)
        onError(errorMessage)
        return
      }
      if (decimals[0].length > (precision-scale)) {
        setErrorMessage(`Máximo ${precision-scale} dígitos enteros.`)
        onError(errorMessage)
        return
      }
      val = decimals.join(".")
      setErrorMessage(null)
      setLocalValue(val)
      onChange(val)
    }

    return (
      <div className={className}>
        {title && (
          <label className="block text-sm font-medium text-gray-700">
            {title}
            {required && <span className="text-red-500"> *</span>}
          </label>
        )}
        {labelElement}
        <div className="mt-1 relative">
          {!hideIcon && (
            <div className="absolute top-0 bottom-0 left-0 flex justify-center items-center ml-2">
              {!customIcon ? (
                  <CurrencyDollarIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <>{customIcon}</>
                )
              }
            </div>
          )}
          <input
            ref={ref}
            type="text"
            className={
              `shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md ${!hideIcon ? "pl-8" : ""}` +
              " " +
              inputClassName
            }
            placeholder={placeholder}
            onChange={(e) => handleChange(e)}
            value={localValue}
            readOnly={readOnly}
            onBlur={() => setErrorMessage(null)}
            disabled={isDisabled}
          />
        </div>
        {errorMessage && showErrors && (
          <div className="text-red-500 italic mt-2">
            {errorMessage}
          </div>
        )}
        {help && <div className="italic text-gray-400 mt-1">{help}</div>}
      </div>
    )
  }
)
