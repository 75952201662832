import axiosInstance from "../helpers/axiosInstance";

export const TRANSACTION_STATUS_IN_PROGRESS = 'IN_PROGRESS'
export const TRANSACTION_STATUS_SUCCESS = 'SUCCESS'
export const TRANSACTION_STATUS_ERROR = 'ERROR'

const DEFAULT_DELAY = 1000;

export const findConcurrentTransaction = async function(transactionId) {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/concurrency/transactions/${transactionId}`)
    return res.data
}

export const validateTransactionStatus = async (transactionId) => {
    console.log('validate transaction id ', transactionId )
    let transaction
    try {
        transaction = await findConcurrentTransaction(transactionId)
    } catch (error) {
        console.log(error)
    }
    if (transaction?.status == TRANSACTION_STATUS_IN_PROGRESS) {
        console.log('transaction status IN_PROGRESS Waiting...')
        await new Promise(r => setTimeout(r, DEFAULT_DELAY))
        console.log('Retrying response...')
        return await validateTransactionStatus(transactionId)
    }
    return transaction?.status
}
