import { createSlice } from '@reduxjs/toolkit'

export const pageSizeSlice = createSlice({
  name: 'pageSize',
  initialState: {
    replenishmentsList: "25",
    replenishmentProductsList: "100",
    warehousesList: "25",
    ordersList: "25",
    processingOrdersList: "25",
    shippedOrdersList: "25",
    returnsList: "25",
    locationsList: "15",
    inventoryHistoryList: "25",
    claimsList: "25",
    operatorsList: "25",
    storesList: "25",
    workOrdersList: "25"
  },
  reducers: {
    setReplenishmentsListPageSize: (state, action) => {
        state.replenishmentsList = action.payload
    },
    setReplenishmentsProductsListPageSize: (state, action) => {
      state.replenishmentProductsList = action.payload
    },
    setWarehousesListPageSize: (state, action) => {
        state.warehousesList = action.payload
    },
    setOrdersListPageSize: (state, action) => {
        state.ordersList = action.payload
    },
    setBillingConfigStoresListPageSize: (state, action) => {
        state.storesList = action.payload
    },
    setProcessingOrdersListPageSize: (state, action) => {
        state.processingOrdersList = action.payload
    },
    setShippedOrdersListPageSize: (state, action) => {
        state.shippedOrdersList = action.payload
    },
    setReturnsListPageSize: (state, action) => {
        state.returnsList = action.payload
    },
    setLocationsListPageSize: (state, action) => {
        state.locationsList = action.payload
    },
    setInventoryHistoryListPageSize: (state, action) => {
        state.inventoryHistoryList = action.payload
    },
    setClaimsPageSize: (state, action) => {
        state.claimsList = action.payload
    },
    setOperatorsPageSize: (state, action) => {
      state.operatorsList = action.payload
    },
    setWorkOrdersPageSize: (state, action) => {
        state.workOrdersList = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  setReplenishmentsListPageSize,
  setReplenishmentsProductsListPageSize,
  setWarehousesListPageSize,
  setOrdersListPageSize,
  setProcessingOrdersListPageSize,
  setShippedOrdersListPageSize,
  setReturnsListPageSize,
  setLocationsListPageSize,
  setInventoryHistoryListPageSize,
  setClaimsPageSize,
  setOperatorsPageSize,
  setBillingConfigStoresListPageSize,
  setWorkOrdersPageSize
 } = pageSizeSlice.actions

export default pageSizeSlice.reducer