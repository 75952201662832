import {useHistory} from "react-router-dom";
import {useRef} from "react";
import {useState, useEffect} from "react";
import {useQuery,useQueryClient} from "react-query";
import { fetchDispatchSchedule } from "../../../services/warehouseSevices";
import { fetchOrderReassingCarrierBulkTracker,  OrderReassingCarrierBulk} from "../../../services/orderServices";
import { TrashIcon } from "../../../components/Icons/TrashIcon"
import { CollapseContainer, InputField, SearchInput, NoticeBanner } from '../../../components'
import {
    ActionMenu,
    ConfirmDialog,
    DialogView,
    FormattedDate,
    NewTable,
    PageTopBar,
    PageView,
    SlidePanel,
    Button,
    Loader
  } from "../../../components";
import { navigation } from "../../../navigation/NavegationRoutes";
export const ReassingCarrier = ({}) => {

    const warehouseId = 1
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [labels, setLabels] = useState([]);
    const [openError, setOpenError] = useState({status: false, text: ''});
    const [showLabelScanned, setShowLabelScanned] = useState(true);
    const [showSchedules, setShowSchedules] = useState(false);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [pickupDate, setPickupDate] = useState(null);
    const [isComplete, setIsComplete] = useState(false)
    const [failures, setFailures] = useState(0)
    const [total, setTotal] = useState(0)
    const [pending, setPending] = useState(0)
    const [hasFetched, setHasFetched] = useState(false);
    const [failuresInfo, setFailuresInfo] = useState([])
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [showFailures, setShowFailures] = useState(false);

    useEffect(() => {
      if (showSchedules && !hasFetched) {
        setHasFetched(true);
      }
    }, [showSchedules, hasFetched]);
  

    const searchinputRef = useRef()

    const { data: dispatchSchedule, isLoading: isLoadingDispatchSchedule, error } = useQuery('dispatchSchedule',() => fetchDispatchSchedule(warehouseId),
        {
          enabled: showSchedules && !showLabelScanned,  // La consulta se habilita solo una vez
        }
      );
    useEffect(() => {
        console.log(dispatchSchedule)
        if (dispatchSchedule) {
            setLoading(false)
        }
    }, [dispatchSchedule])

    const handleSearchChange = async (searchString) => { 
        if (showSchedules) {
            setShowSchedules(false)
            setShowLabelScanned(true)
        }   
        if (searchString === '') return
        if (labels.includes(searchString)) return
        setLabels((prevLabels) => [...prevLabels, searchString]);
        searchinputRef.current?.resetInput()
    }
    
    const handleSelectedSchedule = (schedule) => {
        setSelectedSchedule(schedule)
        setOpenConfirmDialog(true)
    }

    const removeLabel = (labelToRemove) => {
        setLabels((prevLabels) => prevLabels.filter(label => label !== labelToRemove));
    };
    const handleShowSchendules = () => {
        setShowLabelScanned(false)
        setShowSchedules(true)
    }
    const handleConfirmRellocation = async () => {
        try {
        console.log('Confirmar reasignación')
        setConfirmLoading(true)
        let batch = await OrderReassingCarrierBulk({
            "scanned_labels": labels,
            "pickup_date": selectedSchedule,
            "carrier_name": "UBER",
            "delivery_type": "SameDay"
        })
        watchReallocaionProgress(batch.batch_id)
        }catch(e)
        {
            setConfirmLoading(false)
            setOpenConfirmDialog(false)
            alert('Error al reasignar guias a Uber', e)
        }
    }

    const watchReallocaionProgress = function (bulkId) {
        if (isComplete || failures || total || pending)
            return true
      
        const intervalId = setInterval(async () => {
            try {
                const {
                    is_complete,
                    failures: _failures,
                    total: _total,
                    pending: _pending,
                    fail_info: _fail_info
                } = await fetchOrderReassingCarrierBulkTracker(bulkId)
      
                console.log('Reasignación en progreso')
                console.log(is_complete)
                console.log(_failures)
                console.log(_total)
                console.log(_pending)
                console.log(_fail_info)

                setIsComplete(is_complete)
                setFailures(_failures)
                setFailuresInfo(_fail_info)
                setTotal(_total)
                setPending(_pending)
      
                if (isLoading)
                    setIsLoading(false)
      
                if (is_complete) {
                    setConfirmLoading(false)
                    setOpenConfirmDialog(false)
                    console.log('Reasignación completada')
                    console.log(failures)
                    console.log(failuresInfo)
                    if (_failures > 0 && _fail_info.length > 0) {
                        console.log('Mostrar errores')
                        setShowFailures(true)
                    }
                    clearInterval(intervalId);
                }
      
            } catch (e) {
                clearInterval(intervalId);
                console.log(e)
            }
        }, 1000)
      }
    return (
       <>
       <ConfirmDialog
                        open={openConfirmDialog}
                        setOpen={setOpenConfirmDialog}
                        title={"Reasignar guías a Uber"}
                        description={ `¿Deseas reasignar ${labels.length} guías a Uber con horario de recoleción ${selectedSchedule} ?`}
                        confirmLabel="Si"
                        onConfirm={() => {
                            handleConfirmRellocation()
                        }}
                        loading={confirmLoading}
                    />
       <PageView
        topMenu={<PageTopBar>
            <div className="text-lg font-semibold">Reasignación de paqueteria Uber </div>
            
            { isComplete &&
                <Button className="whitespace-nowrap mr-2" onClick={() =>{window.location.reload()}} >
                    Nueva Reasignación
                </Button>
            }
         
      </PageTopBar>}
      >
        {(isComplete ) ? (
            <>
           
                        <section>
                            <CollapseContainer
                                options={{ collapse: false }}
                                headerComponent={
                                    <h2 className="mb-0 text-2xl font-semi-bold">{'Reasignación Finalizada'}{showLabelScanned}</h2>
                                }

                            >
                            <div className="flex-full">
                                <br></br>
                                Se asignaron {total - failures} paquetes a Uber, para la recolección de {selectedSchedule}
                                <br></br>
                                {failures > 0 && <p className='text-red-500'>No se pudieron reasignar <b>{failures} paquetes</b>  </p>}
                            </div>
                           <div className="flex-full">
                                <br></br>
                                {failuresInfo.length > 0 &&
                                    <table className="p-2 w-full text-left">
                                        <thead>
                                            <tr>
                                                <th>{"N° de guia -> Error"}</th>
                                            </tr>
                                        </thead> 

                                        <tbody>
                                            {failuresInfo.map((failure_info) => {
                                                return (
                                                    <tr>
                                                        <td className="text-left p-2 whitespace-nowrap border-t">
                                                            <span className='text-red-800 px-2.5 py-0.5'>
                                                                {failure_info.error_message}
                                                            </span>
                                                        </td>
                                                    </tr>
                                            )})
                                            }    
                                        </tbody>
                                    </table>
                                }
                            </div>

                            </CollapseContainer>
                        </section> 
            </>
        ) : (
            <>
            <section>
                <CollapseContainer
                    options={{ collapse: !showLabelScanned }}
                    headerComponent={
                        showLabelScanned ? <h2 className="mb-0 text-2xl font-semi-bold">{'Escanea las guias de paquetes reasignados a Cuboo que deseas reasignar a Uber'} {showLabelScanned}</h2> : <h2 className="mb-0 text-2xl font-semi-bold">{`Escaneaste ${labels.length} paquetes para reasignar a Uber`} {showLabelScanned}</h2>
                    }
                    footerComponent={
                        showLabelScanned ? 
                        <>
                            <div className="pt-5 pb-5 text-right">
                                <Button className="whitespace-nowrap mr-2" onClick={handleShowSchendules} disabled={labels.length == 0 } >
                                    Continuar para seleccionar horario de recolección 
                                </Button>
                            </div>
                        </>
                        :
                        <>
                        </>
                    }  
                >
                    <div className="flex-full">
                                    <br></br> 
                                    <SearchInput
                                        className="sm:col-span-3"
                                        ref = {searchinputRef}
                                        onChange = {handleSearchChange}
                                        debounce = {500}
                                    ></SearchInput>
                                    <div className='mt-1'>
                                        {/* {openError.status &&
                                            <NoticeBanner text={openError.text} className="mb-2"/>
                                        } */}
                                    </div>

                                    {labels.length > 0 &&
                                        <table className="w-full ">
                                            <thead>
                                                <tr>
                                                    <th> N° de guia</th>
                                                    <th>  </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {labels?.map((label) => {

                                                    return (
                                                        <tr>
                                                            <td className="text-center p-2 whitespace-nowrap border-t">
                                                                <span className='rounded-lg bg-blue-100 text-blue-800 select-none px-2.5 py-0.5'>
                                                                    {label}
                                                                </span>
                                                            </td>

                                                            <td className="text-center p-2 whitespace-nowrap border-t">
                                                            <span onClick={() => removeLabel(label)} className="cursor-pointer text-red-500"> <TrashIcon className="h-4 w-4 text-gray-600" /></span>
                                                            </td>

                                                            
                                                        </tr>
                                                )})}
                                            </tbody>
                                        </table>
                                    }
                                </div>

                </CollapseContainer>
                <br></br>
                <CollapseContainer
                    options={{ collapse: !showSchedules }}
                    headerComponent={
                        <h2 className="mb-0 text-2xl font-semi-bold">{'Selecciona horario de recolección para paquetes scaneados *'}</h2>
                    }
                >
                    <div className="flex-full">
                        {dispatchSchedule && dispatchSchedule.length > 0 &&
                        <>
                            {dispatchSchedule.map((schedule) => {
                                    return (

                                        <div className="flex items-center justify-between p-2 border-b ">
                                            <Button className="whitespace-nowrap mr-1" type="secondary" onClick={() => handleSelectedSchedule(`${schedule.dispatch_date} ${schedule.dispatch_time}`)} >{schedule.dispatch_date} - {schedule.dispatch_time.split("-")[0]} </Button>
                                        </div>
                                        
                                    )
                                })}
                            
                        </>
                        }
                    </div>
                </CollapseContainer>
            </section> 
            </>
        ) 
        }



      </PageView>
      </>
    )
}