import {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"
import { useHistory } from "react-router-dom"

function SlidePanel({ title, children, referrer, onClose=()=>{} }, ref) {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [opacityClass, setOpacityClass] = useState("")

  async function sleep() {
    await new Promise((resolve) => setTimeout(resolve, 100))
  }

  useImperativeHandle(ref, () => ({ closePanel: closePanel }))

  const closePanel = () => {
    handleOnClose(false)
  }

  const handleOnClose = (status) => {
    setOpen(status)
    setOpacityClass("")
    setTimeout(() => {
      if (referrer) {
        history.push(referrer)
      } else {
        history.goBack()
      }
      onClose()
    }, 500)
  }

  useEffect(async () => {
    await sleep()
    setTimeout(() => {
      setOpacityClass("opacity-40")
    }, 10)
    setOpen(true)
  }, [])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden z-10"
        open={open}
        onClose={() => {
          handleOnClose(false)
        }}
        static={true}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay
            className={
              "absolute inset-0 opacity-0 bg-gray-700 transition-opacity " +
              opacityClass
            }
          />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex ">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-5xl ">
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="space-y-3 divide-y divide-gray-200">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          {title}
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => {
                              handleOnClose(false)
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div className="mt-6 relative flex-1 px-4 sm:px-6 ">
                    {/* Replace with your content */}
                    <div className="absolute inset-0 px-4 sm:px-6">
                      <div className="h-full">{children}</div>
                    </div>
                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

SlidePanel = forwardRef(SlidePanel)

export { SlidePanel }
