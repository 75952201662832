import { WAREHOUSE_MULTI_PACKAGE_SHIPPING_METHODS } from './constants'
import { PACKING_OPTIONS_PRIORITIES } from '../RecommendedPackageSection/constants'

export const findMultiPackageShippingMethod = (forWarehouseId, selectOneShippingMethod = true) => {
    if(selectOneShippingMethod){
        return Object.entries(WAREHOUSE_MULTI_PACKAGE_SHIPPING_METHODS).find(([warehouseId, shippingMethod]) => (Number(forWarehouseId) === Number(warehouseId) ))?.[1]
    } else{
        return WAREHOUSE_MULTI_PACKAGE_SHIPPING_METHODS?.[forWarehouseId]
    }
}

export const validateIsMultiPackageShippingMethod = (forWarehouseId, shippingMethod) => {
    const multiPackageShippingMethods = findMultiPackageShippingMethod(forWarehouseId, false)

    return multiPackageShippingMethods?.some((({carrierName, shippingType, deliveryType}) => {
        console.log("carrierName, shippingType, deliveryType", carrierName, shippingType, deliveryType)

        return carrierName === shippingMethod?.carrier_name && shippingType === shippingMethod?.shipping_type && deliveryType === shippingMethod?.delivery_type
    }))
}

export const findShippingMethod = (availableShippingMethods, multiPackageShippingMethod, orderStoreId = null) => {
    if ([1883].includes(orderStoreId)) { // 1883 is the store_id Wine validation
        return availableShippingMethods?.find(({carrier_name, shipping_type, delivery_type}) => (
            carrier_name === "DHL" && shipping_type === multiPackageShippingMethod.shippingType && delivery_type === multiPackageShippingMethod.deliveryType)
        )
    }else{
        return availableShippingMethods?.find(({carrier_name, shipping_type, delivery_type}) => (
            carrier_name === multiPackageShippingMethod.carrierName && shipping_type === multiPackageShippingMethod.shippingType && delivery_type === multiPackageShippingMethod.deliveryType)
        )
    }
}

export const orderShippingPackagesByPriority = (availableShippingPackages, packingOptions) => {
    return availableShippingPackages?.filter(({package_type}) => packingOptions?.includes(package_type)).sort((a,b) => {
        const aIndex = Object.keys(PACKING_OPTIONS_PRIORITIES).findIndex(optionPriority => PACKING_OPTIONS_PRIORITIES[optionPriority] === a.package_type)
        const bIndex = Object.keys(PACKING_OPTIONS_PRIORITIES).findIndex(optionPriority => PACKING_OPTIONS_PRIORITIES[optionPriority] === b.package_type)
        return aIndex - bIndex
    })
}
