import PropTypes from "prop-types";

const getClassNames = (type, className, props) => {
    if (props.disabled) {
        // return `mt-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm ${className}`
        return `cursor-not-allowed inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-100 text-base font-medium text-gray-800 sm:col-start-2 sm:text-sm`
    }
    if(type === "primary"){
        return ` inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm ${className}`
    }
    if(type === "secondary"){
        return `mt-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm ${className}`
    }
    
    return ""
}

export const ButtonLoader = ({type = "primary", loading_text = "Cargando...", loading = false, className="", children, ...props}) => {

    ButtonLoader.propTypes = {
        type: PropTypes.oneOf(['primary', 'secondary'])
    }

    return (
        <button
            className={getClassNames(type, className, props)}
            {...props}
        >
            {loading ?
             <span>{loading_text}</span>
            :
            <>{children}</>
            }
        </button>
    )
}