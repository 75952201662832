export const ClipIcon = ({ className = "" }) => {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={512}
        height={512}
        viewBox="0 0 20.48 20.48"
        xmlSpace="preserve"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        transform="matrix(-6.123233995736766e-17,-1,-1,6.123233995736766e-17,0,0)"
      >
        <path
          fill="currentColor"
          d="M17.542 3.575c-.653-.653-1.512-.98-2.371-.98s-1.718.326-2.371.98l-7.582 7.581a1.998 1.998 0 0 0 2.825 2.825l5.286-5.286-.905-.905-5.286 5.286a.718.718 0 0 1-1.015-1.015l7.582-7.581a2.077 2.077 0 0 1 2.935.003 2.076 2.076 0 0 1 0 2.932l-7.583 7.582a3.437 3.437 0 0 1-4.855-.004 3.434 3.434 0 0 1 0-4.851l6.568-6.567-.905-.905-6.568 6.567a4.716 4.716 0 0 0 .004 6.665 4.714 4.714 0 0 0 6.661 0l7.583-7.582a3.358 3.358 0 0 0-.003-4.745z"
        ></path>
      </svg>
    );
  };
  