export const COLUMN_IDS = {
    FROM: "from",
    TO: "to",
    PRICE: "price",
    UNIT: "unit",
    ACTIONS: "actions"
}

export const UNITS_IDS = {
    PER_UNIT: "PER_UNIT",
    TOTAL: "TOTAL",
    EXCESS_PER_UNIT: "EXCESS_PER_UNIT",
    EXCESS_TOTAL: "EXCESS_TOTAL"
}

export const ACTIONS = {
    DELETE_ROW: "DELETE_ROW"
}

export const ERRORS = {
    MISSING_FROM_VALUE: "MISSING_FROM_VALUE",
    MISSING_TO_VALUE: "MISSING_TO_VALUE",
    MISSING_PRICE_VALUE: "MISSING_PRICE_VALUE",
    MISSING_UNIT_VALUE: "MISSING_UNIT_VALUE",
    FROM_IS_GREATER_THAN_TO: "FROM_IS_GREATER_THAN_TO",
    FROM_AND_TO_ARE_EQUAL: "FROM_AND_TO_ARE_EQUAL",
    EXCESS_IS_ONLY_ALLOWED_AT_END: "EXCESS_IS_ONLY_ALLOWED_AT_END",
    MISSING_PRICE_MODE: "MISSING_PRICE_MODE",
    INVALID_UNIT_VALUE: "INVALID_UNIT_VALUE"
}

export const DEFAULT_AVAILABLE_UNITS_IDS = [
    UNITS_IDS.PER_UNIT,
    UNITS_IDS.TOTAL,
    UNITS_IDS.EXCESS_PER_UNIT,
    UNITS_IDS.EXCESS_TOTAL
]
