import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    textSearch: ""
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setTextSearch: (state, action) => {
            state.textSearch = action.payload
        }
    }
});

export const { setTextSearch } = searchSlice.actions;

export const textSearch = (state) => state.search.textSearch;

export default searchSlice.reducer;