import { CheckCircleTwoTone } from '@ant-design/icons';
import ProductSearch from './ProductSearch';

const ProductListHeader = ({pendingItemsToBeReviewed, productSearch, productSearchRef, onSearchChange = (value) => {}, productSearchDependencies, productSearchAlertMsg}) => {
    return (
        <div className='flex justify-between'>
            <div>
                <div className='flex p-1 gap-2'>
                    <h2 className='mb-0 text-2xl font-bold'>{'Productos requeridos'}</h2>

                    {pendingItemsToBeReviewed === 0 && (
                        <div className='flex flex-col justify-center'>
                            <CheckCircleTwoTone
                                style={{fontSize: '20px'}}
                                twoToneColor="#52c41a"
                            />
                        </div>
                    )}
                </div>

                {pendingItemsToBeReviewed > 0 && <span className='mb-0 text-lg'>{'Escanea todas las piezas del tote'}</span>}
                {pendingItemsToBeReviewed === 0 && <span className='mb-0 text-lg text-green-400 font-medium'>{'¡El pedido está completo!'}</span>}
            </div>

            <ProductSearch
                search={productSearch}
                inputRef={productSearchRef}
                onSearchChange={onSearchChange}
                dependencies={productSearchDependencies}
                alertMsg={productSearchAlertMsg}
            />
        </div>
    )
}

export default ProductListHeader
