import { Tooltip } from "antd"
import { useEffect, useState } from "react"
import { ExclamationIcon } from "../../../components/Icons/ExclamationIcon"
import { TimesCircleIcon } from "../../../components/Icons/TimesCircleIcon"
import { CheckCircleIcon } from "../../../components/Icons/CheckCircleIcon"

const ApiErrorCodes = [
  {
    code: "missing_stock",
    template:
      "Hay productos de este pedido que no han sido asignados. Contacta a soporte técnico. ",
    showForRightColumn: true,
    showForLeftColumn: true,
  },
  {
    code: "excessive_stock",
    template:
      "Hay un exceso de productos asignados al pedido. Contacta a soporte técnico. ",
    showForRightColumn: true,
    showForLeftColumn: true,
  },
  {
    code: "invalid_order_status",
    template:
      "Este pedido no se encuentra en estado 'Pendiente'. Es posible que ya haya sido asignado. ",
    showForRightColumn: true,
    showForLeftColumn: true,
  },
  {
    code: "sxkm_order",
    template: "Este pedido es del cliente SXKM. No debe realizar picking. ",
    showForRightColumn: true,
    showForLeftColumn: true,
  },
  {
    code: "not_apt_for_single_item_picking",
    template: "Este pedido no cumple los requisitos para poder ser agrupado. ",
    showForRightColumn: true,
    showForLeftColumn: false,
  },
  {
    code: "single_item_picking_conflict",
    replaceString: "%CONFLICTING_ORDER",
    template:
      "Este pedido tiene conflictos de SKU o UPC con el pedido #%CONFLICTING_ORDER. No es posible agrupar el pedido en esta sesión de picking. ",
    showForRightColumn: true,
    showForLeftColumn: false,
  },
]

export const showErrorForColumnDirection = (errorCode, direction) => {
  let errorDef = getErrorDefinition(errorCode)
  if (direction === 'left') {
    return errorDef.showForLeftColumn
  }
    return errorDef.showForRightColumn
}

const getErrorDefinition = (errorCode) => {
  let errorDef = ApiErrorCodes.find(
    (errorCodeDef) => errorCodeDef.code === errorCode
  )
  if (!errorDef) {
    throw `getErrorDefinition(): unexpected error code "${errorCode}"`
  }
  return errorDef
}

const getLabelForErrors = (errors) => {
  // console.log("errors: ", errors)
  let errorString = ""
  errors.forEach((error) => {
    let errorDef = getErrorDefinition(error.error_type) 
    if (errorDef.replaceString) {
      let conflictingOrderNumbers = []
      error.conflicting_orders.forEach((conflictingOrder) => {
        conflictingOrderNumbers.push(conflictingOrder.order_number)
      })
      errorString += errorDef.template.replace(
        errorDef.replaceString,
        conflictingOrderNumbers.join(", ")
      )
    } else {
      errorString += errorDef.template
    }
  })
  return errorString
}

export const AssignOrdersGroupableIndicator = ({
  groupable = null,
  errors = [],
}) => {
  const [errorTooltipLabel, setErrorTooltipLabel] = useState(null)

  useEffect(() => {
    setErrorTooltipLabel(getLabelForErrors(errors))
  }, [errors])

  const ErrorTooltip = ({ children }) => {
    return (
      <>
        {errorTooltipLabel !== null ? (
          <Tooltip title={errorTooltipLabel}>{children}</Tooltip>
        ) : (
          children
        )}
      </>
    )
  }

  return (
    <ErrorTooltip>
      <div className="text-center w-100">
        {groupable === null ? (
          <></>
        ) : groupable === true ? (
          <CheckCircleIcon className="h-4 w-4 inline text-green-700" />
        ) : (
          <TimesCircleIcon className="h-4 w-4 inline text-yellow-500" />
        )}
        {errors?.length > 0 && (
          <ExclamationIcon className="w-4 h-4 text-red-700 inline" />
        )}
      </div>
    </ErrorTooltip>
  )
}
