import { Fragment, useContext, useEffect, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Button, Loader, SearchInput } from "../../components"
import { getOperatorUsers } from "../../services/userService"
import { useQuery } from "react-query"
import { UserBadgeIcon } from "../../components/Icons/UserBadgeIcon"
import { createPickingOrder, reassignPickingOrder } from "../../services"
import { Pagination, Table, Tabs } from "antd"
import { LayeredGroupIcon } from "../../components/Icons/LayeredGroupIcon"
import { TableTransfer } from "../../components/TableTransfer"
import { XIcon } from "@heroicons/react/solid"
import { ordersLeftTableColumns, ordersRightTableColumns, pickerColumns } from "./AssignPickingOrders/AssignOrdersColumnConfigs"
import { showErrorForColumnDirection } from "./AssignPickingOrders/AssignOrdersGroupableIndicator"

export const AssignOrdersDialog = ({
  open,
  orders = [],
  changingPicker = false,
  onSuccess = () => {},
  onCancel = () => {},
}) => {

  const { TabPane } = Tabs

  const [searchParams, setSearchParams] = useState({
    search: "",
    page: 1,
    per_page: 10
  })

  const {
    isLoading,
    isError,
    error,
    data: pickerRawData,
    isFetching,
    isPreviousData,
    refetch,
  } = useQuery(
    ["operator_users", searchParams],
    () => getOperatorUsers(searchParams),
    { keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime:5000
    }
  )

  const [pickerData, setPickerData] = useState([])

  useEffect(() => {
    let pickerData = pickerRawData?.users.map(picker => {
      // console.log("picker: ", picker)
      return {
        key: picker.id.toString(),
        ...picker
      }
    })
    setPickerData(pickerData)
  }, [pickerRawData])

  // reset all when modal is opened
  useEffect(() => {
    if (open) {
      refetch()
      resetSelectedUser()
      let newOrdersTableData = mapOrdersAsTableData(orders)
      setActiveTab('select_picker')
      setTargetKeys(filterAptForSip(orders))
      setOrdersTableData(newOrdersTableData)
    }
  }, [open])

  const resetSelectedUser = () => {
    setSelectedUser(null)
    setSelectedUserKeys([])
  }

  const onSearchHandler = (v) => {
    resetSelectedUser()
    setSearchParams({ ...searchParams, search: v, page: 1 })
  }

  const onClickCancel = () => {
    resetSelectedUser()
    setSearchParams({ ...searchParams, search: "", page: 1 })
    onCancel()
  }

  const [busy, setBusy] = useState(false)

  const onSubmit = async () => {
    setBusy(true)
    if(!changingPicker){
      if (!selectedUser || orders.length < 1) {
        window.alert("missing data")
        return
      }
      let submitData = { assigned_to_id: selectedUser.id, orders: [] }
      submitData.orders = ordersTableData.map((order) => {
        let forSip = targetKeys.findIndex(key => order.id.toString() === key) === -1 ? false : true
        return { 
          id: order.id,
          single_item_picking: forSip
        }
      })
      try {
        await createPickingOrder(submitData)
      } catch (error) {
        setOrdersHaveBeenDeleted(false)
        if (error.response?.data?.error?.errors?.length > 0) {
          // console.log(error.response?.data?.error?.errors)
          setReturnedOrderErrors(error.response.data.error.errors)
        }
        else if (error.response?.data?.error?.message) {
          window.alert(error.response?.data?.error?.message)
          console.log(error.response?.data?.error?.message)
        }
        else {
          window.alert(error.response?.error)
          console.log(error.response?.error)
        }
        setBusy(false)
        return
      }
    }else{
      if (!selectedUser) {
        window.alert("missing data")
        return
      }
      let submitData = { assigned_to_id: selectedUser.id, picking_order_id: orders.map(x => x.picking_order.id) }
      try {
        await reassignPickingOrder(submitData)
      } catch (error) {
        setOrdersHaveBeenDeleted(false)
        if (error.response?.data?.error?.errors?.length > 0) {
          // console.log(error.response?.data?.error?.errors)
          setReturnedOrderErrors(error.response.data.error.errors)
        }
        else if (error.response?.data?.error) {
          window.alert(error.response?.data?.error)
          console.log(error.response?.data?.error)
        }
        else {
          window.alert(error.response?.error)
          console.log(error.response?.error)
        }
        setBusy(false)
        return
      }
    }
    setBusy(false)
    onSuccess()
  }

  const [returnedOrderErrors, setReturnedOrderErrors] = useState([])

  // update order table data with errors returned by api
  useEffect(() => {
    let newOrdersTableData = ordersTableData.map(order => {
      let orderErrors = returnedOrderErrors.filter(error => error.order.id === order.id)
      return {
        ...order,
        errors: orderErrors
      }
    })
    // console.log("newOrdersTableData: ", newOrdersTableData)
    setOrdersTableData(newOrdersTableData)
  }, [returnedOrderErrors])

  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedUserKeys, setSelectedUserKeys] = useState([])

  const [activeTab, setActiveTab] = useState('select_picker')

  const TabItem = ({label, icon}) => {
    return (
      <span>{icon}{label}</span>
    )
  }

  const [ordersTableData, setOrdersTableData] = useState([])
  const [targetKeys, setTargetKeys] = useState([])
  const [orderToDelete, setOrderToDelete] = useState(null)
  const [ordersTableSelectedKeys, setOrdersTableSelectedKeys] = useState([])
  const [ordersHaveBeenDeleted, setOrdersHaveBeenDeleted] = useState(false)

  const mapOrdersAsTableData = (orders = []) => {
    let ordersTableData = []
    orders.forEach(order => {
      let foundIndex = ordersTableData.findIndex(orderTableData => orderTableData.id === order.entity.id)
      if (foundIndex === -1) {
        ordersTableData.push({
          ...order.entity,
          key: order.entity.id.toString(),
          disabled: !order.entity.apt_for_single_item_picking,
          deleteCallback: (orderId) => { setOrderToDelete(orderId) }
        })
      }
    })
    return ordersTableData
  }

  // delete order from table data
  useEffect(() => {
    if (orderToDelete !== null) {
      let newOrdersTableData = ordersTableData.filter(order => order.id != orderToDelete)
      // console.log("newOrdersTableData: ", newOrdersTableData)
      setOrdersTableData(newOrdersTableData)
      // unselect if selected
      setOrdersTableSelectedKeys(ordersTableSelectedKeys.filter(key => key !== orderToDelete.toString()))
      setOrderToDelete(null)
      setOrdersHaveBeenDeleted(true)
      // console.log("ordersTableSelectedKeys", ordersTableSelectedKeys)
    }
  }, [orderToDelete])

  const filterAptForSip = (orders) => {
    return orders.filter(order => order.entity.apt_for_single_item_picking).map(order => order.entity.id.toString())
  }

  const onTransferChange = (newTargetKeys) => {
    // console.log("newTargetKeys: ", newTargetKeys)
    setTargetKeys(newTargetKeys)
  }

  const onPickerPageChange = (page) => {
    resetSelectedUser()
    setSearchParams({...searchParams, page: page})
  }

  const sipTabDisabled = () => {
    if (selectedUser !== null) {
      return false
    }
    return true
  }

  const getClassForRow = (record, index, direction) => {
    let rowClass = ''
    if (orderHasVisibleErrors(record)) {
      rowClass = 'bg-red-200 text-red-500'
    }
    return rowClass
  }

  const orderHasVisibleErrors = (order) => {
    let errorIsVisible = false
    if (order.errors?.length > 0) {
      let direction = targetKeys.includes(order.id.toString()) ? 'right' : 'left'
      order.errors?.forEach(error => {
        if (showErrorForColumnDirection(error.error_type, direction)) {
          errorIsVisible = true
        }
      })
    }
    return errorIsVisible
  }

  const getOrdersWithVisibleErrors = (direction = null) => {
    return ordersTableData.filter(order => {
      let orderDirection = targetKeys.includes(order.id.toString()) ? 'right' : 'left'
      if (orderHasVisibleErrors(order) && (direction === null || direction === orderDirection)) {
        return true
      }
      return false
    })
  }

  const getTransferTableSelectLabel = (direction, selectedCount, totalCount) => {
    let directionVisibleErrors = getOrdersWithVisibleErrors(direction)
    return (
      <>
        {direction === 'left' ? 
          <span>{selectedCount}/{totalCount} Pedidos {directionVisibleErrors.length > 0 && <span className="text-red-500">{directionVisibleErrors.length} Conflicto{directionVisibleErrors.length > 1 ? 's' : ''}</span>}</span>
        :
          <span>{selectedCount}/{totalCount} Pedidos Agrupados {directionVisibleErrors.length > 0 && <span className="text-red-500">{directionVisibleErrors.length} Conflicto{directionVisibleErrors.length > 1 ? 's' : ''}</span>}</span>
        }
      </>
    )
  }

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => {
          // do nothing here, avoid closing on dismiss
          // onClickCancel()
        }}
        
      >
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative bg-white rounded-lg shadow-xl sm:max-w-5xl sm:w-full p-6 text-gray-600 my-3 overflow-hidden max-h-[length:90vh]">
              <Loader show={busy}/>
              <div className="absolute top-0 right-0 p-5 cursor-pointer" onClick={() => { onClickCancel() }}><XIcon className="h-5 w-5"/></div>
              <Dialog.Title className="text-lg text-center" >Asignar Picking de Pedidos</Dialog.Title>
              <Tabs activeKey={activeTab} centered onChange={(key) => {setActiveTab(key)}} >
                <TabPane tab={<TabItem label="Seleccionar Operador" icon={<UserBadgeIcon className="inline h-4 w-4 mr-2"/>}/>} key="select_picker">
                  <div className="my-2 mx-2">
                    <SearchInput
                      onChange={(v) => {
                        onSearchHandler(v)
                      }}
                      debounce={250}
                      placeholder="Buscar Operador"
                      className="focus-within:text-gray-600 text-gray-400 "
                    />
                  </div>
                  <div className="relative">
                    <Loader show={isFetching || isLoading}/>
                    <Table
                      rowSelection={{
                        type: 'radio',
                        onChange: (selectedRowKeys, selectedRows) => { 
                          setSelectedUser(selectedRows[0]) 
                          console.log("selectedRowKeys: ", selectedRowKeys)
                            setSelectedUserKeys(selectedRowKeys)
                        },
                        selectedRowKeys: selectedUserKeys
                      }}
                      size="small"
                      columns={pickerColumns}
                      dataSource={pickerData}
                      pagination={false}
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: event => {
                            setSelectedUser(record)
                            setSelectedUserKeys([record.id.toString()])
                          }, // click row
                        }
                      }}
                    />
                    <div className="flex justify-center mt-2 pb-2">
                      <Pagination
                        size="small"
                        total={pickerRawData?.meta.total_count}
                        showTotal={(total) => <span>{total} Operadores</span>}
                        current={searchParams.page}
                        onChange={(page) => onPickerPageChange(page)}
                        showSizeChanger={false}
                      />
                    </div>
                  </div>
                  <div className="mt-1 flex justify-between">
                    <Button className="m-1 sm:mt-1" type="secondary" onClick={() => { onClickCancel() }}>Cancelar</Button>
                    <Button className="m-1" disabled={sipTabDisabled()} type="primary" onClick={() => { setActiveTab('select_sips') }}>{selectedUser === null ? 'Elige un operador' : 'Continuar'}</Button>
                  </div>
                </TabPane>
                <TabPane tab={<TabItem label="Agrupar Pedidos" icon={<LayeredGroupIcon className="inline h-4 w-4 mr-2"/>}/>} key="select_sips" disabled={sipTabDisabled()}>
                  <TableTransfer
                    dataSource={ordersTableData}
                    targetKeys={targetKeys}
                    showSelectAll={false}
                    disabled={busy}
                    titles={['Totes Independientes', 'Carrito Agrupado']}
                    selectAllLabels={[
                      ({ selectedCount, totalCount }) => getTransferTableSelectLabel('left', selectedCount, totalCount), 
                      ({ selectedCount, totalCount }) => getTransferTableSelectLabel('right', selectedCount, totalCount)
                    ]}
                    onChange={(targetKeys, direction, moveKeys) => {onTransferChange(targetKeys)}}
                    leftColumns={ordersLeftTableColumns}
                    rightColumns={ordersRightTableColumns}
                    selectedKeys={ordersTableSelectedKeys}
                    onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => setOrdersTableSelectedKeys(sourceSelectedKeys.concat(targetSelectedKeys))}
                    tableRowClassName={getClassForRow}
                    empty={['Ningún tote independiente', 'Ningún Pedido Agrupado']}
                    tableClassName={'max-h-60vh overflow-y-auto'}
                  />
                  { getOrdersWithVisibleErrors().length > 0 &&
                    <div className="mt-1 text-red-500 text-right">
                      <span>Resuelve {getOrdersWithVisibleErrors().length} Conflicto{getOrdersWithVisibleErrors().length > 1 ? 's' : ''}</span>
                    </div>
                  }
                  <div className="mt-1 flex justify-between">
                    <Button className="m-1 sm:mt-1" type="secondary" onClick={() => { setActiveTab('select_picker') }}>Regresar</Button>
                    <Button className="m-1" disabled={getOrdersWithVisibleErrors().length > 0 && !ordersHaveBeenDeleted} type="primary" onClick={() => {onSubmit()}} loading={busy}>Confirmar {ordersTableData.length} Pedidos</Button>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
