export const FormattedDate = ({date, shortDate = false}) => {

    const getShortDate = (value) => {
        let date = new Date(value)
        return date.toLocaleString([], {year: "2-digit",
        month: "numeric",
        day: "2-digit", hour: '2-digit', minute:'2-digit'})
    }
    return (
        <>
            { date === null ? '-' :
                shortDate ? 
                    getShortDate(date)
                :
                new Intl.DateTimeFormat("es", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                    hour: 'numeric',
                    minute: 'numeric'
                }).format(new Date(date))
            }
        </>
    )
}