import AttachmentsTable from "./AttachmentsTable";

const AttachmentsListSection = ({shippingDocuments, onPrintDocument, isHidden=false}) => {
    if(isHidden) return (<></>)

    return (
        <section className="bg-white rounded-lg flex flex-col gap-4 pb-2">
            <div className="m-4">
                <h2 className="mb-0 text-2xl font-bold">{`Documentos adjuntos`}</h2>
                <p className="mb-0 text-lg">
                    {'Recuerda que los documentos se imprimirán el generar la guía, '}
                    <span className="font-bold">{'no hace falta imprimirlas manual'}</span>
                </p>
            </div>

            <div className='max-h-40 overflow-y-auto'>
                <AttachmentsTable
                    shippingDocuments={shippingDocuments}
                    onPrintDocument={onPrintDocument}
                />
            </div>
        </section>
    )
}

export default AttachmentsListSection