import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/outline"
import { Button } from "./Button";

export const DropdownMenu = ({ options, selected, element, action, isSuperAdminOrAdmin }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [initialState, setInitialState] = useState(selected);

  const handleDropdownToggle = () => {
    setShowDropdown((prevShowDropdown) => !prevShowDropdown);
  };

  const handleConfirmButton = (event) => {
    action(element, initialState);
    setShowDropdown(false);
  };

  const handleCancelButton = (event) => {
    setInitialState(selected);
    setShowDropdown(false);
  };

  const handleOptionClick = (option) => {
    if (initialState.includes(option.role)) {
      setInitialState((prevInitialState) =>
        prevInitialState.filter((role) => role !== option.role)
      );
    } else {
      setInitialState((prevInitialState) => [...prevInitialState, option.role]);
    }
  };

  useEffect(() => {
    setInitialState(selected);
  }, [selected]);

  return (
    <div className="relative inline-block">
      <button
        className={!isSuperAdminOrAdmin ? "flex items-center text-black-500 focus:outline-none" : "flex items-center text-gray-300 focus:outline-none"}
        onClick={handleDropdownToggle}
      >
        {initialState.length === 1
          ? `${initialState.length} rol asignado`
          : `${initialState.length} roles asignados`}
        {
          !isSuperAdminOrAdmin && (
            <svg
              className={`ml-2 w-5 h-5`}
              fill="none"
              stroke={"currentColor"}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          )
        }

      </button>
      {showDropdown && !isSuperAdminOrAdmin && (
        <div className="absolute left-0 right-auto mt-2 border rounded-lg shadow-lg z-50" style={{ width: "18vw", minWidth: "18vw", background: "#f1f5f9" }}>
        <ul>
          {options.map((option, index) => (
            <li key={index} className={`mt-1 ml-1 mr-1 rounded-lg ${option.role === "ROLE_OPERATOR" ? "bg-gray-500" : initialState.includes(option.role) ? "bg-blue-600" : "bg-white"}`}>
              <a
                className={`block text-sm ${initialState.includes(option.role) ? "text-white" : "text-black"} hover:no-underline p-2`}
                href="#"
                onClick={() => option.role !== "ROLE_OPERATOR" && handleOptionClick(option)}
              >
                <span>
                  <div className="max-w-40 whitespace-normal inline" style={{ color: initialState.includes(option.role) ? "white" : "black", fontWeight: "600" }}>
                    {option.name}
                  </div>
                  <Tooltip placement="bottomLeft" className="max-w-40 whitespace-normal text-justify inline" style={{ color: initialState.includes(option.role) ? "white" : "black" }} title={option.description}>
                    <InformationCircleIcon className="ml-2 mb-0.5 w-4 h-4 inline text-gray-500" style={{ color: initialState.includes(option.role) ? "white" : "black" }} />
                  </Tooltip>
                </span>
              </a>
            </li>
          ))}
        </ul>
          <div className="flex justify-end pr-2 mb-3 mr-1">
            <Button
              type="secondary"
              className="mr-2 px-1 py-1 text-gray-700 hover:text-gray-900"
              onClick={handleCancelButton}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              className="px-1 py-1 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
              onClick={handleConfirmButton}
            >
              Confirmar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
