
import { TrashIcon } from "../../../components/Icons/TrashIcon"
import { AssignOrdersGroupableIndicator } from "./AssignOrdersGroupableIndicator"

const truncateWithEllipsis = (string, maxLength) => {
  if (string.length <= maxLength) {
    return string
  }
  let truncatedString = string.slice(0, (maxLength))
  truncatedString += "..."
  return truncatedString
}

export const ordersLeftTableColumns = [
  {
    dataIndex: "store",
    title: "Tienda",
    render: (store) => <span title={store.name}>{truncateWithEllipsis(store.name, 10)}</span>,
  },
  {
    dataIndex: "order_number",
    title: "Núm de Pedido",
    render: (order_number) => <>#{order_number}</>,
  },
  {
    dataIndex: "apt_for_single_item_picking",
    title: "Agrupable",
    className: "w-0",
    render: (sip, object) => (
      <AssignOrdersGroupableIndicator groupable={sip} errors={object.errors}/>
    ),
  },
  {
    dataIndex: "deleteCallback",
    title: "",
    className: "w-0",
    render: (deleteCallback = () => {}, object) => (
      <div onClick={() => {deleteCallback(object.id)}} className="text-center w-100 cursor-pointer rounded-md hover:bg-gray-200 p-1">
        <TrashIcon className="w-4 h-4 text-red-700 inline" />
      </div>
    ),
  },
]

export const ordersRightTableColumns = [
  {
    dataIndex: "store",
    title: "Tienda",
    render: (store) => <span title={store.name}>{truncateWithEllipsis(store.name, 10)}</span>,
  },
  {
    dataIndex: "order_number",
    title: "Núm de Pedido",
    render: (order_number) => <>#{order_number}</>,
  },
  {
    dataIndex: "id",
    title: "",
    render: (id, object) => (
      <AssignOrdersGroupableIndicator groupable={null} errors={object.errors}/>
    ),
  },
  {
    dataIndex: "deleteCallback",
    title: "",
    className: "w-0",
    render: (deleteCallback = () => {}, object) => (
      <div onClick={() => {deleteCallback(object.id)}} className="text-center w-100 cursor-pointer rounded-md hover:bg-gray-200 p-1">
        <TrashIcon className="w-4 h-4 text-red-700 inline" />
      </div>
    ),
  },
]

export const pickerColumns = [
  {
    title: "Nombre Completo",
    dataIndex: "first_name",
    render: (firstName, object) => (
      <>
        {firstName} {object.last_name}
      </>
    ),
  },
  {
    title: "Pedidos en pickings encolados",
    children: [
      {
        dataIndex: "new_picking_orders",
        title: "Independientes",
        render: (incomplete_picking_orders) => (
          <>{incomplete_picking_orders.filter(po => po.single_item_picking === false).length}</>
        ),
      },
      {
        dataIndex: "new_picking_orders",
        title: "Agrupados",
        render: (incomplete_picking_orders) => (
          <>{incomplete_picking_orders.filter(po => po.single_item_picking === true).length}</>
        ),
      },
    ],
  },
  {
    title: "Pedidos en picking en curso",
    children: [
      {
        dataIndex: "incomplete_picking_orders",
        title: "Independientes",
        render: (incomplete_picking_orders) => (
          <>{incomplete_picking_orders.filter(po => po.single_item_picking === false).length}</>
        ),
      },
      {
        dataIndex: "incomplete_picking_orders",
        title: "Agrupados",
        render: (incomplete_picking_orders) => (
          <>{incomplete_picking_orders.filter(po => po.single_item_picking === true).length}</>
        ),
      },
    ],
  },
]
