import React from "react";

export const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, disabled, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        const getStyleClass = () => {
            let styleClass = "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            if (disabled) {
                styleClass += " opacity-30"
            }
            return styleClass
        }

        return (

            <div className="flex items-center h-5">
                <input
                    type="checkbox"
                    className={getStyleClass()}
                    ref={resolvedRef}
                    disabled={disabled}
                    {...rest}
                />
            </div>

        )
    }
)