import { useMemo, useState } from "react"

const CopyAddress = ({shippingAddress}) => {
    const [copied, setCopied] = useState(false)

    const fullShippingAddress = useMemo(() => {
        let fullShippingAddress = ""

        fullShippingAddress = shippingAddress.address ? shippingAddress.address : ''
        fullShippingAddress += shippingAddress.address2 ? ' ' + shippingAddress.address2 : ''
        fullShippingAddress += shippingAddress.city ? ' ' + shippingAddress.city : ''
        fullShippingAddress += shippingAddress.province ? ' ' + shippingAddress.province : ''
        fullShippingAddress += shippingAddress.country?.label ? ' ' + shippingAddress.country?.label : ''
        fullShippingAddress += shippingAddress.zipCode ? ' ' + shippingAddress.zipCode : ''

        return fullShippingAddress
    }, [shippingAddress])

    const handleCopyShippingAddress = () => {
        if(copied){
            return
        }

        navigator.clipboard.writeText(fullShippingAddress)
        setCopied(true)

        setTimeout(() => {
            setCopied(false)
        }, 2000)
    }

    return (
        <div className="flex justify-end">
            <span className={`text-blue-500 ${!copied ? "cursor-pointer hover:text-blue-400" : "cursor-default"}`} onClick={handleCopyShippingAddress}>
                { copied ? "Dirección copiada!" : "Copiar dirección completa"}
            </span>
        </div>
    )
}

export default CopyAddress