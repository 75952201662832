import { Transfer, Table } from "antd"
import difference from "lodash/difference"

export const TableTransfer = ({
  leftColumns,
  rightColumns,
  tableRowClassName = (record, index, direction) => "",
  empty = ['Sin Filas', 'Sin Filas'],
  // tableScroll = {},
  tableClassName = '',
  ...restProps
}) => (
  <Transfer {...restProps}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === "left" ? leftColumns : rightColumns

      const rowSelection = {
        getCheckboxProps: (item) => ({
          disabled: listDisabled || item.disabled,
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key)
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys)
          onItemSelectAll(diffKeys, selected)
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected)
        },
        selectedRowKeys: listSelectedKeys,
      }

      return (
        <Table
          pagination={false}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{ pointerEvents: listDisabled ? "none" : null }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return
              onItemSelect(key, !listSelectedKeys.includes(key))
            },
          })}
          rowClassName={(record, index) =>
            tableRowClassName(record, index, direction)
          }
          locale={{ emptyText: direction === 'left' ? empty[0] : empty[1] }}
          // scroll={tableScroll}
          // components= {{
          //   body: {
          //     wrapper: (props) => <tbody {...props}></tbody>
          //   }
          // }}
          className={tableClassName}
        />
      )
    }}
  </Transfer>
)
