import {useState, useCallback, useRef,useContext, useMemo} from 'react';
import {Route, Switch, useHistory, Link, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {fetchProcessingOrders} from "../../services";
import { fetchWarehouseShippingMethods } from '../../services/warehouseSevices';
import {useDispatch, useSelector} from "react-redux";
import {UserContext} from "../../hooks/UserContext";
import {
    ActionMenu,
    Button,
    ConfirmDialog,
    FormattedDate, Notification,
    PageTopBar,
    PageView, SlidePanel,
} from "../../components"
import { BasicTable } from "./../../components/BasicTable/BasicTable"
import {PROCESSING_GENERATE_LABEL, ORDERS_PROCESSING, PROCESSING_ORDER_DETAIL, GENERATE_LABELS, GENERATE_ORDER_LABEL, GENERATE_SHARED_LABEL} from "../../navigation/constants"
import { StatusPill } from '../../components/BasicTable/StatusPill'
import { ORDER_STATUSES_PROCESSING } from '../../components/BasicTable/StatusPillDefinitions';
import { Loader } from '../../components/Loader';
import {ShowOrderContainer} from "./ShowOrderContainer";
import { DateTimeFilter, DateTimeRangeFilter, SelectColumnFilter, SelectColumnMultiFilter } from '../../components/BasicTable/modules/ColumnFiltersUI';
import { pickAllOrderStocks } from '../../services/orderServices';
import { AssignOrdersDialog } from './AssignOrdersDialog';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { Tooltip } from 'antd';
import { setProcessingOrdersListPageSize } from '../../redux/pageSizeSlice';
import { FormattedRelativeTime } from '../../components/FormattedRelativeTime';
const grouped_orders_default_warehouse = 100 // default only for warehouse 4 in Mexico


const table_columns = [
    {
        Header: 'Tienda',
        accessor: 'store',
        disableFilters: false,
        disableSortBy: false
    },
    {
        Header: 'Nº DE ORDEN',
        accessor: 'order_number', // accessor is the "key" in the data
        disableFilters: false,
        disableSortBy: false,
    },
    {
        Header: 'Empacar antes de',
        accessor: 'max_packing_time',
        Filter: DateTimeFilter,
        disableFilters: false,
        disableSortBy: false,
        shrinkToContent: true
    },
    {
        Header: 'Canal',
        accessor: 'channel_name', 
        disableFilters: false,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        selectFilterOptions: ['manual', 'shopee', 'meli', 'liverpool', 'walmart', 'vtex'].map((channel_type)=>{
            
            return {
                id: channel_type,
                label: channel_type
            }
        }),
    },
    {
        Header: 'TOTE',
        accessor: 'tote', // accessor is the "key" in the data
        disableFilters: false,
        disableSortBy: true,
        searchAs: 'tote_search',
    },
    {
        Header: 'Picker',
        accessor: 'assigned_to', // accessor is the "key" in the data
        disableFilters: false,
        disableSortBy: true,
    },
    // {
    //     Header: 'REFERENCIA',
    //     accessor: '',
    //     disableFilters: true,
    //     disableSortBy: true
    // },
    {
        Header: 'Estado',
        accessor: 'status', // accessor is the "key" in the data
        Filter: SelectColumnMultiFilter,
        selectFilterOptions: ORDER_STATUSES_PROCESSING.filter((status)=>status.filterable !== false).map((status)=> {
            return status.filterable === false ? null : {id: status.status, label: status.label}
        }),
        disableFilters: false,
        disableSortBy: false,
    },
    // {
    //     Header: 'DESTINO (e-mail)',
    //     accessor: 'email',
    //     disableFilters: false,
    //     disableSortBy: true,
    // },
    // {
    //     Header: 'Fecha de Creación',
    //     accessor: 'channel_created_at', // accessor is the "key" in the data
    //     Filter: DateTimeRangeFilter,
    //     disableFilters: false,
    //     disableSortBy: false,
    //     shrinkToContent: true
    // },
    /*{
        Header: 'En pendiente desde',
        accessor: 'pending_at', // accessor is the "key" in the data
        Filter: DateTimeRangeFilter,
        disableFilters: false,
        disableSortBy: false,
        shrinkToContent: true
    },*/
    {
        Header: "",
        accessor: 'actions', // accessor is the "key" in the data
        disableFilters: true,
        disableSortBy: true,
        fetchingIndicator : true,
        shrinkToContent: true
    },
]





export const ListProcessingOrders = () => {
    const history = useHistory()
    const {user} = useContext(UserContext)
    const {warehouse_id} = useParams()
    const [showPickAllSuccess, setShowPickAllSuccess] = useState(false)
    const [showPickAllConfirmation, setShowPickAllConfirmation] = useState(false)
    const [pickAllStockOrder, setPickAllStockOrder] = useState(null)
    const [loadingPickAllStock, setLoadingPickAllStock] = useState(null)

    const dispatch = useDispatch()
    const pageSize = useSelector((state) => state.pageSize.processingOrdersList)
    const defaultSearchParams = {
        page: 1,
        per_page: pageSize,
        status: ['processing', 'picking', 'packing'],
        order_by: 'max_packing_time',
        order: 'ASC',
        warehouse_id: isNaN(parseInt(warehouse_id)) ? null : parseInt(warehouse_id),
        pick_n_ship: false
    }

    const [searchParams, setSearchParams] = useState({...defaultSearchParams,  warehouse_id: warehouse_id})

    const additionalParams = {
        include_inactive: true
    }
    
    const {
        data: shippingMethodsData, 
        isLoading: isLoadingShippingMethods ,
        isError: isErrorShippingMethods,
        error: errorShippingMethods,
        isFetching: isFetchingShippingMethods,
        isPreviousData: isPreviousDataShippingMethods,
        refetch: refetchShippingMethods
    } = useQuery(['shipping_methods', warehouse_id], () => fetchWarehouseShippingMethods(warehouse_id, additionalParams), { 
        keepPreviousData : true,
        refetchOnWindowFocus: false
    })

    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,
        refetch,
    } = useQuery(['processing_orders', searchParams], () => fetchProcessingOrders(searchParams), { keepPreviousData : true })

    const handlerOnClickStatus = (order) => {
        // setOrderClicked(order)
        // setOpenDialogErrors(!openDialogErrors)
        history.push(PROCESSING_ORDER_DETAIL.replace(':id', order.id).replace(':warehouse_id', warehouse_id))
    }

    const onSortChange = (orderBy) => {
        let newSearchParams = {...searchParams}
        tableRef.current.resetSelection()
        tableRef.current.resetPagination()
        if (orderBy.length > 0) {
            setSearchParams({
                ...newSearchParams,
                order_by: orderBy[0].id, 
                order: orderBy[0].desc ? 'DESC' : 'ASC'
            })
        }
        else {
            if ('order_by' in newSearchParams) {
                newSearchParams.order_by = defaultSearchParams.order_by
                newSearchParams.order = defaultSearchParams.order
                setSearchParams(newSearchParams)
            }
        }
    }

    const onFilterChange = (activeFilters) => {
        let newSearchParams = {...searchParams}
        memoizedColumns.forEach(col => {
            if (newSearchParams[col.searchAs || col.accessor]) {
                delete newSearchParams[col.searchAs || col.accessor]
            }
        })
        let filters = {}
        activeFilters.forEach(filter => {
            let column = memoizedColumns.find((col) => col.accessor === filter.id)
            filters[column.searchAs || column.accessor] = filter.value
        })
        if (!('status' in filters) || filters.status.length === 0) {
            filters.status = []
            filters.status.push(...defaultSearchParams.status)
        }
        tableRef.current.resetPagination()
        setSearchParams({...newSearchParams, ...filters, page:1})
    }

    const handlePickAllStock = (order) => {
        setPickAllStockOrder(order)
        setShowPickAllConfirmation(true)
    }

    const onCancelPickAllStock = () => {
        setPickAllStockOrder(null)
        setShowPickAllConfirmation(false)
    }

    const onConfirmPickAllStock = async (order) => {
        setLoadingPickAllStock(true)
        try {
            await pickAllOrderStocks(order.id)
            await refetch()
            setPickAllStockOrder(null)
            setShowPickAllConfirmation(false)
            setShowPickAllSuccess(true)
        } catch (error) {
            window.alert(error.response.data?.error)
            console.log(error)
            console.log(error.response.data?.error)
        }
        setLoadingPickAllStock(false)
    }

    const getActions = (order) => {
        if (order.status == "packing") {
            return <Link  to={GENERATE_ORDER_LABEL.replace(":id", order.id).replace(":warehouse_id",warehouse_id)} className="text-indigo-600 hover:text-indigo-900">Generar Guía</Link>
        }
        else if (user?.permissions?.pick_all_order_stocks && order.assigned_stock_quantity && order.assigned_stock_quantity >= 20) {
            return <span className="text-indigo-600 hover:text-indigo-900 cursor-pointer" onClick={() => handlePickAllStock(order)}>Pickear Piezas</span>
        }

        return <></>
    }

    const buildToteRow = function(picking_order) {
        if (!picking_order?.tote) {
            return ''
        }

        return (
            <div className="flex">
                {picking_order.tote.unique_code} {picking_order.in_review && <Tooltip title="Tote en revisión"><ExclamationCircleIcon className="h-5 w-5 ml-1 text-red-400" /></Tooltip>}
            </div>
        )
    }

    const getTableData = () => {
        return data.orders.map((order)=>{
            if(order.store.name.length > 27) order.store.name = order.store.name.substring(0, 24) + '...'
            return {
                entity: {...order},
                store:<div className="">{String(order.store.name)}</div>,
                order_number: <div className="hover:underline cursor-pointer " onClick={()=>handlerOnClickStatus(order)}>#{String(order.order_number)}</div>,
                channel_name: order.channel_name,
                tote: buildToteRow(order.picking_order),
                picking_order: order.picking_order,
                assigned_to: order.picking_order && order.picking_order.assigned_to ? `${order.picking_order.assigned_to.first_name} ${order.picking_order.assigned_to.last_name}`:"",
                status: <StatusPill className="cursor-pointer" status={order.status} statuses={ORDER_STATUSES_PROCESSING} onClick={()=>handlerOnClickStatus(order)}/>,
                shipping_method: order.shipping_method==null ? "-": `${order.shipping_method.carrier_name} ${order.shipping_method.shipping_name}`,
                pending_at: <FormattedDate date={order.pending_at} shortDate />,
                max_packing_time: order.max_packing_time ? <FormattedRelativeTime date={order.max_packing_time} calendarTime/> : "",
                actions: getActions(order)
            }
        })
    }

    const getColumns = () => {
        if(shippingMethodsData) {
            let newColumns = [...table_columns]
            let index = newColumns.findIndex(column => column.accessor === "shipping_method")
            if(index === -1) {
                newColumns.splice(3,0,{
                    Header: 'MÉTODO DE ENVÍO',
                    accessor: 'shipping_method',
                    disableFilters: false,
                    disableSortBy: false,
                    Filter: SelectColumnFilter,
                    selectFilterOptions:shippingMethodsData.map((ship)=> {
                        return {
                            id: ship.carrier_name + " " + ship.shipping_name,
                            label: ship.carrier_name +" "+ ship.shipping_name
                        }
                    })
                })
            }
            return newColumns
        }
        return []
    }
    
    const [selectedOrders, setSelectedOrders] = useState([])
    const [showAssignOrderDialog, setShowAssignOrderDialog] = useState(false)
    const prepareModalData = (picking_order) => {
        setShowAssignOrderDialog(true)
    }

    const canOrdersBeReassigned  = () => {
        return selectedOrders.length == selectedOrders.filter(x => x.status.props.status == 'processing' || x.status.props.status == 'picking').length
    }

    const onAssignOrders = () => {
        setShowAssignOrderDialog(true)
    }

    const onOrdersAssigned = () => {
        setShowAssignOrderDialog(false)
        setSearchParams({...defaultSearchParams})
        refetch()
        tableRef.current.resetSelection()
        tableRef.current.resetPagination()
    }

    const memoizedColumns = useMemo(() => getColumns(), [shippingMethodsData])

    const tableRef = useRef()

    return (
        <>
           <PageView
                topMenu={<PageTopBar>
                    <div className="text-lg font-semibold">Pedidos Procesando</div>     
                    <>
                        {user?.permissions?.reassign_picking_order && (
                            canOrdersBeReassigned() ? 
                                <Button className="whitespace-nowrap mr-2" onClick={onAssignOrders} disabled={selectedOrders.length < 1}>
                                        {`Reasignar Picker (${selectedOrders.length})`}
                                </Button>
                                :
                                <Button className="whitespace-nowrap mr-2 has-tooltip opacity-50">
                                    <Tooltip title="Selecciona solamente órdenes en estado Picking ó Procesando">
                                       Reasignar Picker <ExclamationCircleIcon className="text-white w-4 h-4 inline" />
                                    </Tooltip>
                                </Button>
                        )}
                        <Button className="whitespace-nowrap mr-2" onClick={()=>{
                            history.push(GENERATE_LABELS.replace(':warehouse_id', warehouse_id))
                            }
                        }>
                            Generar Guías 
                        </Button>  
                        { warehouse_id == grouped_orders_default_warehouse && 

                        <Button className="whitespace-nowrap mr-2" onClick={()=>{
                            history.push(GENERATE_SHARED_LABEL.replace(':warehouse_id', warehouse_id))
                            }
                        }>
                            Generar Guía Agrupada
                        </Button>             
                        }
                    </>
                </PageTopBar>}
                childrenFullWidth={true}
                topMenuFullWidth={true}
                //bottomMenu={<Button>Crear Orden</Button>}
            >
                
                {(!isPreviousData && isLoading) || (!isPreviousDataShippingMethods && isLoadingShippingMethods) ? (
                    <><Loader show={true}></Loader></>
                ) : isError ? (
                    <>Error: {error.message}</>
                ) : isErrorShippingMethods ? (
                    <>Error: {errorShippingMethods.message}</>
                ):(
                        <>
                             <BasicTable
                        showHeader
                        showLoader={isFetching && isPreviousData}
                        columns={memoizedColumns}
                        filterable
                        sortable
                        showPaginator
                        selectable
                        data = {getTableData()}
                        onFilterChange={(activeFilters) => onFilterChange(activeFilters)}
                        onSortChange={(activeSort) => onSortChange(activeSort)}
                        paginationMeta={data?.meta}
                        labelToPaginator="Pedidos"
                        pagesSize = {searchParams.per_page}
                        onPaginationChange= { (requestedPage) => { setSearchParams({...searchParams, page: requestedPage}) } }
                        onPageSizeChange= { (pageSize) => {
                            setSearchParams({...searchParams, per_page: pageSize, page: 1})
                            dispatch(setProcessingOrdersListPageSize(pageSize))
                            tableRef.current.resetPagination()
                        }}
                        onSelectionChange={(rows) => {
                            setSelectedOrders(rows)
                            // console.log("rows", rows)
                        }}
                        showDataStatus
                        isFetching={isFetching}
                        emptyTableText="No hay pedidos que mostrar."
                        ref={tableRef}
                        />
                        </> 
                   
                    )
                }
            </PageView>
            {user?.permissions?.reassign_picking_order &&
                <AssignOrdersDialog open={showAssignOrderDialog} orders={selectedOrders} changingPicker={true} onCancel={() => setShowAssignOrderDialog(false)} onSuccess={() => onOrdersAssigned()} />
            }
            <Notification
                show={showPickAllSuccess}
                setShow={setShowPickAllSuccess}
                type="success"
                title="Orden pickeada correctamente"
            />
            <ConfirmDialog
                open={showPickAllConfirmation}
                setOpen={setShowPickAllConfirmation}
                title={`Confirma pickear piezas de #${pickAllStockOrder?.order_number}`}
                description={`Confirma que deseas pickear todas las ${pickAllStockOrder?.assigned_stock_quantity} piezas de este pedido.`}
                confirmLabel="Confirmar"
                cancelLabel="Cancelar"
                onConfirm={() => onConfirmPickAllStock(pickAllStockOrder)}
                onCancel={() => onCancelPickAllStock()}
                loading={loadingPickAllStock}
            />
            <Switch>
                <Route exact path={PROCESSING_ORDER_DETAIL}>
                    <SlidePanel title="Ver Orden" referrer={ORDERS_PROCESSING.replace(':warehouse_id', warehouse_id)}>
                        <ShowOrderContainer />
                    </SlidePanel>
                </Route>
            </Switch>
        </>
    )
}