function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const ActionMenuItem = ({active, title, ...props}) => {
    return (
        <a
            className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex items-center px-4 py-2 text-sm cursor-pointer '
            )}

            {...props}
        >
            {title}
        </a>
    )
}