import { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { cloneDeep } from "lodash"
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline"

import RangeBillingPriceConfigTable from "../../RangeBillingPriceConfigTable"
import { PACKING_MATERIAL_CODES, PRICE_MODES, SERVICE_TYPES } from "../../constants"
import { findOrCreateBillingService, getCurrency } from "../../utils"
import ShippingPackagesServices from "./ShippingPackagesServices"
import { UNITS_IDS } from "../../RangeBillingPriceConfigTable/constants"
import BillingPriceConfigTable from "../../BillingPriceConfigTable"
import { CurrencyInputField } from "../../../CurrencyInputField"

const PickAndPackServices = ({ storeBillingServices, onUpdateStoreBillingServices, onIsValid, shippingPackages, storeId, warehouse }) => {
    const { i18n } = useTranslation()

    const tableHeaders = [
        { label: i18n.t("billing.table_headers.from_piece"), id: 'from' },
        { label: i18n.t("billing.table_headers.to_piece"), id: 'to' },
        { label: i18n.t("billing.table_headers.price"), id: 'price' },
        { label: i18n.t("billing.table_headers.unit"), id: 'unit' },
        { label: null, id: 'actions' }
    ]

    const tableHeadersPackingMaterial = [
        { label: i18n.t("billing.table_headers.from_piece"), id: "from" },
        { label: i18n.t("billing.table_headers.to_piece"), id: "to" },
        { label: i18n.t("billing.table_headers.packing_material"), id: "package" },
        { label: i18n.t("billing.table_headers.price"), id: "price" },
        { label: i18n.t("billing.table_headers.unit"), id: "unit" }
    ]

    const [areAllPickingServicesValid, setAreAllPickingServicesValid] = useState(false)
    const [isPickingServiceValid, setIsPickingServiceValid] = useState(false)
    const [isInsertServiceValid, setIsInsertServiceValid] = useState(false)
    const [isSerialNumberServiceValid, setIsSerialNumberServiceValid] = useState(false)
    const [isFragileServiceValid, setIsFragileServiceValid] = useState(false)
    const [isPackageServiceValid, setIsPackageServiceValid] = useState(false)
    const [isPackingMaterialServiceValid, setIsPackingMaterialServiceValid] = useState(false)

    const pickingService = findOrCreateBillingService({ serviceType: SERVICE_TYPES.SERVICE_PICK_AND_PACK, billingServices: storeBillingServices, currency: getCurrency(warehouse?.country), storeId: storeId, warehouseId: warehouse?.id })
    const insertService = findOrCreateBillingService({ serviceType: SERVICE_TYPES.SERVICE_PICK_AND_PACK_INSERT, billingServices: storeBillingServices, currency: getCurrency(warehouse?.country), storeId: storeId, warehouseId: warehouse?.id })
    const serialNumberService = findOrCreateBillingService({ serviceType: SERVICE_TYPES.SERVICE_PICK_AND_PACK_SERIAL_NUMBER, billingServices: storeBillingServices, currency: getCurrency(warehouse?.country), storeId: storeId, warehouseId: warehouse?.id })
    const fragileService = findOrCreateBillingService({ serviceType: SERVICE_TYPES.SERVICE_PICK_AND_PACK_FRAGILE, billingServices: storeBillingServices, currency: getCurrency(warehouse?.country), storeId: storeId, warehouseId: warehouse?.id })

    const packingMaterialCodes = PACKING_MATERIAL_CODES

    const [packingMaterialBillingServices, setPackingMaterialBillingServices] = useState((() => {
        const packingMaterialServices = storeBillingServices?.filter(({ service_type }) => service_type === SERVICE_TYPES.SERVICE_PACKING_MATERIAL)

        const newPackingMaterialServices = packingMaterialCodes.map(packingMaterial => {
            let currentPriceConfiguration = null
            const currentBillingService = packingMaterialServices?.find(({ service_type_category }) => service_type_category === packingMaterial)

            let newBillingService = {
                store_id: storeId,
                warehouse_id: warehouse?.id,
                service_type: SERVICE_TYPES.SERVICE_PACKING_MATERIAL,
                service_type_category: packingMaterial,
                currency: getCurrency(warehouse?.country),
                price_configuration: []
            }

            if (currentBillingService?.price_configuration?.length > 0) {
                currentPriceConfiguration = currentBillingService.price_configuration.map(priceRange => ({
                    ...priceRange,
                    from: isNaN(parseFloat(priceRange.from)) ? null : parseFloat(priceRange.from),
                    to: isNaN(parseFloat(priceRange.to)) ? null : parseFloat(priceRange.to),
                    price: isNaN(parseFloat(priceRange.price)) ? null : parseFloat(priceRange.price)
                }))

                newBillingService = {
                    ...newBillingService,
                    price_configuration: currentPriceConfiguration
                }
            } else {
                const priceRange = { from: 0, to: null, price: null, price_mode: PRICE_MODES.PRICE_MODE_TOTAL, excess: false }
                newBillingService.price_configuration.push(priceRange)
            }

            return newBillingService
        })

        newPackingMaterialServices.sort((a, b) => {
            if (a.service_type_category < b.service_type_category) return -1;
            if (a.service_type_category > b.service_type_category) return 1;
            return 0;
        });

        return newPackingMaterialServices
    })())

    const packingMaterialtablesData = packingMaterialBillingServices?.map(billingService => {
        const data = billingService?.price_configuration?.map((priceRange, priceRangeIndex) => {
            return {
                from: { value: priceRange.from, isDisabled: true },
                to: { value: priceRange.to, isDisabled: true },
                price: { value: priceRange.price },
                price_mode: { value: priceRange.price_mode },
                serviceTypeCategory: { value: priceRange.service_type_category },
                excess: { value: priceRange.excess },
                range_exclusion: { value: priceRange.range_exclusion, isDisabled: true }
            }
        })
        return { category: billingService.service_type_category, data: data }
    })

    const pickingTableData = pickingService?.price_configuration.map(priceRange => {
        return {
            from: { value: priceRange.from, isDisabled: true },
            to: { value: priceRange.to },
            price: { value: priceRange.price },
            price_mode: { value: priceRange.price_mode },
            excess: { value: priceRange.excess },
            range_exclusion: { value: priceRange.range_exclusion },
        }
    })

    const insertTableData = insertService?.price_configuration.map(priceRange => {
        return {
            from: { value: priceRange.from, isDisabled: true },
            to: { value: priceRange.to },
            price: { value: priceRange.price },
            price_mode: { value: priceRange.price_mode },
            excess: { value: priceRange.excess },
            range_exclusion: { value: priceRange.range_exclusion },
        }
    })

    const serialNumberTableData = serialNumberService?.price_configuration.map(priceRange => {
        return {
            from: { value: priceRange.from, isDisabled: true },
            to: { value: priceRange.to },
            price: { value: priceRange.price },
            price_mode: { value: priceRange.price_mode },
            excess: { value: priceRange.excess },
            range_exclusion: { value: priceRange.range_exclusion },
        }
    })

   const priceModesPickAndPackFragile = [UNITS_IDS.TOTAL]
    const fragileTableData = fragileService?.price_configuration.map(priceRange => {
        return {
            from: { value: priceRange.from, isDisabled: true },
            to: { value: priceRange.to, isDisabled: true },
            price: { value: priceRange.price },
            price_mode: { value: priceRange.price_mode || UNITS_IDS.TOTAL, isDisabled: true },
            excess: { value: priceRange.excess },
            range_exclusion: { value: priceRange.range_exclusion },
        }
    })

    const unitOptionsLabels = {
        PER_UNIT: i18n.t("billing.pick_and_pack_services.picking_unit_options.PER_UNIT"),
        TOTAL: i18n.t("billing.pick_and_pack_services.picking_unit_options.TOTAL"),
        EXCESS_PER_UNIT: i18n.t("billing.pick_and_pack_services.picking_unit_options.EXCESS_PER_UNIT"),
        EXCESS_TOTAL: i18n.t("billing.pick_and_pack_services.picking_unit_options.EXCESS_TOTAL")
    }

    const unitOptionsLabelsFragile = {
        TOTAL: i18n.t("billing.pick_and_pack_services.fragile_picking_unit_options.TOTAL")
    }

    const handleOnUpdateData = (serviceType, newTableData, service) => {
        let newStoreBillingServices = cloneDeep(storeBillingServices)

        const newPriceConfiguration = newTableData.map(priceRangeData => {
            return {
                from: priceRangeData.from.value,
                to: priceRangeData.to.value,
                price: priceRangeData.price.value,
                price_mode: priceRangeData.price_mode.value,
                excess: priceRangeData.excess.value,
                range_exclusion: priceRangeData.range_exclusion.value
            }
        })

        const serviceExists = newStoreBillingServices.some(({ service_type }) => service_type === serviceType)

        if (serviceExists) {
            newStoreBillingServices = newStoreBillingServices.map(billingService => {
                if (billingService.service_type === serviceType) {
                    billingService.price_configuration = newPriceConfiguration
                }
                return billingService
            })
        } else {
            const newService = cloneDeep(service)
            newService.price_configuration = newPriceConfiguration
            newStoreBillingServices.push(newService)
        }

        onUpdateStoreBillingServices(newStoreBillingServices)
    }

    const handleOnUpdatePricePackingMaterialService = (newPrice, serviceTypeCategory, priceRangeIndex) => {
        const newRangePrice = isNaN(parseFloat(newPrice)) ? null : parseFloat(newPrice)
        const newPackingMaterialBillingServices = cloneDeep(packingMaterialBillingServices)
        const packingMaterialBillingServicesIndex = packingMaterialBillingServices.findIndex(({ service_type_category }) => service_type_category === serviceTypeCategory)

        newPackingMaterialBillingServices[packingMaterialBillingServicesIndex].price_configuration[priceRangeIndex].price = newRangePrice
        setPackingMaterialBillingServices(newPackingMaterialBillingServices)

        const newValidStatus = newPackingMaterialBillingServices.every(({ price_configuration }) => {
            return price_configuration.every(({ price }) => !isNaN(parseFloat(price)))
        })

        const newStoreBillingServices = storeBillingServices ? cloneDeep(storeBillingServices) : []

        newPackingMaterialBillingServices.forEach(packingMaterialService => {
            const storeBillingServiceIndex = newStoreBillingServices.findIndex(({ service_type, service_type_category }) => {
                return service_type === SERVICE_TYPES.SERVICE_PACKING_MATERIAL && service_type_category === packingMaterialService.service_type_category
            })

            if (storeBillingServiceIndex >= 0) {
                const priceConfigurationCopy = cloneDeep(packingMaterialService.price_configuration)
                newStoreBillingServices[storeBillingServiceIndex].price_configuration = priceConfigurationCopy
            }
            else {
                const serviceCopy = cloneDeep(packingMaterialService)
                newStoreBillingServices.push(serviceCopy)
            }
        })

        onUpdateStoreBillingServices(newStoreBillingServices)
        setIsPackingMaterialServiceValid(newValidStatus)
    }

    useEffect(() => {
        setAreAllPickingServicesValid(isPickingServiceValid && isFragileServiceValid && isInsertServiceValid && isSerialNumberServiceValid)
    },[isPickingServiceValid, isFragileServiceValid, isInsertServiceValid, isSerialNumberServiceValid])

    const handleOnIsValid = (serviceType, isValid) => {
        switch (serviceType) {
            case SERVICE_TYPES.SERVICE_PICK_AND_PACK:
                setIsPickingServiceValid(isValid)
                break

            case SERVICE_TYPES.SERVICE_PICK_AND_PACK_FRAGILE:
                setIsFragileServiceValid(isValid)
                break

            case SERVICE_TYPES.SERVICE_PICK_AND_PACK_INSERT:
                setIsInsertServiceValid(isValid)
                break

            case SERVICE_TYPES.SERVICE_PICK_AND_PACK_SERIAL_NUMBER:
                setIsSerialNumberServiceValid(isValid)
                break

            case SERVICE_TYPES.SERVICE_PICK_AND_PACK_PACKAGE:
                setIsPackageServiceValid(isValid)
                break

            //SERVICE_PACKING_MATERIAL is handled on handleOnUpdatePricePackingMaterialService due to the different structure needed to validate multiple categories

            default:
                break
        }
    }

    useEffect(() => {
        const allServicesValid = isPickingServiceValid && isInsertServiceValid && isSerialNumberServiceValid && isFragileServiceValid && isPackageServiceValid;

        if (warehouse?.country === 'BR') {
            onIsValid(allServicesValid && isPackingMaterialServiceValid);
        } else {
            onIsValid(allServicesValid);
        }

    }, [isPickingServiceValid, isInsertServiceValid, isSerialNumberServiceValid, isFragileServiceValid, isPackageServiceValid, isPackingMaterialServiceValid])

    useEffect(() => {
        if (packingMaterialBillingServices?.length > 0) {
            const newValidStatus = packingMaterialBillingServices.every(({ price_configuration }) => (
                price_configuration?.length > 0 && price_configuration.every(({ price }) => !isNaN(parseFloat(price)))
            ))
            setIsPackingMaterialServiceValid(newValidStatus)
        }
    }, [packingMaterialBillingServices, isPackingMaterialServiceValid])

    return (
        <div className="flex flex-col gap-5">
            <div>
                <div className="flex gap-1">
                    <h2 className="text-xl text-gray-500"><span className="text-red-500">*</span>{i18n.t("billing.pick_and_pack_services.picking_title")}</h2>
                    {areAllPickingServicesValid ? (
                        <CheckCircleIcon className="h-5 w-5 text-green-500" />
                        ) : (
                        <ExclamationCircleIcon className="h-5 w-5 text-yellow-500" />
                    )}
                </div>
                <div className="flex gap-1">
                    <div className="text-xl text-gray-500 mb-2">{i18n.t("billing.pick_and_pack_services.picking_title")}</div>
                </div>

                <RangeBillingPriceConfigTable
                    headers={tableHeaders}
                    priceConfiguration={pickingTableData}
                    currency={getCurrency(warehouse?.country)}
                    onUpdatePriceConfiguration={newTableData => handleOnUpdateData(SERVICE_TYPES.SERVICE_PICK_AND_PACK, newTableData, pickingService)}
                    onIsValid={(isValid) => handleOnIsValid(SERVICE_TYPES.SERVICE_PICK_AND_PACK, isValid)}
                    unitOptionsLabels={unitOptionsLabels}
                />
            </div>

            <div>
                <div className="flex gap-1">
                    <div className="text-xl text-gray-500 mb-2">{i18n.t("billing.pick_and_pack_services.insert_title")}</div>
                </div>

                <RangeBillingPriceConfigTable
                    headers={tableHeaders}
                    priceConfiguration={insertTableData}
                    currency={getCurrency(warehouse?.country)}
                    onUpdatePriceConfiguration={newTableData => handleOnUpdateData(SERVICE_TYPES.SERVICE_PICK_AND_PACK_INSERT, newTableData, insertService)}
                    onIsValid={(isValid) => handleOnIsValid(SERVICE_TYPES.SERVICE_PICK_AND_PACK_INSERT, isValid)}
                    unitOptionsLabels={unitOptionsLabels}
                />
            </div>

            <div>
                <div className="flex gap-1">
                    <div className="text-xl text-gray-500 mb-2">{i18n.t("billing.pick_and_pack_services.serial_number_title")}</div>
                </div>

                <RangeBillingPriceConfigTable
                    headers={tableHeaders}
                    priceConfiguration={serialNumberTableData}
                    currency={getCurrency(warehouse?.country)}
                    onUpdatePriceConfiguration={newTableData => handleOnUpdateData(SERVICE_TYPES.SERVICE_PICK_AND_PACK_SERIAL_NUMBER, newTableData, serialNumberService)}
                    onIsValid={(isValid) => handleOnIsValid(SERVICE_TYPES.SERVICE_PICK_AND_PACK_SERIAL_NUMBER, isValid)}
                    unitOptionsLabels={unitOptionsLabels}
                />
            </div>

            <div>
                <div className="flex gap-1">
                    <div className="text-xl text-gray-500 mb-2">{i18n.t("billing.pick_and_pack_services.fragile_title")}</div>
                </div>

                <RangeBillingPriceConfigTable
                    headers={tableHeaders}
                    priceConfiguration={fragileTableData}
                    currency={getCurrency(warehouse?.country)}
                    onUpdatePriceConfiguration={newTableData => handleOnUpdateData(SERVICE_TYPES.SERVICE_PICK_AND_PACK_FRAGILE, newTableData, fragileService)}
                    onIsValid={(isValid) => handleOnIsValid(SERVICE_TYPES.SERVICE_PICK_AND_PACK_FRAGILE, isValid)}
                    availableUnitOptions={priceModesPickAndPackFragile}
                    unitOptionsLabels={unitOptionsLabelsFragile}
                />
            </div>


            <ShippingPackagesServices
                storeBillingServices={storeBillingServices}
                onUpdateStoreBillingServices={onUpdateStoreBillingServices}
                onIsValid={(isValid) => handleOnIsValid(SERVICE_TYPES.SERVICE_PICK_AND_PACK_PACKAGE, isValid)}
                shippingPackages={shippingPackages}
                storeId={storeId}
                warehouse={warehouse}
            />

            {warehouse?.country === 'BR' && (
                <>
                    <div>
                        <div className="flex gap-1">
                            <h2 className="text-xl text-gray-500"><span className="text-red-500">*</span>{i18n.t("billing.packing_material_services.packing_material_title")}</h2>

                            {isPackingMaterialServiceValid === true ? (
                                <CheckCircleIcon className="h-5 w-5 text-green-500" />
                            ) : (
                                <ExclamationCircleIcon className="h-5 w-5 text-yellow-500" />
                            )}
                        </div>

                        <div className="flex flex-col gap-5">
                            {packingMaterialCodes.map(packingMaterial => {
                                const data = packingMaterialtablesData.find(({ category }) => category === packingMaterial)?.data
                                const service = packingMaterialBillingServices.find(({ service_type, service_type_category }) => {
                                    return service_type === SERVICE_TYPES.SERVICE_PACKING_MATERIAL && service_type_category === packingMaterial
                                })

                                return (
                                    <div key={`packing-material-${packingMaterial}`} className="flex flex-col gap-2">
                                        <div className="text-xl text-gray-500">
                                            {i18n.t(`billing.packing_material_services.packing_material_codes.${packingMaterial}`)}
                                        </div>

                                        <BillingPriceConfigTable
                                            headers={tableHeadersPackingMaterial}
                                            rows={data.map((priceRange, priceRangeIndex) => ([
                                                {
                                                    id: "from",
                                                    cell: <div className="flex items-center ml-2">
                                                        <div className="mt-1">{i18n.t("billing.from_prefix")}</div>
                                                        <CurrencyInputField
                                                            value={priceRange.from.value === null ? "" : priceRange.from.value}
                                                            onChange={(v) => { }}
                                                            readOnly={true}
                                                            scale={5}
                                                            hideIcon={true}
                                                            inputClassName={"border-none bg-transparent shadow-none focus:ring-0"}
                                                        />
                                                    </div>,
                                                    isDisabled: true,
                                                },
                                                {
                                                    id: "to",
                                                    cell: <CurrencyInputField
                                                        value={priceRange.to.value === null ? "" : priceRange.to.value}
                                                        onChange={(v) => { }}
                                                        placeholder={i18n.t("billing.range_billing_price_config_table.last_row_placeholder")}
                                                        readOnly={true}
                                                        scale={5}
                                                        hideIcon={true}
                                                        inputClassName={"border-none bg-transparent shadow-none focus:ring-0"}
                                                    />,
                                                    isDisabled: true,
                                                },
                                                {
                                                    id: "package",
                                                    cell: <div className="p-2">
                                                        <div>{i18n.t(`billing.packing_material_services.packing_material_codes.${packingMaterial}`)}</div>
                                                    </div>,
                                                    isDisabled: true,
                                                },
                                                {
                                                    id: "price",
                                                    cell: <CurrencyInputField
                                                        value={priceRange.price.value === null ? "" : priceRange.price.value}
                                                        onChange={(v) => handleOnUpdatePricePackingMaterialService(v, service.service_type_category, priceRangeIndex)}
                                                        customIcon={<div className="font-bold">{service?.currency}</div>}
                                                        inputClassName={"pl-12 border-none bg-transparent shadow-none focus:ring-0"}
                                                        scale={8}
                                                        precision={16}
                                                    />,
                                                    isInvalid: isNaN(parseFloat(priceRange.price.value)),
                                                    errorMessage: i18n.t(`billing.range_billing_price_config_table.errors.MISSING_PRICE_VALUE`)
                                                },
                                                {
                                                    id: "unit",
                                                    cell: <div className="px-2">
                                                        {i18n.t(`billing.packing_material_services.unit_options.${priceRange.price_mode.value}`)}
                                                    </div>,
                                                    isDisabled: true,
                                                },
                                            ]))}
                                            className="w-full"
                                            headerClassName="text-left text-gray-500 p-2 border-2 bg-white"
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </>)}
        </div>
    )
}

export default PickAndPackServices