// import { useState } from "react";

export const Loader = ({ show = false, className = "" }) => {
  // const [show, setShow] = useState(show)
  return (
    <>
      {show && (
        <div
          className={
            "absolute inset-0 flex justify-center items-center backdrop-blur-sm backdrop-filter " + className
          }
          style={{zIndex:1}}
        >
          <div>
            {/* <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{margin:"auto",background:"#fff",display:"block"}} width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <rect x="-3.5" y="-3.5" width="35" height="35" fill="#008fd4">
                <animate attributeName="fill" values="#001f2d;#008fd4;#008fd4" keyTimes="0;0.125;1" dur="0.5s" repeatCount="indefinite" begin="0s" calcMode="discrete"></animate>
                </rect><rect x="32.5" y="-3.5" width="35" height="35" fill="#008fd4">
                <animate attributeName="fill" values="#001f2d;#008fd4;#008fd4" keyTimes="0;0.125;1" dur="0.5s" repeatCount="indefinite" begin="0.0625s" calcMode="discrete"></animate>
                </rect><rect x="68.5" y="-3.5" width="35" height="35" fill="#008fd4">
                <animate attributeName="fill" values="#001f2d;#008fd4;#008fd4" keyTimes="0;0.125;1" dur="0.5s" repeatCount="indefinite" begin="0.125s" calcMode="discrete"></animate>
                </rect><rect x="-3.5" y="32.5" width="35" height="35" fill="#008fd4">
                <animate attributeName="fill" values="#001f2d;#008fd4;#008fd4" keyTimes="0;0.125;1" dur="0.5s" repeatCount="indefinite" begin="0.4375s" calcMode="discrete"></animate>
                </rect><rect x="68.5" y="32.5" width="35" height="35" fill="#008fd4">
                <animate attributeName="fill" values="#001f2d;#008fd4;#008fd4" keyTimes="0;0.125;1" dur="0.5s" repeatCount="indefinite" begin="0.1875s" calcMode="discrete"></animate>
                </rect><rect x="-3.5" y="68.5" width="35" height="35" fill="#008fd4">
                <animate attributeName="fill" values="#001f2d;#008fd4;#008fd4" keyTimes="0;0.125;1" dur="0.5s" repeatCount="indefinite" begin="0.375s" calcMode="discrete"></animate>
                </rect><rect x="32.5" y="68.5" width="35" height="35" fill="#008fd4">
                <animate attributeName="fill" values="#001f2d;#008fd4;#008fd4" keyTimes="0;0.125;1" dur="0.5s" repeatCount="indefinite" begin="0.3125s" calcMode="discrete"></animate>
                </rect><rect x="68.5" y="68.5" width="35" height="35" fill="#008fd4">
                <animate attributeName="fill" values="#001f2d;#008fd4;#008fd4" keyTimes="0;0.125;1" dur="0.5s" repeatCount="indefinite" begin="0.25s" calcMode="discrete"></animate>
                </rect>
            </svg> */}
            <svg className="w-28 h-28" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <g transform="translate(50 50) scale(0.7) translate(-50 -50)"><g>
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" calcMode="spline" dur="4s" values="0 50 50;90 50 50;180 50 50;270 50 50;360 50 50" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 1 0 1;0 1 0 1;0 1 0 1;0 1 0 1"></animateTransform>
                <g>
                  <animateTransform attributeName="transform" type="scale" dur="1s" repeatCount="indefinite" calcMode="spline" values="1;1;0.5" keyTimes="0;0.5;1" keySplines="1 0 0 1;1 0 0 1"></animateTransform>
                  <g transform="translate(25 25)">
                <rect x="-25" y="-25" width="52" height="52" fill="#182731">
                  
                  <animate attributeName="fill" dur="4s" repeatCount="indefinite" calcMode="spline" values="#182731;#a7d4ec;#182731;#a7d4ec;#182731" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 1 0 1;0 1 0 1;0 1 0 1;0 1 0 1"></animate>
                </rect>
              </g><g transform="translate(25 75)">
                <rect x="-25" y="-25" width="52" height="50" fill="#182731">
                  <animateTransform attributeName="transform" type="scale" dur="1s" repeatCount="indefinite" calcMode="spline" values="0;1;1" keyTimes="0;0.5;1" keySplines="1 0 0 1;1 0 0 1"></animateTransform>
                  <animate attributeName="fill" dur="4s" repeatCount="indefinite" calcMode="spline" values="#182731;#a7d4ec;#182731;#a7d4ec;#182731" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 1 0 1;0 1 0 1;0 1 0 1;0 1 0 1"></animate>
                </rect>
              </g><g transform="translate(75 25)">
                <rect x="-25" y="-25" width="50" height="52" fill="#182731">
                  <animateTransform attributeName="transform" type="scale" dur="1s" repeatCount="indefinite" calcMode="spline" values="0;1;1" keyTimes="0;0.5;1" keySplines="1 0 0 1;1 0 0 1"></animateTransform>
                  <animate attributeName="fill" dur="4s" repeatCount="indefinite" calcMode="spline" values="#182731;#a7d4ec;#182731;#a7d4ec;#182731" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 1 0 1;0 1 0 1;0 1 0 1;0 1 0 1"></animate>
                </rect>
              </g><g transform="translate(75 75)">
                <rect x="-25" y="-25" width="50" height="50" fill="#182731">
                  <animateTransform attributeName="transform" type="scale" dur="1s" repeatCount="indefinite" calcMode="spline" values="0;1;1" keyTimes="0;0.5;1" keySplines="1 0 0 1;1 0 0 1"></animateTransform>
                  <animate attributeName="fill" dur="4s" repeatCount="indefinite" calcMode="spline" values="#182731;#a7d4ec;#182731;#a7d4ec;#182731" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 1 0 1;0 1 0 1;0 1 0 1;0 1 0 1"></animate>
                </rect>
              </g>
                </g>
              </g></g>
            </svg>
          </div>
        </div>
      )}
    </>
  );
};
