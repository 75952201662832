import { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { cloneDeep } from "lodash"
import { TrashIcon } from "../../Icons/TrashIcon"

import BillingPriceConfigTable from "../BillingPriceConfigTable"
import { CurrencyInputField } from "../../CurrencyInputField"
import { COLUMN_IDS, UNITS_IDS, ACTIONS, DEFAULT_AVAILABLE_UNITS_IDS } from "./constants"
import { getToErrorCode, getPriceErrorCode, getUnitErrorCode, validatePriceConfiguration } from "./validationUtils"

const RangeBillingPriceConfigTable = ({
    headers,
    priceConfiguration,
    currency,
    onUpdatePriceConfiguration,
    onIsValid,
    unitOptionsLabels=null,
    availableUnitOptions=DEFAULT_AVAILABLE_UNITS_IDS,
    updateOnInvalid=true
}) => {
    const { i18n } = useTranslation()
    const [rows, setRows] = useState([])
    const [localPriceConfiguration, setLocalPriceConfiguration] = useState(priceConfiguration)

    const onCellChange = (rowIndex, columnId, value, currentPriceConfiguration) => {
        let addNewRow = false
        let newPriceConfiguration = cloneDeep(currentPriceConfiguration)

        switch(columnId){
            case COLUMN_IDS.TO:
                {
                    let newValue = value === null || value === "" ? null : parseFloat(value)

                    if(isNaN(newValue)){
                        newValue = null
                    }

                    addNewRow = rowIndex === newPriceConfiguration.length - 1
                    newPriceConfiguration = newPriceConfiguration.map((priceRange, index) => {
                        const newPriceRange = cloneDeep(priceRange)

                        if(index === rowIndex){
                            newPriceRange.to = {...newPriceRange.to, value: newValue}
                        } else if(index === rowIndex + 1){
                            const newFromValue = newValue === null || newValue === "" ? null : newValue
                            newPriceRange.from = {...priceRange.from, value: newFromValue}
                        }
                        return newPriceRange
                    })
                    break
                }

            case COLUMN_IDS.UNIT:
                {
                    newPriceConfiguration[rowIndex].excess.value = value === UNITS_IDS.EXCESS_PER_UNIT || value === UNITS_IDS.EXCESS_TOTAL

                    if (value === UNITS_IDS.EXCESS_PER_UNIT){
                        newPriceConfiguration[rowIndex].price_mode.value = UNITS_IDS.PER_UNIT
                    } else if (value === UNITS_IDS.EXCESS_TOTAL){
                        newPriceConfiguration[rowIndex].price_mode.value = UNITS_IDS.TOTAL
                    } else {
                        newPriceConfiguration[rowIndex].price_mode.value = value
                    }
                    break
                }

            default:
                {
                    newPriceConfiguration[rowIndex][columnId].value = value
                    break
                }
        }

        if (addNewRow){
            const newPriceRange = cloneDeep(newPriceConfiguration[newPriceConfiguration.length - 1])

            newPriceRange.from.value = parseFloat(value)
            newPriceRange.to.value = null
            newPriceRange.price.value = null
            newPriceRange.price_mode.value = null
            newPriceRange.excess.value = false
            newPriceRange.range_exclusion.value = null

            newPriceConfiguration.push(newPriceRange)

            // Reset unit from the previous row if excess is true
            if(newPriceConfiguration[rowIndex].excess.value){
                newPriceConfiguration[rowIndex].excess.value = false
                newPriceConfiguration[rowIndex].price_mode.value = null
            }
        }

        const isValid = validatePriceConfiguration(newPriceConfiguration, availableUnitOptions)
        if(isValid || updateOnInvalid){
            onUpdatePriceConfiguration(newPriceConfiguration)
        }
        setLocalPriceConfiguration(newPriceConfiguration)
    }

    const onAction = (action, rowIndex, currentPriceConfiguration) => {
        switch(action){
            case ACTIONS.DELETE_ROW:
                {
                    let priceConfigurationCopy = cloneDeep(currentPriceConfiguration)
                    priceConfigurationCopy = priceConfigurationCopy.filter((priceRange, index) => index !== rowIndex)

                    const lastIndex = priceConfigurationCopy.length - 1

                    priceConfigurationCopy[lastIndex].to.value = null
                    priceConfigurationCopy[lastIndex].price_mode.value = null
                    priceConfigurationCopy[lastIndex].excess.value = false

                    const isValid = validatePriceConfiguration(priceConfigurationCopy, availableUnitOptions)
                    if(isValid || updateOnInvalid){
                        onUpdatePriceConfiguration(priceConfigurationCopy)
                    }
                    setLocalPriceConfiguration(priceConfigurationCopy)
                    break
                }
            default:
                break
        }
    }

    useEffect(() => {
        const isValid = validatePriceConfiguration(localPriceConfiguration, availableUnitOptions)
        onIsValid(isValid)
    }, [localPriceConfiguration, availableUnitOptions])

    useEffect(() => {
        let unitOptions = [
            {label: i18n.t(`billing.range_billing_price_config_table.unit_options.${UNITS_IDS.PER_UNIT}`), value: UNITS_IDS.PER_UNIT},
            {label: i18n.t(`billing.range_billing_price_config_table.unit_options.${UNITS_IDS.TOTAL}`), value: UNITS_IDS.TOTAL},
            {label: i18n.t(`billing.range_billing_price_config_table.unit_options.${UNITS_IDS.EXCESS_PER_UNIT}`), value: UNITS_IDS.EXCESS_PER_UNIT, lastRowOnly: true},
            {label: i18n.t(`billing.range_billing_price_config_table.unit_options.${UNITS_IDS.EXCESS_TOTAL}`), value: UNITS_IDS.EXCESS_TOTAL, lastRowOnly: true}
        ]

        if(unitOptionsLabels){
            Object.entries(unitOptionsLabels).forEach(([key, label]) => {
                unitOptions = unitOptions.map(option => option.value === key ? {...option, label: label} : {...option})
            })
        }

        unitOptions = unitOptions.filter(({value}) => availableUnitOptions.some(availableOption => availableOption === value))

        const rowsFromPriceConfigurations = localPriceConfiguration.map((priceRange, rowIndex, {length}) => {
            const {from, to, price, price_mode: priceMode, excess} = priceRange
            let unit = {value: null}

            if(excess.value && priceMode.value === UNITS_IDS.PER_UNIT){
                unit.value = UNITS_IDS.EXCESS_PER_UNIT
            } else if(excess.value && priceMode.value === UNITS_IDS.TOTAL){
                unit.value = UNITS_IDS.EXCESS_TOTAL
            } else{
                unit.value = priceMode.value
            }

            const isUnitDisabled = priceMode.isDisabled || excess.isDisabled ? true : false

            const hasActionColumn = headers.some(header => header.id === COLUMN_IDS.ACTIONS)
            const isLastRow = rowIndex === length - 1

            const toErrorCode = getToErrorCode(priceRange, isLastRow)
            const priceErrorCode = getPriceErrorCode(priceRange)
            const unitErrorCode = getUnitErrorCode(priceRange, isLastRow, rowIndex === 0, availableUnitOptions)

            const columns = [
                {
                    cell: <div className="flex items-center ml-2">
                        <div className="mt-1">{i18n.t("billing.from_prefix")}</div>
                        <CurrencyInputField
                            value={from.value === null ? "" : from.value}
                            onChange={(v) => onCellChange(rowIndex, COLUMN_IDS.FROM, v, localPriceConfiguration)}
                            placeholder={from.placeholder || ""}
                            readOnly={true}
                            scale={from.scale || ""}
                            hideIcon={true}
                            inputClassName={"border-none bg-transparent shadow-none focus:ring-0"}
                        />
                    </div>,
                    id: COLUMN_IDS.FROM,
                    isInvalid: false,
                    errorMessage: null,
                    isDisabled: from.isDisabled === true
                },
                {
                    cell: <CurrencyInputField
                        value={to.value === null ? "" : to.value}
                        onChange={(v) => onCellChange(rowIndex, COLUMN_IDS.TO, v, localPriceConfiguration)}
                        placeholder={isLastRow ? i18n.t("billing.range_billing_price_config_table.last_row_placeholder") : ""}
                        readOnly={to.isDisabled === true}
                        scale={to.scale || 0}
                        hideIcon={true}
                        inputClassName={"border-none bg-transparent shadow-none focus:ring-0"}
                    />,
                    id: COLUMN_IDS.TO,
                    isInvalid: toErrorCode !== null,
                    errorMessage: i18n.t(`billing.range_billing_price_config_table.errors.${toErrorCode}`),
                    isDisabled: to.isDisabled === true
                },
                {
                    cell: <CurrencyInputField
                        value={price.value === null ? "" : price.value}
                        onChange={(v) => onCellChange(rowIndex, COLUMN_IDS.PRICE, v, localPriceConfiguration)}
                        customIcon={<div className="font-bold">{currency}</div>}
                        placeholder={price.placeholder || ""}
                        readOnly={price.isDisabled === true}
                        scale={8}
                        precision={16}
                        inputClassName={"pl-12 border-none bg-transparent shadow-none focus:ring-0"}
                    />,
                    id: COLUMN_IDS.PRICE,
                    isInvalid: priceErrorCode !== null,
                    errorMessage: i18n.t(`billing.range_billing_price_config_table.errors.${priceErrorCode}`),
                    isDisabled: price.isDisabled === true,
                    className: `${hasActionColumn ? "border-r-0" : ""}`
                },
                {
                    cell: !isUnitDisabled ? (
                                <select
                                    onChange={(v) => onCellChange(rowIndex, COLUMN_IDS.UNIT, v.target.value, localPriceConfiguration)}
                                    value={unit.value || ""}
                                    className={`border-none focus:ring-0 w-full `}
                                >
                                    <option value={""} hidden={true} key={`option-${rowIndex}`}>{"Selecciona una opción"}</option>

                                    {unitOptions.map((option, index) =>
                                        <option value={option.value} hidden={option.lastRowOnly && (!isLastRow || rowIndex <= 0)}  key={`option-${rowIndex}-${index}`}>
                                            {option.label}
                                        </option>
                                    )}
                                </select>
                            ) :
                            <div className="px-2">{unitOptions.find(({value}) => value === unit.value)?.label || ""}</div>,
                    id: COLUMN_IDS.UNIT,
                    isInvalid: unitErrorCode !== null,
                    errorMessage: i18n.t(`billing.range_billing_price_config_table.errors.${unitErrorCode}`),
                    isDisabled: isUnitDisabled === true
                }
            ]

            if(hasActionColumn && isLastRow && length > 1){
                const actionColumn = {
                    cell: <button className="px-2 outline-none flex items-center justify-center w-full" onClick={() => onAction(ACTIONS.DELETE_ROW, rowIndex, localPriceConfiguration)}>
                        <TrashIcon className="h-4 w-4 text-gray-600" />
                    </button>,
                    id: COLUMN_IDS.ACTIONS,
                    className: "border-l-0"
                }
                columns.push(actionColumn)
            } else if(hasActionColumn){
                const actionColumn = {
                    cell: <div></div>,
                    id: COLUMN_IDS.ACTIONS,
                    className: "border-l-0"
                }
                columns.push(actionColumn)
            }

            return columns
        })

        setRows(rowsFromPriceConfigurations)
    }, [localPriceConfiguration, currency, headers, i18n, unitOptionsLabels, availableUnitOptions])

    return (
        <BillingPriceConfigTable
            headers={headers}
            rows={rows}
            className="w-full"
            headerClassName="text-left text-gray-500 p-2 border-2 bg-white"
            cellClassName=""
        />
    )
}

export default RangeBillingPriceConfigTable