import {useState, useCallback, useRef,useContext, useEffect, useMemo} from 'react';
import {Route, Switch, useHistory, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {fetchPendingOrders} from "../../services";
import {fetchWarehouseShippingMethods} from "../../services/warehouseSevices"
import {UserContext} from "../../hooks/UserContext";
import {useDispatch, useSelector} from "react-redux";
import {
    ActionMenu,
    Button, ConfirmDialog,
    FormattedDate, Notification,
    PageTopBar,
    PageView, SlidePanel,
} from "../../components"
import { BasicTable } from "./../../components/BasicTable/BasicTable"
import {SHOW_ORDERS} from "../../navigation"
import {GENERATE_LABEL, ORDERS} from "../../navigation/constants"
import { StatusPill } from '../../components/BasicTable/StatusPill'
import { ORDER_STATUSES} from '../../components/BasicTable/StatusPillDefinitions';
import { Loader } from '../../components/Loader';
import {ShowOrderContainer} from "./ShowOrderContainer";
import { DateTimeRangeFilter, SelectColumnFilter, NumericRangeFilter, DateTimeFilter } from '../../components/BasicTable/modules/ColumnFiltersUI';
import { AssignOrdersDialog } from './AssignOrdersDialog';
import  sameDay  from './../../sameday.json'
import { shipping_methods} from '../../components/BasicTable/ListShippingMethods';
import { setOrdersListPageSize } from "../../redux/pageSizeSlice";
import {pickAllOrderStocks} from "../../services/orderServices";
import { FormattedRelativeTime } from '../../components/FormattedRelativeTime';

const table_columns = [
    {
        Header: 'Tienda',
        accessor: 'store',
        disableFilters: false,
        disableSortBy: false,
        placeholder: "Buscar: -A, B",
        inputHoverDescription: (
            <div>
                Para buscar más de una tienda usa "," Ej: Levis, Dossier
                <br/> <br/>
                Para omitir algunas tiendas usa "-" EJ: -Levis, Dossier
            </div>
        )

    },
    {
        Header: 'ÁREA',
        accessor: 'warehouse_area', // accessor is the "key" in the data
        disableFilters: false,
        disableSortBy: false,
    },
    {
        Header: 'Productos',
        accessor: 'assigned_stock_quantity',
        Filter: NumericRangeFilter,
        disableFilters: false,
        disableSortBy: false,
        shrinkToContent: true
    },
    {
        Header: 'Empacar antes de',
        accessor: 'max_packing_time',
        Filter: DateTimeFilter,
        disableFilters: false,
        disableSortBy: false,
        shrinkToContent: true
    },
    {
        Header: 'Nº DE ORDEN',
        accessor: 'order_number', // accessor is the "key" in the data
        disableFilters: false,
        disableSortBy: false,
    },
    {
        Header: 'Canal',
        accessor: 'channel_name', 
        disableFilters: false,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        selectFilterOptions: ['manual', 'shopee', 'meli', 'liverpool', 'walmart', 'vtex'].map((channel_type)=>{
            
            return {
                id: channel_type,
                label: channel_type
            }
        }),
    },
    {
        Header: 'Tipo de envío',
        accessor: 'shippment_type',
        Filter: SelectColumnFilter,
        selectFilterOptions: ['sameday', 'standard'].map((shipping_type)=>{
            
            return {
                id: shipping_type,
                label: shipping_type === 'sameday' ? 'Mismo día' : 'Estándar'
            }
        }),
        disableFilters: false,
        disableSortBy: true,
    },
    /*{
        Header: 'En pendiente desde',
        accessor: 'pending_at', // accessor is the "key" in the data
        Filter: DateTimeRangeFilter,
        disableFilters: false,
        disableSortBy: false,
        shrinkToContent: true
    },*/
    {
        Header: "",
        accessor: 'actions', // accessor is the "key" in the data
        disableFilters: true,
        disableSortBy: true,
        fetchingIndicator : true,
        shrinkToContent: true
    },
]

export const ListOrderContainer = () => {
    const history = useHistory()
    const {user} = useContext(UserContext)
    const {warehouse_id} = useParams()
    const dispatch = useDispatch()
    const pageSize = useSelector((state) => state.pageSize.ordersList)

    const defaultSearchParams = {
        page: 1,
        per_page: pageSize,
        status: ['pending'],
        order_by: 'max_packing_time',
        order: 'ASC',
        warehouse_id: isNaN(parseInt(warehouse_id)) ? null : parseInt(warehouse_id),
        pick_n_ship: false,
        not_store: false 
    }

    const [showBulkPickSuccess, setShowBulkPickSuccess] = useState(false)
    const [showBulkPickConfirmation, setShowBulkPickConfirmation] = useState(false)
    const [selectedBulkPickOrder, setSelectedBulkPickOrder] = useState(null)
    const [loadingBulkPick, setLoadingBulkPick] = useState(null)
    const [searchParams, setSearchParams] = useState({...defaultSearchParams})
    const [selectedOrders, setSelectedOrders] = useState([])

    const additionalParams = {
        include_inactive: true
    }

    const {
        data: shippingMethodsData, 
        isLoading: isLoadingShippingMethods ,
        isError: isErrorShippingMethods,
        error: errorShippingMethods,
        isFetching: isFetchingShippingMethods,
        isPreviousData: isPreviousDataShippingMethods,
        refetch: refetchShippingMethods
    } = useQuery(['shipping_methods', warehouse_id], () => fetchWarehouseShippingMethods(warehouse_id, additionalParams), { 
        keepPreviousData : true,
        refetchOnWindowFocus: false
    })

    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,
        refetch,
    } = useQuery(['pending_orders', searchParams], () => fetchPendingOrders(searchParams), { keepPreviousData : true })

    const handlerOnClickStatus = (order) => {
        // setOrderClicked(order)
        // setOpenDialogErrors(!openDialogErrors)
        history.push(SHOW_ORDERS.replace(':id', order.id).replace(':warehouse_id', warehouse_id))
    }


    const onSortChange = (orderBy) => {
        // console.log("ON_ORDER_BY",orderBy)
        tableRef.current.resetSelection()
        tableRef.current.resetPagination()
        if (orderBy.length > 0) {
            setSearchParams({
                ...searchParams,
                order_by: orderBy[0].id, 
                order: orderBy[0].desc ? 'desc' : 'asc'
            })
        }
        else {
            if ('order_by' in searchParams) {
                searchParams.order_by = defaultSearchParams.order_by
                searchParams.order = defaultSearchParams.order
                setSearchParams({...searchParams})
            }
        }
    }

    const onFilterChange = (activeFilters) => {
        let isNotStore = defaultSearchParams["not_store"]
        memoizedColumns.forEach(col => {
            if (searchParams[col.accessor]) {
                delete searchParams[col.accessor]
            }
        })
        let filters = []
        activeFilters.forEach(filter => {
            let value = filter.value
            if (filter.id === 'store') {
                if(value.startsWith('-')){
                    isNotStore = true
                    value = value.slice(1)
                }else{
                    isNotStore = false
                }
                value = value.split(/\s*,\s*/).filter(item => item !== '')
            }
            filters[filter.id] = value
        })
        if (!('status' in filters) || filters.status.length === 0) {
            filters.status = []
            filters.status.push(...defaultSearchParams.status)
        }
        tableRef.current.resetSelection()
        tableRef.current.resetPagination()
        setSearchParams({...searchParams, ...filters, not_store: isNotStore, page:1})
    }

    const getTotalStockUnitCount = (orderLines) => {
        let count = 0
        orderLines.forEach(ol => {
            count += ol.quantity
        })
        return count
    }

    const sameDayOrder = (code = "", carrier = "") => {
        let newJson = sameDay["codes"]
        
        let foundIndex = newJson.findIndex(def => def == code);

        if(foundIndex != -1 && carrier === 'TREGGO')  {
            return "Mismo día";
        }   else    {
            return "Estándar";
        }
    }

    const handleBulkPick = function(order) {
        setSelectedBulkPickOrder(order)
        setShowBulkPickConfirmation(true)
    }

    const cleanBulkPickState = function() {
        setSelectedBulkPickOrder(null)
        setShowBulkPickConfirmation(false)
    }

    const onConfirmBulkPick = async function(order) {
        setLoadingBulkPick(true)

        try {
            await pickAllOrderStocks(order.id)
        } catch (error) {
            window.alert(error.response.data?.error)
            console.log(error)
        }

        await refetch()
        cleanBulkPickState()
        setShowBulkPickSuccess(true)
        setLoadingBulkPick(false)
    }

    const buildActions = function (order) {
        const actions = [
            {
                title: "Ver orden",
                clickHandler: ()=> history.push(SHOW_ORDERS.replace(':id', order.id).replace(':warehouse_id', warehouse_id))
            },
            {
                title: "Generar guía",
                clickHandler: ()=> history.push(GENERATE_LABEL.replace(':id', order.id).replace(':warehouse_id', warehouse_id))
            }
        ]
        
        if (user?.permissions?.pick_all_order_stocks && order.assigned_stock_quantity && order.assigned_stock_quantity >= 20) {
            actions.push({
                title: "Pickear piezas",
                clickHandler: ()=> handleBulkPick(order)
            })
        }
        
        return actions
    }

    const buildWarehouseArea = order_lines =>
        [...new Set(order_lines.map(order_line => order_line.stock_units.map(stock_unit => stock_unit.warehouse_location?.area )).flat())].join(', ')


    const getTableData = () => {
        return data.orders.map((order)=>{
            if(order.store.name.length > 27) order.store.name = order.store.name.substring(0, 24) + '...'
            return {
                entity: {...order},
                store:<div className="">{String(order.store.name)}</div>,
                order_number: <div className="hover:underline cursor-pointer " onClick={()=>handlerOnClickStatus(order)}>#{String(order.order_number)}</div>,
                channel_name: order.channel_name,
                warehouse_area: order.warehouse_areas,
                shipping_method: order.shipping_method==null ? "-": `${order.shipping_method.carrier_name}`,
                pending_at: <FormattedDate date={order.pending_at} shortDate />,
                max_packing_time: <FormattedRelativeTime date={order.max_packing_time} calendarTime/>,
                assigned_stock_quantity: order.assigned_stock_quantity,
                shippment_type: order.is_same_day_delivery ? 'Mismo día' : 'Estándar',
                actions: (
                    <ActionMenu className="float-right" items={buildActions(order)}/>
                )
            }
        })
    }

    const getColumns = () => {
        if(shippingMethodsData) {
            let newColumns = [...table_columns]
            let index = newColumns.findIndex(column => column.accessor === "shipping_method")
            if(index === -1) {
                newColumns.splice(5,0,{
                    Header: 'MÉTODO DE ENVÍO',
                    accessor: 'shipping_method',
                    disableFilters: false,
                    disableSortBy: false,
                    Filter: SelectColumnFilter,
                    selectFilterOptions:shippingMethodsData.map((ship)=> {
                        return {
                            id: ship.carrier_name + " " + ship.shipping_name,
                            label: ship.carrier_name +" "+ ship.shipping_name
                        }
                    })
                })
            }
            return newColumns
        }
        return []
    }

    const memoizedColumns = useMemo(() => getColumns(), [shippingMethodsData])

    const [showAssignOrderDialog, setShowAssignOrderDialog] = useState(false)
    const onAssignOrders = () => {
        setShowAssignOrderDialog(true)
    }

    const onOrdersAssigned = () => {
        setShowAssignOrderDialog(false)
        setSearchParams({...defaultSearchParams})
        refetch()
        tableRef.current.resetSelection()
        tableRef.current.resetPagination()
    }

    const isBulkPickOrder = assigned_stock_quantity => assigned_stock_quantity > 1000

    const tableRef = useRef()

    return (
        <>
           <PageView
                topMenu={<PageTopBar>
                    <div className="text-lg font-semibold">Pedidos Pendientes</div>
                    {user?.permissions?.reassign_picking_order &&
                        <Button className="whitespace-nowrap mr-2" onClick={onAssignOrders} disabled={selectedOrders.length < 1}>
                            {`Asignar Pedidos (${selectedOrders.length})`}
                        </Button>
                    }
                    
                </PageTopBar>}
                childrenFullWidth={true}
                topMenuFullWidth={true}
                //bottomMenu={<Button>Crear Orden</Button>}
            >
                
                {(!isPreviousData && isLoading) || (!isPreviousDataShippingMethods && isLoadingShippingMethods) ? (
                    <><Loader show={true}></Loader></>
                ) : isError ? (
                    <>Error: {error.message}</>
                ) : isErrorShippingMethods ? (
                    <>Error: {errorShippingMethods.message}</>
                ):(

                        <>
                            <BasicTable
                                showHeader
                                showLoader={isFetching && isPreviousData}
                                columns={memoizedColumns}
                                filterable
                                sortable
                                showPaginator
                                selectable
                                data = {getTableData()}
                                onFilterChange={(activeFilters) => onFilterChange(activeFilters)}
                                onSortChange={(activeSort) => onSortChange(activeSort)}
                                paginationMeta={data?.meta}
                                labelToPaginator="Pedidos"
                                pagesSize = {searchParams.per_page}
                                onPaginationChange= { (requestedPage) => { setSearchParams({...searchParams, page: requestedPage}) } }
                                onPageSizeChange= { (pageSize) => {
                                    setSearchParams({...searchParams, per_page: pageSize, page: 1})
                                    dispatch(setOrdersListPageSize(pageSize))
                                    tableRef.current.resetPagination()
                                }}
                                onSelectionChange={(rows) => {
                                    setSelectedOrders(rows)
                                    // console.log("rows", rows)
                                }}
                                showDataStatus
                                isFetching={isFetching}
                                emptyTableText="No hay pedidos que mostrar."
                                ref={tableRef}
                            />

                        </>


                    )
                }
                {user?.permissions?.reassign_picking_order &&
                    <AssignOrdersDialog open={showAssignOrderDialog} orders={selectedOrders} onCancel={()=>setShowAssignOrderDialog(false)} onSuccess={() => onOrdersAssigned()}></AssignOrdersDialog>
                }
            </PageView>
            <ConfirmDialog
                open={showBulkPickConfirmation}
                setOpen={setShowBulkPickConfirmation}
                title={`Confirma pickear piezas de #${selectedBulkPickOrder?.order_number}`}
                description={`Confirma que deseas pickear todas las ${selectedBulkPickOrder?.assigned_stock_quantity} piezas de este pedido.`}
                confirmLabel="Confirmar"
                cancelLabel="Cancelar"
                onConfirm={() => onConfirmBulkPick(selectedBulkPickOrder)}
                onCancel={() => cleanBulkPickState()}
                loading={loadingBulkPick}
            />
            <Notification
                show={showBulkPickSuccess}
                setShow={setShowBulkPickSuccess}
                type="success"
                title="Orden pickeada correctamente"
            />
            <Switch>
                <Route exact path={SHOW_ORDERS}>
                    <SlidePanel title="Ver Orden" referrer={ORDERS.replace(':warehouse_id', warehouse_id)}>
                        <ShowOrderContainer />
                    </SlidePanel>
                </Route>
            </Switch>
        </>
    )
}