import { TABLE_COLUMNS, DOCUMENT_TYPES } from './constants';

const AttachmentsTable = ({shippingDocuments, onPrintDocument = (documentFile)=>{}}) => {
    return (
        <table className="w-full">
            <thead>
                <tr>
                    {TABLE_COLUMNS.map(column => (
                        <th
                            className="px-6 py-3 font-medium text-gray-500 bg-white tracking-wider border-t"
                            key={column.accessor}
                        >
                            {column['Header']}
                        </th>
                    ))}
                </tr>
            </thead>

            <tbody>
                {shippingDocuments?.map((document, index) => (
                    <tr key={`AttachmentsTable-${index + Math.random()}`}>
                        <td className="text-center p-2 whitespace-nowrap border-t">
                            {document.file.filename}
                        </td>

                        <td className="text-center p-2 whitespace-nowrap border-t">
                            {document.printing_type === DOCUMENT_TYPES.LABEL ? "Etiqueta" : "Documento"}
                        </td>

                        <td className="text-center p-2 whitespace-nowrap border-t">
                            <button
                                className="text-blue-600"
                                onClick={() => onPrintDocument(document.file)}
                            >
                                {'Imprimir'}
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default AttachmentsTable
