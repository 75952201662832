import { InputField } from "../../../../../components";

const FullNameData = ({shippingAddress, onShippingAddressChange, isEditDisabled}) => {
    return (
        <div className="flex gap-4">
            <InputField
                title={"Nombres"}
                value={shippingAddress.firstName}
                onChange={newValue => onShippingAddressChange({...shippingAddress, firstName: newValue})}
                disabled={isEditDisabled}
                className="w-2/4"
            />
            <InputField
                title={"Apellidos"}
                value={shippingAddress.lastName}
                onChange={newValue => onShippingAddressChange({...shippingAddress, lastName: newValue})}
                disabled={isEditDisabled}
                className="w-2/4"
            />
        </div>
    )
}

export default FullNameData