export const getStatusDefinition = (status = "", statuses = []) => {
  let foundIndex = statuses.findIndex(def => def.status === status)
  if (foundIndex === -1) {
    return statuses[0]
  }
  else {
    return statuses[foundIndex]
  }
}

export const ORDER_STATUSES = [
  {
    status: "unknown",
    label: "STATUS_NO_DEF",
    classes: "",
    filterable: false
  },
  {
    status: "initial",
    label: "Sincronizando",
    classes: "border-red-400 text-red-400",
  },
  {
    status: "empty",
    label: "Sin Piezas",
    classes: "border-red-400 text-red-400",
  },
  {
    status: "pending",
    label: "Pendiente",
    classes: "border-yellow-400 text-yellow-400",
  },
  {
    status: "processing",
    label: "Procesando",
    classes: "border-gray-500 text-gray-500",
  },
  {
    status: "packing",
    label: "Packing",
    classes: "border-blue-500 text-blue-500",
    filterable: false
  },
  {
    status: "picking",
    label: "Picking",
    classes: "border-blue-300 text-blue-300",
    filterable: false
  },
  {
    status: "packed",
    label: "Packed",
    classes: "border-blue-400 text-blue-400",
    filterable: false
  },
  {
    status: "shipped",
    label: "Enviado",
    classes: "border-green-500 text-green-500",
  },
  {
    status: "returned",
    label: "Retornado",
    classes: "border-green-700 text-green-700",
  },
  {
    status: "returning",
    label: "En Devolución",
    classes: "border-purple-500 text-purple-500",
  },
  {
    status: "canceled",
    label: "Cancelado",
    classes: "border-red-500 text-red-500",
  },
  {
    status: "error",
    label: "Falta Información",
    classes: "border-red-500 text-red-500",
  },
  {
    status: "backorder",
    label: "Sin Stock",
    classes: "border-red-400 text-red-400",
  },
  {
    status: "unpaid",
    label: "Sin pagar",
    classes: "border-pink-500 text-pink-500",
  },
  {
    status: "hold",
    label: "En pausa",
    classes: "border-blue-500 text-blue-500"
  },
  {
    status: "return_arrived",
    label: "Retornó a Cubbo",
    classes: "border-pink-500 text-pink-500"
  },
  {
    status: "reeterned",
    label: "Reingresado",
    classes: "border-pink-500 text-pink-500"
  }
];

export const ORDER_STATUSES_PROCESSING = [
 
  {
    status: "processing",
    label: "Procesando",
    classes: "border-gray-500 text-gray-500",
  },
  {
    status: "packing",
    label: "Packing",
    classes: "border-blue-500 text-blue-500",
  },
  {
    status: "picking",
    label: "Picking",
    classes: "border-blue-300 text-blue-300",
  }
];

export const ORDER_STATUSES_SHIPPED = [
  {
    status: "shipped",
    label: "Enviado",
    classes: "border-green-500 text-green-500",
  },
  {
    status: "returned",
    label: "Recibiendo retorno",
    classes: "border-green-700 text-green-700",
  },
  {
    status: "returning",
    label: "En Devolución",
    classes: "border-purple-500 text-purple-500",
  },
  {
    status: "waiting_pick_up",
    label: "Esperando recolección",
    classes: "bg-blue-50 text-blue-500",
    filterable: true
  },
  {
    status: "return_arrived",
    label: "Retornó a Cubbo",
    classes: "bg-pink-50 text-pink-500",
    filterable: true
  },
  {
    status: "reentered",
    label: "Reingresado",
    classes: "bg-pink-50 text-pink-500",
    filterable: true
  },
];

export const PICK_N_SHIP_STATUSES = [
  {
    status: "awaiting_pick_up",
    label: "Pendiente de recolectar",
    classes: "",
    filterable: true
  },
  {
    status: "picked_up",
    label: "En manos de Cubbo",
    classes: "border-blue-400 text-blue-400",
    filterable: true
  },
  {
    status: "in_warehouse",
    label: "Recibido en almacén",
    classes: "border-green-400 text-green-400",
    filterable: true
  },
]

export const TRACKING_STATUSES = [
  {
    status: "unknown",
    label: "Sin status",
    classes: "",
    filterable: false
  },
  {
    status: "shipment_not_defined",
    label: "Sin status",
    classes: "",
    filterable: false
  },
  {
    status: "shipment_created",
    // label: "En tránsito",
    label: "Guía generada",
    classes: "border-blue-400 text-blue-400",
  },
  {
    status: "shipment_collected",
    // label: "En tránsito",
    label: "Recolectado",
    classes: "border-blue-500 text-blue-500",
  },
  {
    status: "shipment_in_transit",
    label: "En tránsito",
    classes: "border-yellow-400 text-yellow-400",
  },
  {
    status: "shipment_delivered",
    label: "Entregado",
    classes: "border-green-400 text-green-400",
  },
  {
    status: "shipment_canceled",
    // label: "En tránsito",
    label: "Cancelado por paquetería",
    classes: "border-red-400 text-red-400",
  },
  {
    status: "shipment_returned",
    // label: "En tránsito",
    label: "Retornado por paqueteria",
    classes: "border-red-500 text-red-500",
  },
  {
    status: "shipment_with_incident",
    // label: "En tránsito",
    label: "Incidente",
    classes: "border-red-600 text-red-600",
  }
];

export const REPLENISHMENT_STATUSES = [
  {
    status: "unknown",
    label: "Sin status",
    classes: "",
    filterable: false
  },
  {
    status: "WITHOUT_SCHEDULING",
    label: "Sin Agendar",
    classes: "border-indigo-400 text-indigo-400",
  },
  {
    status: "REQUESTED",
    label: "Solicitado",
    classes: "border-yellow-400 text-yellow-400",
  },
  {
    status: "PENDING",
    label: "En progreso",
    classes: "border-blue-500 text-blue-500",
    filterable: false
  },
  {
    status: "PROCESSING",
    label: "Procesando",
    classes: "border-grey-500 text-grey-500",
  },
  {
    status: "COMPLETED",
    label: "Completado",
    classes: "border-green-500 text-green-500",
  },
    {
    status: "IN_TRANSIT",
    label: "En tránsito",
    classes: "border-green-500 text-green-500",
  },
  {
    status: "CANCELED",
    label: "Cancelado",
    classes: "border-red-500 text-red-500",
    filterable: false
  },
  {
    status: "RECEIVED",
    label: "Recibido en Almacén",
    classes: "border-blue-500 text-blue-500",
  },
  {
    status: "IN_PUTAWAY",
    label: "Put Away",
    classes: "border-blue-600 text-blue-600",
  }
];

export const CLAIMS_STATUSES = [
  {
    status: "unknown",
    label: "Sin status",
    classes: "",
    filterable: false
  },
  {
    status: "PENDING",
    label: "Pendiente",
    classes: "border-yellow-400 text-yellow-400",
  },
  {
    status: "REJECTED",
    label: "Rechazada",
    classes: "border-red-500 text-red-500",
  },
  {
    status: "ACCEPTED",
    label: "Aceptada",
    classes: "border-green-500 text-green-500",
  }
];

export const RETURNS_STATUSES = [
  {
    status: "unknown",
    label: "Sin status",
    filterable: false
  },
  {
    status: "REQUESTED",
    label: "Solicitado",
    classes: "border-blue-500 text-blue-500",
  },
  {
    status: "PENDING",
    label: "Decisión Pendiente",
    classes: "border-red-500 text-red-500",
  },
  {
    status: "CANCELLED",
    label: "Cancelado",
    classes: "border-red-400 text-red-400",
  },
  {
    status: "ORDERED",
    label: "Ordenado",
    classes: "border-green-500 text-green-500",
  },
  {
    status: "REENTERED",
    label: "Retorno reingresado",
    classes: "border-green-500 text-green-500",
  },
  {
    status: "ARRIVED_AT_WAREHOUSE",
    label: "Llegó al almacén",
    classes: "border-green-500 text-green-500",
  },
  {
    status: "RECEIVED",
    label: "statuses.returns_statuses.received",
    classes: "bg-teal-50 text-teal-600",
  }
];

export const RETURNS_STATUSES_SELECT = [
  {
    status: "unknown",
    label: "Sin status",
    filterable: false
  },
  {
    status: "REQUESTED",
    label: "Esperando retorno",
    classes: "border-blue-500 text-blue-500",
  },
  {
    status: "ARRIVED_AT_WAREHOUSE",
    label: "Llegó al almacén",
    classes: "border-pink-500 text-pink-500",
  },
  {
    status: "RECEIVED",
    label: "Recibiendo retorno",
    classes: "border-green-500 text-green-500",
  },
  {
    status: "REENTERED",
    label: "Retorno reingresado",
    classes: "border-green-500 text-green-500",
  },
  {
    status: "PENDING",
    label: "Decisión Pendiente",
    classes: "border-red-500 text-red-500",
  },
  {
    status: "CANCELLED",
    label: "Reenvío Cancelado",
    classes: "border-red-400 text-red-400",
  },
  {
    status: "ORDERED",
    label: "Reenvío ordernado",
    classes: "border-green-500 text-green-500",
  }
];

export const STOCK_STATUSES = [
  {
    status: "unknown",
    label: "Desconocido",
    classes: "",
    filterable: false
  },
  {
    status: "ACTIVE",
    label: "En venta",
    classes: "",
  },
  {
    status: "RESERVED",
    label: "Reservadas",
    classes: "",
  },
  // virtual status for reserved with initiated picking (orders in picking)
  {
    status: "RESERVED_IN_PICKING",
    label: "Reservadas para Picker",
    classes: "",
  },
  // virtual status for reserved awaiting tote (orders in processing)
  {
    status: "RESERVED_AWAITING_TOTE",
    label: "Reservadas Asignando Tote",
    classes: "",
  },
  {
    status: "DAMAGED",
    label: "Dañadas",
    classes: "",
  },
  {
    status: "RECEPTION",
    label: "Recepción",
    classes: "",
  },
  {
    status: "RECEPTION-INCOMING-SELL-AHEAD",
    label: "Recepción Preventa",
    classes: "",
  },
  {
    status: "RESERVED-RECEPTION-INCOMING-SELL-AHEAD",
    label: "Reservadas Preventa",
    classes: "",
  },
  {
    status: "LOST",
    label: "Perdidas",
    classes: "",
  },
  {
    status: "HOLDED",
    label: "Pausadas",
    classes: "",
  },
  {
    status: "EXPIRED",
    label: "Expiradas",
    classes: "",
  },
];

export const SHIPPING_INCIDENT_STATUSES = [
  {
    status: "PENDING",
    label: "orders.shipping_incident.status.PENDING",
  },
  {
    status: "IN_REVIEW",
    label: "orders.shipping_incident.status.IN_REVIEW",
  },
  {
    status: "RESOLVED",
    label: "orders.shipping_incident.status.RESOLVED",
  },
];

export const SHIPPING_INCIDENT_CATEGORIES = [
  {status: 'CANCELED', label: 'orders.shipping_incident.category.CANCELED'},
  {status: 'MISSING_REFERENCES', label: 'orders.shipping_incident.category.MISSING_REFERENCES'},
  {status: 'DELAYED', label: 'orders.shipping_incident.category.DELAYED'},
  {status: 'FALSE_DELIVERY', label: 'orders.shipping_incident.category.FALSE_DELIVERY'},
  {status: 'DAMAGED', label: 'orders.shipping_incident.category.DAMAGED'},
  {status: 'MISSING_PRODUCT', label: 'orders.shipping_incident.category.MISSING_PRODUCT'},
  {status: 'CHANGE_ADDRESS', label: 'orders.shipping_incident.category.CHANGE_ADDRESS'},
  {status: 'RESCHEDULE', label: 'orders.shipping_incident.category.RESCHEDULE'},
];
