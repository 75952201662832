import { LabelView } from "../Orders/GenerateShipment/LabelView"
import { jsPDF } from 'jspdf';
import JsBarcode from 'jsbarcode';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const GenerateReplenishmentLabel = ({
    replenishment_info = {},
    onSuccess = () => {},
    onCancel = () => {},
  }) => {
    const { t, i18n} = useTranslation()
    const [path, setPath] = useState(null)
    const [openDialogLabel, setOpenDialogLabel] = useState(false)

    useEffect ( ( )  =>  { 
        try {
            let replenishment_id = replenishment_info.replenishment?.id ? replenishment_info.replenishment.id : replenishment_info.id
            let store_name = replenishment_info.store.name
            if (replenishment_id) {

                const pdf = new jsPDF({
                    orientation: 'portrait',
                    unit: 'mm',
                    format: [76, 102]
                });

                let yPosition = 10;

                const addCenteredText = (text, fontSize, y, bold = false) => {
                    pdf.setFontSize(fontSize);
                    pdf.setFont('helvetica', bold ? 'bold' : 'normal');
                    pdf.text(text, pdf.internal.pageSize.getWidth() / 2, y, { align: 'center' });
                };

                const addBarcode = (id, y) => {
                    const canvas = document.createElement('canvas');
                    JsBarcode(canvas, id, { format: "CODE128" });
            
                    const barcodeImage = canvas.toDataURL('image/png');
                    const imgProps = pdf.getImageProperties(barcodeImage);
            
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const imgWidth = 70;
                    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
                    const x = (pdfWidth - imgWidth) / 2;
            
                    pdf.addImage(barcodeImage, 'PNG', x, y, imgWidth, imgHeight);
                };

                addCenteredText(`${t('replenishment_labels.store')}:`, 16, yPosition, true);
                yPosition += 8
                addCenteredText(store_name, 18, yPosition, false);
                yPosition += 8
                addCenteredText(`${t('replenishment_labels.replenishment_id')}:`, 16, yPosition, true);
                yPosition += 1;

                addBarcode(replenishment_id, yPosition);

                const pdfBlob = pdf.output('blob');
                const pdfUrl = URL.createObjectURL(pdfBlob);
                setPath(pdfUrl);
                setOpenDialogLabel(true);
            }
          } catch (error) {
              console.error("Error generating PDF:", error);
          }
    } ,  [replenishment_info] )

    return (
        <LabelView
            title={t('replenishment_labels.label_title')}
            type={"label"}
            file_path = {path}
            is_replenishment_label = {true}
            onCancel = {() => {
                setOpenDialogLabel(false)
                onCancel()
            }}
            onPrint = {() => {
                setOpenDialogLabel(false)
                onSuccess()
            }}
        />
    )
  }