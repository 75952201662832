import axios from "axios";
import axiosInstance from "../helpers/axiosInstance";
import { validateTransactionStatus, TRANSACTION_STATUS_SUCCESS } from "../services/concurrencyService"

export const fetchIntegrationShippingMethods = async (id) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/integration_shipping_methods/channel_integration/${id}`)
    return res.data
}

export const fetchCubboShippingMethods = async () => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/shipping_method`)
    return res.data
}
export const fetchWarehouseCubboShippingMethods = async (store_id) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/warehouses/${store_id}/shipping-methods`)
    return res.data
}

export const fetchOrderShippingPackages = async (order_id, includeGlobalPackages=true) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/fulfillment-tasks/shipping-packages/${order_id}`, {params: {global_packages: includeGlobalPackages}})
    return res.data
}

export const syncIntegrationsShippingMethods = async (id) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/channel_integrations/synchronize_shipping_methods/${id}`)
    return res.data
}

export const getShipingLabels= async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/shipping_label`, {params: params})
    return res.data
}

export const getLabel= async (id) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/shipping_label/${id}`)
    return res.data
}

/**
 *
 */
export const saveMatchShippingMethods = async (
    shipping_matches
) => {
    const data = {
        data:shipping_matches
    }
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/shipping_matches`, data)
    return res.data
}

const getShippingInfo = ( shippingAddress, shippingDetails, productsData, trackingNumber) => {
    const shippingPackages = shippingDetails.packages.map(shippingPackage => ({
        id: shippingPackage.id,
        quantity: 1,
        length_cm: shippingPackage.length_cm,
        width_cm: shippingPackage.width_cm,
        height_cm: shippingPackage.height_cm,
        weight_kg: shippingPackage.weight_kg,
        volumetric_weight: shippingPackage.volumetric_weight
    }))

    const data = {
        destination:{

            first_name: shippingAddress.firstName,
            last_name: shippingAddress.lastName,
            phone: shippingAddress.phone,
            email: shippingAddress.email,
            address1: shippingAddress.address,
            address2: shippingAddress.address2,
            country: shippingAddress.country.value,
            city: shippingAddress.city,
            province: shippingAddress.province,
            zip_code: shippingAddress.zipCode
        },
        shipping_details: {
            shipping_method: shippingDetails.shippingMethod.id,
            shipping_date: shippingDetails.shippingDate,
            description: shippingDetails.description,
            package_id: shippingDetails.package_id,
            packages: shippingPackages,
            packages_count: shippingDetails.packages_count
        },
        tracking_number: trackingNumber,
        ...(productsData && { shipment: { products_data: productsData }})
    }
    return data
}

export const generateLabel = async (
    shipment,
    shippingAddress,
    shippingDetails,
    trackingNumber
) => {
    const data = {
        ...getShippingInfo(shippingAddress, shippingDetails, shipment.productsData, trackingNumber),
    }
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/shipping_label/generate/${shipment.orderId}`, data)
    return res.data
}

export const generateGroupOrderLabel = async (
    referenceOrderId,
    storeId,
    groupingOrders,
    shippingDetails,
    shippingAddress
) => {
    
    const data = {
        shipping_details: {
            shipping_method: shippingDetails.shippingMethod ? shippingDetails.shippingMethod.id : null,
            shipping_date: shippingDetails.shippingDate,
            description: shippingDetails.description,
            package_id: shippingDetails.package_id,
            package: {
                id: shippingDetails.packages[0].id,
                quantity: 1
            }
        },
        destination:{
            first_name: shippingAddress.firstName,
            last_name: shippingAddress.lastName,
            phone: shippingAddress.phone,
            email: shippingAddress.email,
            address1: shippingAddress.address,
            address2: shippingAddress.address2,
            country: shippingAddress.country.value,
            city: shippingAddress.city,
            province: shippingAddress.province,
            zip_code: shippingAddress.zipCode
        },
        grouping_orders: groupingOrders,
        reference_order_id: referenceOrderId,
        store_id: storeId
    }
    try {
        const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/shipping-label/generate-shared-label`, data)
        if (res.status === 202 && res.data.transaction_id) {
            const transactionStatus = await validateTransactionStatus(res.data.transaction_id)
            console.log("transactionStatus: ", transactionStatus)
            if (transactionStatus != TRANSACTION_STATUS_SUCCESS) {
                throw new Error('Error')
            }
        }
        return res.data
    } catch (error) {
        throw error
    }
}
