import { useQuery, useQueryClient } from "react-query"
import { useHistory, useParams, useLocation } from "react-router-dom"
import {
  Button,
  ButtonLoader,
  ConfirmDialog,
  FormattedDate,
  Notification,
  SelectInput,
  TextareaField,
} from "../../components"
import { Loader } from "../../components/Loader"
import { SHOW_ORDERS } from "../../navigation/constants"
import { StatusPill } from "../../components/BasicTable/StatusPill"
import { CLAIMS_STATUSES } from "../../components/BasicTable/StatusPillDefinitions"
import {
  updateOperatorPwd
} from "../../services/operatorServices"
import { useCallback, useEffect, useMemo, useState } from "react"

export const OperatorDetailContainer = ({ onClose }) => {
  let { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const queryClient = useQueryClient()
  const {warehouse_id} = useParams()
  console.log(location.state)
  const [showClipboardCopied, setShowClipboardCopied] = useState(false)
  // const { isLoading, isFetching, isError, error, data, refetch } = useQuery(
  //   "claim",
  //   () => fetchClaim(id),
  //   {
  //     // refetchOnWindowFocus: false,
  //   }
  // )

  const [userPassword, setUserPassword] = useState(null)
  // const [selectedSubcategory, setSelectedSubcategory] = useState(null)
  const [resolution, setResolution] = useState(null)
  const [showResetNotification, setShowResetNotification] = useState(false)
  const [loadingSubmitReset, setLoadingSubmitReset] = useState(false)
  // const [showAcceptConfirmDialog, setShowAcceptConfirmDialog] = useState(false)
  // const [showRejectConfirmDialog, setShowRejectConfirmDialog] = useState(false)
  // const [loadingSubmitClaim, setLoadingSubmitClaim] = useState(false)
  // const [accepted, setAccepted] = useState(null)
  // const [acceptedValue, setAcceptedValue] = useState(null)

  // useEffect(() => {
  //   setSelectedCategory(findCategory(data?.category))
  //   setSelectedSubcategory(findSubcategory(data?.subcategory))
  // }, [data])

  // const claimCategories = useMemo(() => {
  //   let categories = [{ id: 0, name: "Ninguna", subcategories: [] }]
  //   categories = categories.concat(
  //     CLAIM_CATEGORIES.map((cat, index) => {
  //       return { ...cat, name: cat.label, id: index + 1 }
  //     })
  //   )
  //   // console.log("categories", categories)
  //   return categories
  // }, [true])

  // const subcategories = useMemo(() => {
  //   if (selectedCategory === null) {
  //     return [{ id: 0, name: "Ninguna" }]
  //   } else if (selectedCategory.id === 0) {
  //     return [{ id: 0, name: "Selecciona categoría" }]
  //   }
  //   let subcategories = [{ id: 0, name: "Ninguna" }]
  //   subcategories = subcategories.concat(
  //     selectedCategory.subcategories.map((subcat, index) => {
  //       return { name: subcat, id: index + 1 }
  //     })
  //   )
  //   // console.log("subcategories", subcategories)
  //   return subcategories
  // }, [selectedCategory])

  // const findCategory = (key) => {
  //   let foundIndex = claimCategories.findIndex((cat) => cat.key === key)
  //   // console.log("claimCategories[foundIndex]", claimCategories[foundIndex])
  //   if (foundIndex === -1) {
  //     foundIndex = 0
  //   }
  //   return claimCategories[foundIndex]
  // }

  // const findSubcategory = (subcategory) => {
  //   // console.log("findSubcategory-subcategories", subcategories)
  //   // console.log("subcategory", subcategory)
  //   let subCatIndex = subcategories.findIndex(
  //     (subcat) => subcat.name === subcategory
  //   )
  //   if (subCatIndex === -1) {
  //     subCatIndex = 0
  //   }
  //   // console.log("subcategories[subCatIndex]", subcategories[subCatIndex])
  //   return subcategories[subCatIndex]
  // }

  // const onChangeCategory = async (val) => {
  //   if (selectedCategory.id != val.id) {
  //     setSelectedCategory(val)
  //     let sentCat = val.key
  //     if (val.id === 0) {
  //       sentCat = null
  //     }
  //     try {
  //       await claimCategory(data.id, { category: sentCat, subcategory: null })
  //       setShowCategoryNotification(true)
  //     } catch (error) {
  //       console.log(error)
  //       window.alert(error)
  //     }
  //   }
  // }

  // const onChangeSubcategory = async (val) => {
  //   if (
  //     selectedSubcategory.id != val.id ||
  //     selectedSubcategory.name != val.name
  //   ) {
  //     setSelectedSubcategory(val)
  //     let sentSubcat = val.name
  //     if (val.id === 0) {
  //       sentSubcat = null
  //     }
  //     try {
  //       await claimCategory(data.id, {
  //         category: selectedCategory.key,
  //         subcategory: sentSubcat,
  //       })
  //       setShowCategoryNotification(true)
  //     } catch (error) {
  //       console.log(error)
  //       window.alert(error)
  //     }
  //   }
  // }

  // const onChangeResolution = (val) => {
  //   setResolution(val.trim())
  // }

  // const onAcceptClaim = async () => {
  //   setLoadingSubmitClaim(true)
  //   try {
  //     await acceptClaim(data.id, resolution, acceptedValue)
  //     queryClient.invalidateQueries('claims_pending_count')
  //     setShowAcceptConfirmDialog(false)
  //     refetch()
  //   } catch (error) {
  //     console.log(error)
  //     window.alert(error)
  //   }
  //   setLoadingSubmitClaim(false)
  // }

  // const onRejectClaim = async () => {
  //   setLoadingSubmitClaim(true)
  //   try {
  //     await rejectClaim(data.id, resolution)
  //     queryClient.invalidateQueries('claims_pending_count')
  //     setShowRejectConfirmDialog(false)
  //     refetch()
  //   } catch (error) {
  //     console.log(error)
  //     window.alert(error)
  //   }
  //   setLoadingSubmitClaim(false)
  // }

  // const onAcceptanceRadioChange = (val) => {
  //   if (val === "accepted") {
  //     setAccepted(true)
  //   } else {
  //     setAccepted(false)
  //   }
  // }

  // const disableSubmit = () => {
  //   if (resolution === null || resolution === "") {
  //     return true
  //   }
  //   if (accepted === null) {
  //     return true
  //   }
  //   if (accepted === true) {
  //     if (acceptedValue === null || acceptedValue === "") {
  //       return true
  //     }
  //   }
  //   return false
  // }
  const generatePwd = (id) => {
    let firstBlockChars = "abcdefghijklmnopqrstuvwxyz";
    let firstBlockPasswordLength = 3;
    let secondBlockChars = "0123456789";
    let secondBlockPasswordLength = 2;
    let thirdBlockChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let thirdBlockPasswordLength = 2;
    let password = "";
    for (var i = 0; i <= firstBlockPasswordLength; i++) {
      let randomNumber = Math.floor(Math.random() * firstBlockChars.length);
      password += firstBlockChars.substring(randomNumber, randomNumber +1);
    }
    password += "@";
    for (var i = 0; i <= secondBlockPasswordLength; i++) {
      let randomNumber = Math.floor(Math.random() * secondBlockChars.length);
      password += secondBlockChars.substring(randomNumber, randomNumber +1);
    }
    for (var i = 0; i <= thirdBlockPasswordLength; i++) {
      let randomNumber = Math.floor(Math.random() * thirdBlockChars.length);
      password += thirdBlockChars.substring(randomNumber, randomNumber +1);
    }
    setUserPassword(password)
  }

  return (
    <>
      <Notification
        show={showClipboardCopied}
        setShow={setShowClipboardCopied}
        type="success"
        title="Copiado!"
      />
      <Notification
        show={showResetNotification}
        setShow={setShowResetNotification}
        type="success"
        title="La password se actualizó correctamente!"
      />
      {/* <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">Applicant Information</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
      </div> */}
      <div className="mt-5 border-t border-gray-200">
        <dl className="divide-y divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">Nombre</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <span className="flex-grow">{location.state.user.first_name + " "+location.state.user.last_name}</span>
              <span className="ml-4 flex-shrink-0">
              </span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">Email</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <span className="flex-grow">{location.state.user.email}</span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">Reset Password</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    value={userPassword}
                    onChange={(e) => setUserPassword(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
              <span className="ml-4 flex-shrink-0">
                <ButtonLoader
                  type="primary"
                  className=""
                  loading={loadingSubmitReset}
                  onClick={async () => {
                    setLoadingSubmitReset(true)
                    let data = await updateOperatorPwd(location.state.user.email, userPassword)
                    setLoadingSubmitReset(false)
                    setShowResetNotification(true)
                  }}
                >
                  Reset
                </ButtonLoader>
              </span>
            </dd>
            <a onClick={() => generatePwd()}>Generar Password</a>
            <a onClick={() => {
                  navigator.clipboard.writeText(userPassword)
                  setShowClipboardCopied()
                }}>Copiar Password</a>
          </div>
        </dl>
      </div>
    </>
  )
}
