import axios from "axios";
import userToken from "../redux/userToken";

const isLogged = async () => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/user/refresh_token`, {}, {withCredentials: true})
        userToken.token = res.data.token
        return true
    } catch (e){
        return false
    }
}

export default isLogged