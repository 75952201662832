export const getReturnTypeDefinition = (type = "") => {
    let foundIndex = RETURN_TYPES.findIndex(def => def.type === type)
    if (foundIndex === -1) {
      return RETURN_TYPES[0]
    }
    else {
      return RETURN_TYPES[foundIndex]
    }
  }
  
export const RETURN_TYPES = [
    {
        type: "unknown",
        label: "Desconocido",
        classes: "",
        filterable: false
    },
    {
        type: "SIMPLE_RETURN",
        label: "Devolución",
        classes: "",
        filterable: true
    },
    {
        type: "EXCHANGE",
        label: "Cambio",
        classes: "",
        filterable: true
    },
    {
        type: "IMMEDIATE_EXCHANGE",
        label: "Cambio Inmediato",
        classes: "",
        filterable: true
    },
    {
        type: "RETURNED_BY_CARRIER",
        label: "Paquetería",
        classes: "",
        filterable: true
    },
    {
        type: "UNIDENTIFIED",
        label: "No identificado",
        classes: "",
        filterable: true
    }
]