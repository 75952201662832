import { UNITS_IDS, ERRORS } from "./constants"

export const getFromErrorCode = (priceRangeToValidate, isLastRange) => {
    const {from, to} = priceRangeToValidate
    let error = null

    if(from.value === null || from.value === undefined || from.value === ""){
        error = ERRORS.MISSING_FROM_VALUE
    } else if(!isLastRange && from.value > to.value){
        error = ERRORS.FROM_IS_GREATER_THAN_TO
    } else if(!isLastRange && from.value === to.value){
        error = ERRORS.FROM_AND_TO_ARE_EQUAL
    }
    return error
}

export const getToErrorCode = (priceRangeToValidate, isLastRange) => {
    const {from, to} = priceRangeToValidate
    let error = null

    if(!isLastRange && (to.value === null || to.value === undefined || to.value === "")){
        error = ERRORS.MISSING_TO_VALUE
    } else if(!isLastRange && from.value > to.value){
        error = ERRORS.FROM_IS_GREATER_THAN_TO
    } else if(!isLastRange && from.value === to.value){
        error = ERRORS.FROM_AND_TO_ARE_EQUAL
    }
    return error
}

export const getPriceErrorCode = (priceRangeToValidate) => {
    const {price} = priceRangeToValidate
    let error = null

    if(price.value === null || price.value === undefined || price.value === ""){
        error = ERRORS.MISSING_TO_VALUE
    }
    return error
}

export const validateExcessAndPriceMode = (priceRangeToValidate, isLastRange, isFirstRange, availableUnitOptions) => {
    const isPerUnitAvailable = availableUnitOptions.some(unitOption => unitOption === UNITS_IDS.PER_UNIT)
    const isTotalAvailable = availableUnitOptions.some(unitOption => unitOption === UNITS_IDS.TOTAL)
    const isExcessPerUnitAvailable = availableUnitOptions.some(unitOption => unitOption === UNITS_IDS.EXCESS_PER_UNIT)
    const isExcessTotalAvailable = availableUnitOptions.some(unitOption => unitOption === UNITS_IDS.EXCESS_TOTAL)

    const excess = priceRangeToValidate.excess.value
    const priceMode = priceRangeToValidate.price_mode.value

    if(!isLastRange && excess){
        return false
    } else if(isFirstRange && excess){
        return false
    } else if(!isPerUnitAvailable && !excess && priceMode === UNITS_IDS.PER_UNIT){
        return false
    } else if(!isTotalAvailable && !excess && priceMode === UNITS_IDS.TOTAL){
        return false
    } else if(!isExcessPerUnitAvailable && excess && priceMode === UNITS_IDS.PER_UNIT){
        return false
    } else if(!isExcessTotalAvailable && excess && priceMode === UNITS_IDS.TOTAL){
        return false
    }

    return true
}

export const getUnitErrorCode = (priceRangeToValidate, isLastRange, isFirstRange, availableUnitOptions) => {
    const {price_mode: priceMode, excess} = priceRangeToValidate
    let error = null

    if(priceMode.value === null || priceMode.value === undefined || priceMode.value === ""){
        error = ERRORS.MISSING_PRICE_MODE
    } else if((!isLastRange || isFirstRange) && excess.value){
        error = ERRORS.EXCESS_IS_ONLY_ALLOWED_AT_END
    } else if(!validateExcessAndPriceMode(priceRangeToValidate, isLastRange, isFirstRange, availableUnitOptions)){
        error = ERRORS.INVALID_UNIT_VALUE
    }
    return error
}

export const validatePriceConfiguration = (priceConfigurationToValidate, availableUnitOptions) => {
    if(!priceConfigurationToValidate || priceConfigurationToValidate.length === 0){
        return false
    }

    const isValid = priceConfigurationToValidate.every((priceRange, rowIndex, {length}) => {
        const isLastRow = rowIndex === (length - 1)

        if(getFromErrorCode(priceRange, isLastRow)){ return false }
        if(getToErrorCode(priceRange, isLastRow)){return false}
        if(getPriceErrorCode(priceRange)){return false}
        if(getUnitErrorCode(priceRange, isLastRow, rowIndex === 0, availableUnitOptions)){return false}

        return true
    })

    return isValid
}
