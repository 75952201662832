import { DocumentTextIcon } from '@heroicons/react/outline';

import { InformationCard } from "../../../../components";

const GeneratedLabelNotificationSection = ({isShippingLabelGenerated}) => {
    return (
        <InformationCard
            title={"Guías generadas"}
            description={"Ya se han generado guías para esta orden"}
            iconComponent={<DocumentTextIcon className="h-full" />}
            containerStyles={"bg-green-100 text-green-600"}
            isHidden={!isShippingLabelGenerated}
        />
    )
}

export default GeneratedLabelNotificationSection