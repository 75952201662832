import {useState} from "react";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import {debounce as _debounce} from 'lodash'

export const InputField = forwardRef(
    ({
         value,
         onChange,
         title=null,
         labelElement=null,
         type="text",
         disabled = false,
         placeholder,
         label,
         ...params
    }, ref) => {
//export const InputField = ({value, onChange, title=null, labelElement=null, placeholder, ...params}) => {
    const { className } = params
    useImperativeHandle(ref, () => ({
        focus: () => {
            inputRef.current.focus()
        }
    }))
    const inputRef = useRef(null)

    return (
        <div className={className}>
            { title && <label className="block text-sm font-medium text-gray-700">
                {title}
            </label>}
            {labelElement}
            <div className="mt-1">
                <input
                    type={type}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:opacity-50"
                    placeholder={placeholder}
                    onChange={(event) => {
                        if(onChange!=null){
                            onChange(event.target.value)
                        }
                    }}
                    value={value}
                    ref={inputRef}
                    disabled={disabled}
                />
            </div>
            { label && (
                <label className="block text-xs font-light text-gray-700">{label}</label>
            )}
        </div>
    )
})