import axios from 'axios'
import axiosInstance from "../helpers/axiosInstance";

export const login = async (email, password) => {
    const res = await axios.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/login`, {email, password}, {withCredentials: true})
    return res.data
}

export const getUser = async () => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/user`)
    return res.data
}