import { Tooltip } from "antd"
import {
  getStatusDefinition,
  STOCK_STATUSES,
} from "../../components/BasicTable/StatusPillDefinitions"

const StockDetailTooltip = ({ count }) => {
  return (
    <span className="">
      {count.map((stock_count, i) => (
        <span key={stock_count.status}>
          {getStatusDefinition(stock_count.status, STOCK_STATUSES).label}: {stock_count.count}
          <br></br>
        </span>
      ))}
    </span>
  )
}

export const TotalCountContainer = ({ total, countByStatus }) => {
  return (
    <div className="w-full">
          <span className="text-lg">
            Total: {total}
          </span>
          <br></br>
        <StockDetailTooltip count={countByStatus} />
        
    </div>
  )
}
