export const DamagedIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
    >
      <path fill="currentColor" d="M 7 2 C 6.448 2 6 2.448 6 3 L 6 8.7910156 C 6 11.76784 8.0861251 14.426553 11 14.912109 L 11 20 L 9 20 A 1.0001 1.0001 0 1 0 9 22 L 11.832031 22 A 1.0001 1.0001 0 0 0 12.158203 22 L 15 22 A 1.0001 1.0001 0 1 0 15 20 L 13 20 L 13 14.910156 C 15.83286 14.431875 18 11.966821 18 9 L 18 3 C 18 2.448 17.552 2 17 2 L 14.617188 2 L 12.902344 5 L 14.09375 5 C 14.46575 5 14.707016 5.3916094 14.541016 5.7246094 L 12.679688 9.4472656 C 12.510687 9.7852656 12.164156 10 11.785156 10 C 11.042156 10 10.558625 9.2177344 10.890625 8.5527344 L 11.667969 7 L 10.507812 7 C 10.128813 7 9.8873594 6.5957187 10.068359 6.2617188 L 12.382812 2 L 7 2 z"></path>
    </svg>
  )
}
