import { NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES, NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES } from "./constants"

export const findOrCreateBillingService = ({
    serviceType,
    serviceTypeCategory="NONE_APPLIES",
    billingServices=null,
    currency=null,
    storeId,
    warehouseId
}) => {
    const foundBillingService = billingServices?.find(({service_type, service_type_category}) => {
        return service_type === serviceType && service_type_category === serviceTypeCategory}
    )

    if(foundBillingService){
        return foundBillingService
    }
    const createdBillingService = {
        store_id: storeId,
        warehouse_id: warehouseId,
        service_type: serviceType,
        service_type_category: serviceTypeCategory,
        currency: currency,
        price_configuration: [{from:0,to:null,price:null,price_mode:null,range_exclusion:null,excess:false}]
    }
    return createdBillingService
}

export const deleteBillingService = ({
    serviceType,
    serviceTypeCategory="NONE_APPLIES",
    billingServices
}) => {
    if (!billingServices) {
        return []
    }

    const updatedBillingServices = billingServices.filter(({ service_type, service_type_category }) => {
        return !(service_type === serviceType && service_type_category === serviceTypeCategory)
    })

    return updatedBillingServices
}


export const getCurrency = (warehouseCountry) => {
    switch (warehouseCountry) {
        case 'MX':
            return 'MXN'
        case 'BR':
            return 'R$'
        default:
            console.log(`No currency found for ${warehouseCountry}`)
      }
}

export const getShippingLabelCategories = (warehouseCountry) => {
    switch (warehouseCountry) {
        case 'MX':
            return NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES
        case 'BR':
            return NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES
        default:
            console.log(`No categories found for ${warehouseCountry}`)
      }
}
