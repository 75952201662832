import {
    ORDERS,
    ORDERS_SHIPPED,
    CLAIMS,
    OPERATORS,
    REPLENISHMENTS,
    ORDERS_PROCESSING,
    RETURNS,
    LOCATIONS,
    ROOT,
    GENERATE_LABELS,
    BILLING,
    WORK_ORDERS,
} from "./constants";

export const  navigation = [
    { id: 0, name: 'Billing', href: BILLING, permission_key: 'create_billing_configuration', validate: true},
    { id: 1, name: 'Dashboard', href: ROOT, permission_key: 'read_order_stats', validate: true},
    { id: 2, name: 'Pendientes', href: ORDERS, permission_key: 'index_pending_orders', validate: true},
    { id: 3, name: 'Pick & Pack', href: ORDERS_PROCESSING, permission_key: 'index_processing_orders', validate: true},
    { id: 4, name: 'Envíos', href: ORDERS_SHIPPED, permission_key: 'index_shipped_orders', validate: true},
    { id: 5, name: 'Generar Guías', href: GENERATE_LABELS, permission_key: 'generate_labels_only', validate: false},
    { id: 6, name: 'Retornos', href: RETURNS, permission_key: 'create_and_recieve_returns', validate: true},
    { id: 7, name: 'Inventario', href: LOCATIONS, permission_key: 'index_inventory' , validate: true},
    { id: 8, name: 'Recibo', href: REPLENISHMENTS, permission_key: 'index_replenishments', validate: true},
    { id: 9, name: 'Disputas', href: CLAIMS, permission_key: 'index_claims', validate: true},
    { id: 10, name: 'Maquilas', href: WORK_ORDERS, permission_key: 'index_work_orders', validate: true},
    { id: 11, name: 'Operadores', href: OPERATORS, permission_key: 'index_operators'}
];  

export const userHasPermission = (user, permission_key) => {
    if (permission_key) {
        if ((user.permissions && (permission_key in user.permissions) && user.permissions[permission_key])) {
            return true
        }
        else {
            return false
        }
    }
    return true
}