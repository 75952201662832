import Select from 'react-select'

import { InputField } from "../../../../../components";

const AddressData = ({shippingAddress, onShippingAddressChange, isEditDisabled, countyOptions}) => {
    return (
        <>
            <InputField
                title={"Dirección"}
                value={shippingAddress.address}
                onChange={newValue => onShippingAddressChange({...shippingAddress, address: newValue})}
                disabled={isEditDisabled}
                className="w-full"
            />

            <InputField
                title={"Apartamento, local, colonia, etc. (opcional)"}
                value={shippingAddress.address2}
                onChange={newValue => onShippingAddressChange({...shippingAddress, address2: newValue})}
                disabled={isEditDisabled}
                className="w-full"
            />

            <div className="flex gap-4">
                <div className="w-2/4">
                    <span className='text-sm font-medium text-gray-700'>{'País'}</span>

                    <Select
                        options={countyOptions}
                        isDisabled={isEditDisabled}
                        value={shippingAddress.country}
                        onChange={newValue => onShippingAddressChange({...shippingAddress, country: newValue})}
                    />
                </div>

                <InputField
                    title={"Ciudad"}
                    value={shippingAddress.city}
                    onChange={newValue => onShippingAddressChange({...shippingAddress, city: newValue})}
                    disabled={isEditDisabled}
                    className="w-2/4"
                />
            </div>

            <div className="flex gap-4">
                <InputField
                    title={"Estado / Provincia"}
                    value={shippingAddress.province}
                    onChange={newValue => onShippingAddressChange({...shippingAddress, province: newValue})}
                    disabled={isEditDisabled}
                    className="w-2/4"
                />

                <InputField
                    title={"Código Postal"}
                    value={shippingAddress.zipCode}
                    onChange={newValue => onShippingAddressChange({...shippingAddress, zipCode: newValue})}
                    disabled={isEditDisabled}
                    className="w-2/4"
                />
            </div>
        </>
    )
}

export default AddressData