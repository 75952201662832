import {
  ActionMenu,
  BasicTable,
  ConfirmDialog,
  Notification,
  PageTopBar,
  PageView,
  SearchInput,
} from "../../components"
import { useQuery, useQueryClient } from "react-query"
import {UserContext} from "../../hooks/UserContext";
import {Link} from 'react-router-dom'
import { Route, Switch, useHistory,useParams } from "react-router-dom"
import { useRef, useState,useContext, useMemo } from "react"
import { Loader } from "../../components/Loader"
import { ButtonLoader } from "../../components"
import { ExpandibleRow } from "../../components/BasicTable/ExpandibleRow"
import { getSkus } from "../../utils/productUtils"
import {
  exportWarehouseLocations,
  fetchWarehouseLocations,
  enqueueLocationsExport,
  getLocationsExport
} from "../../services/locationServices"
import { TotalCountContainer } from "./TotalCountContainer"
import { SelectColumnFilter } from "../../components/BasicTable/modules/ColumnFiltersUI"
import { LOCATION_TYPES } from "./LocationTypes"
import { INVENTORY_HISTORY } from "../../navigation/constants"
import { setLocationsListPageSize } from '../../redux/pageSizeSlice';
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleIcon, DownloadIcon } from "@heroicons/react/outline";
import { TableImage } from "../../components/TableImage";



function getName(product) {
  //console.log("product")
  if (product.is_dropshipping) {
    return (
      <>
        {product.name}
        <br></br>
        <span
          className={
            "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mt-1 bg-red-100 text-red-800"
          }
        >
          Dropshipping
        </span>
      </>
    )
  } else if (product.is_kit) {
    return (
      <>
        {product.name}
        <br></br>
        <span
          className={
            "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mt-1 bg-blue-100 text-blue-800"
          }
        >
          Kit
        </span>
      </>
    )
  } else {
    return product.name
  }
}

export const ListLocationsContainer = () => {
  const history = useHistory()
  const {user} = useContext(UserContext)
  const [loadingButton, setLoadingButton] = useState(false)
  const {warehouse_id} = useParams()
  const dispatch = useDispatch()
  const pageSize = useSelector((state) => state.pageSize.locationsList)
  const [searchParams, setSearchParams] = useState({
    page: 1,
    per_page: pageSize
  })
  const tableRef = useRef()
  const queryClient = useQueryClient()

  const [exportDialogOpen, setExportDialogOpen] = useState(false)
  const [enqueueingExport, setEnqueueingExport] = useState(false)
  const [exportError, setExportError] = useState(null)
  const [exportNotificationOpen, setExportNotificationOpen] = useState(false)
  const [exportProgress, setExportProgress] = useState(0)
  const [exportFileUrl, setExportFileUrl] = useState(null)

  const permissions = useMemo(() => {
    return {
      showInventoryHistory: user ? user.permissions.index_inventory_history : false,
    }
  }, [user])

  const wrapWithIndigoSpan = text => (<span className="text-indigo-600 text-base">{text}</span>)

  const locationToExportDescription = location => (
      <>
          Con ubicación igual o parecida a {wrapWithIndigoSpan(location)}
      </>
  )
  const productSkuToExportDescription = sku => (
    <>
        Con SKU igual o parecido a {wrapWithIndigoSpan(sku)}
    </>
  )
  const productUpcToExportDescription = upc => (
    <>
        Con UPC igual o parecido a {wrapWithIndigoSpan(upc)}
    </>
  )
  const storeToExportDescription = store => (
    <>
        Con nombre de tienda igual o parecido a {wrapWithIndigoSpan(store)}
    </>
  )
  const locationTypeToExportDescription = type => (
    <>
        Con ubicación tipo {wrapWithIndigoSpan(type)}
    </>
  )
  const productNameToExportDescription = productName => (
      <>
          Con nobre de producto igual o parecido a {wrapWithIndigoSpan(productName)}
      </>
  )
  
  const ordersExportDescriptionBuilders = {
      'location_code': locationToExportDescription,
      'store': storeToExportDescription,
      'location_type': locationTypeToExportDescription,
      'product_name': productNameToExportDescription,
      'sku': productSkuToExportDescription,
      'upc': productUpcToExportDescription,
  }

  const buildExportDescription = function (tableFilters) {
    if (!tableFilters || !Object.keys(tableFilters).length)
        return (<p className="text-sm text-gray-500">Se exportaran todos los productos del almacén. Si deseas, usa los filtros para obtener un reporte específico.</p>)
    //console.log("tableFilters", tableFilters)
    const filtersDescriptions = Object.keys(tableFilters)
        .filter(filterKey => ordersExportDescriptionBuilders[filterKey] instanceof Function)
        .map(filterKey => ordersExportDescriptionBuilders[filterKey](tableFilters[filterKey]))
    //console.log("filtersDescriptions", filtersDescriptions)
    if (filtersDescriptions.length) {
        return (
            <>
                <div className="mt-3 text-base sm:mt-0 sm:ml-4 sm:text-left">
                    <p className="text-gray-500 mb-3">Se exportarán productos que cumplan con las siguientes condiciones: </p>
                    <ul className="list-disc ml-6">
                        {filtersDescriptions.map(description => <li>{description}</li>)}
                    </ul>
                </div>
            </>
        )
    } else {
        return (<p>Se exportaran todos los productos del almacén. Si deseas, usa los filtros para obtener un reporte específico.</p>)
    }
}

  const [exportDescription, setExportDescription] = useState(buildExportDescription(null))

  const queryEnabled = useMemo(() => {
    let filterParamKeys = Object.keys(searchParams).filter(key => searchParams[key] != null && searchParams[key] != "" && key !== 'page' && key !== 'per_page' && key !== 'order_by' && key !== 'order')
    let enableQuery = filterParamKeys.length > 0
    if (!enableQuery) queryClient.resetQueries("warehouse_locations", { exact: false })
    return enableQuery
  }, [searchParams])

  const { isLoading, isError, error, data, isFetching, isPreviousData } =
    useQuery(
      ["warehouse_locations", searchParams, warehouse_id],
      () => fetchWarehouseLocations(warehouse_id, searchParams),
      {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: queryEnabled
      }
    )

  const onSortChange = (orderBy) => {
    // console.log("ON_ORDER_BY",orderBy)
    if (orderBy.length > 0) {
      setSearchParams({
        ...searchParams,
        order_by: orderBy[0].id,
        order: orderBy[0].desc ? "desc" : "asc",
      })
    } else {
      if ("order_by" in searchParams) {
        delete searchParams.order_by
        delete searchParams.order
        setSearchParams({ ...searchParams })
      }
    }
  }

  const onFilterChange = (activeFilters) => {
    // console.log("ON_CHANGE_FILTER", activeFilters)
    columns.forEach((col) => {
      if (searchParams[col.accessor]) {
        delete searchParams[col.accessor]
      }
    })
    let filters = []
    activeFilters.forEach((filter) => {
      filters[filter.id] = filter.value
    })
    tableRef.current.resetPagination()
    const mergedFilters = {...searchParams, ...filters}
    setSearchParams({ ...mergedFilters, page: 1 })
    setExportDescription(buildExportDescription(mergedFilters))
    //console.log("searchParams",mergedFilters)
  }

  const columns = [
    {
      Header: "Imagen",
      accessor: "product_image_path",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Ubicación",
      accessor: "location_code",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Tienda",
      accessor: "store",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Tipo",
      accessor: "location_type",
      disableFilters: false,
      disableSortBy: true,
      Filter: cell => (
        <SelectColumnFilter
            column={cell.column}
            onSubmit = {value => {
                cell.column.setFilter(value)
                const activeFilters = cell.columns.reduce((acc, column) => {
                  if (column.id === cell.column.id) acc.push({ id: column.id, value: value })
                  else if (column.filterValue) acc.push({ id: column.id, value: column.filterValue })
                  return acc
                }, [])
                onFilterChange(activeFilters)
            }}
        />
      ),
      selectFilterOptions: LOCATION_TYPES.map((locationType) => {
        return {
          id: locationType,
          label: locationType,
        }
      }),
    },
    {
      accessor: "product_name",
      Header: "Nombre Producto",
      disableFilters: false,
      disableSortBy: true
    },
    {
      Header: "SKU",
      accessor: "sku",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "UPC",
      accessor: "upc",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Cantidad",
      accessor: "stock_total_count",
      disableFilters: true,
      disableSortBy: true,
      shrinkToContent: true,
    },
    {
      Header: "",
      fetchingIndicator: true,
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
      shrinkToContent: true,
    },
  ]

  const getMenuOptions = (location) => {
    const options = []
    return options
  }

  const getTableData = () => {
    if (!data?.locations) return []
    return data.locations.map((location) => {
      return {
        ...location,
        location_code: <div>{location.warehouse_location?.unique_code}</div>,
        location_type: <div>{location.warehouse_location?.location_type}</div>,
        store: <div>{location.product.store?.name}</div>,
        product_name: getName(location.product),
        sku: getSkus(location.product.product_codes),
        upc: <div>{location.product.bar_code}</div>,
        stock_total_count: <TotalCountContainer countByStatus={location.stock_count_by_status} total={location.stock_total_count} />,
        product_image_path: <TableImage thumbnail_image={location.product.product_image_path?.thumbnail_path} regular_image={location.product.product_image_path?.path} />,
        actions: (
          <ActionMenu
            className="float-right"
            items={getMenuOptions(location)}
          />
        ),
      }
    })
  }
  const handleExportDownload = function () {
      setExportNotificationOpen(false)
      setExportFileUrl(null)
      window.open(process.env.REACT_APP_PLATFORM_URL + exportFileUrl)
  }
  const exportNotificationBody = (
    <>
        <div className="w-0 flex-1 flex justify-between">
            {exportFileUrl &&
                <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                </div>
            }
            <p className="my-0 ml-2 w-0 flex-1 text-sm font-medium text-gray-900">{!exportFileUrl ? 'Generando archivo' : 'Archivo generado'}</p>
            {!exportFileUrl &&
                <p className="my-0 flex-shrink-0 text-base font-medium text-indigo-600">{exportProgress}%</p>
            }
            {exportFileUrl &&
                <button
                    type="button"
                    className="ml-3 flex-shrink-0 bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={handleExportDownload}
                >
                    Descargar
                </button>
            }
        </div>
    </>
)

  const onConfirmExport = async function () {
    setEnqueueingExport(true)
    const { job_id } = await enqueueLocationsExport({
        ...searchParams,
        id: warehouse_id,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })

    setExportFileUrl(null)
    watchExportProgress(job_id)

    setExportDialogOpen(false)
    setExportNotificationOpen(true)
    setEnqueueingExport(false)
}

const watchExportProgress = function (job_id) {
    const intervalId = setInterval(async () => {
        const {progress_percentage: progressPercentage, download_url: downloadUrl} = await getLocationsExport(job_id)
        if (progressPercentage < 100) {
            setExportProgress(progressPercentage)
        } else {
            setExportFileUrl(downloadUrl)
            setExportProgress(0)
            clearInterval(intervalId);
        }
    }, 1000)
}

  return (
    <>
      <ConfirmDialog
        open={exportDialogOpen}
        setOpen={ value => setExportDialogOpen(value) }
        onCancel={() => setExportDialogOpen(false)}
        title="Exportar inventario"
        description={exportDescription}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
        errorMessage={exportError}
        onConfirm={onConfirmExport}
        loading={enqueueingExport}
      />
      <Notification
        show={exportNotificationOpen}
        setShow={setExportNotificationOpen}
        customBody={exportNotificationBody}
        ephemeral={false}
      />
      <PageView
        topMenu={
          <PageTopBar>
            <div className="text-lg font-semibold">
              Inventario{" "}
              <span className="text-sm text-gray-400">
                Ubicaciones Por Producto - 
              </span>
              <span className="text-sm text-gray-400">
                 Almacén {warehouse_id}
              </span>
            </div>
            <>
              <span className="mr-5">
                <ButtonLoader className="whitespace-nowrap mr-1" type="secondary" onClick={() => setExportDialogOpen(true)}>
                    <DownloadIcon
                        className="mr-1 -ml-1 h-4 w-4"
                        aria-hidden="true"
                    />
                    Exportar 
                </ButtonLoader>
              </span>

              {permissions.showInventoryHistory && (
                <span>
                  <ButtonLoader
                    className="whitespace-nowrap"
                    type={"secondary"}
                    loading={loadingButton}
                    disabled={loadingButton}
                  >
                    <Link to={INVENTORY_HISTORY.replace(':warehouse_id', warehouse_id)} className="text-black hover:text-black">
                      Historial de inventario
                    </Link>
                  </ButtonLoader>
                </span>
              )}

            </>
          </PageTopBar>
        }
      >
        { isError ? (
          <>Error: {error.message}</>
        ) : (
          <>
            { !isPreviousData && isLoading && <Loader show={true}></Loader> }
            <BasicTable
              onFilterChange={onFilterChange}
              onSortChange={onSortChange}
              columns={columns}
              showPaginator
              showHeader
              filterable
              sortable
              paginationMeta={data?.meta}
              labelToPaginator="Ubicaciones"
              pagesSize = {searchParams.per_page}
              onPaginationChange= { (requestedPage) => { setSearchParams({...searchParams, page: requestedPage}) } }
              onPageSizeChange= { (pageSize) => {
                  setSearchParams({...searchParams, per_page: pageSize, page: 1})
                  dispatch(setLocationsListPageSize(pageSize))
                  tableRef.current.resetPagination()
              }}
              showLoader={isFetching && isPreviousData}
              isFetching={isFetching}
              data={getTableData()}
              emptyTableText="No hay productos que mostrar."
              queryOnClick
              ref={tableRef}
            />
          </>
        )}
      </PageView>
      {/* <Switch>
        <Route exact path={ADD_PRODUCT}>
          <SlidePanel title="Crear Producto">
            <AddProductContainer type="kit" edit={true} />
          </SlidePanel>
        </Route>
      </Switch> */}
    </>
  )
}
