export const SkeletonLoader = ({isLoading, children}) => {
    return (
        <>
            {isLoading ? (
                <div className="animate-pulse flex flex-col gap-5">
                    <div className="bg-gray-300 w-1/4 rounded h-3"></div>

                    <div className="flex flex-col gap-3">
                        <div className="flex flex-col gap-2">
                            <div className="bg-gray-300 rounded h-3"></div>
                            <div className="bg-gray-300 w-8/12 rounded h-3"></div>
                        </div>

                        <div className="flex flex-col gap-2">
                            <div className="bg-gray-300 rounded h-3"></div>
                            <div className="bg-gray-300 w-8/12 rounded h-3"></div>
                        </div>
                    </div>
                </div>
            ) :
                <>{children}</>
            }
        </>
    )
}
