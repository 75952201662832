import { configureStore } from '@reduxjs/toolkit';
import searchReducer from './searchSlice';
import pageSizeRangeReducer from './pageSizeSlice';
import createShipmentReducer from './createShipmentSlice';
import webcamRecorderReducer from './webcamRecorderSlice'

export const store = configureStore({
    reducer: {
        search: searchReducer,
        createShipment: createShipmentReducer,
        pageSize: pageSizeRangeReducer,
        webcamRecorder: webcamRecorderReducer,
    },
});
