export const getReturnNewOrderLabel = (type = "", status) => {
    let foundIndex = RETURN_TYPES_NEW_ORDER_LABEL.findIndex(def => def.value === type)
    if (foundIndex === -1) {
      return null
    }
    else {
      if (RETURN_TYPES_NEW_ORDER_LABEL[foundIndex][status]) {
        return RETURN_TYPES_NEW_ORDER_LABEL[foundIndex][status]
      }
      return "UNDEFINED"
    }
  }
  
 export const RETURN_TYPES_NEW_ORDER_LABEL = [
    {
      value: "SIMPLE_RETURN",
      REQUESTED: "No Aplica",
      RECEIVED: "No Aplica",
      REENTERED: "No Aplica",
    },
    {
      value: "EXCHANGE",
      REQUESTED: "Esperando retorno",
      ARRIVED_AT_WAREHOUSE: "Llegó al almacén"
    },
    {
      value: "RETURNED_BY_CARRIER",
      REENTERED: "No Aplica",
      CANCELLED: "Reenvío Cancelado",
      PENDING: "Decisión Pendiente",
      ARRIVED_AT_WAREHOUSE: "Llegó al almacén",
      REQUESTED: "Esperando retorno",
      RECEIVED: "No Aplica",
    },
    {
      value: "UNIDENTIFIED",
      REQUESTED: "No Aplica",
      RECEIVED: "No Aplica",
      REENTERED: "No Aplica",
    },
  ]