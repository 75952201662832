import S3Client from '../helpers/s3Client'

export const PACKING_PROOF_CONFIG = {
  bucketName: 'cubbo-packing-proofs-staging',
  dirName: 'packing_videos' /* optional */,
  region: 'us-east-2',
  accessKeyId: 'AKIA4NL5VMKBJ2EZEEHE',
  secretAccessKey: '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', // hardcoded for now
}

const MAX_CHUNK_SIZE = 5 * 1024 * 1024 // 5MB, note: parts must be min of 5MB except for the last part

export const uploadPackingProofVideo = async (orderId, videoBlob) => {
  console.log(`uploadPackingProofVideo start for order id ${orderId}`, videoBlob)
  const s3Client = new S3Client(PACKING_PROOF_CONFIG)
  await s3Client.createMultipartUpload(String(orderId))

  const chunks = splitBlobIntoChunks(videoBlob)
  console.log(`uploadPackingProofVideo blob split into ${chunks.length} chunks`)

  // run uploadPart in parallel and wait for all to finish
  await Promise.all(
    chunks.map(async (chunk, index) => {
      await s3Client.uploadPart(chunk, index)
      console.log(`uploadPackingProofVideo uploaded chunk ${index + 1} of ${chunks.length} with size ${chunk.size} bytes`)
    }),
  )

  const completeMultipartUpload = await s3Client.completeMultipartUpload()
  console.log(`uploadPackingProofVideo completeMultipartUpload`, completeMultipartUpload)

  return completeMultipartUpload
}

const splitBlobIntoChunks = (blob, chunkSize = MAX_CHUNK_SIZE) => {
  const chunks = []
  let start = 0
  while (start < blob.size) {
    chunks.push(blob.slice(start, start + chunkSize))
    start += chunkSize
  }
  return chunks
}
