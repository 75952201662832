import React, {useContext} from 'react';
import { Route, Redirect } from 'react-router-dom';
import {UserContext} from "../../hooks/UserContext";

const PublicRoute = ({component: Component, restricted, ...rest}) => {
    const { user, isLoading } = useContext(UserContext);

    if(isLoading) {
        return <>Loading</>
    }

    const render = props => {
        if (user && user.isLogged && restricted) {
            return <Redirect to={"/"} />;
        }

        return <Component {...props} />;
    };

    return <Route {...rest} render={render} />
};

export default PublicRoute;