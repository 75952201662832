import { useMemo, useState } from 'react';

import ProductsTable from './ProductsTable';
import ProductsTotal from './ProductsTotal';
import ProductListHeader from './ProductListHeader';
import { CollapseContainer } from '../../../../../components';

const ProductListSection = ({products, productSearch, productSearchRef, onSearchChange = (value) => {}, productSearchDependencies, productSearchAlertMsg, latestAddedProduct}) => {
    const productsWithStock = useMemo(() => Object.values(products).filter(({quantity}) => quantity > 0), [products])

    const pendingItemsToBeReviewed = useMemo(() => productsWithStock.reduce((accumulator, {quantity, reviewedItemsCount}) => {
        const reviewedQuantity = reviewedItemsCount || 0
        return accumulator + (quantity - reviewedQuantity)
    }, 0), [productsWithStock])

    const [collapseOptions, setCollapseOptions] = useState({
        collapse: false
    })

    const handleOnSearchChange = (newSearchValue) => {
        setCollapseOptions({...collapseOptions, collapse: false})
        onSearchChange(newSearchValue)
    }

    return (
        <section className='bg-white rounded-lg'>
            <CollapseContainer
                destroyOnHide={false}
                options={collapseOptions}
                onCollapsedText={"Ver productos"}
                onNotCollapsedText={"Ocultar productos"}
                isTogglePositionTop={false}
                containerStyles={"bg-white rounded-lg pt-4"}
                headerStyles={"px-4 pb-4"}
                footerStyles={`px-4 py-1 ${pendingItemsToBeReviewed > 0 ? "bg-yellow-200" : "bg-green-50"} rounded-b-lg mt-4`}
                headerComponent={
                    <ProductListHeader
                        pendingItemsToBeReviewed={pendingItemsToBeReviewed}
                        productSearch={productSearch}
                        productSearchRef={productSearchRef}
                        onSearchChange={handleOnSearchChange}
                        productSearchDependencies={productSearchDependencies}
                        productSearchAlertMsg={productSearchAlertMsg}
                    />
                }
                footerComponent={
                    <ProductsTotal
                        productsWithStock={productsWithStock}
                        pendingItemsToBeReviewed={pendingItemsToBeReviewed}
                    />
                }
            >
                <div className='max-h-60 overflow-y-auto'>
                    <ProductsTable
                        productsWithStock={productsWithStock}
                        focusedProduct={latestAddedProduct}
                    />
                </div>
            </CollapseContainer>
        </section>
    )
}

export default ProductListSection