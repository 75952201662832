import { useState } from "react"
import { useTranslation } from "react-i18next";

import ReplenishmentServices from "../../components/Billing/StoreBillingServices/ReplenishmentServices"
import ReturnServices from "../../components/Billing/StoreBillingServices/ReturnServices"
import StorageService from "../../components/Billing/StoreBillingServices/StorageService"
import PickAndPackServices from "../../components/Billing/StoreBillingServices/PickAndPackServices"
import NationalShippingLabelServices from "../../components/Billing/StoreBillingServices/NationalShippingLabelServices"
import InternationalShippingLabelServices from "../../components/Billing/StoreBillingServices/InternationalShippingLabelServices"
import StoreBillingConfig from "../../components/Billing/StoreBillingConfig"
import WorkOrdersServices from "../../components/Billing/StoreBillingServices/WorkOrdersServices"
import { BILLING_CONFIG_TABS } from "./constants"
import { Button } from "../../components"
import DifalServices from "../../components/Billing/StoreBillingServices/DifalServices";
import ShippingInsuranceServices from "../../components/Billing/StoreBillingServices/ShippingInsuranceServices";

export const BillingConfigTab = (
    {
        configKey,
        onContinue = (configKey) => {},
        onUpdateBillingServices,
        onUpdateBillingConfiguration,
        storeBillingServices,
        storeBillingConfiguration,
        storeShippingPackages,
        warehouseLocationVolumeCategories,
        currentIndex,
        lastIndex,
        onIsValid = (configKey, isValid) => {},
        storeId,
        warehouse
    }
) => {
    const { t, i18n} = useTranslation();
    const [isValid, setIsValid] = useState(false)

    const handleOnContinue = () => {
        if(isValid){
            onContinue(configKey)
        }
    }

    const handleOnIsValid = (newIsValid) => {
        setIsValid(newIsValid)
        onIsValid(configKey, newIsValid)
    }

    return (
        <>
            <div className="border-b pb-3 mt-3 flex justify-between items-center">
                <div className="text-lg font-bold">
                    {t(`billing.tabTitles.${configKey}`)}
                </div>
                <Button type={"primary"} disabled={!isValid} onClick={handleOnContinue} >
                    {currentIndex < lastIndex ? t('billing.continue') : t('billing.save')}
                </Button>
            </div>
            <div className='relative p-4'>
                <div className="space-y-1 mt-3">
                    {configKey === BILLING_CONFIG_TABS.BILLING_DATE && (
                        <StoreBillingConfig
                            storeBillingConfiguration={storeBillingConfiguration}
                            onUpdateConfig={onUpdateBillingConfiguration}
                            onIsValid={handleOnIsValid}
                        />
                    )}

                    {configKey === BILLING_CONFIG_TABS.STORAGE && (
                        <StorageService
                            storeBillingServices={storeBillingServices}
                            onUpdateStoreBillingServices={onUpdateBillingServices}
                            onIsValid={handleOnIsValid}
                            warehouseLocationVolumeCategories={warehouseLocationVolumeCategories}
                            storeId={storeId}
                            warehouse={warehouse}
                        />
                    )}

                    {configKey === BILLING_CONFIG_TABS.PICK_AND_PACK && (
                        <PickAndPackServices
                            storeBillingServices={storeBillingServices}
                            onUpdateStoreBillingServices={onUpdateBillingServices}
                            onIsValid={handleOnIsValid}
                            shippingPackages={storeShippingPackages}
                            storeId={storeId}
                            warehouse={warehouse}
                        />
                    )}

                    {configKey === BILLING_CONFIG_TABS.SHIPPING_LABEL && (
                        <NationalShippingLabelServices
                            storeBillingServices={storeBillingServices}
                            onUpdateStoreBillingServices={onUpdateBillingServices}
                            onIsValid={handleOnIsValid}
                            storeId={storeId}
                            warehouse={warehouse}
                        />
                    )}

                    {configKey === BILLING_CONFIG_TABS.INTERNATIONAL_SHIPPING_LABEL && (
                        <InternationalShippingLabelServices
                            storeBillingServices={storeBillingServices}
                            onUpdateStoreBillingServices={onUpdateBillingServices}
                            onIsValid={handleOnIsValid}
                            storeId={storeId}
                            warehouse={warehouse}
                        />
                    )}

                    {configKey === BILLING_CONFIG_TABS.REPLENISHMENT && (
                        <ReplenishmentServices
                            storeBillingServices={storeBillingServices}
                            onUpdateStoreBillingServices={onUpdateBillingServices}
                            onIsValid={handleOnIsValid}
                            storeId={storeId}
                            warehouse={warehouse}
                        />
                    )}

                    {configKey === BILLING_CONFIG_TABS.RETURNS && (
                        <ReturnServices
                            storeBillingServices={storeBillingServices}
                            onUpdateStoreBillingServices={onUpdateBillingServices}
                            onIsValid={handleOnIsValid}
                            storeId={storeId}
                            warehouse={warehouse}
                        />
                    )}

                    {configKey === BILLING_CONFIG_TABS.WORK_ORDERS && (
                        <WorkOrdersServices
                            storeBillingServices={storeBillingServices}
                            onUpdateStoreBillingServices={onUpdateBillingServices}
                            onIsValid={handleOnIsValid}
                            storeId={storeId}
                            warehouse={warehouse}
                        />
                    )}

                    {configKey === BILLING_CONFIG_TABS.SHIPPING_INSURANCE && (
                        <ShippingInsuranceServices
                            storeBillingServices={storeBillingServices}
                            onUpdateStoreBillingServices={onUpdateBillingServices}
                            onIsValid={handleOnIsValid}
                            storeId={storeId}
                            warehouse={warehouse}
                        />
                    )}

                    {warehouse?.country === 'BR' && (
                        <>
                            {configKey === BILLING_CONFIG_TABS.DIFAL && (
                                <DifalServices
                                    storeBillingServices={storeBillingServices}
                                    onUpdateStoreBillingServices={onUpdateBillingServices}
                                    onIsValid={handleOnIsValid}
                                    storeId={storeId}
                                    warehouse={warehouse}
                                />
                            )}
                        </>
                    )}

                </div>
            </div>
            <div className="pt-3 my-3 flex justify-end">
                <Button type={"primary"} disabled={!isValid} onClick={handleOnContinue} >
                    {currentIndex < lastIndex ? t('billing.continue') : t('billing.save')}
                </Button>
            </div>
        
            
        </>
    )
};