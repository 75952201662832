//import {DotsVerticalIcon, DotsHorizontalIcon} from "@heroicons/react/solid";
/*import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'*/
import PropTypes from 'prop-types'
//import {ActionMenuItem} from "./ActionMenuItem";
import { Menu, Dropdown, Button } from 'antd';
import {DotsHorizontalIcon} from "@heroicons/react/solid";


export const ActionMenu = ({className=null, items=[]}) => {

    ActionMenu.propTypes = {
        className: PropTypes.string,
        menuItem: PropTypes.arrayOf(PropTypes.element)
    }

    let classNameMenu = "relative inline-block text-left"
    if(className){
        classNameMenu += " " + className
    }

    const itemClickHandler = (e, index) => {
        e.stopPropagation()
        items[index].clickHandler()
    }
    const menu = (
        <Menu>
            {items.map((item, index)=>{
                return (
                    <Menu.Item key={index}>
                        <a className="cursor-pointer" 
                            onClick={(e) => {itemClickHandler(e, index)}}>
                            {item.title}
                        </a>
                    </Menu.Item>
                )
            })}
        </Menu>
    );

    return (
        <>
        {items.length > 0 &&
            <Dropdown onClick={(e)=> e.stopPropagation()} overlay={menu} placement="bottomLeft" className={classNameMenu}>
                <Button>
                    <DotsHorizontalIcon className=" h-5 w-5" aria-hidden="true" />
                </Button>
            </Dropdown>
        }
        </>
        /*<Menu as="div" className={classNameMenu}>
            {({ open }) => (
                <>
                    <div>
                        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-2 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                            <DotsHorizontalIcon className=" h-5 w-5" aria-hidden="true" />
                        </Menu.Button>
                    </div>

                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                            <div className="py-1" >
                                {items.map((item, index)=>{
                                    return (
                                        <Menu.Item key={index}>
                                            {({ active }) => <ActionMenuItem title={item.title} onClick={item.clickHandler} active={active}/>}
                                        </Menu.Item>
                                    )
                                })}
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>*/
    )
}