export const PackageOption = ({image, name, selectionTitle, isSelected}) => {
    return (
        <div className={`py-1 px-2 flex gap-2 ${isSelected ? "bg-gray-100 rounded-lg" : ""}`}>
            <img className="w-14" src={image} alt="Package option" />
            <div className="flex flex-col justify-center">
                <span className="text-sm leading-5">{selectionTitle}</span>
                <span className="font-medium text-lg leading-5">{name}</span>
            </div>
        </div>
    )
}

export default PackageOption