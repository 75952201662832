import { useQuery } from "react-query"
import { fetchOrder, findOrderReturn, fetchArrivedReturn } from "../../services"
import { fetchWarehouse } from "../../services/warehouseSevices";
import { Combobox } from "@headlessui/react"
import { useDispatch,  } from 'react-redux'
import { UserContext} from "../../hooks/UserContext";
import { useHistory, useParams } from "react-router-dom"
import {
  Button,
  ConfirmDialog,
  Loader,
  PageTopBar,
  PageView,
  SearchInput,
  DialogView,
  TextareaField,
  InputField,
} from "../../components"

import { TrashIcon, ChevronDownIcon, SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import { useEffect, useMemo, useState, useContext } from "react"
import { AutoComplete, Input, InputNumber, Select, Skeleton } from "antd"
import { DamagedIcon } from "../../components/Icons/DamagedIcon"
import { createReturn, receiveReturn, receiveUnidentifiedReturn } from "../../services/returnServices"
import { fetchStores} from "../../services/storeService"
import { RETURNS, RECEIVE_RETURN, SHOW_RECEIVING_ORDER, ORDERS_SHIPPED, SHOW_CREATING_RETURN_ORDER, CREATE_RETURN } from "../../navigation/constants"
import { ShowOrderContainer } from "../Orders/ShowOrderContainer"
import { useRef } from "react"
import { fetchProductsBySkyOrUpc, fetchProductLots } from "../../services/productServices"
import { XIcon } from "@heroicons/react/solid"
import { SearchIcon } from "@heroicons/react/outline"
import { CreateLotsDialog } from "../Replenishments/CreateLotsDialog"
import { TableImage } from "../../components/TableImage"
import { GenerateReplenishmentLabel } from "../Replenishments/GenerateReplenishmentLabel"

export const SearchReturnContainer = ({
  onSuccess = () => {},
  onCancel = () => {},
}) => {
  const {user} = useContext(UserContext)
  const dispatch = useDispatch()
  const {warehouse_id, scann_string, order_id} = useParams()
  const history = useHistory()
  const [foundProducts, setFoundProducts] = useState([])
  const [textToSearch, setTextToSearch] = useState("")
  const [exists, setExists] = useState(false)
  const [lastScannedProduct, setLastScannedProduct] = useState({id: 0})
  const searchInputRef = useRef()
  const [messageProductsNotFoundInOrder, setProductsNotFoundInOrder] = useState(false)
  const [openDialogLabel, setOpenDialogLabel] = useState(false)
  const [replenishmentInfo, setReplenishmentInfo] = useState(null)
  const [showCreateLotsDialog, setShowCreateLotsDialog] = useState(false)
  const [productToCreateLot, setProductToCreateLot] = useState(null)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [foundOrder, setFoundOrder] = useState(null)
  const [messageProductsNotFound, setProductsNotFound] = useState(false)
  const [productSearchIsLoading, setproductSearchIsLoading] = useState(false)
  const [selectedStore, setSelectedStore] = useState(null)
  const [storeSearch, setStoreSearch] = useState("")
  const [storeList, setStoreList] = useState([])
  const [fetchingStore, setFetchingStore] = useState(false)
  const [optionMessage, setOptionMessage] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [titleConfirmDialog, setTitleConfirmDialog] = useState("Recibir retorno")
  const [descriptionConfirmDialog, setDescriptionConfirmDialog] = useState("")
  const [submitAction , setSubmitAction] = useState(null)
  const [returnId, setReturnId] = useState(null)

  const [submitData, setSubmitData] = useState({
    return_id: null,
    order_id: null, 
    store_id: null,
    order_number: null,
    notes: "",    
    return_tracking_number: scann_string,
    scanned_string: scann_string,
    warehouse_id: warehouse_id,
    products: [],
    carrier_name: null
  })

  const resetSearch = async () => {
    setProductsNotFound(false)
    setTextToSearch("")
  }

  const renderStoreList =  useMemo(() => {
    setIsLoading(false)
    return  storeList.slice(0, 10)

  },[storeList])

  const {
    isLoading: isLoadingWarehouse,
    isError: isErrorWarehouse,
    error: errorWarehouse,
    data: warehouse,
    isPreviousData: isPreviousDataWarehouse,    
  } = useQuery('warehouse', () => fetchWarehouse(warehouse_id), { keepPreviousData: true }) 

  const uniqueSubmitableProducts = useMemo(() => {
    const submitableProducts = []
    submitData.products.map(product => {
      if(submitableProducts.findIndex(submitableProduct => submitableProduct.product_id == product.product_id) < 0) submitableProducts.push(product)
    })
    return submitableProducts
  }, [submitData])

  const handleSearchChange = async (val) => {
    setProductsNotFound(false)
    setproductSearchIsLoading(true)
    let search_res = null
    if (val !== "" ){
        
        if(search_res === null) {
          const storeId = submitData.store_id ? submitData.store_id  : orderData.store.id
          search_res = await fetchProductsBySkyOrUpc({search: val, is_kit: false, store_id: storeId})
        }
        
        setproductSearchIsLoading(false)
        if (search_res === null || search_res.products.length != 1) {
            //setFoundProduct(null)
            setExists(false)
            if (val === ""){
                setProductsNotFound(false)
            }
            setProductsNotFound(true)
            setLastScannedProduct({id: 0})
            
        } else {
            let foundProduct = search_res.products[0]
            let lots = []
            if(foundProduct.has_expiration) lots = await fetchProductLots(foundProduct.id)
            lots.map(l => {
              l.value = l.unique_code
              l.label = l.unique_code
            })
            if(lastScannedProduct.id != foundProduct) {
                /*let scannedProductLots = data.replenishment.lots.filter(lot => lot.product_id === search_res.product.id)
                if(!scannedProductLots.map(lot => lot.id).includes(activeLot))
                    setActiveLot(null)*/
            }
            setLastScannedProduct(foundProduct)
            if (search_res.exists && search_res.exists === true) {
                setExists(true)
            } else {
                setExists(false)
            }
            let newFoundProducts = [...foundProducts]
            if(foundProducts.findIndex(product => product.product_id == foundProduct.id) == -1){
              newFoundProducts.push({
                id: foundProduct.id,
                product_id: foundProduct.id,
                product_codes: foundProduct.product_codes,
                quantity: 0,
                quantity_damaged: 0,
                label: foundProduct.name,
                sku:
                foundProduct.product_codes.length > 0
                    ? foundProduct.product_codes[0].sku
                    : null,
                upc: foundProduct.bar_code,
                has_expiration: foundProduct.has_expiration,
                damaged: false,
                product_image_path: foundProduct.product_image_path,
                lots: lots, 
                selected_lot: null,
                selecting_lot: false
              })
            }else{
            }
            setProductsNotFound(false)
            setFoundProducts(newFoundProducts)
            searchInputRef.current?.resetInput()
        }
        setproductSearchIsLoading(false)
    }
  }

  const removeSelectedLot = (index) => {
    const prods = [...foundProducts]
    prods[index].selected_lot = null
    prods[index].selecting_lot = false
    setFoundProducts(prods)
  }

  const setSelectedLot = (lot, index) => {
    const prods = [...foundProducts]
    prods[index].selected_lot = lot
    setFoundProducts(prods)
  }

  const handleCreateLot = (product) => {
    setProductToCreateLot(product)
    setShowCreateLotsDialog(true)
  }

  const disableReceiveButton = product => (product.has_expiration && !product.selected_lot) || isDamaged(product)

  const isDamaged = (product) => {
   return false
  }

  const totalProductItems = useMemo(() => {
    let count = 0
    count = submitData.products.reduce((total, product) => total + product.quantity + product.quantity_damaged, 0)
    return count
  }, [submitData])

  const onSubmitConfirm = async () => {
    setLoadingSubmit(true)
    if(submitAction == "receive_unidentified_return" && warehouse.country == "BR") {
      history.push(RETURNS.replace(':warehouse_id', warehouse_id))
    }
    let response = null
    try {
      if(submitAction == "receive_return") { response = await receiveReturn(returnId, submitData) }
      else if(submitAction == "create_return") { response = await createReturn(submitData) }
      else if(submitAction == "receive_unidentified_return") { 
        response = await receiveUnidentifiedReturn(submitData) 
      }
      if (response) {
        setReplenishmentInfo(response)
        setLoadingSubmit(false)
        setShowConfirmDialog(false)
      }

    } catch (error) {
      setLoadingSubmit(false)
      console.log("error", error)
    }
  }

  const handleAddToReturnData = (product, index, damaged = false) => {
    let submitableProducts = submitData.products
    const submitableProductIndex = submitableProducts.findIndex(submitableProduct => 
      !submitableProduct.has_expiration ? submitableProduct.product_id == product.product_id : submitableProduct.product_id == product.product_id && product.selected_lot.unique_code == submitableProduct.selected_lot.unique_code
    )
    if(submitableProductIndex == -1){
      if(damaged) product.quantity_damaged = 1
      else product.quantity = 1
      submitableProducts.push(product)
    }else{
      if(damaged) submitableProducts[submitableProductIndex].quantity_damaged++
      else submitableProducts[submitableProductIndex].quantity++
      
    }

    setSubmitData({
      ...submitData,
      products: submitableProducts
    })
  
    removeProduct(index)
  }

  const removeProduct = (productIndex) => {
    let products = [...foundProducts]
    products.splice(productIndex, 1)
    setFoundProducts(products)
    console.log("products", products)
    /*let products = [...submitData.products]
    products.splice(productIndex, 1)
    setSubmitData({ ...submitData, products: [...products] })*/
  }

  const {
    isLoading: orderIsLoading,
    isFetching: orderIsFetching,
    isError: orderIsError,
    error: orderError ,
    data: orderData,
  } = useQuery(["order", order_id], () => fetchOrder(order_id), {
    enabled: order_id !== undefined,
    onSuccess: fetchedData => {
      setSubmitData({...submitData, order_id: fetchedData.id, store_id: fetchedData.store.id, carrier_name: fetchedData.shipping_method.carrier_name})
    }
  })


  const {
    isLoading: ArrivedReturnIsLoading,
    isFetching: ArrivedReturnIsFetching,
    isError: ArrivedReturnIsError,
    error: ArrivedReturnError ,
    data: ArrivedReturnData,
  } = useQuery(["ArrivedReturn", scann_string], () => fetchArrivedReturn(scann_string), {
    enabled: scann_string !== undefined,
    onSuccess: fetchedData => {
      setSubmitData({...submitData, return_tracking_number: fetchedData.scanned_return_label || scann_string, carrier_name: fetchedData.carrier_name})
    },
    onError: () => {
      setSubmitData({...submitData, return_tracking_number: scann_string})
    },
    staleTime: Infinity
  })


  const removeSubmitableProduct = (product) => {
    let products = submitData.products
    products = products.filter(prod => prod.product_id != product.product_id)
    setSubmitData({
      ...submitData,
      products: products,
    })
  }

  // search store
  useEffect(() => {
    if (!storeSearch || storeSearch === "") {
      setStoreList([])
      return
    }
    setTimeout(() => {
      searchStoreFunc(storeSearch.trim())
    }, 500)
  }, [storeSearch])

  useEffect(() => {
    if (selectedStore) {
      setSubmitData({
        ...submitData,
        store_id: selectedStore[0]
      })
    }
  }, [selectedStore])

  const searchStoreFunc = async (search_code) => {
    if (search_code === "") return
    setFetchingStore(true)
    try {
        let list = await fetchStores({name:search_code, warehouse_id: warehouse_id, has_billing_configuration: true})
        
          list = list.stores
          if (list.length > 10) setOptionMessage(true)
          else setOptionMessage(false)
          setStoreList(list)
      
    } catch (error) {
        console.log(error)
        setStoreList([])
    }
    setFetchingStore(false)
  }

  useEffect(() => {
    if (foundOrder !== null && submitAction!== null) {
      buildConfirmDialogDescription();
    }
    else if (submitAction == 'receive_unidentified_return'){
      buildConfirmDialogDescription();
    }
  }, [foundOrder, submitAction]);

  useEffect(() => {
    if ( replenishmentInfo !== null) {
      setOpenDialogLabel(true);
    }
  }, [replenishmentInfo]);

  const handleSearchOrder = async () => {
    const products = []
    setFoundOrder(null)
    submitData.products.forEach(product => {

      if(product.quantity_damaged > 0){
        const damaged_product = {...product,
          quantity: product.quantity_damaged,
          damaged: true
        }
        products.push(damaged_product)
        
        if(product.quantity > 0) {
          products.push({
            ...product,
            damaged: false
          })
        }
      }else{
        products.push(product)
      }
      
    })
    setSubmitData({
      ...submitData,
      products: products
    })
    
    setIsLoading(true)
    if ( orderData !== undefined){
      setSubmitAction("receive_unidentified_return")
        setIsLoading(false)
        setShowConfirmDialog(true)
    }
    else{
      try {
        let data = {
          scanned_string: scann_string,
          warehouse_id: warehouse_id, 
          store_id: submitData.store_id,
          order_number: submitData.order_number}

        let response = await findOrderReturn(data)
        
        if (response) {
          setSubmitData({
            ...submitData,
            order_id: response.id,
            store_id: response.store.id
          })
  
          if (response.return){
            let mustBeReceived = (response.return?.status == "REQUESTED" || response.return?.status == "ARRIVED_AT_WAREHOUSE" )
            if (mustBeReceived) { 
              setSubmitAction('receive_return') 
              setReturnId(response.return.id) 

            }
            else if (response.status == 'returned' || response.status == 'reentered'  && response.has_multi_packages == false){
              setSubmitAction("receive_unidentified_return")
            }
          }
          else{
            setSubmitAction('create_return')
          }
          setFoundOrder(response)
        }
        else {
          setSubmitAction("receive_unidentified_return")

        }
        setIsLoading(false)
        setShowConfirmDialog(true)
      } catch (e) {
        setIsLoading(false)
        console.log("error", e)
        let error = e.response?.data?.error
        let error_code = e.response?.data?.error_code
        if (error_code && error_code == "ORDER_NOT_FOUND") {
          console.log(error)
          setFoundOrder(e.response.data)
          setSubmitAction("receive_unidentified_return")
          setShowConfirmDialog(true)
        }
        else{
          alert("Ha ocurrido un error inesperado")
        }
      }
      setIsLoading(false)
    }
  }

  const setSelectingLotMode = (index) => {
    const prods = [...foundProducts]
    prods[index].selecting_lot = true
    setFoundProducts(prods)
  }

  const validForm = () => {
    console.log(submitData.products.length)
    console.log(submitData.return_tracking_number)
    console.log(submitData.products.length === 0 && submitData.return_tracking_number)
    console.log(submitData.products.length === 0 || submitData.return_tracking_number)
    if(submitData.products.length === 0 || submitData.return_tracking_number ){
      return true
    }
    return false
  }
  
  const buildConfirmDialogDescription = () => {
    console.log("submitAction", submitAction, foundOrder)
    let title = "";
    let description = "";
    let order = null

    if (orderData?.order_number || foundOrder?.order_number) {
      order = orderData || foundOrder;
    }
  
    if (order && order.order_number && submitAction === 'receive_unidentified_return' ) {
      title = "Recibir retorno";
      description = `<p>La orden <b>#${order.order_number}</b> de la tienda <b>${order.store.name}</b> fue identificada pero ya ha sido retornada por otro recibo, se generará un recibo de inventario para reingresar el producto</p>`;
    } else if ((order && order.order_number && (submitAction === 'create_return' || submitAction =='receive_return'))) {
      title = "Recibir retorno";
      description = `<div>
        <li><b>Número de Pedido:</b> #${order.order_number}</li>
        <li><b>Tienda:</b> ${order.store.name}</li>
        <li><b>Piezas retornadas:</b> ${totalProductItems} producto${totalProductItems > 1 ? "s" : ""}.</li>
      </div>`;
    } else if (warehouse.country === "BR") {
      title = "Não foi possível identificar o pedido de devolução";
      description = "<p>Como não é identificado um pedido dessa devolução, será impossível criar um acompanhamento, atualizar informações para busca</p>";
    } else {
      title = "Crear recibo de inventario para reingresar producto";
      description = "<p>Al no identificarse una orden de este retorno, se generará un recibo de inventario para recibir el producto</p>";
    }
  
    setTitleConfirmDialog(title);
    setDescriptionConfirmDialog(description);
  }

  return (
    <>
      <PageView className="bg-black"
          topMenu={
            <PageTopBar>
              <div className="text-lg font-semibold">

                    <a className="text-gray-400" onClick={() => {
                      history.push(ORDERS_SHIPPED.replace(':warehouse_id', warehouse_id))
                    }}>Recibos de retornos por paqueteria  →</a> Recibo de un retorno no identificable
              </div>
            </PageTopBar>
          }
          childrenFullWidth={true}
          topMenuFullWidth={true}
        >
         {(isLoading || orderIsLoading)  ? (
          <>
            <Loader show={true}></Loader>
          </>
        ) :
        (
        <div className="relative">
          <Loader show={false} />
          <div className="">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg font-semibold leading-6 text-gray-900">Recibiendo Retorno</h3>
                { orderData ? (
                <p className="mt-1 max-w-full text-sm text-gray-500">
                    
                  La orden <b> #{orderData.order_number} </b>de la tienda <b>{orderData.store.name} </b> fue identificada, pero ya ha sido retornada por otro recibio, se generará un recibo de inventario para recibir el producto
                </p>
                ):
                (
                  <p className="mt-1 max-w-full text-sm text-gray-500">
                    La guía <b>{scann_string}</b> no se ha podido vincular con ningún orden de retorno. Completa la siguiente información para iniciar una busqueda avanzada.
                  </p>
                )
                }
              </div>
              <div >
                <dl className="border-t border-gray-200 m-0">
                  <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                    { scann_string ? "" :<b className="text-red-500">*</b>} Guía de retorno/ Id de Pedido / Chave
                    </dt>
                    <dd
                      className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                    >

                    { scann_string ? (
                      <p>{scann_string}</p>
                      ):(
                        <InputField
                          className="sm:col-span-3"
                          placeholder="Escribe el número de guía de retorno"
                          onChange={(val) => setSubmitData({ ...submitData, return_tracking_number: val })}
                        /> 
                    )  
                 }

                    </dd>
                  </div>
                </dl>
                <dl className="border-t border-gray-200 m-0">
                  <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                     <b className="text-red-500">*</b> Tienda 
                    </dt>
                    <dd
                      className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                       <div className="sm:col-span-3 mt-2">
                                    { orderData ? (
                                      <p className="mt-1 max-w-full text-sm text-gray-500">
                                        {orderData?.store?.id} - {orderData.store.name}
                                      </p>

                                      ):
                                      (
                                        <>
                                            <Combobox value={selectedStore} onChange={setSelectedStore} >
                                              <div className="relative">
                                                  <div className="appearance-none block w-full px-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                                      
                                                      <Combobox.Input 
                                                      className="w-full border-none text-sm leading-5 text-gray-900 focus:ring-0"
                                                      displayValue={(_store) => {
                                                          return  Array.isArray(_store) ? _store[1] : _store
                                                      }}
                                                      onChange={(evt) => {
                                                        setStoreSearch(evt.target.value)
                                                      }} 
                                                      />
                                                      {renderStoreList.length > 0 &&
                                                      <Combobox.Button className="absolute right-1 mt-2 items-center pr-2">
                                                      <ChevronDownIcon
                                                          className="h-5 w-5 text-gray-400"
                                                          aria-hidden="true"
                                                      />
                                                      </Combobox.Button>
                                                      }
                                                  </div>
                                                  <Combobox.Options
                                                  className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                      {renderStoreList.length === 0 && !fetchingStore && storeSearch != "" ? (
                                                          <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                          Sin coincidencias
                                                          </div>
                                                      ) : (
                                                      renderStoreList.map((store) => (
                                                          <Combobox.Option 
                                                          key={store.id+store.name}
                                                          className={({ active }) =>`relative cursor-pointer select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-gray-800' : 'text-gray-900'}`}
                                                          value={[store.id, store.name]}>
                                                              {({ selected, active }) => (
                                                                  <>
                                                                      <span
                                                                      className={`block truncate ${
                                                                          selected ? 'font-bold' : 'font-normal'
                                                                      }`}
                                                                      >
                                                                      {store.id} - {store.name}
                                                                      </span>
                                                                      {selected ? (
                                                                      <span
                                                                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                                          active ? 'text-teal-500' : 'text-teal-600'
                                                                          }`}
                                                                      >
                                                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                      </span>
                                                                      ) : null}
                                                                  </>
                                                              )}

                                                          </Combobox.Option>
                                                      )))
                                                      }
                                                      {optionMessage && renderStoreList.length > 0 &&
                                                            <Combobox.Option
                                                              disabled
                                                              className="relative cursor-pointer select-none py-2 pl-10 pr-4"
                                                              key={"externo"}>
                                                              Existen más coincidencias... 
                                                            </Combobox.Option>
                                                      }
                                                  </Combobox.Options>
                                              </div>
                                          </Combobox>
                                          {<p className="text-sm text-red-600">{selectedStore == '' || selectedStore == null}</p>}
                                        </>

                                      )}
                            </div>

                    </dd>
                  </div>
                </dl>
                {( orderData?.store?.id  || submitData.store_id) && (
                  <>
                    <dl className="border-t border-gray-200 m-0">
                      <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Número de Pedido {orderData?.order_number ?  "" : "(OPCIONAL)"}</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          
                            { orderData?.order_number ? (
                              <p className="mt-1 max-w-full text-sm text-gray-500">

                                {orderData?.order_number}
                              </p>
                              ):(
                              <InputField  value ={submitData.order_number} onChange={(val) => setSubmitData({ ...submitData, order_number: val })} />

                            )}
                        
                        </dd>
                      </div>

                    </dl>
                    <dl className="border-t border-gray-200 m-0">
                      <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Notas</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          
                          <TextareaField  value ={submitData.notes} onChange={(val) => setSubmitData({ ...submitData, notes: val })} />
                        
                        </dd>
                      </div>

                    </dl>
                    
                  </>
                )}
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t border-gray-200 m-0">
                  <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-3">
                    <div className="text-sm font-bold mb-4">Escanea para recibir los productos retornados</div>
                    <div className="mb-0 text-gray-500">También deberás registrar a qué lote pertenece (en caso de ser un producto con lote).</div>
                  </dd>
                  <dd className="mt-1 text-sm text-gray-400 sm:mt-0 sm:col-span-1">
                    <SearchInput debounce="30" dependencies={[foundProducts, submitData]} inputValue={textToSearch} className="w-full my-2" onChange={handleSearchChange} onReset={() => {resetSearch()}} ref={searchInputRef}/>
                  </dd>
                  <dd className="mt-1 pt-4 text-sm text-gray-400 sm:mt-0 sm:col-span-2">
                    {messageProductsNotFound && "No se encontraron productos"}
                    {messageProductsNotFoundInOrder && "Este produto não pertence ao pedido"}
                  </dd>
              </div>
             
              <div className="bg-white pb-5 w-full">
                  <div className="w-full text-left">
                    <div className="bg-gray-50 px-6">
                      <div className="text-gray-500 uppercase grid grid-cols-12">
                        <div className="py-3">Imagen</div>
                        <div className="py-3 col-span-2">Producto</div>
                        <div className="col-span-2 py-3">UPC</div>
                        <div className="col-span-2 py-3">SKU</div>
                        <div className="col-span-2 py-3">Lote</div>
                        <div className="py-3">Piezas retornadas sin daños</div>
                        <div className="py-3">Piezas retornadas con daños</div>
                      </div>
                    </div>
                    
                    <div className="p-3">
                      {foundProducts.map((product, index) => (
                        <div className="text-gray-500 grid grid-cols-12 border rounded-lg mb-3"
                          key={
                            product.damaged
                              ? String(product.product_id) + "D"
                              : String(product.product_id)
                          }
                        >
                          <div className="p-3 py-6">
                            <TableImage
                              thumbnail_image= {product.product_image_path?.thumbnail_path}
                              regular_image= {product.product_image_path?.path}
                            ></TableImage>
                          </div>
                          <div className="p-3 py-6 col-span-2">
                            {product.label}{" "}
                            {product.damaged && (
                              <i className="text-red-500">(Piezas Dañadas)</i>
                            )}
                          </div>
                          <div className="col-span-2 py-6">{product.upc ? product.upc : "Sin UPC"}</div>
                          <div className="col-span-2 py-6">
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mx-1">
                              {product.sku}
                            </span>
                          </div>
                          <div className="py-6 col-span-2">
                            {product.has_expiration ? <>
                                {product.selected_lot && 
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-blue-800 text-white mx-1">{product.selected_lot.unique_code}
                                  <a onClick={() => removeSelectedLot(index)}><XIcon className="text-white ml-1 h-4 w-4" aria-hidden="true" /></a>
                                  </div>}
                                {!product.selected_lot && 
                                  <button onClick={() => setSelectingLotMode(index)} className="rounded-xl border-4 items-center border-gray-400 w-4/5 p-3 text-md mb-4">Selecciona o crea el lote del producto retornado.</button>}
                                {!product.selected_lot && product.selecting_lot && <AutoComplete dropdownClassName=""
                                  className="w-full rounded-t-xl border-2 border-gray-400 bg-gray-100"
                                  defaultActiveFirstOption={false}
                                  options={product.lots} 
                                  autoFocus={true}
                                  filterOption={true}
                                  onSelect={(value, option) => {
                                    setSelectedLot(option, index)
                                  }}
                                  notFoundContent={
                                    <Button className="px-2 has-tooltip" dense onClick={() => handleCreateLot(product)}>
                                      <span className="tooltip rounded shadow-lg p-1.5 bg-black text-white -mt-12">
                                        Si no encuentras el lote del producto puedes crear uno.
                                      </span>
                                      Crear un nuevo lote
                                    </Button>
                                  }>
                                  <Input size="large" bordered={false}  placeholder="Buscar lote" className="placeholder-black" prefix={<SearchIcon className="text-black mr-1 h-4 w-4" aria-hidden="true" />}/>
                                </AutoComplete> }
                            </> : 'No requiere lote'}
                            
                            
                          </div>
                          <div className="py-6">
                            <Button className={`px-2 ${disableReceiveButton(product) ? 'has-tooltip' : ''}`} dense disabled={disableReceiveButton(product)} onClick={() => handleAddToReturnData(product, index)}>
                              <span className="tooltip rounded shadow-lg p-1.5 bg-black text-white -mt-12">
                                {isDamaged(product) ? 
                                "El producto está marcado como dañado en el retorno" :
                                product.has_expiration ? <>Para poder recibir éste producto,<br/>primero debes seleccionar <strong>a qué<br/>lote pertenece</strong>.</> : ''
                              }
                              </span>
                              Recibir
                            </Button>
                          </div>
                          <div className="py-6">
                            <Button className="px-2 has-tooltip" disabled={product.has_expiration && !product.selected_lot} dense type="danger" onClick={() => handleAddToReturnData(product, index, true)}>
                              {product.has_expiration && !product.selected_lot && <span className="tooltip rounded shadow-lg p-1.5 bg-black text-white -mt-12">
                                Para poder recibir éste producto,<br/>primero debes seleccionar <strong>a qué<br/>lote pertenece</strong>.
                              </span>}
                              Dañado
                            </Button>
                          </div>
                          <div className="py-6 text-right pr-3">
                            <Button className="px-2 has-tooltip ml-2" dense type="secondary" onClick={() => removeProduct(index)}>
                              <span className="tooltip rounded shadow-lg p-1.5 bg-black text-white -mt-12">
                                Quitar producto
                              </span>
                              <TrashIcon className="w-4 h-4" />
                            </Button>
                          </div>
                        </div>
                      ))}
                      {productSearchIsLoading && <Skeleton active title={false}></Skeleton>}
                    </div>
                  </div>
              </div>

              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dd className="mt-1 text-sm text-gray-400 sm:mt-0 sm:col-span-3">
                  <div className="text-lg font-bold mb-4">Productos recibidos del retorno</div>
                </dd>
              </div>
              <div className="bg-white pb-5 w-full">
                <div className="w-full text-left">
                  <div className="bg-gray-50 px-6">
                    <div className="text-gray-500 font-sm uppercase grid grid-cols-12">
                    <div className="py-3">Imagen</div>
                      <div className="py-3 col-span-2">Producto</div>
                      <div className="py-3 col-span-2">UPC</div>
                      <div className="py-3">SKU</div>
                      <div className="py-3">Piezas recibidas</div>
                      <div className="col-span-2 py-3">Lote</div>
                      <div className="py-3">Piezas retornadas sin daños</div>
                      <div className="py-3">Piezas retornadas con daños</div>
                    </div>
                  </div>
                  <div className="p-3">
                    {uniqueSubmitableProducts.map(submitableProduct => {
                      return <>
                        <div className="text-gray-500 grid grid-cols-12 border rounded-lg mb-3 my-6"
                          key={
                            submitableProduct.damaged
                              ? String(submitableProduct.product_id) + "D"
                              : String(submitableProduct.product_id)
                          }
                        >
                          <div className="p-3 py-6">
                            <TableImage
                              thumbnail_image= {submitableProduct.product_image_path?.thumbnail_path}
                              regular_image= {submitableProduct.product_image_path?.path}
                            ></TableImage>
                          </div>
                          <div className="p-3 py-6 col-span-2">
                            {submitableProduct.label}{" "}
                            {submitableProduct.damaged && (
                              <i className="text-red-500">(Piezas Dañadas)</i>
                            )}
                          </div>
                          <div className="py-6 col-span-2">{submitableProduct.upc ? submitableProduct.upc : "Sin UPC"}</div>
                          <div className="py-6">
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mx-1">
                              {submitableProduct.sku}
                            </span>
                          </div>
                          <div className="py-6">
                            {submitData.products.filter(product => product.product_id == submitableProduct.product_id)
                              .reduce((total, product) => total + product.quantity + product.quantity_damaged, 0)
                            }
                          </div>
                          <div className="col-span-5">
                            {submitData.products.filter(product => product.product_id == submitableProduct.product_id).map((product, index) => {
                              return <div className={`grid grid-cols-5 mr-3 ${product.has_expiration && submitData.products.filter(product => product.product_id == submitableProduct.product_id).length > 1 ? 'border rounded-lg my-3' : ''}`}>
                                  <div className="py-6 pl-1 col-span-2">
                                    {product.has_expiration ?
                                      <div className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-blue-800 text-white mx-1">{product.selected_lot.unique_code}</div> :
                                      'No requiere lote'
                                    }
                                  </div>
                                  <div className="py-6">
                                    {product.quantity}
                                  </div>
                                  <div className="py-6">
                                  {product.quantity_damaged}
                                  </div>
                                  <div className="py-6 text-right pr-3">
                                    <Button
                                      className="px-2 has-tooltip ml-2"
                                      dense
                                      type="secondary"
                                      onClick={() => removeSubmitableProduct(product)}
                                    >
                                      <span className="tooltip rounded shadow-lg p-1.5 bg-black text-white -mt-12">
                                        Quitar producto
                                      </span>
                                      <TrashIcon className="w-4 h-4" />
                                    </Button>
                                  </div>
                                </div>
                            })}
                          </div>
                          
                        
                      </div>
                        </>
                    })
                      
                    }
                  </div>
                </div>
              </div>
              <div className="flex place-content-end p-3 px-3">
                <Button
                  className="mr-2"
                  onClick={() => {
                    history.push(RETURNS.replace(':warehouse_id', warehouse_id))
                  }}
                  type="secondary"
                >
                  Cancelar
                </Button>
                <Button
                  disabled={submitData.products.length === 0 || submitData.return_tracking_number ==  null}
                  className=""
                  onClick={() => handleSearchOrder()}
                >
                  { orderData ? (
                    <p>"Recibir Retorno" {submitData.return_tracking_number}</p> 
                    ):(
                     "Buscar Pedido"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
        )}

            <ConfirmDialog
              open={showConfirmDialog}
              setOpen={(val) => setShowConfirmDialog(val)}
              cancelLabel="Cancelar"
              confirmLabel="Confirmar"
              description={descriptionConfirmDialog}
              isHtmlDescription={true}
              title={titleConfirmDialog}
              loading={loadingSubmit}
              onConfirm={() => onSubmitConfirm()}
            />
            <CreateLotsDialog open={showCreateLotsDialog} 
              onSuccess={(lots) => {
                setShowCreateLotsDialog(false)
                const products = [...foundProducts]
                const index = products.findIndex(product => product.product_id == productToCreateLot.id)
                products[index].lots = [...products[index].lots,...lots]
                products[index].selected_lot = lots[0]
                //refetch()
                setFoundProducts(products)
              }}
              onCancel={()=>setShowCreateLotsDialog(false)}
              lots={[]} products={[productToCreateLot]} replenishment_products={[]} replenishment_id={0}
            ></CreateLotsDialog>
             <DialogView className="w-full" setOpen={setOpenDialogLabel} open={openDialogLabel} >
                <GenerateReplenishmentLabel
                  replenishment_info={replenishmentInfo}
                  onCancel = {() => {
                    setOpenDialogLabel(false)
                    history.push(RETURNS.replace(':warehouse_id', warehouse_id))
                  }}
                  onSuccess = {() => {
                    setOpenDialogLabel(false)
                    history.push(RETURNS.replace(':warehouse_id', warehouse_id))
                  }}
                />
              </DialogView>

      </PageView>

    </>
  )
}
