import axiosInstance from "../helpers/axiosInstance";

export const fetchClaims = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/claims`, { params })
    return res.data
}

export const fetchClaim = async (claimId) => {
    if(claimId===undefined){
        return null
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/claims/${claimId}`)
    return res.data
}

export const updateClaim = async (claimId,data) => {
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/claims/${claimId}`, data)
    return res.data
}

export const createClaim = async (data) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/claims`, data)
    return res.data
}

export const updateClaimArea = async (claimId, data) => {
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/claims/${claimId}/area`, data)
    return res.data
}

export const acceptClaim = async (claimId, resolution, acceptedValue) => {
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/claims/${claimId}/accept`, {resolution: resolution, accepted_value: acceptedValue})
    return res.data
}

export const rejectClaim = async (claimId, resolution) => {
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/claims/${claimId}/reject`, {resolution: resolution})
    return res.data
}

export const resetClaim = async (claimId) => {
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/claims/${claimId}/reset`)
    return res.data
}

export const countPendingClaims = async (warehouseId = null, storeId = null) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/claims/count-pending`, { params: {store_id: storeId, warehouse_id: warehouseId} })
    return res.data
}

export const fetchUsages = async (orderId) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store-billing-service-usages/order-usages`, { params: {order_id: orderId} })
    return res.data
}
