import React, { useEffect, useMemo, useState } from 'react'
import BottomFixedOverlay from '../BottomFixedOverlay'
import { INCIDENT_CATEGORIES, INCIDENT_RESOLUTIONS, INCIDENT_STATUSES, SHIPPING_STATUS_UPDATE } from './IncidentStatusDefinitions'

import { ChevronLeftIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'

const TAB_SELECT_ACTION = 'SELECT_ACTION'
const TAB_CREATE_INCIDENT = 'CREATE_INCIDENT'
const TAB_UPDATE_INCIDENT = 'UPDATE_INCIDENT'
const TAB_UPDATE_SHIPPING = 'UPDATE_SHIPPING'

const ShippedOrderMultiselection = ({
  selectedOrders,
  onCreateIncidents = category => {},
  onSetIncidentsResolution = resolution => {},
  onSetIncidentsStatus = status => {},
  onSetUpdateShippingStatus = ( shipping_status, description) => {},
  onDeselectOrders = () => {},
  enableCreateIncident = false,
  enableUpdateIncidentStatus = false,
  enableUpdateShippingStatus = false,
  ...props
}) => {
  const { i18n } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [activeTab, setActiveTab] = useState(TAB_SELECT_ACTION)

  useEffect(() => {
    if (!selectedOrders) return
    if (selectedOrders.length > 0) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [selectedOrders])

  useEffect(() => {
    if (visible) {
      setActiveTab(TAB_SELECT_ACTION)
    }
  }, [visible])

  const ActiveTab = () => {
    switch (activeTab) {
      case TAB_SELECT_ACTION:
        return <SelectActionTab />
      case TAB_CREATE_INCIDENT:
        return <CreateIncidentTab />
      case TAB_UPDATE_INCIDENT:
        return <UpdateIncidentTab />
      case TAB_UPDATE_SHIPPING:
        return <UpdateShippingTab />
      default:
        return <></>
    }
  }
  const disable_update_incident_button = () => {
    if (selectedOrders.length == countOrdersWithoutIncidents) {
      return true
    }
    return false 
  }

  const SelectActionTab = () => {
    return (
      <>
        <div className="mb-4 flex justify-between">
          <span className="font-bold text-base">{i18n.t('orders.incidents_bar.select_action')}</span>
          <span className="font-normal text-base cursor-pointer text-blue-400 hover:text-blue-300" onClick={() => onDeselectOrders()}>{i18n.t('orders.incidents_bar.deselect_all_orders')}</span>
        </div>
        <div className="flex flex-wrap">

          {enableCreateIncident && (
            <div className="flex-col w-1/8 mr-3 mb-2">
              <button className={`rounded-md bg-white border border-gray-300 shadow-sm p-2 outline-none`} onClick={() => setActiveTab(TAB_CREATE_INCIDENT)}>
                {i18n.t('orders.list.create_incident')}
              </button>
            </div>
          )}

          {enableUpdateIncidentStatus && (
            <div className="flex-col w-1/8 mr-3 mb-2">
              <button className={`rounded-md bg-white border border-gray-300 shadow-sm p-2 outline-none ${disable_update_incident_button() ? 'text-gray-400' : ''}`} onClick={() => setActiveTab(TAB_UPDATE_INCIDENT)}
                disabled={disable_update_incident_button()} 
                title={disable_update_incident_button() ? i18n.t(`orders.shipping_incident.cant_update`) : null} 
              >
                {i18n.t('orders.incidents_bar.update_incident')}
              </button>
            </div>
          )}

          {enableUpdateShippingStatus && (
            <div className="flex-col w-1/8 mr-3 mb-2">
              <button className={`rounded-md bg-white border border-gray-300 shadow-sm p-2 outline-none`} onClick={() => setActiveTab(TAB_UPDATE_SHIPPING)}>
                {i18n.t('orders.incidents_bar.update_shipping_status')}
              </button>
            </div>
          )}
        </div>
      </>
    )
  }

  const CreateIncidentTab = () => {
    return (
      <>
        <div className="mb-4">
          <span className="font-bold text-base">{i18n.t('orders.incidents_bar.create_incident')}</span>
        </div>
        <div className="flex flex-wrap">
          <div className="flex-col min-w-max mr-6 mb-2">
            <button className="rounded-md bg-transparent border border-blue-500 text-blue-500 shadow-sm p-2 outline-none" onClick={() => setActiveTab(TAB_SELECT_ACTION)}>
              <ChevronLeftIcon className="w-3 h-3 inline" />
              {i18n.t('orders.incidents_bar.create_incident')}
            </button>
          </div>
          {Object.values(INCIDENT_CATEGORIES).map(incident_category => {
            const button_disabled = ["MISSING_REFERENCES", "MISSING_PRODUCT", "DAMAGED", "CHANGE_ADDRESS", "RESCHEDULE"].includes(incident_category)
            return <div key={incident_category} className="flex-col w-1/8 mr-3 mb-2">
              <button className={`rounded-md bg-white border border-gray-300 shadow-sm p-2 outline-none ${button_disabled ? 'text-gray-400' : ''}`} 
                //Temporarily disabled
                disabled={button_disabled} 
                title={button_disabled ? i18n.t(`orders.shipping_incident.cant_create.temporarily_disabled`) : null} 
                onClick={() => onCreateIncidents(incident_category)}>
                  <span>{i18n.t(`orders.shipping_incident.category.${incident_category}`)}</span>
              </button>
            </div>
          })}
        </div>
      </>
    )
  }

  const UpdateIncidentTab = () => {
    return (
      <>
        <div className="mb-4">
          <span className="font-bold text-base">{i18n.t('orders.incidents_bar.update_current_status_for')}</span>
        </div>
        <div className="flex">
          <div className="flex flex-col justify-center min-w-max mr-6 mb-2">
            <button className="rounded-md bg-transparent border border-blue-500 text-blue-500 shadow-sm p-2 outline-none" onClick={() => setActiveTab(TAB_SELECT_ACTION)}>
              <ChevronLeftIcon className="w-3 h-3 inline" />
              {i18n.t('orders.incidents_bar.update_incident')}
            </button>
          </div>
          <div className="flex flex-row flex-wrap w-auto mr-3 mb-2">
            <div className=" mr-3 mb-2">
            <button className="rounded-md bg-white border border-gray-300 shadow-sm p-2 outline-none" onClick={() => onSetIncidentsStatus(INCIDENT_STATUSES.PENDING)}>
                <span>{i18n.t('orders.shipping_incident.status.PENDING')}</span>
              </button>
            </div>
            <div className=" mr-3 mb-2">
              <button className="rounded-md bg-white border border-gray-300 text-pink-600 shadow-sm p-2 outline-none" onClick={() => onSetIncidentsStatus(INCIDENT_STATUSES.IN_REVIEW)}>
                <span>{i18n.t('orders.shipping_incident.status.IN_REVIEW')}</span>
              </button>
            </div>
            {Object.values(INCIDENT_RESOLUTIONS).map(resolution => (
              <div key={resolution} className=" mr-3 mb-2">
                <button className="rounded-md bg-white border border-gray-300 text-green-500 shadow-sm p-2 outline-none" onClick={() => onSetIncidentsResolution(resolution)}>
                    {i18n.t('orders.shipping_incident.status.RESOLVED')}: {i18n.t(`orders.shipping_incident.resolution.${resolution}`)}
                </button>
              </div>
            ))}
          </div>
        </div>
      </>
    )
  }

  const UpdateShippingTab = () => {
    return (
      <>
        <div className="mb-4">
          <span className="font-bold text-base">{i18n.t('orders.incidents_bar.update_current_shipping_status_for')}</span>
        </div>
        <div className="flex flex-wrap">
          <div className="flex-col w-1/8 mr-3 mb-2">
            <button className="rounded-md bg-transparent border border-blue-500 text-blue-500 shadow-sm p-2 outline-none" onClick={() => setActiveTab(TAB_SELECT_ACTION)}>
              <ChevronLeftIcon className="w-3 h-3 inline" />
              {i18n.t('orders.incidents_bar.update_shipping_status')}
            </button>
          </div>
          {Object.values(SHIPPING_STATUS_UPDATE).map(shipping_status => (
              <div key={shipping_status.label} className="flex-col w-1/8 mr-3 mb-2">
                <button className="rounded-md bg-white border border-gray-300 shadow-sm p-2" onClick={() => onSetUpdateShippingStatus(shipping_status.status, shipping_status.description)}>
                    {i18n.t(`statuses.tracking_statuses.${shipping_status.label}`)}
                </button>
              </div>
          ))}
        </div>
      </>
    )
  }

  const countOrdersWithoutIncidents = useMemo(() => selectedOrders?.filter(order => order.shipping_incidents?.length === 0).length, [selectedOrders])
  const countOrdersWithIncidentsOfStatus = status =>
    selectedOrders?.filter(order => order.shipping_incidents?.some(incidence => incidence.status === status)).length
  const countOrdersWithPendingIncidents = useMemo(() => countOrdersWithIncidentsOfStatus(INCIDENT_STATUSES.PENDING), [selectedOrders])
  const countOrdersWithReviewIncidents = useMemo(() => countOrdersWithIncidentsOfStatus(INCIDENT_STATUSES.IN_REVIEW), [selectedOrders])
  const countOrdersWithResolvedIncidents = useMemo(() => countOrdersWithIncidentsOfStatus(INCIDENT_STATUSES.RESOLVED), [selectedOrders])

  const CurrentlySelectedOrders = () => {
    return (
      <div className="border-t-2 pt-2 my-4">
        <span className="font-normal text-gray-500 lowercase mr-6">
          {selectedOrders.length} <span className="">{i18n.t('orders.incidents_bar.orders_selected')}</span>
        </span>
        <span className="font-bold text-gray-500 border-r border-gray-700 mr-2 pr-2">
          {i18n.t('orders.shipping_incident.status.NO_INCIDENT')}:{' '}
          <span className="font-normal lowercase">
            {countOrdersWithoutIncidents} {i18n.t('orders.incidents_bar.orders')}
          </span>
        </span>
        <span className="font-bold text-gray-500 border-r border-gray-700 mr-2 pr-2">
          {i18n.t('orders.shipping_incident.status.PENDING')}:{' '}
          <span className="font-normal lowercase">
            {countOrdersWithPendingIncidents} {i18n.t('orders.incidents_bar.orders')}
          </span>
        </span>
        <span className="font-bold text-gray-500 border-r border-gray-700 mr-2 pr-2">
          {i18n.t('orders.shipping_incident.status.IN_REVIEW')}:{' '}
          <span className="font-normal lowercase">
            {countOrdersWithReviewIncidents} {i18n.t('orders.incidents_bar.orders')}
          </span>
        </span>
        <span className="font-bold text-gray-500 ">
          {i18n.t('orders.shipping_incident.status.RESOLVED')}:{' '}
          <span className="font-normal lowercase">
            {countOrdersWithResolvedIncidents} {i18n.t('orders.incidents_bar.orders')}
          </span>
        </span>
      </div>
    )
  }

  return (
    <BottomFixedOverlay centered visible={visible} className="w-10/12">
      <div className={`shadow-md rounded rounded-lg border border-blue-300 shadow-xl bg-gray-100 px-6 pt-4`}>
          <ActiveTab />
          <CurrentlySelectedOrders />
      </div>
    </BottomFixedOverlay>
  )
}

export default ShippedOrderMultiselection
