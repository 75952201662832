import PageViewFooter from './PageViewFooter';

export const PageView = ({topMenu=null, topMenuFullWidth=true, bottomMenu=null, children, childrenFullWidth=true, bottomMenuStyles=null, errorMessage=null}) => {
    return (
        <>
            <div className="h-16 min-h-16 bg-blue-500 flex-shrink-0" >
                <div className="h-full bg-white">
                    <div className={"mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 " + (childrenFullWidth ? "lg:max-w-full" : "lg:max-w-7xl")} >
                        <div className=" align-middle inline-block min-w-full  sm:px-6 lg:px-8 ">
                            {topMenu}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-auto flex-grow  overflow-y-scroll">
                <div className="relative bg-white mt-5 h-full bg-gray-100">
                    <div className={"mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 " + (childrenFullWidth ? "lg:max-w-full" : "lg:max-w-7xl")}>
                        <div className="flex flex-col">

                                <div className="align-middle inline-block min-w-full  sm:px-6 lg:px-8">
                                    <div className=" border-gray-200 sm:rounded-lg mb-3">
                                        {children}
                                    </div>
                                </div>

                        </div>
                    </div>
                </div>
            </div>

            <PageViewFooter
                bottomMenu={bottomMenu}
                errorMessage={errorMessage}
                bottomMenuStyles={bottomMenuStyles}
            />
        </>
    )
}