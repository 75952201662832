import { SearchInput } from "../../../../../components";

const ProductSearch = ({alertMsg, search, inputRef, onSearchChange = (value) => {}, dependencies}) => {
    return (
        <div className="flex flex-col justify-center w-2/5">
            <SearchInput
                inputValue={search}
                selectedInitial={search}
                placeholder = "Escanea la etiqueta de un producto"
                hideIcons = {true}
                ref={inputRef}
                debounce= {250}
                onChange={onSearchChange}
                onPaste={(e) => {e.preventDefault()}}
                dependencies= {[dependencies]}
                disabledSearchOnChange={true}
            />

            {alertMsg && <p className="mt-1 text-sm text-red-600">{alertMsg}</p>}
        </div>
    )
}

export default ProductSearch