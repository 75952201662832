import { InputQuantity } from "../../../../../components";

const PackageQuantitySelect = ({initialPackageQuantity, onUpdateQuantity = (newQuantity)=>{}}) => {
    return (
        <div className="p-4 border-t flex justify-between">
            <span className="font-medium text-lg flex items-center">{'Paquetes'}</span>
            <InputQuantity
                initialValue={initialPackageQuantity}
                updateData={onUpdateQuantity}
            />
        </div>
    )
}

export default PackageQuantitySelect