import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { cloneDeep } from "lodash"
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline"

import { CurrencyInputField } from "../../../CurrencyInputField"
import BillingPriceConfigTable from "../../BillingPriceConfigTable"
import { SERVICE_TYPES, RANGE_EXCLUSION_MODES, PRICE_MODES, PACKAGE_SERVICE_TYPE_CATEGORIES } from "../../constants"
import { getCurrency } from "../../utils"

const ShippingPackagesServices = ({storeBillingServices, onUpdateStoreBillingServices, onIsValid, shippingPackages, storeId, warehouse}) => {
    const { i18n } = useTranslation()
    const [isValid, setIsValid] = useState(null)

    const tableHeaders = [
        {label: i18n.t("billing.table_headers.from_weight"), id: "from"},
        {label: i18n.t("billing.table_headers.to_weight"), id: "to"},
        {label: i18n.t("billing.table_headers.package"),id: "package"},
        {label: i18n.t("billing.table_headers.price"), id: "price"},
        {label: i18n.t("billing.table_headers.unit"),id: "unit"}
    ]

    const packageTypes = shippingPackages.map(({package_type}) => package_type).concat(PACKAGE_SERVICE_TYPE_CATEGORIES).filter((currentPackageType, packageTypeIndex, allPackageTypes) => {
        const lastPackageTypeIndex = allPackageTypes.findLastIndex(packageType => packageType === currentPackageType)
        return lastPackageTypeIndex === packageTypeIndex
    })

    const [packagesBillingServices, setPackagesBillingServices] = useState((() => {
        const packageServices = storeBillingServices?.filter(({service_type}) => service_type === SERVICE_TYPES.SERVICE_PICK_AND_PACK_PACKAGE)

        const newPackingServices = packageTypes.map(packageType => {
            let currentPriceConfiguration = null
            const currentBillingService = packageServices?.find(({service_type_category}) => service_type_category === packageType)

            if(currentBillingService?.price_configuration?.length > 0){
                currentPriceConfiguration = currentBillingService.price_configuration.map(priceRange => ({
                    ...priceRange,
                    from: isNaN(parseFloat(priceRange.from)) ? null : parseFloat(priceRange.from),
                    to: isNaN(parseFloat(priceRange.to)) ? null : parseFloat(priceRange.to),
                    price: isNaN(parseFloat(priceRange.price)) ? null : parseFloat(priceRange.price)
                }))
            }

            const newBillingService = {
                store_id: storeId,
                warehouse_id: warehouse?.id,
                service_type: SERVICE_TYPES.SERVICE_PICK_AND_PACK_PACKAGE,
                service_type_category: packageType,
                currency: getCurrency(warehouse?.country),
                price_configuration: []
            }

            const sortedPackages = shippingPackages.filter(({package_type}) => (
                package_type === packageType
            )).sort((a, b) => (
                parseFloat(a.volumetric_weight) - parseFloat(b.volumetric_weight)
            ))

            sortedPackages.forEach((shippingPackage, index, allSortedPackages) => {
                const previousPackageWeight = index === 0 ? null : parseFloat(allSortedPackages[index - 1].volumetric_weight)
                const packageWeight = parseFloat(shippingPackage.volumetric_weight)

                if(previousPackageWeight !== packageWeight){
                    let newFrom = 0
                    const newTo = packageWeight

                    if(newBillingService.price_configuration.length > 0){
                        newFrom = newBillingService.price_configuration[newBillingService.price_configuration.length - 1].to
                    }
                    const currentPriceRange = currentPriceConfiguration?.find(currentPriceRange => (
                        newFrom === currentPriceRange.from && newTo === currentPriceRange.to
                    ))
                    const newPrice = currentPriceRange ? currentPriceRange.price : null
                    const priceRange = { from: newFrom, to: newTo, price: newPrice, price_mode: PRICE_MODES.PRICE_MODE_TOTAL, excess: false }

                    newBillingService.price_configuration.push(priceRange)
                }
            })

            if(sortedPackages.length <= 0){
                const priceRange = { from: 0, to: null, price: null, price_mode: PRICE_MODES.PRICE_MODE_TOTAL, excess: false }
                newBillingService.price_configuration.push(priceRange)
            }

            const lastNewPriceRangeIndex = newBillingService.price_configuration.length - 1
            const lastNewPriceRange = newBillingService.price_configuration[lastNewPriceRangeIndex]
            const lastCurrentPriceRange = currentPriceConfiguration?.length > 0 ? currentPriceConfiguration[currentPriceConfiguration.length - 1] : null

            newBillingService.price_configuration[lastNewPriceRangeIndex].to = null

            if(lastCurrentPriceRange && lastCurrentPriceRange.from === lastNewPriceRange.from && lastCurrentPriceRange.to === lastNewPriceRange.to){
                newBillingService.price_configuration[lastNewPriceRangeIndex].price = lastCurrentPriceRange.price
            } else {
                newBillingService.price_configuration[lastNewPriceRangeIndex].price = null
            }

            return newBillingService
        })

        return newPackingServices
    })())

    const tablesData = packagesBillingServices?.map(billingService => {
        const data = billingService?.price_configuration.map((priceRange, priceRangeIndex) => {
            const rangeShippingPackages = shippingPackages.filter(({package_type, volumetric_weight}) => {
                const from = parseFloat(priceRange.from)
                const to = parseFloat(priceRange.to)
                const volumetricWeight = parseFloat(volumetric_weight)

                if(package_type !== billingService.service_type_category){ return false }
                if(volumetricWeight < from){ return false }
                if(!isNaN(to) && volumetricWeight > to){ return false }

                if(priceRangeIndex > 0 && !priceRange.range_exclusion && volumetricWeight <= from){
                    return false
                } else if(priceRangeIndex > 0 && priceRange.range_exclusion === RANGE_EXCLUSION_MODES.RANGE_EXCLUDE_FROM && volumetricWeight <= from){
                    return false
                } else if(!isNaN(to) && priceRange.range_exclusion === RANGE_EXCLUSION_MODES.RANGE_EXCLUDE_TO && volumetricWeight >= to){
                    return false
                }

                return true
            })

            return {
                from: {value: priceRange.from, isDisabled: true},
                to: {value: priceRange.to, isDisabled: true},
                price: {value: priceRange.price},
                price_mode: {value: priceRange.price_mode},
                serviceTypeCategory: {value: priceRange.service_type_category},
                excess: {value: priceRange.excess},
                range_exclusion: {value: priceRange.range_exclusion, isDisabled: true},
                rangeShippingPackages: {value: rangeShippingPackages}
            }
        })
        return {category: billingService.service_type_category, data: data}
    })

    const handleOnUpdatePrice = (newPrice, serviceTypeCategory, priceRangeIndex) => {
        const newRangePrice = isNaN(parseFloat(newPrice)) ? null : parseFloat(newPrice)
        const newPackagesBillingServices = cloneDeep(packagesBillingServices)
        const packagesBillingServicesIndex = packagesBillingServices.findIndex(({service_type_category}) => service_type_category === serviceTypeCategory)

        newPackagesBillingServices[packagesBillingServicesIndex].price_configuration[priceRangeIndex].price = newRangePrice
        setPackagesBillingServices(newPackagesBillingServices)

        const newValidStatus = newPackagesBillingServices.every(({price_configuration}) => {
            return price_configuration.every(({price}) => !isNaN(parseFloat(price)))
        })

        const newStoreBillingServices = storeBillingServices ? cloneDeep(storeBillingServices) : []

        newPackagesBillingServices.forEach(packageService => {
            const storeBillingServiceIndex = newStoreBillingServices.findIndex(({service_type, service_type_category}) => {
                return service_type === SERVICE_TYPES.SERVICE_PICK_AND_PACK_PACKAGE && service_type_category === packageService.service_type_category
            })

            if(storeBillingServiceIndex >= 0){
                const priceConfigurationCopy = cloneDeep(packageService.price_configuration)
                newStoreBillingServices[storeBillingServiceIndex].price_configuration = priceConfigurationCopy
            }
            else {
                const serviceCopy = cloneDeep(packageService)
                newStoreBillingServices.push(serviceCopy)
            }
        })

        onUpdateStoreBillingServices(newStoreBillingServices)
        setIsValid(newValidStatus)
        onIsValid(newValidStatus)
    }

    useEffect(() => {
        if(isValid === null && packagesBillingServices?.length > 0){
            const newValidStatus = packagesBillingServices.every(({price_configuration}) => (
                price_configuration?.length > 0 && price_configuration.every(({price}) => !isNaN(parseFloat(price)))
            ))
            setIsValid(newValidStatus)
            onIsValid(newValidStatus)
        }
    }, [packagesBillingServices, isValid])

    return (
        <div>
            <div className="flex gap-1">
                <h2 className="text-xl text-gray-500"><span className="text-red-500">*</span>{i18n.t("billing.pick_and_pack_services.packages_title")}</h2>

                {isValid === true ? (
                    <CheckCircleIcon className="h-5 w-5 text-green-500"/>
                ) : (
                    <ExclamationCircleIcon className="h-5 w-5 text-yellow-500"/>
                )}
            </div>

            <div className="flex flex-col gap-5">
                {packageTypes.map(packageType => {
                    const data = tablesData.find(({category}) => category === packageType)?.data
                    const service = packagesBillingServices.find(({service_type, service_type_category}) => {
                        return service_type === SERVICE_TYPES.SERVICE_PICK_AND_PACK_PACKAGE && service_type_category === packageType
                    })

                    return (
                        <div key={`shipping-package-${packageType}`} className="flex flex-col gap-2">
                            <div className="text-xl text-gray-500">
                                {i18n.t(`billing.pick_and_pack_services.package_title.${packageType}`)}
                            </div>

                            <BillingPriceConfigTable
                                headers={tableHeaders}
                                rows={data.map((priceRange, priceRangeIndex) => ([
                                    {
                                        id: "from",
                                        cell: <div className="flex items-center ml-2">
                                            <div className="mt-1">{i18n.t("billing.from_prefix")}</div>
                                            <CurrencyInputField
                                                value={priceRange.from.value === null ? "" : priceRange.from.value}
                                                onChange={(v) => {}}
                                                readOnly={true}
                                                scale={5}
                                                hideIcon={true}
                                                inputClassName={"border-none bg-transparent shadow-none focus:ring-0"}
                                            />
                                        </div>,
                                        isDisabled: true,
                                    },
                                    {
                                        id: "to",
                                        cell: <CurrencyInputField
                                            value={priceRange.to.value === null ? "" : priceRange.to.value}
                                            onChange={(v) => {}}
                                            placeholder={i18n.t("billing.range_billing_price_config_table.last_row_placeholder")}
                                            readOnly={true}
                                            scale={5}
                                            hideIcon={true}
                                            inputClassName={"border-none bg-transparent shadow-none focus:ring-0"}
                                        />,
                                        isDisabled: true,
                                    },
                                    {
                                        id: "package",
                                        cell: <div className="p-2">
                                            {priceRange.rangeShippingPackages.value.map(shippingPackage => (
                                                <div key={`shipping-package-${packageType}-id-${shippingPackage.id}`}>
                                                    {shippingPackage.name}
                                                    <span className="text-gray-400 ml-2">
                                                    {`(${shippingPackage.volumetric_weight} m3)`}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>,
                                        isDisabled: true,
                                    },
                                    {
                                        id: "price",
                                        cell: <CurrencyInputField
                                            value={priceRange.price.value === null ? "" : priceRange.price.value}
                                            onChange={(v) => handleOnUpdatePrice(v, service.service_type_category, priceRangeIndex)}
                                            customIcon={<div className="font-bold">{service.currency}</div>}
                                            inputClassName={"pl-12 border-none bg-transparent shadow-none focus:ring-0"}
                                            scale={8}
                                            precision={16}
                                        />,
                                        isInvalid: isNaN(parseFloat(priceRange.price.value)),
                                        errorMessage: i18n.t(`billing.range_billing_price_config_table.errors.MISSING_PRICE_VALUE`)
                                    },
                                    {
                                        id: "unit",
                                        cell: <div className="px-2">
                                            {i18n.t(`billing.pick_and_pack_services.package_unit_options.${priceRange.price_mode.value}`)}
                                        </div>,
                                        isDisabled: true,
                                    },
                                ]))}
                                className="w-full"
                                headerClassName="text-left text-gray-500 p-2 border-2 bg-white"
                            />
                        </div>
                    )
                })}
            </div>

        </div>
    )
}

export default ShippingPackagesServices