import { useMemo } from "react"

import InsertPreview from "./InsertPreview";
import { CollapseContainer } from "../../../../../components";

const InsertsSection = ({orderLines}) => {
    const insertProducts = useMemo(() => {

        const inserts = []

        orderLines?.forEach(orderLine => {
            if(orderLine.product.is_kit === true){
                orderLine.product.kit_childs.forEach(kitChild => {
                    if(kitChild.child_product.is_scannable === false){
                        inserts.push(kitChild)
                    }
                })
            }
            else if(orderLine.product.is_scannable === false){
                inserts.push(orderLine)
            }
        })

        return inserts
    },[orderLines])

    const insertTotalQuantity = useMemo(() => insertProducts?.reduce((accumulator, {quantity}) => accumulator + quantity, 0),[insertProducts])

    if(insertTotalQuantity <= 0){
        return <></>
    }

    return (
        <section>
            <CollapseContainer
                headerComponent={
                    <h2 className="mb-0 text-2xl font-bold">{`Injertos requeridos (${insertTotalQuantity})`}</h2>
                }
                onCollapsedText={"Ver injertos"}
                onNotCollapsedText={"Ocultar injertos"}
            >
                <p className="mb-0 text-lg">{'No olvides incluir los siguientes injertos al empacar'}</p>

                <div className="flex gap-4 mt-4 flex-wrap">
                    {insertProducts?.map((insertProduct, index) => {
                        const insert = insertProduct.hasOwnProperty("product") ? insertProduct.product : insertProduct.child_product

                        return (
                            <InsertPreview
                                insertQuantity={insertProduct.quantity}
                                thumbnailImagePath={`${process.env.REACT_APP_PLATFORM_URL}${insert?.product_image_path?.thumbnail_path}`}
                                fullScreenImagePath={`${process.env.REACT_APP_PLATFORM_URL}${insert?.product_image_path?.path}`}
                                key={`insert-product-${index}`}
                            />
                        )
                    })}
                </div>
            </CollapseContainer>
        </section>
    )
}

export default InsertsSection
