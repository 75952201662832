import PropTypes from "prop-types";

const getClassNames = (type, className, dense, disabled) => {
    let classes = ""
    if(type === "primary"){
        classes = ` inline-flex justify-center rounded-md border border-transparent shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm ${className}`
    }
    else if(type === "secondary"){
        classes = `mt-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm ${className}`
    }
    else if(type === "success"){
        classes = `mt-3 inline-flex justify-center rounded-md border border-green-400 shadow-sm py-2 bg-green-500 text-base font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-200 sm:mt-0 sm:col-start-1 sm:text-sm ${className}`
    }
    else if(type === "danger"){
        classes = `mt-3 inline-flex justify-center rounded-md border border-red-500 shadow-sm py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300 sm:mt-0 sm:col-start-1 sm:text-sm ${className}`
    }
    if (!dense) {
        classes += " px-4"
    }
    if (disabled) {
        classes += " cursor-default"
    }

    return classes
}

export const Button = ({type = "primary", className="", children, loading = false, loading_label = 'Cargando...', disabled = false, dense = false, ...props}) => {

    Button.propTypes = {
        type: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger']),
        disabled: PropTypes.bool,
    }

    return (
        <button
            type="button"
            className={`disabled:opacity-50 ${getClassNames(type, className, dense, disabled)}`}
            disabled={disabled || loading}
            {...props}
        >
            {loading ?
             <span>{loading_label}</span>
            :
            <>{children}</>
            }
        </button>
    )
}