import React, { useEffect, useMemo, useRef, useState } from 'react'

const BottomFixedOverlay = ({ children, visible = true, className = null, centered = false, ...props }) => {
  const containerRef = useRef(null)

  const [spacerHeight, setSpacerHeight] = useState(0)
  const [refreshSpacerHeight, setRefreshSpacerHeight] = useState(false)

  useEffect(() => {
    setRefreshSpacerHeight(true)
  }, [children])

  useEffect(() => {
    if (refreshSpacerHeight) {
      let newSpacerHeight = calculateSpacerHeight()
      if (spacerHeight !== newSpacerHeight) {
        setSpacerHeight(newSpacerHeight)
      }
      setRefreshSpacerHeight(false)
    }
  }, [refreshSpacerHeight])

  const calculateSpacerHeight = () => {
    if (!containerRef.current) return 0
    const containerHeight = containerRef.current.offsetHeight
    if (containerHeight === 0) return 0
    const contentComputedStyle = getComputedStyle(containerRef.current)
    const contentComputedBottomPosition = parseInt(contentComputedStyle.getPropertyValue('bottom'))
    return containerHeight + contentComputedBottomPosition
  }

  const getContainerClassName = () => {
    let containerClassName = `fixed bottom-2 max-w-full `
    if (centered) containerClassName += 'left-1/2 transform -translate-x-1/2 '
    if (className) containerClassName += className
    return containerClassName
  }

  const containerClassName = useMemo(getContainerClassName, [centered, className])

  if (!visible) return <></>
  return (
    <>
      {/* BottomFixedOverlay spacer */}
      <div style={{ paddingBottom: spacerHeight }}></div>

      {/* content */}
      <div className={containerClassName} ref={containerRef} {...props}>
        {children}
      </div>
    </>
  )
}

export default BottomFixedOverlay
