const InformationCard = ({title, description, iconComponent, containerStyles, isHidden=false}) => {
    if(isHidden){
        return <></>
    }

    return (
        <div className={`p-4 rounded-lg flex ${iconComponent ? "justify-between" : "w-full"} ${containerStyles ? containerStyles : "bg-white" }`}>
            <div>
                <h2 className="mb-0 text-2xl font-bold text-current">{title}</h2>
                <p className="mb-0 text-lg font-medium">{description}</p>
            </div>

            {iconComponent && (
                <div className="h-full mr-4">
                    {iconComponent}
                </div>
            )}
        </div>
    )
}

export default InformationCard
