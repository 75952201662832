import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { cloneDeep } from "lodash"

import { INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES, SERVICE_TYPES, INTERNATIONAL_SHIPPING_LABELS_RANGES, PRICE_MODES } from "../constants"
import BillingPriceConfigTable from "../BillingPriceConfigTable"
import { CurrencyInputField } from "../../CurrencyInputField"
import { UNITS_IDS } from "../RangeBillingPriceConfigTable/constants"
import { getCurrency } from "../utils"

const InternationalShippingLabelServices = ({storeBillingServices, onUpdateStoreBillingServices, onIsValid, storeId, warehouse}) => {
    const { i18n } = useTranslation()

    const tableHeaders = [
        {label: i18n.t("billing.table_headers.from_weight"), id: "from"},
        {label: i18n.t("billing.table_headers.to_weight"), id: "to"},
        {label: i18n.t("billing.table_headers.NORTH_AMERICA"), id: "NORTH_AMERICA_price"},
        {label: i18n.t("billing.table_headers.LATIN_AMERICA"), id: "LATIN_AMERICA_price"},
        {label: i18n.t("billing.table_headers.CARIBBEAN"), id: "CARIBBEAN_price"},
        {label: i18n.t("billing.table_headers.EUROPE"), id: "EUROPE_price"},
        {label: i18n.t("billing.table_headers.ASIA"), id: "ASIA_price"},
        {label: i18n.t("billing.table_headers.AFRICA"), id: "AFRICA_price"},
        {label: i18n.t("billing.table_headers.unit"),id: "unit"},
    ]

    const serviceTypeCategories = Object.values(INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES)

    const calculateRangeNumber = () => {
        let hasInternational1Range = false
        let hasInternational2Range = false

        storeBillingServices.filter(({service_type}) => (
            service_type === SERVICE_TYPES.SERVICE_INTERNATIONAL_SHIPPING_LABEL
        )).forEach(({price_configuration}) => {
            const rangeCount = price_configuration.reduce((accumulator, priceRange) => !priceRange.excess ? (accumulator + 1) : accumulator, 0)
            if(rangeCount === INTERNATIONAL_SHIPPING_LABELS_RANGES.INTERNATIONAL_1){ hasInternational1Range = true }
            if(rangeCount === INTERNATIONAL_SHIPPING_LABELS_RANGES.INTERNATIONAL_2){ hasInternational2Range = true }
        })
        let calculatedRange = null

        if(hasInternational1Range){ calculatedRange = INTERNATIONAL_SHIPPING_LABELS_RANGES.INTERNATIONAL_1 }
        else if(hasInternational2Range){ calculatedRange = INTERNATIONAL_SHIPPING_LABELS_RANGES.INTERNATIONAL_2 }
        else{ calculatedRange = INTERNATIONAL_SHIPPING_LABELS_RANGES.INTERNATIONAL_1 }

        return calculatedRange
    }

    const rangeNumber = calculateRangeNumber()

    const [internationalShippingLabelServices, setInternationalShippingLabelServices] = useState(serviceTypeCategories.map(serviceTypeCategory => {
        const foundBillingService = storeBillingServices?.find(({service_type, service_type_category}) => {
            return service_type === SERVICE_TYPES.SERVICE_INTERNATIONAL_SHIPPING_LABEL && service_type_category === serviceTypeCategory}
        )
        const hasExcessRange = foundBillingService && foundBillingService.price_configuration[foundBillingService.price_configuration.length - 1].excess

        if(foundBillingService?.price_configuration.length === rangeNumber && hasExcessRange){
            return foundBillingService
        }

        const newBillingService = foundBillingService ? cloneDeep(foundBillingService) : {
            store_id: storeId,
            warehouse_id: warehouse?.id,
            service_type: SERVICE_TYPES.SERVICE_INTERNATIONAL_SHIPPING_LABEL,
            service_type_category: serviceTypeCategory,
            currency: getCurrency(warehouse?.country),
            price_configuration: []
        }

        while(newBillingService.price_configuration.length < rangeNumber){
            const currentIndex = newBillingService.price_configuration.length

            newBillingService.price_configuration.push({
                from:currentIndex,to:currentIndex+1,price:null,price_mode:PRICE_MODES.PRICE_MODE_TOTAL,range_exclusion:null,excess:false
            })
        }

        if(!hasExcessRange){
            const previousTo = newBillingService.price_configuration[newBillingService.price_configuration.length - 1].to

            newBillingService.price_configuration.push({
                from:previousTo,to:null,price:null,price_mode:PRICE_MODES.PRICE_MODE_PER_UNIT,range_exclusion:null,excess:true
            })
        }

        return newBillingService
    }))

    const buildTableData = () => {
        const rows = []
        const totalRows = rangeNumber + 1

        for(let i=0; i < totalRows; i++){
            const serviceNorthAmerica = internationalShippingLabelServices.find(
                ({service_type_category}) => service_type_category === INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.NORTH_AMERICA
            )
            const serviceLatinAmerica = internationalShippingLabelServices.find(
                ({service_type_category}) => service_type_category === INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.LATIN_AMERICA
            )
            const serviceCaribbean = internationalShippingLabelServices.find(
                ({service_type_category}) => service_type_category === INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.CARIBBEAN
            )
            const serviceEurope = internationalShippingLabelServices.find(
                ({service_type_category}) => service_type_category === INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.EUROPE
            )
            const serviceAsia = internationalShippingLabelServices.find(
                ({service_type_category}) => service_type_category === INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.ASIA
            )
            const serviceAfrica = internationalShippingLabelServices.find(
                ({service_type_category}) => service_type_category === INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AFRICA
            )

            const row = {
                from: {value: i},
                to: {value: i === (totalRows - 1) ? null : i + 1},
                NORTH_AMERICA_price: {
                    value: serviceNorthAmerica.price_configuration[i].price,
                    key: INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.NORTH_AMERICA
                },
                LATIN_AMERICA_price: {
                    value: serviceLatinAmerica.price_configuration[i].price,
                    key: INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.LATIN_AMERICA
                },
                CARIBBEAN_price: {
                    value: serviceCaribbean.price_configuration[i].price,
                    key: INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.CARIBBEAN
                },
                EUROPE_price: {
                    value: serviceEurope.price_configuration[i].price,
                    key: INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.EUROPE
                },
                ASIA_price: {
                    value: serviceAsia.price_configuration[i].price,
                    key: INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.ASIA
                },
                AFRICA_price: {
                    value: serviceAfrica.price_configuration[i].price,
                    key: INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AFRICA
                },
                unit: {value: i === (totalRows - 1) ? UNITS_IDS.EXCESS_PER_UNIT : UNITS_IDS.TOTAL}
            }
            rows.push(row)
        }

        return rows
    }

    const internationalShippingLabelTablesData = buildTableData()
    const currency = getCurrency(warehouse?.country)

    const handleOnPriceChange = (price, priceKey, priceRangeIndex) => {
        const serviceIndex = internationalShippingLabelServices.findIndex(({service_type_category}) => (
            service_type_category === priceKey
        ))
        const newPrice = isNaN(parseFloat(price)) ? null : parseFloat(price)
        const newInternationalShippingLabelServices = cloneDeep(internationalShippingLabelServices)
        newInternationalShippingLabelServices[serviceIndex].price_configuration[priceRangeIndex].price = newPrice

        setInternationalShippingLabelServices(newInternationalShippingLabelServices)

        const storeBillingServicesCopy = cloneDeep(storeBillingServices)

        newInternationalShippingLabelServices.forEach(service => {
            const serviceIndex = storeBillingServicesCopy.findIndex(({service_type, service_type_category}) => (
                service_type === service.service_type && service_type_category === service.service_type_category
            ))

            if(serviceIndex >= 0){
                storeBillingServicesCopy[serviceIndex].price_configuration = cloneDeep(service.price_configuration)
            } else{
                storeBillingServicesCopy.push(cloneDeep(service))
            }
        })

        onUpdateStoreBillingServices(storeBillingServicesCopy)
    }

    useEffect(() => {
        const isValid = internationalShippingLabelServices.every(({price_configuration}) => (
            price_configuration.every(({price}) => !isNaN(parseFloat(price)))
        ))
        onIsValid(isValid)
    }, [internationalShippingLabelServices])

    return (
        <>
        <BillingPriceConfigTable
            headers={tableHeaders}
            rows={internationalShippingLabelTablesData.map((row, index) => (
                [
                    {
                        cell: <div className="flex items-center ml-2">
                            <div className="mt-1 flex-shrink-0">{i18n.t("billing.from_prefix")}</div>
                            <CurrencyInputField
                                value={row.from.value}
                                readOnly={true}
                                scale={0}
                                hideIcon={true}
                                inputClassName={"border-none bg-transparent shadow-none focus:ring-0"}
                            />
                        </div>,
                        id: "from",
                        isInvalid: false,
                        errorMessage: null,
                        isDisabled: true
                    },
                    {
                        cell: <CurrencyInputField
                            value={row.to.value === null ? "" : row.to.value}
                            readOnly={true}
                            placeholder={i18n.t("billing.range_billing_price_config_table.last_row_placeholder")}
                            scale={0}
                            hideIcon={true}
                            inputClassName={"border-none bg-transparent shadow-none focus:ring-0"}
                        />,
                        id: "to",
                        isInvalid: false,
                        errorMessage: null,
                        isDisabled: true
                    },
                    {
                        id: "NORTH_AMERICA_price",
                        cell: <CurrencyInputField
                            value={row.NORTH_AMERICA_price.value === null ? "" : row.NORTH_AMERICA_price.value}
                            onChange={(v) => handleOnPriceChange(v, INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.NORTH_AMERICA, index)}
                            customIcon={<div className="font-bold">{currency}</div>}
                            inputClassName={"pl-12 border-none bg-transparent shadow-none focus:ring-0"}
                            scale={8}
                            precision={16}
                        />,
                        isDisabled: false,
                        isInvalid: isNaN(parseFloat(row.NORTH_AMERICA_price.value)),
                        errorMessage: i18n.t("billing.billing_storage_table.tariff_error_message")
                    },
                    {
                        id: "LATIN_AMERICA_price",
                        cell: <CurrencyInputField
                            value={row.LATIN_AMERICA_price.value === null ? "" : row.LATIN_AMERICA_price.value}
                            onChange={(v) => handleOnPriceChange(v, INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.LATIN_AMERICA, index)}
                            customIcon={<div className="font-bold">{currency}</div>}
                            inputClassName={"pl-12 border-none bg-transparent shadow-none focus:ring-0"}
                            scale={8}
                            precision={16}
                        />,
                        isDisabled: false,
                        isInvalid: isNaN(parseFloat(row.LATIN_AMERICA_price.value)),
                        errorMessage: i18n.t("billing.billing_storage_table.tariff_error_message")
                    },
                    {
                        id: "CARIBBEAN_price",
                        cell: <CurrencyInputField
                            value={row.CARIBBEAN_price.value === null ? "" : row.CARIBBEAN_price.value}
                            onChange={(v) => handleOnPriceChange(v, INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.CARIBBEAN, index)}
                            customIcon={<div className="font-bold">{currency}</div>}
                            inputClassName={"pl-12 border-none bg-transparent shadow-none focus:ring-0"}
                            scale={8}
                            precision={16}
                        />,
                        isDisabled: false,
                        isInvalid: isNaN(parseFloat(row.CARIBBEAN_price.value)),
                        errorMessage: i18n.t("billing.billing_storage_table.tariff_error_message")
                    },
                    {
                        id: "EUROPE_price",
                        cell: <CurrencyInputField
                            value={row.EUROPE_price.value === null ? "" : row.EUROPE_price.value}
                            onChange={(v) => handleOnPriceChange(v, INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.EUROPE, index)}
                            customIcon={<div className="font-bold">{currency}</div>}
                            inputClassName={"pl-12 border-none bg-transparent shadow-none focus:ring-0"}
                            scale={8}
                            precision={16}
                        />,
                        isDisabled: false,
                        isInvalid: isNaN(parseFloat(row.EUROPE_price.value)),
                        errorMessage: i18n.t("billing.billing_storage_table.tariff_error_message")
                    },
                    {
                        id: "ASIA_price",
                        cell: <CurrencyInputField
                            value={row.ASIA_price.value === null ? "" : row.ASIA_price.value}
                            onChange={(v) => handleOnPriceChange(v, INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.ASIA, index)}
                            customIcon={<div className="font-bold">{currency}</div>}
                            inputClassName={"pl-12 border-none bg-transparent shadow-none focus:ring-0"}
                            scale={8}
                            precision={16}
                        />,
                        isDisabled: false,
                        isInvalid: isNaN(parseFloat(row.ASIA_price.value)),
                        errorMessage: i18n.t("billing.billing_storage_table.tariff_error_message")
                    },
                    {
                        id: "AFRICA_price",
                        cell: <CurrencyInputField
                            value={row.AFRICA_price.value === null ? "" : row.AFRICA_price.value}
                            onChange={(v) => handleOnPriceChange(v, INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES.AFRICA, index)}
                            customIcon={<div className="font-bold">{currency}</div>}
                            inputClassName={"pl-12 border-none bg-transparent shadow-none focus:ring-0"}
                            scale={8}
                            precision={16}
                        />,
                        isDisabled: false,
                        isInvalid: isNaN(parseFloat(row.AFRICA_price.value)),
                        errorMessage: i18n.t("billing.billing_storage_table.tariff_error_message")
                    },
                    {
                        cell: <div className="px-2">{i18n.t(`billing.international_shipping_label_services.unit_options.${row.unit.value}`)}</div>,
                        id: "unit",
                        isInvalid: false,
                        errorMessage: "",
                        isDisabled: false
                    }
                ]
            ))}
            className="w-full"
            headerClassName="text-left text-gray-500 p-2 border-2 bg-white"
            cellClassName=""
        />
        </>
    )
}

export default InternationalShippingLabelServices