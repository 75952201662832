import { SearchIcon } from "@heroicons/react/solid";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { debounce as _debounce } from 'lodash'
import { CloseIcon } from "./Icons/CloseIcon";
import { Tooltip } from "antd";
import { InformationCircleIcon } from "@heroicons/react/outline";

export const SearchInput = forwardRef(({
    inputValue = '',
    onChange = (v) => { },
    onPaste = (v) => { },
    onSubmit = (v) => { },
    className = "",
    placeholder = "Buscar",
    inputHoverDescription = null,
    debounce = 0,
    queryOnClick = false,
    numericOnly = false,
    hideIcons = false,
    dependencies = [],
    disabledSearchOnChange = false
}, ref) => {
    const [isSearchDisabled, setIsSearchDisabled] = useState(false)

    useImperativeHandle(ref, () => ({
        resetInput: resetInput,
        focus: () => {
            inputRef.current.focus()
        },
        setIsSearchDisabled: setIsSearchDisabled
    }))

    const [searchText, setSearchText] = useState(inputValue)

    const inputRef = useRef(null)

    const resetInput = () => {
        setSearchText('')
        if (!queryOnClick) {
            onChange('')
            onSubmit('')
        }
    }

    useEffect(() => {
        setSearchText(inputValue)
    }, [inputValue])

    // RESET INPUT IF ESC IS PRESSED WHILE ACTIVE
    const onKeyDown = (e) => {
        if (e.keyCode === 27) {
            resetInput()
        }
        if (e.key === 'Enter') {
            setSearchText(e.target.value)
            onSubmit(e.target.value);
        }
    }

    const handleChange = (e) => {
        let newVal = e.target.value
        if (numericOnly) {
            newVal = newVal.replace(/\D/g, '')
        }
        setSearchText(newVal)
        if (debounce > 0) {
            debouncedChange(newVal)
        }
        else {
            onChange(newVal)
        }
    }

    const handlePaste = (e) => {
        onPaste(e)
    }

    const debouncedChange = useCallback(_debounce(v => {
        if (disabledSearchOnChange) {
            setIsSearchDisabled(true)
        }
        onChange(v)
    }, debounce), [...dependencies, disabledSearchOnChange])

    const hasText = () => {
        return searchText.length > 0
    }

    const [isHovered, setIsHovered] = useState(false)

    return (
        <div className={className ? 'relative ' + className : 'relative'} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            {!hideIcons &&
                <div className={"absolute inset-y-0 left-0 pl-3 flex items-center " + (hasText() ? 'cursor-pointer' : 'pointer-events-none')} onClick={resetInput}>
                    {hasText() ?
                        <CloseIcon className="h-5 w-5" />
                        :
                        <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    }
                </div>
            }
            <Tooltip placement="bottomLeft" title={isHovered && !searchText && inputHoverDescription}  className="max-w-40 whitespace-normal text-justify inline">
            <input
                className={"min-w-6 block w-full bg-white py-2 pr-3 border border-gray-300 rounded-md leading-5 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm" + (hideIcons ? ' pl-2' : ' pl-10')}
                placeholder={placeholder}
                name="search"
                value={searchText}
                onChange={(v) => handleChange(v)}
                onPaste={(v) => handlePaste(v)}
                onKeyDown={(e) => onKeyDown(e)}
                ref={inputRef}
                disabled={isSearchDisabled}
            />
            </Tooltip>
        </div>
    )
})
