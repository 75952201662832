export const FilterMenu = ({children}) => {
    return (
        <div className="overflow-hidden bg-white flex flex-row justify-between my-1 ">
            <div className="relative text-gray-400 focus-within:text-gray-600 m-2">
                {children[0]}
            </div>

            <div className="m-2">
                {children[1]}
            </div>
        </div>
    )
}