import {
    PageView
} from "../../components";
import { useTranslation } from "react-i18next";
import {useQuery} from "react-query";
import {useContext, useRef, useState} from "react";

export const AccessDenied = () => {
    const { i18n } = useTranslation();
    return (
        <>
            <PageView>
                <h1>{i18n.t("access_denied")}</h1>
            </PageView>
        </>
    )
}