import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { cloneDeep } from "lodash"
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline"

import { findOrCreateBillingService, getCurrency } from "../utils"
import { SERVICE_TYPES } from "../constants"
import RangeBillingPriceConfigTable from "../RangeBillingPriceConfigTable"

const ReplenishmentServices = ({storeBillingServices, onUpdateStoreBillingServices, onIsValid, storeId, warehouse}) => {
    const { i18n } = useTranslation()

    const tableHeaders = [
        {label: i18n.t("billing.table_headers.from_piece"), id: 'from'},
        {label: i18n.t("billing.table_headers.to_piece"), id: 'to'},
        {label: i18n.t("billing.table_headers.price"), id: 'price'},
        {label: i18n.t("billing.table_headers.unit"),id: 'unit'},
        {label: null,id: 'actions'}
    ]

    const [isReplenishmentValid, setIsReplenishmentValid] = useState(false)
    const [isReplenishmentLotsValid, setIsReplenishmentLotsValid] = useState(false)

    const replenishmentService = findOrCreateBillingService({serviceType: SERVICE_TYPES.SERVICE_REPLENISHMENT, billingServices: storeBillingServices, currency: getCurrency(warehouse?.country), storeId: storeId, warehouseId: warehouse?.id})
    const replenishmentLotsService = findOrCreateBillingService({serviceType: SERVICE_TYPES.SERVICE_REPLENISHMENT_LOTS, billingServices: storeBillingServices, currency: getCurrency(warehouse?.country), storeId: storeId, warehouseId: warehouse?.id})

    const replenishmentTableData = replenishmentService?.price_configuration.map(priceRange => {
        return {
            from: {value: priceRange.from, isDisabled: true},
            to: {value: priceRange.to},
            price: {value: priceRange.price},
            price_mode: {value: priceRange.price_mode},
            excess: {value: priceRange.excess},
            range_exclusion: {value: priceRange.range_exclusion},
        }
    })
    const replenishmentLotsTableData = replenishmentLotsService?.price_configuration.map(priceRange => {
        return {
            from: {value: priceRange.from, isDisabled: true},
            to: {value: priceRange.to},
            price: {value: priceRange.price},
            price_mode: {value: priceRange.price_mode},
            excess: {value: priceRange.excess},
            range_exclusion: {value: priceRange.range_exclusion},
        }
    })

    const unitOptionsLabels = {
        PER_UNIT: i18n.t("billing.replenishment_services.unit_options.PER_UNIT"),
        TOTAL: i18n.t("billing.replenishment_services.unit_options.TOTAL"),
        EXCESS_PER_UNIT: i18n.t("billing.replenishment_services.unit_options.EXCESS_PER_UNIT"),
        EXCESS_TOTAL: i18n.t("billing.replenishment_services.unit_options.EXCESS_TOTAL")
    }

    const handleOnUpdateData = (serviceType, newTableData, service) => {
        let newStoreBillingServices = cloneDeep(storeBillingServices)

        const newPriceConfiguration = newTableData.map(priceRangeData => {
            return {
                from: priceRangeData.from.value,
                to: priceRangeData.to.value,
                price: priceRangeData.price.value,
                price_mode: priceRangeData.price_mode.value,
                excess: priceRangeData.excess.value,
                range_exclusion: priceRangeData.range_exclusion.value
            }
        })

        const serviceExists = newStoreBillingServices.some(({service_type}) => service_type === serviceType)

        if(serviceExists){
            newStoreBillingServices = newStoreBillingServices.map(billingService => {
                if(billingService.service_type === serviceType){
                    billingService.price_configuration = newPriceConfiguration
                }
                return billingService
            })
        } else{
            const newService = cloneDeep(service)
            newService.price_configuration = newPriceConfiguration
            newStoreBillingServices.push(newService)
        }

        onUpdateStoreBillingServices(newStoreBillingServices)
    }

    const handleOnIsValid = (serviceType, isValid) => {
        switch(serviceType){
            case SERVICE_TYPES.SERVICE_REPLENISHMENT:
                setIsReplenishmentValid(isValid)
                break

            case SERVICE_TYPES.SERVICE_REPLENISHMENT_LOTS:
                setIsReplenishmentLotsValid(isValid)
                break

            default:
                break
        }
    }

    useEffect(() => {
        const newValidStatus = isReplenishmentValid && isReplenishmentLotsValid
        onIsValid(newValidStatus)
    }, [isReplenishmentValid, isReplenishmentLotsValid])

    return (
        <div className="flex flex-col gap-5">
            <div>
                <div className="flex gap-1">
                    <h2 className="text-xl text-gray-500"><span className="text-red-500">*</span>{i18n.t("billing.replenishment_services.replenishment_title")}</h2>

                    {isReplenishmentValid ? (
                        <CheckCircleIcon className="h-5 w-5 text-green-500"/>
                    ) : (
                        <ExclamationCircleIcon className="h-5 w-5 text-yellow-500"/>
                    )}
                </div>

                <RangeBillingPriceConfigTable
                    headers={tableHeaders}
                    priceConfiguration={replenishmentTableData}
                    currency={getCurrency(warehouse?.country)}
                    onUpdatePriceConfiguration={newTableData => handleOnUpdateData(SERVICE_TYPES.SERVICE_REPLENISHMENT, newTableData, replenishmentService)}
                    onIsValid={(isValid) => handleOnIsValid(SERVICE_TYPES.SERVICE_REPLENISHMENT, isValid)}
                    unitOptionsLabels={unitOptionsLabels}
                />
            </div>

            <div>
                <div className="flex gap-1">
                    <h2 className="text-xl text-gray-500"><span className="text-red-500">*</span>{i18n.t("billing.replenishment_services.replenishment_lots_title")}</h2>

                    {isReplenishmentValid ? (
                        <CheckCircleIcon className="h-5 w-5 text-green-500"/>
                    ) : (
                        <ExclamationCircleIcon className="h-5 w-5 text-yellow-500"/>
                    )}
                </div>

                <RangeBillingPriceConfigTable
                    headers={tableHeaders}
                    priceConfiguration={replenishmentLotsTableData}
                    currency={getCurrency(warehouse?.country)}
                    onUpdatePriceConfiguration={newTableData => handleOnUpdateData(SERVICE_TYPES.SERVICE_REPLENISHMENT_LOTS, newTableData, replenishmentLotsService)}
                    onIsValid={(isValid) => handleOnIsValid(SERVICE_TYPES.SERVICE_REPLENISHMENT_LOTS, isValid)}
                    unitOptionsLabels={unitOptionsLabels}
                />
            </div>
        </div>
    )
}

export default ReplenishmentServices