import { useRef, useState } from 'react'
import { Dialog } from '@headlessui/react'
import {DialogView} from "./DialogView"
import {SearchInput} from "./SearchInput"


export const CorreiosDialog = ({
    open,
    setOpen,
    title='Coloque o código',
    onSubmit,
    onCancel,
    cancelLabel = 'Cancelar',
}) => {
    const cancelButtonRef = useRef()
    const [codeNotValid, setCodeNotValid] = useState(false)
    
  
    const isCodeValid = (code) => {
        let regex = /^[A-Z]{2}[\d]{9}[A-Z]{2}$/g
        if(regex.test(code)){
            code.slice(0,10) 
            let verify_code = verifying_digit(code)
            return verify_code === code
        } else return false
            
    }
    const verifying_digit = (code) => {
       
        let prefix = code.substring(0, 2)
        let number = code.substring(2, 10)
        let suffix = code.substring(11).trim()
        let return_number = number;
        let dv;
        let multipliers = [8, 6, 4, 2, 3, 5, 9, 7];
        let sum = 0;
        if(code.length < 12){
            return code
        }
        else if(number.length < 8 && code.length == 12){
            let zeros = "";
            let diferenca = 8 - number.length;
            for(let i=0; i<diferenca; i++){
                zeros += "0";
            }
            return_number = zeros + number;
        }else{
            return_number = number.substring(0, 8);
        }
        for(let i=0; i<8; i++){
            sum +=  parseInt(return_number.substring(i, (i+1))) * multipliers[i];
        }
        let rest = sum % 11;
        if(rest == 0){
            dv = "5";
        }else if(rest == 1){
            dv = "0";
        }else{
            dv = parseInt(11 - rest).toString();
        }
        return_number += dv;
        return_number = prefix + return_number + suffix;
        return return_number
    }

    const handleSearchChange = async (value) => {
        if(isCodeValid(value)){
            setCodeNotValid(false)
            onSubmit(value)
        }
        else{
            setCodeNotValid(true)
        }
    }
    
    return (
        <DialogView setOpen={setOpen} open={open} >
            <div>
                <div className="mt-3 text-center sm:mt-5 ">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        {title}
                    </Dialog.Title>
                </div>
            </div>
            <div className="mt-5 sm:mt-6">
                <SearchInput
                    className="w-full"
                    placeholder="Código de barras"
                    onChange={(value) => {
                        handleSearchChange(value)
                    }}
                    numericOnly={false}
                    hideIcons={true}
                    debounce={300}
                />
                { codeNotValid &&
                    <p className="text-red-600" >O código é inválido</p>
                }
            </div>
            <div className="mt-5 sm:mt-6">
                <button
                    type="button"
                    className="mt-5 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={onCancel}
                    ref={cancelButtonRef}
                >
                    {cancelLabel}
                </button>
            </div>
        </DialogView>
    )
}