import { TABLE_COLUMNS } from './constants';
import { FormattedDate } from '../../../../../components';

const ShippingLabelsTable = ({validShippingLabels, meliShipmentId, onPrintLabel = (labelFile)=>{}}) => {
    const validateCanPrintLabel = (carrierName, meliShipmentId) => carrierName !== 'TREGGO' || !meliShipmentId

    return (
        <table className="w-full">
            <thead>
                <tr>
                    {TABLE_COLUMNS.map(column => (
                        <th
                            className="px-6 py-3 font-medium text-gray-500 bg-white tracking-wider border-t"
                            key={column.accessor}
                        >
                            {column['Header']}
                        </th>
                    ))}
                </tr>
            </thead>

            <tbody>
                {validShippingLabels?.map((label, index) => (
                    <tr key={`ShippingLabelsTable-${index + Math.random()}`}>
                        <td className="text-center p-2 whitespace-nowrap border-t">
                            {label.shipping_method}
                        </td>

                        <td className="text-center p-2 whitespace-nowrap border-t">
                            {label.shipping_number}
                        </td>

                        <td className="text-center p-2 whitespace-nowrap border-t">
                            <FormattedDate date={label.created_at} shortDate />
                        </td>

                        <td className="text-center p-2 whitespace-nowrap border-t">
                            {validateCanPrintLabel(label.shipping_method, meliShipmentId) ? (
                                <button
                                    className="text-blue-600"
                                    onClick={() => onPrintLabel(label.label_file)}
                                >
                                    {'Imprimir'}
                                </button>
                            ) : (
                                <>{'No hace falta imprimir'}</>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default ShippingLabelsTable