export const REQUESTED = "REQUESTED"
export const IN_VALIDATION = "IN_VALIDATION"
export const SCHEDULED = "SCHEDULED"
export const IN_PROGRESS = "IN_PROGRESS"
export const COMPLETED = "COMPLETED"
export const REJECTED = "REJECTED"
export const CANCELED = "CANCELED"

export const WORK_ORDER_STATUSES = [
    {
        status: REQUESTED,
        label: "work_orders.work_order_details.work_order_statuses.REQUESTED"
    },
    {
        status: IN_VALIDATION,
        label: "work_orders.work_order_details.work_order_statuses.IN_VALIDATION"
    },
    {
        status: SCHEDULED,
        label: "work_orders.work_order_details.work_order_statuses.SCHEDULED"
    },
    {
        status: IN_PROGRESS,
        label:"work_orders.work_order_details.work_order_statuses.IN_PROGRESS"
    },
    {
        status: COMPLETED,
        label: "work_orders.work_order_details.work_order_statuses.COMPLETED"
    },
    {
        status: REJECTED,
        label: "work_orders.work_order_details.work_order_statuses.REJECTED"
    },
    {
        status: CANCELED,
        label: "work_orders.work_order_details.work_order_statuses.CANCELED"
    }
]

export const LABELING_PLASTISEAL = "LABELING_PLASTISEAL"
export const PRODUCT_VALIDATION = "PRODUCT_VALIDATION"
export const KIT_ASSEMBLY_SHRINKWRAP = "KIT_ASSEMBLY_SHRINKWRAP"
export const PACKAGING = "PACKAGING"
export const PRODUCT_SEGREGATION = "PRODUCT_SEGREGATION"

export const WORK_ORDER_TYPES = [
    {
        status: LABELING_PLASTISEAL,
        label: "work_orders.work_order_details.work_order_types.LABELING_PLASTISEAL"
    },
    {
        status: PRODUCT_VALIDATION,
        label: "work_orders.work_order_details.work_order_types.PRODUCT_VALIDATION"
    },
    {
        status: KIT_ASSEMBLY_SHRINKWRAP,
        label: "work_orders.work_order_details.work_order_types.KIT_ASSEMBLY_SHRINKWRAP"
    },
    {
        status: PACKAGING,
        label: "work_orders.work_order_details.work_order_types.PACKAGING"
    },
    {
        status: PRODUCT_SEGREGATION,
        label: "work_orders.work_order_details.work_order_types.PRODUCT_SEGREGATION"
    }
]

export const DEFAULT_QUERY_STALE_TIME = 1000 * 60 // 1 minute