export const StatusPill = ({
  status = "unknown",
  className = "",
  statuses = [],
  ...props
}) => {
  let statusDef = statuses.find((item) => item.status === status)
  statusDef = statusDef ? statusDef : statuses[0];

  return (
    <span
      className={`rounded-full py-1.5 px-3 font-bold border-2 ${statusDef.classes} ${className}`}
      {...props}
    >
      {statusDef.label}
    </span>
  );
};
