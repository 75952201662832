import { useState, useContext, useMemo } from "react"
import { Route, Switch, useHistory, useParams } from "react-router-dom"
import { useQuery } from "react-query"
import {
  ActionMenu,
  Notification,
  PageTopBar,
  PageView,
  SlidePanel,
  ButtonLoader,
  NewTable
} from "../../components"
import { Loader } from "../../components/Loader"
import { UserContext } from "../../hooks/UserContext"
import { fetchOperators, createOperator, updateOperatorStatus, updateRoles, fetchOperatorsAccount } from "../../services/operatorServices"
import { OPERATORS, SHOW_OPERATORS } from "../../navigation/constants"
import { OperatorDetailContainer } from "./OperatorDetailContainer"
import { setOperatorsPageSize } from '../../redux/pageSizeSlice';
import { useDispatch, useSelector } from "react-redux"
import { useRef } from "react"
import { Checkbox } from "../../components"
import DropdownMenu from "../../components/DropdownMenu"

/*
const columns = [
  {
    Header: "Nombre",
    accessor: "full_name", // accessor is the "key" in the data
    searchAs: "full_name",
    disableFilters: false,
    disableSortBy: false,
  },
  {
    Header: "Email",
    accessor: "email", // accessor is the "key" in the data
    searchAs: "email",
    disableFilters: true,
    disableSortBy: false,
  },
  {
    Header: "Rol asignado",
    accessor: "rol", // accessor is the "key" in the data
    disableFilters: true,
    disableSortBy: false,
  },
  {
    Header: "Estado",
    accessor: "estado", // accessor is the "key" in the data
    Filter: ({ column: { setFilter, filterValue } }) => {
      const toggleCheckboxChange = () => {
        setFilter(!filterValue);
      };

      return (
        <div>
          <Checkbox
            label="Mostrar sólo usuarios activos"
            checked={filterValue || false}
            onChange={toggleCheckboxChange}
            size={5}
          />
        </div>
      );
    },
    searchAs: "return_type",
    disableFilters: false,
    disableSortBy: false,
  },
  /* {
     Header: "Estado",
     accessor: "estado", // accessor is the "key" in the data
     disableFilters: true,
     disableSortBy: true,
   },
  {
    Header: "",
    accessor: "actions", // accessor is the "key" in the data
    disableFilters: true,
    disableSortBy: true,
    fetchingIndicator: true,
    shrinkToContent: true,
  }
]
*/

/*

const CustomCheckboxFilter = ({ column: { setFilter, filterValue } }) => {
  return (
    <div>
      <Checkbox
        checked={filterValue || false}
        onChange={(e) => {
          setFilter(e.target.checked ? true : undefined);
        }}
      />
    </div>
  );
};
*/

export const ListOperatorsContainer = () => {
  const { user } = useContext(UserContext)
  const tableRef = useRef()
  const history = useHistory()
  const { warehouse_id } = useParams()
  const dispatch = useDispatch()
  const pageSize = useSelector((state) => state.pageSize.operatorsList)
  const initialSearchParams = {
    page: 1,
    per_page: pageSize,
    warehouse_id: warehouse_id
  }
  const [searchParams, setSearchParams] = useState({...initialSearchParams, is_enabled: true})
  const [showClipboardCopied, setShowClipboardCopied] = useState(false)
  const [showOperatorCreated, setOperatorCreated] = useState(false)
  const [loadOperatorCreation, setLoadOperatorCreation] = useState(false)
  const [userName, setUserName] = useState(null)
  const [userLastname, setUserLastname] = useState(null)
  const [userEmail, setUserEmail] = useState(null)
  const [userPassword, setUserPassword] = useState(null)
  const [showOperatorRolesUpdatedSuccess, setOperatorRolesUpdatedSuccess] = useState(false)
  const [showOperatorRolesUpdatedFailed, setOperatorRolesUpdatedFailed] = useState(false)


  const OPERATOR_ROLES = [
    {
      role: "ROLE_OPERATOR",
      name: "Operador",
      description: "Tiene permisos para llevar a cabo funciones dentro del sistema de gestión de almacén, incluyendo la lectura y seguimiento de pedidos o productos hasta la manipulación y transferencia de inventario, así como la generación y actualización de etiquetas de envío.",
    },
    {
      role: "ROLE_LAST_MILE_SUPERVISOR",
      name: "Supervisor de última milla",
      description: "Tiene autorización para supervisar y gestionar aspectos de la última etapa del proceso de envío en la cadena de suministro, incluyendo el manejo de incidentes de envío y reclamaciones, así como el acceso a información sobre pedidos, productos y el estado general del almacén.",
    },
    {
      role: "ROLE_INVENTORY_SUPERVISOR",
      name: "Supervisor de inventario",
      description: "Tiene permisos para supervisar y gestionar diversos aspectos del inventario en el almacén, incluyendo recuentos, transferencias, reabastecimientos, historial y revisiones de órdenes de recogida, entre otras funciones críticas para el control efectivo del inventario.",
    },
    {
      role: "ROLE_REVERSE_LOGISTICS_OPERATOR",
      name: "Operador de logística inversa",
      description: "Tiene permisos para gestionar y supervisar diversas actividades de logística inversa y devoluciones en el almacén, incluyendo la creación de reposiciones de inventario, recepción y manejo de devoluciones, gestión de pedidos enviados y transferencia de ubicaciones de almacenamiento.",
    },
  ];

  const OPERATOR_ROLES_FILTERS = [
    {
      role: "ROLE_SUPER_ADMIN",
      name: "Súper administrador",
    },
    {
      role: "ROLE_LAST_MILE_SUPERVISOR",
      name: "Supervisor de última milla",
    },
    {
      role: "ROLE_INVENTORY_SUPERVISOR",
      name: "Supervisor de inventario",
    },
    {
      role: "ROLE_REVERSE_LOGISTICS_OPERATOR",
      name: "Operador de logística inversa",
    }
  ];

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    isPreviousData,
    refetch,
  } = useQuery(["operators", searchParams], () => fetchOperators(searchParams), {
    keepPreviousData: true,
  })

  const resetNewOperatorFields = () => {
    setUserName('');
    setUserLastname('');
    setUserEmail('');
    setUserPassword('');
  }

  const handleOnCreateOperator = async () => {
    setLoadOperatorCreation(true);
    const operatorsAccount = await fetchOperatorsAccount(searchParams);
    console.log(operatorsAccount)
    if (operatorsAccount) {
      try {
        let response = await createOperator({
          name: userName,
          lastname: userLastname,
          email: `operadores+${userEmail}@cubbo.com`,
          password: userPassword,
          account_id: operatorsAccount.id,
          roles: ["ROLE_OPERATOR"],
        });
        refetch();
        setOperatorCreated(true);
        resetNewOperatorFields();
      } catch (error) {
        console.log(error);
      } finally {
        setLoadOperatorCreation(false);
      }
    } else {
      console.error("operators_account.id no disponible aún.");
    }
  };

  const handlerOnClickReset = (user) => {
    history.push({
      pathname: SHOW_OPERATORS.replace(":warehouse_id", warehouse_id),
      search: '?query=abc',
      state: { user: user }
    })
  }

  const handlerOnClickUpdateOperatorStatus = async (user) => {
    let updatedUser = await updateOperatorStatus(user.id, !user.is_enabled)
    refetch();
  }

  const getOrderBy = (orderName) => {
    switch (orderName) {
      case 'full_name_or_email':
        return 'first_name';
      case 'estado':
        return 'is_enabled';
      default:
        return orderName;
    }
  }

  const onSortChange = (orderBy) => {
    if (orderBy.length > 0) {
      setSearchParams({
        ...searchParams,
        order_by: getOrderBy(orderBy[0].id),
        order: orderBy[0].desc ? "DESC" : "ASC",
      })
    } else {
      if ("order_by" in searchParams) {
        delete searchParams.order_by
        delete searchParams.order
        setSearchParams({ ...searchParams })
      }
    }
  }

  const updateFiltersData = (updatedData, filter) => {
    // Update filters with new data
    setFiltersData((prev) => {
      return prev.map((item) => {
          if (item.key == filter) 
              return {
                  ...item,
                  data: updatedData
              }
          else return item
      }).sort((a, b) => a.data.visible > b.data.visible ? -1 : 1) // Sort to preserve the order of the filters
    })

    // Set search params only if needed
    const filterItem = filtersData.find(item => item.key == filter)
    if ((Array.isArray(filterItem.data.value) && filterItem.data.value.length === 0 && updatedData.value.length == 0)) return
    if (!['boolean','text'].includes(filterItem.data.type) && filterItem.data.value === updatedData.value) return
    let value = updatedData.value
    const filter_name = filterItem.key_alias || filterItem.key
    setSearchParams((prev) => ({ ...prev, [filter_name]: value, page: 1 }))
  }

  const getMenuOptions = (user) => {
    const isSuperAdminOrAdmin = user.roles?.some(role => ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_WH_ADMIN"].includes(role))
    const options = [
      {
        title: "Resetear Password",
        clickHandler: () => handlerOnClickReset(user),
      },
      {
        title: user.is_enabled ? "Deshabilitar usuario" : "Habilitar usuario",
        className: "text-red",
        clickHandler: () => {
          if (!isSuperAdminOrAdmin) {
            handlerOnClickUpdateOperatorStatus(user);
          }
        },
      }
    ]
    if (isSuperAdminOrAdmin) {
      options[1].title = <span style={{ color: "lightgray" }}>{options[1].title}</span>;
    }
    return options
  }

  const generatePwd = (id) => {
    let firstBlockChars = "abcdefghijklmnopqrstuvwxyz";
    let firstBlockPasswordLength = 3;
    let secondBlockChars = "0123456789";
    let secondBlockPasswordLength = 2;
    let thirdBlockChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let thirdBlockPasswordLength = 2;
    let password = "";
    for (var i = 0; i <= firstBlockPasswordLength; i++) {
      let randomNumber = Math.floor(Math.random() * firstBlockChars.length);
      password += firstBlockChars.substring(randomNumber, randomNumber + 1);
    }
    password += "@";
    for (var i = 0; i <= secondBlockPasswordLength; i++) {
      let randomNumber = Math.floor(Math.random() * secondBlockChars.length);
      password += secondBlockChars.substring(randomNumber, randomNumber + 1);
    }
    for (var i = 0; i <= thirdBlockPasswordLength; i++) {
      let randomNumber = Math.floor(Math.random() * thirdBlockChars.length);
      password += thirdBlockChars.substring(randomNumber, randomNumber + 1);
    }
    setUserPassword(password)
  }

  const handleResetFilters = () => {
    setSearchParams(initialSearchParams)
  }

  const handleUpdatedRolesNotification = (status) => {
    if (status === 200) {
      setOperatorRolesUpdatedSuccess(true)
    } else {
      setOperatorRolesUpdatedFailed(true)
    }
  }

  const handleRolesUpdate = async (operatorId, roles) => {
    const res = await updateRoles(operatorId, roles);
    handleUpdatedRolesNotification(res.status)
  };

  const operatorRolesFilters = OPERATOR_ROLES_FILTERS.filter(
    (role) => role.filterable !== false).map((role) => {
        return role.filterable === false
            ? null : { id: role.role, label: role.name }
        }
  )

  const memoizedTableData = useMemo(() => {
    return data?.users?.map((user) => {
      const isSuperAdminOrAdmin = user.roles?.some(role => ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_WH_ADMIN"].includes(role))

      const operatorFullNameOrSupervisorFullNameWithBadge = (
        isSuperAdminOrAdmin ? (
          <>
            <div className="text-base mb-1">{user.first_name} {user.last_name}</div>
            <div className="inline-flex items-center px-3 py-1 rounded text-xs font-semibold bg-yellow-400 text-white">
              Super admin
            </div>
          </>
        ) : (
          <>
            <div className="text-bas mb-1">{user.first_name} {user.last_name}</div>
            <div className="inline-flex items-center px-3 py-1 rounded text-xs font-semibold bg-blue-500 text-white">
              Operador
            </div>
          </>
        )
      );

      return {
        key: user.id,
        object: user,
        full_name_or_email: operatorFullNameOrSupervisorFullNameWithBadge,
        email: (
          <div className="font-normal text-base text-gray-700">
            {user.email}
          </div>
        ),
        roles: (
          <DropdownMenu options={OPERATOR_ROLES} selected={user.roles} element={user.id} action={handleRolesUpdate} isSuperAdminOrAdmin={isSuperAdminOrAdmin} />
        ),
        estado: (
          <span
            className={`text-${user.is_enabled ? "green" : "gray"
              }-400 font-medium`}
          >
            {user.is_enabled ? "Activo" : "Deshabilitado"}
          </span>
        ),
        actions: !isSuperAdminOrAdmin ? (
          <ActionMenu className="float-right" items={getMenuOptions(user)} />
        ) : null,
      };
    });
  }, [data?.users]);

  const memoizedColumns = useMemo(() => {
    if (!data?.users) return []

    return [
      {
        Header: "Nombre",
        accessor: "full_name_or_email", // accessor is the "key" in the data
      },
      {
        Header: "Correo",
        accessor: "email", // accessor is the "key" in the data
      },
      {
        Header: "Roles asignados",
        accessor: "roles", // accessor is the "key" in the data
        disableSortBy: true
      },
      {
        Header: "Estado",
        accessor: "estado", // accessor is the "key" in the data
      },
      {
        Header: "",
        accessor: "actions",
        disableSortBy: true,
        fetchingIndicator: true,
      },
    ]
  }, [data])

  const filtersInitialData = [
    {
      key: "full_name_or_email",
      placeholder: "Buscar por nombre, apellido o email",
      label: "Buscar por nombre, apellido o email",
      data: {
        visible: true,
        type: 'text',
        value: ''
      },
    },
    {
      key: "roles",
      label: "Buscar por rol",
      data: {
          visible: true,
          type: 'multiFilter',
          value: [],
          placeholder: "Buscar por rol",
          options: operatorRolesFilters
      },
    },
    {
      key: "is_enabled",
      placeholder: "Mostar sólo usuarios activos",
      label: 'Mostar sólo usuarios activos',
      data: {
        visible: true,
        type: 'boolean',
        onClickValue: true,
      },
    },
  ]

  const [filtersData, setFiltersData] = useState(filtersInitialData)

  return (
    <>
      <Notification
        show={showClipboardCopied}
        setShow={setShowClipboardCopied}
        type="success"
        title="Copiado!"
      />
      <Notification
        show={showOperatorCreated}
        setShow={setOperatorCreated}
        type="success"
        title="Operador creado!"
      />
      <Notification
        show={showOperatorRolesUpdatedSuccess}
        setShow={setOperatorRolesUpdatedSuccess}
        type="success"
        title="Roles actualizados con éxito!"
      />
      <Notification
        show={showOperatorRolesUpdatedFailed}
        setShow={setOperatorRolesUpdatedFailed}
        type='success'
        title="Hubo un error al modificar los roles, por favor intenta de nuevo."
      />
      <PageView
        topMenu={
          <PageTopBar>
            <div className="text-lg font-semibold">Gestión de Operadores</div>
            {/* {user.current_store && (
              <Button
                className="whitespace-nowrap"
                onClick={() => {
                }}
              >
                Crear Disputa
              </Button>
            )} */}
          </PageTopBar>
        }
        childrenFullWidth={true}
        topMenuFullWidth={true}
      >
        {!isPreviousData && isLoading ? (
          <>
            <Loader show={true}></Loader>
          </>
        ) : isError ? (
          <>Error: {error.message}</>
        ) : (
          <>
            <div className="text-xl font-bold mb-4">Nuevo operador</div>
            <div className="w-4/4 bg-white mb-10 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2 p-10">
              <div className="sm:col-span-1">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  Nombre
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="sm:col-span-1">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                  Apellido
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    value={userLastname}
                    onChange={(e) => setUserLastname(e.target.value)}
                    autoComplete="family-name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="sm:col-span-1">
                <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                    operadores+
                  </span>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    autoComplete="username"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    className="block w-full min-w-0 flex-1 rounded-none border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                  <span className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                    @cubbo.com
                  </span>
                </div>
                <a onClick={() => {
                  navigator.clipboard.writeText("operadores+" + userEmail + "@cubbo.com")
                  setShowClipboardCopied()
                }}>Copiar Email</a>
              </div>
              <div className="sm:col-span-1">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    value={userPassword}
                    onChange={(e) => setUserPassword(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <a onClick={() => generatePwd()}>Generar Password</a>
                <span className="text-blue-600">  |  </span>
                <a onClick={() => {
                  navigator.clipboard.writeText(userPassword)
                  setShowClipboardCopied()
                }}>Copiar Password</a>
              </div>
              <ButtonLoader loading={loadOperatorCreation} className="w-full whitespace-nowrap mr-1" type="primary" onClick={() => handleOnCreateOperator()}>
                Crear Operador
              </ButtonLoader>
            </div>

            <div className="text-xl font-bold mb-4">Operadores</div>

            <NewTable
              data={memoizedTableData}
              columns={memoizedColumns}
              selectable={false}
              cursorPointer={false}
              showHeader
              showLoader={isLoading || isFetching}
              isFetching={isFetching}
              emptyTableText="No hay operadores que mostrar."
              paginationMeta={data?.meta}
              onPaginationChange={(requestedPage) => { setSearchParams({ ...searchParams, page: requestedPage }) }}
              onPageSizeChange={(pageSize) => {
                setSearchParams({ ...searchParams, per_page: pageSize.id, page: 1 })
                dispatch(setOperatorsPageSize(pageSize.id))
              }}

              handleResetFilters={handleResetFilters}
              filtersData={filtersData}
              onSortChange={onSortChange}
              hasExport={false}
              onSearchHandler={updateFiltersData}
            />
          </>
        )}

      </PageView>
      <Switch>
        <Route exact path={SHOW_OPERATORS}>
          <SlidePanel
            title="Ver Operador"
            referrer={OPERATORS.replace(':warehouse_id', warehouse_id)}
            onClose={() => refetch()}
          >
            <OperatorDetailContainer />
          </SlidePanel>
        </Route>
      </Switch>
    </>
  )
}
