import axiosInstance from "../helpers/axiosInstance";

export const fetchWarehouseLocations = async (warehouse_id, params = {}) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/warehouses/${warehouse_id}/locations`, { params: params, timeout: 25000 })
    return res.data
}

export const exportWarehouseLocations = async (warehouse_id, params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/warehouses/${warehouse_id}/export-locations-by-product.csv`, {
        responseType: 'blob', // Important
        params: params
    })
    return res.data
}

export const enqueueLocationsExport = async function (data) {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/user-async-tasks/locations_export`, data)
    return res.data
}

export const getLocationsExport = async function (jobId) {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/user-async-tasks/locations_export/${jobId}`)
    return res.data
}