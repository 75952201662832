import { useTranslation } from "react-i18next";
import { useState, useMemo, useContext, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { WORK_ORDER_STATUSES } from '../../components/NewTable/StatusHighlightedDefinitions'

import { UserContext } from "../../hooks/UserContext"
import { useDispatch, useSelector } from "react-redux"
import {
    ActionMenu,
    FormattedDate,
    NewTable,
    PageTopBar,
    PageView
} from "../../components"
import { Loader } from "../../components/Loader"
import { SHOW_WORK_ORDER } from "../../navigation/constants"
import { setWorkOrdersPageSize } from '../../redux/pageSizeSlice'
import LogRocket from "logrocket"
import { fetchWorkOrders } from "../../services/workOrderServices"
import { StatusHighlighted } from '../../components/NewTable/StatusHighlighted'
import { StatusPill } from "../../components/BasicTable/StatusPill";
import { IN_PROGRESS, IN_VALIDATION, WORK_ORDER_TYPES, REQUESTED, SCHEDULED, DEFAULT_QUERY_STALE_TIME } from "./constants";
import { ArrowRightIcon } from "@heroicons/react/outline";

const statusFilters = WORK_ORDER_STATUSES.filter(
    (status) => status.filterable !== false).map((status) => {
        return status.filterable === false
            ? null : { id: status.status, label: status.label }
    }
    )

const workOrderTypeFilters = WORK_ORDER_TYPES.filter(
    (type) => type.filterable !== false).map((type) => {
        return type.filterable === false
            ? null : { id: type.status, label: type.label }
    }
    )

export const ListWorkOrdersContainer = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory()
    const { user } = useContext(UserContext)
    const { warehouse_id } = useParams()
    const dispatch = useDispatch()
    const pageSize = useSelector((state) => state.pageSize.workOrdersList)
    const initialSearchParams = {
        page: 1,
        per_page: pageSize,
        order_by: "created_at",
        warehouse_id: warehouse_id
    }
    const [searchParams, setSearchParams] = useState(initialSearchParams)
    const [workOrders, setWorkOrders] = useState([])

    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData
    } = useQuery(
        ['work_orders_list', searchParams], () => fetchWorkOrders(searchParams),
        { 
            keepPreviousData: false,
            refetchOnWindowFocus: false
        }
    )

    const onSortChange = (orderBy) => {
        if (orderBy.length > 0) {
            setSearchParams({
                ...searchParams,
                order_by: orderBy[0].id,
                order: orderBy[0].desc ? "desc" : "asc",
            })
        } else {
            if ("order_by" in searchParams) {
                searchParams.order_by = "created_at"
                delete searchParams.order
                setSearchParams({ ...searchParams })
            }
        }
    }

    useEffect(() => {
        if (!data) {
            return
        }
        if (data.work_orders) setWorkOrders(data.work_orders)
    }, [data])

    const buildStatusHighlighted = (workOrder) => {
        return (
            <div workOrder={workOrder}>
                <StatusHighlighted
                    className=""
                    status={workOrder.status}
                    statuses={WORK_ORDER_STATUSES}
                />
                {!!workOrder.created_at && (
                    <div className="mt-3 ml-0.5">
                        <div className="mt-2 ml-0.5 text-gray-400 text-sm">
                            {i18n.t("work_orders.work_order_list.on_date")} <FormattedDate date={workOrder.created_at} shortDate={true} />
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const buildWorkOrderType = (workOrder) => {
        const type = WORK_ORDER_TYPES.find((type) => type.status === workOrder.work_order_type)
        return (
            <div className="t-1 text-sm text-gray-400">
                {i18n.t(type?.label)}
            </div>
        )
    }

    const memoizedTableData = useMemo(() => {
        return workOrders.map((workOrder) => {
            return {
                object: workOrder,
                id: (
                    <div className="font-normal text-base text-gray-700">
                        <div className="t-1 text-sm text-gray-400">{String(workOrder.id)}</div>
                    </div>
                ),
                store: (
                    <div className="font-normal text-base text-gray-700">
                        <div className="t-1 text-sm text-gray-400">{String(workOrder.store.name)}</div>
                    </div>
                ),
                work_order_types: buildWorkOrderType(workOrder),
                statuses: buildStatusHighlighted(workOrder),
                created_at: <div className="t-1 text-sm text-gray-400"><FormattedDate className="t-1 text-sm text-gray-400" date={workOrder.created_at} shortDate /></div>,
                actions: (
                    <ArrowRightIcon color="gray" className="h-5 h-5 mt-4"/>
                ),
            }
        })
    }, [workOrders])

    const memoizedColumns = useMemo(() => {
        if (!workOrders) return []

        // accessor is the "key" in the data
        return [
            {
                Header: i18n.t("work_orders.work_order_list.header.work_order_id"),
                accessor: "id",
                disableSortBy: true
            },
            {
                Header: i18n.t("work_orders.work_order_list.header.customer"),
                accessor: "store",
                disableSortBy: true
            },
            {
                Header: i18n.t("work_orders.work_order_list.header.work_order_type"),
                accessor: "work_order_types",
                disableSortBy: true
            },
            {
                Header: i18n.t("work_orders.work_order_list.header.status"),
                accessor: "statuses",
                disableSortBy: true
            },
            {
                Header: i18n.t("work_orders.work_order_list.header.created_at"),
                accessor: "created_at",
                disableSortBy: true
            },
            {
                Header: "",
                accessor: "actions",
                disableSortBy: true,
                fetchingIndicator: true,
            },
        ]
    }, [workOrders])

    const callFilterShortcut = (filters) => {
        LogRocket.track('WorkOrdersFilterShortCut', {
            warehouse_id: warehouse_id,
            shortcut: filters.map(filter => `${filter.key}=${filter.value}`).join(', '),
        })
        const newSearchParams = {...searchParams}
        filters.forEach(filter => {
            let currentFilter = filtersData.find(item => item.key == filter.key)
            currentFilter.data.value = filter.value
            currentFilter.data.visible = true
            let filterKey = currentFilter.key_alias ? currentFilter.key_alias : currentFilter.key
            newSearchParams[filterKey] = filter.value
        })
        setSearchParams(newSearchParams)

    }

    const updateFiltersData = (updatedData, filter) => {
        // Update filters with new data
        setFiltersData((prev) => {
            return prev.map((item) => {
                if (item.key == filter) 
                    return {
                        ...item,
                        data: updatedData
                    }
                else return item
            }).sort((a, b) => a.data.visible > b.data.visible ? -1 : 1) // Sort to preserve the order of the filters
        })

        // Set search params only if needed
        const filterItem = filtersData.find(item => item.key == filter)
        if ((Array.isArray(filterItem.data.value) && filterItem.data.value.length === 0 && updatedData.value.length == 0)) return
        if (!['boolean','text'].includes(filterItem.data.type) && filterItem.data.value === updatedData.value) return
        let value = updatedData.value
        const filter_name = filterItem.key_alias || filterItem.key

        if (!updatedData.visible) {
            setSearchParams((prev) => {
                // Delete filter key from searchParams
                const newSearchParams = {...prev, page: 1}
                delete newSearchParams[[filter_name]]
                return newSearchParams
            })
        }
        else {
            setSearchParams((prev) => ({...prev, [filter_name]: value, page: 1}))
        }
    }

    const handleResetFilters = () => {
        setSearchParams(initialSearchParams)
    }

    const filtersInitialData = [
        {
            key: "id",
            placeholder: i18n.t("work_orders.work_order_list.filters.work_order_id"),
            label: i18n.t("work_orders.work_order_list.filters.work_order_id"),
            data: {
                visible: true,
                type: 'bulk',
                value: []
            },
        },
        {
            key: "statuses",
            label: i18n.t("work_orders.work_order_list.filters.status"),
            data: {
                visible: true,
                type: 'multiFilter',
                value: [],
                placeholder: i18n.t("work_orders.work_order_list.filters.status"),
                options: statusFilters
            },
        },
        {
            key: "work_order_types",
            label: i18n.t("work_orders.work_order_list.filters.work_order_type"),
            data: {
                visible: true,
                type: 'multiFilter',
                value: [],
                placeholder: i18n.t("work_orders.work_order_list.filters.work_order_type"),
                options: workOrderTypeFilters
            },
        }
    ]

    const [filtersData, setFiltersData] = useState(filtersInitialData)

    return (
        <>
            <PageView
                topMenu={<PageTopBar>
                    <div className="text-lg font-semibold">Maquilas</div>
                </PageTopBar>}
                childrenFullWidth={true}
                topMenuFullWidth={true}
            >
                {(!isPreviousData && isLoading) ? (
                    <>
                        <Loader show={true}></Loader>
                    </>
                ) : isError ? (
                    <>Error: {error.message}</>
                ) : (

                    <>
                        <div className="flex flex-col">
                            <nav className='flex relative rounded-lg border bg-white py-2 mb-2 text-gray-700'>
                                <div className="flex flex-col lg:flex-row justify-start border-l-2 lg:border-0 ml-5 lg:ml-0">
                                    <div className="lg:max-w-min ml-5 my-1 lg:my-2 text-base leading-4 py-2 pl-0">
                                        {i18n.t("work_orders.work_order_list.queued")}
                                    </div>
                                    <div className="max-w-min flex flex-col static ml-5 my-1 lg:my-2">
                                        <button className="text-left whitespace-nowrap border border-gray-300 shadow-sm rounded-lg py-3 px-3 text-gray-700 font-medium hover:bg-gray-50 outline-none"
                                            onClick={() => {
                                                callFilterShortcut([{ key: "statuses", value: IN_VALIDATION }])
                                            }}>
                                            <StatusPill status={IN_VALIDATION} statuses={[{
                                                status: IN_VALIDATION,
                                                label: i18n.t("work_orders.work_order_details.work_order_statuses.IN_VALIDATION"),
                                                classes: "bg-gray-50 text-gray-500 border-gray-50"
                                            }]} />
                                        </button>
                                    </div>
                                    <div className="max-w-min flex flex-col static ml-5 my-1 lg:my-2">
                                        <button className="text-left whitespace-nowrap border border-gray-300 shadow-sm rounded-lg py-3 px-3 text-gray-700 font-medium hover:bg-gray-50 outline-none"
                                            onClick={() => {
                                                callFilterShortcut([{ key: "statuses", value: REQUESTED }])
                                            }}>
                                            <StatusPill status={REQUESTED} statuses={[{
                                                status: REQUESTED,
                                                label: i18n.t("work_orders.work_order_details.work_order_statuses.REQUESTED"),
                                                classes: "bg-yellow-50 text-yellow-500 border-yellow-50"
                                            }]} />
                                        </button>
                                    </div>
                                    <div className="max-w-min flex flex-col static ml-5 my-1 lg:my-2">
                                        <button className="text-left whitespace-nowrap border border-gray-300 shadow-sm rounded-lg py-3 px-3 text-gray-700 font-medium hover:bg-gray-50 outline-none"
                                            onClick={() => {
                                                callFilterShortcut([{ key: "statuses", value: SCHEDULED }])
                                            }}>
                                            <StatusPill status={SCHEDULED} statuses={[{
                                                status: SCHEDULED,
                                                label: i18n.t("work_orders.work_order_details.work_order_statuses.SCHEDULED"),
                                                classes: "bg-pink-50 text-pink-500 border-pink-50"
                                            }]} />
                                        </button>
                                    </div>
                                    <div className="max-w-min flex flex-col static ml-5 my-1 lg:my-2">
                                        <button className="text-left whitespace-nowrap border border-gray-300 shadow-sm rounded-lg py-3 px-3 text-gray-700 font-medium hover:bg-gray-50 outline-none"
                                            onClick={() => {
                                                callFilterShortcut([{ key: "statuses", value: IN_PROGRESS }])
                                            }}>
                                            <StatusPill status={IN_PROGRESS} statuses={[{
                                                status: IN_PROGRESS,
                                                label: i18n.t("work_orders.work_order_details.work_order_statuses.IN_PROGRESS"),
                                                classes: "bg-purple-50 text-purple-500 border-purple-50"
                                            }]} />
                                        </button>
                                    </div>
                                </div>
                            </nav>
                        </div>
                        <NewTable
                            data={memoizedTableData}
                            columns={memoizedColumns}
                            showLoader={isFetching && isPreviousData}
                            showPaginationOnFooter
                            isFetching={isFetching}
                            emptyTableText={i18n.t("work_orders.work_order_list.footer.no_work_orders")}
                            paginationMeta={data?.meta}
                            onPaginationChange={(page) => {
                                setSearchParams((prev) => ({ ...prev, page: page }))
                            }}
                            onPageSizeChange={(page) => {
                                setSearchParams((prev) => ({ ...prev, per_page: page.id, page: 1 }))
                                dispatch(setWorkOrdersPageSize(page.id))
                            }}
                            onSortChange={onSortChange}
                            onSearchHandler={updateFiltersData}
                            handleResetFilters={handleResetFilters}
                            onClickFilterDropdown={() => {
                                LogRocket.track('WorkOrdersFilterDropdown', {
                                    warehouse_id: warehouse_id
                                })
                            }}
                            filtersData={filtersData}
                            hasExport={false}
                            rowProps={row => ({
                                onClick: () => history.push(SHOW_WORK_ORDER.replace(':warehouse_id', warehouse_id).replace(':id', row.original.object?.id))
                            })}
                            footerText={i18n.t("work_orders.work_order_list.footer.work_order")}
                        />
                    </>

                )}
            </PageView>
        </>
    )
}
