import './App.css';
import './index.css'

import {
    BrowserRouter
} from "react-router-dom";
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query'
import {RouterConfig} from "./navigation";
import useFindUser from "./hooks/useFindUser";
import { UserContext } from "./hooks/UserContext"
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

const queryClient = new QueryClient()

function App() {

    LogRocket.init('lkrho1/wms-production');
    setupLogRocketReact(LogRocket);

    const { user, setUser, isLoading } = useFindUser();

    return (
        <QueryClientProvider client={queryClient}>
            {/* <PackingProofVideo /> */}
            <UserContext.Provider value={{ user, setUser, isLoading }}>
              <BrowserRouter>
                  <RouterConfig />
              </BrowserRouter>
            </UserContext.Provider>
        </QueryClientProvider>
    );
}

export default App;
