import { useEffect, useRef } from 'react';
import { PRODUCT_TABLE_COLUMNS } from './constants';

const ProductsTable = ({productsWithStock, focusedProduct}) => {
    const focusedProductRef = useRef(null)
    const getSku = product => product.product_codes.find(productCode => productCode.sku).sku

    useEffect(() => {
        if(focusedProductRef.current){
            // Scroll table to the row of the focused product
            focusedProductRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    },[focusedProduct])

    const validateIsFocusedProduct = (productId) => {
        return focusedProduct && focusedProduct.hasOwnProperty("id") && productId === focusedProduct.id
    }

    return (
        <table className="w-full ">
            <thead>
                <tr>
                    {PRODUCT_TABLE_COLUMNS.map(column => (
                        <th
                            className="px-6 py-3 font-medium text-gray-500 bg-white tracking-wider border-t"
                            key={column.accessor}
                        >
                            {column['Header']}
                        </th>
                    ))}
                </tr>
            </thead>

            <tbody>
                {productsWithStock?.map((product) => {
                    const isRowFocused = validateIsFocusedProduct(product.id)

                    // Remove the focused effect after some time
                    if(isRowFocused){
                        setTimeout(() => {
                            focusedProductRef.current?.classList.remove("animate-pulse", "bg-gray-50")
                        }, 1000)
                    }

                    return (
                        <tr
                            key={`ProductsTable-${product.id}`}
                            ref={isRowFocused ? focusedProductRef : null}
                            className={`${isRowFocused ? "animate-pulse bg-gray-50" : ""}`}
                        >
                            <td className="text-center p-2 whitespace-nowrap border-t">
                                <span className='rounded-lg bg-blue-100 text-blue-800 select-none px-2.5 py-0.5'>
                                    {getSku(product)}
                                </span>
                            </td>

                            <td className="text-center p-2 whitespace-nowrap border-t">
                                <span className='rounded-lg bg-blue-100 text-blue-800 select-none px-2.5 py-0.5'>
                                    {product.bar_code === null ? 'Sin datos' : product.bar_code}
                                </span>
                            </td>

                            <td className="text-center p-2 whitespace-nowrap border-t">
                                {product.quantity}
                            </td>

                            <td className="text-center p-2 whitespace-nowrap border-t">
                                {product.reviewedItemsCount || 0}
                            </td>

                            <td className="text-center p-2 whitespace-nowrap border-t">
                                {product.quantity === product.reviewedItemsCount && (
                                    <span className='rounded-lg bg-green-50 text-green-500 select-none px-2.5 py-0.5'>
                                        {'Completas'}
                                    </span>
                                )}

                                {product.quantity !== product.reviewedItemsCount && (
                                    <span className='rounded-lg bg-yellow-100 text-yellow-800 select-none px-2.5 py-0.5'>
                                        {'Pendiente'}
                                    </span>
                                )}
                            </td>
                        </tr>
                )})}
            </tbody>
        </table>
    )
}

export default ProductsTable