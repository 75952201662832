import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isRecording: null,
  fileIdentifier: null,
}

export const webcamRecorderSlice = createSlice({
  name: 'webcamRecorder',
  initialState,
  reducers: {
    setIsRecording: (state, action) => {
      state.isRecording = action.payload
    },
    setFileIdentifier: (state, action) => {
      state.fileIdentifier = action.payload
    },
    setRecordingState: (state, action) => {
      state.isRecording = action.payload?.isRecording ? action.payload?.isRecording : null
      state.fileIdentifier = action.payload?.fileIdentifier ? action.payload?.fileIdentifier : null
    },
    stopRecording: (state, action) => {
      state.isRecording = false
      state.fileIdentifier = null
    }
  },
})

export const { setIsRecording, setFileIdentifier, setRecordingState, stopRecording } = webcamRecorderSlice.actions

export const isRecording = (state) => state.webcamRecorder.isRecording
export const fileIdentifier = (state) => state.webcamRecorder.fileIdentifier

export default webcamRecorderSlice.reducer
