import parcel_bag_image from '../../../../../assets/parcel_bag.png';
import jiffy_envelope_image from '../../../../../assets/jiffy_envelope.png';
import cubbo_box_image from '../../../../../assets/cubbo_box.png';
import packageless_image from '../../../../../assets/packageless.png';

export const PACKAGE_OPTIONS = {
    PARCEL_BAG: 'parcel_bag',
    JIFFY_ENVELOPE: 'jiffy_envelope',
    CUBBO_BOX: 'cubbo_box',
    PACKAGELESS: 'packageless',
    CUSTOMER_BOX: 'customer_box'
}

export const PACKAGE_OPTIONS_IMAGES = {
    PARCEL_BAG: parcel_bag_image,
    JIFFY_ENVELOPE: jiffy_envelope_image,
    CUBBO_BOX: cubbo_box_image,
    PACKAGELESS: packageless_image,
}

export const PACKAGE_OPTIONS_TITLES = {
    PARCEL_BAG: "Bolsa",
    JIFFY_ENVELOPE: "Sobre burbuja",
    CUBBO_BOX: "Caja Cubbo",
    PACKAGELESS: "Enviar sin empaque",
}

export const PACKAGE_SELECTION_TITLES = {
    0: "Preferida",
    1: "2º opción",
    2: "3º opción",
    3: "4º opción",
    4: "5º opción",
}

export const UNAVAILABLE_RECOMMENDED_PACKAGE_OPTIONS = [
    'customer_box'
]

export const RESERVED_PACKAGE_OPTIONS = [
    'packageless'
]

export const PACKING_OPTIONS_PRIORITIES = {
    0: "packageless",
    1: "customer_box",
    2: "parcel_bag",
    3: "jiffy_envelope",
    4: "cubbo_box"
}

export const FRAGILE_OPTIONS = {
    FRAGILE_ONLY: "FRAGILE_ONLY",
    NON_FRAGILE_ONLY: "NON_FRAGILE_ONLY",
    BOTH: "BOTH",
    NEITHER: "NEITHER",
}
