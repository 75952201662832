import { GlobeIcon } from '@heroicons/react/outline';

import { InformationCard } from "../../../../../components";

const InternationalNotificationSection = ({isInternationalOrder}) => {
    return (
        <InformationCard
            title={"Envío internacional"}
            description={"El destino del pedido es internacional"}
            iconComponent={<GlobeIcon className="h-full" />}
            containerStyles={"bg-purple-100 text-purple-600"}
            isHidden={!isInternationalOrder}
        />
    )
}

export default InternationalNotificationSection