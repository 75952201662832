import { useMemo } from 'react';

import ShippingLabelsTable from './ShippingLabelsTable';
import { SHIPPING_LABEL_STATUSES } from './constants';

const ShippingLabelsListSection = ({shippingLabels, meliShipmentId, onPrintLabel = (labelFile)=>{}, isHidden=false}) => {
    const validShippingLabels = useMemo(() => shippingLabels?.filter(({status, label_file}) => (
        status === SHIPPING_LABEL_STATUSES.VALID && label_file
    )), [shippingLabels])

    if(isHidden) return (<></>)

    return (
        <section className="bg-white rounded-lg flex flex-col gap-4 pb-2">
            <div className='m-4'>
                <h2 className="mb-0 text-2xl font-bold">{`Guías generadas`}</h2>
                <p className="mb-0 text-lg">{'Ya se han generado guías para esta orden'}</p>
            </div>

            <div className='max-h-40 overflow-y-auto'>
                <ShippingLabelsTable
                    validShippingLabels={validShippingLabels}
                    meliShipmentId={meliShipmentId}
                    onPrintLabel={onPrintLabel}
                />
            </div>
        </section>
    )
}

export default ShippingLabelsListSection