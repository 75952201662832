import { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useQuery } from "react-query"
import { useTranslation } from "react-i18next"

import { CurrencyInputField } from "../../components/CurrencyInputField"
import { ConfirmDialog, Button, Loader, Notification } from "../../components"
import { fetchStoreBillingConfigurations, createCustomServiceWithUsage, fetchStore } from "../../services/storeService"
import { BILLING_STORE_CONFIGS } from "../../navigation/constants"
import { fetchWarehouse } from "../../services/warehouseSevices"

const CreateCustomService = () => {
    const [isDiscount, setIsDiscount] = useState(false)
    const [amount, setAmount] = useState(null)
    const [title, setTitle] = useState(null)
    const [description, setDescription] = useState(null)

    const maxTitleLength = 50
    const maxDescriptionLength = 150

    const [isDiscountValid, setIsDiscountValid] = useState(false)
    const [isAmountValid, setIsAmountValid] = useState(false)
    const [isTitleValid, setIsTitleValid] = useState(false)
    const [isDescriptionValid, setIsDescriptionValid] = useState(false)
    const [areValid, setAreValid] = useState(false)

    const {i18n} = useTranslation()
    const { store_id, warehouse_id, billing_configuration_id } = useParams()
    const [confirmCreateDialog, setConfirmCreateDialog] = useState({show: false, title: "", description: ""})
    const [isCreatingCustomService, setIsCreatingCustomService] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState({show: false, type: "error", title: ""})
    const history = useHistory()

    const {
        isLoading: storeBillingConfigurationIsLoading,
        isError: storeBillingConfigurationIsError,
        data: storeBillingConfiguration
    } = useQuery(['store-billing-configuration', store_id, billing_configuration_id], ()=>fetchStoreBillingConfigurations({
        store_id: store_id,
        id: billing_configuration_id
    }), {
        keepPreviousData: true,
        refetchOnWindowFocus: false
    })

    const {
        isLoading: storeIsLoading,
        isError: storeIsError,
        data: store
    } = useQuery(['store', store_id], ()=>fetchStore(store_id), {
        keepPreviousData: true,
        refetchOnWindowFocus: false
    })

    const {
        isLoading: warehouseIsLoading,
        isError: warehouseIsError,
        data: warehouse
    } = useQuery(['warehouse', warehouse_id], ()=>fetchWarehouse(warehouse_id), {
        keepPreviousData: true,
        refetchOnWindowFocus: false
    })

    useEffect(() => { setIsDiscountValid(typeof isDiscount === "boolean") }, [isDiscount])
    useEffect(() => { setIsAmountValid(!isNaN(parseFloat(amount)) && amount !== 0) }, [amount])
    useEffect(() => { setIsTitleValid(typeof title === "string" && title.length > 0) }, [title])
    useEffect(() => { setIsDescriptionValid(typeof description === "string" && description.length > 0) }, [description])

    useEffect(() => {
        setAreValid( isDiscountValid && isAmountValid && isTitleValid && isDescriptionValid )
    } , [isDiscountValid, isAmountValid, isTitleValid, isDescriptionValid])

    useEffect(() => {
        if(storeBillingConfigurationIsError || storeIsError || warehouseIsError){
            setNotificationMessage({show: true, type: "error", title: i18n.t("billing.custom_services.create_custom_service.init_error")})
        }
    }, [storeBillingConfigurationIsError, storeIsError, warehouseIsError, i18n])

    const handleOnChangeAmount = (newAmount) => {
        const updatedAmount = isNaN(parseFloat(newAmount)) ? null : parseFloat(newAmount)
        setAmount(updatedAmount)
    }

    const handleOnChangeTitle = (event) => {
        const newTitle = event.target.value

        if(!newTitle || newTitle?.length <= maxTitleLength){
            setTitle(newTitle)
        }
    }

    const handleOnChangeDescription = (event) => {
        const newDescription = event.target.value

        if(!newDescription || newDescription?.length <= maxDescriptionLength){
            setDescription(newDescription)
        }
    }

    const handleOnCreateCustomService = async () => {
        setIsCreatingCustomService(true)

        let billed_total_price = null

        if((isDiscount && parseFloat(amount) > 0) || (!isDiscount && parseFloat(amount) < 0)){
            billed_total_price = parseFloat(amount) * (-1)
        } else{
            billed_total_price = parseFloat(amount)
        }

        const expectedBillingConfigurationId = parseInt(billing_configuration_id)
        const obtainedBillingConfigurationId = parseInt(storeBillingConfiguration?.store_billing_configurations[0].id)

        if(isNaN(expectedBillingConfigurationId) || isNaN(obtainedBillingConfigurationId) || expectedBillingConfigurationId !== obtainedBillingConfigurationId){
            throw new Error("Invalid billing configuration id")
        }

        const params = {
            store_billing_configuration_id: billing_configuration_id,
            billed_total_price: billed_total_price,
            warehouse_id: warehouse_id,
            title: title,
            description: description,
            is_discount: isDiscount
        }

        let isCreatedCustomService = false

        try{
            if(!areValid){
                throw new Error("Invalid content")
            }
            await createCustomServiceWithUsage(params)
            isCreatedCustomService = true
        } catch(error){
            setNotificationMessage({show: true, type: "error", title: i18n.t("billing.custom_services.create_custom_service.create_error")})
            isCreatedCustomService = false
        }

        setIsCreatingCustomService(false)
        setConfirmCreateDialog({show: false, title: "", description: ""})

        if(isCreatedCustomService){
            history.push(BILLING_STORE_CONFIGS.replace(':warehouse_id', warehouse_id).replace(':store_id', store_id))
        }
    }

    const handleOnCreate = () => {
        const amountType = isDiscount ? "DISCOUNT" : "CHARGE"

        setConfirmCreateDialog({
            show: true,
            title: i18n.t(`billing.custom_services.create_custom_service.confirm_create_dialog.title_${amountType}`),
            description: i18n.t(`billing.custom_services.create_custom_service.confirm_create_dialog.description_${amountType}`).replace("{STORE_NAME}", store.name).replace("{WAREHOUSE_NAME}",warehouse.name )
        })
    }

    const calculateRemainingTextLength = (currentText, maxLength) => {
        return !currentText ? maxLength : maxLength - currentText.length
    }

    return (
        <div className="flex flex-col gap-5 w-full">
            <div className="flex flex-col gap-2">
                <div>
                    <span className="font-bold">{i18n.t("billing.custom_services.create_custom_service.store_name")}</span>
                    <span>{store?.name}</span>
                </div>

                <div>
                    <span className="font-bold">{i18n.t("billing.custom_services.create_custom_service.warehouse_name")}</span>
                    <span>{warehouse?.name}</span>
                </div>
            </div>

            <div className="flex gap-2 items-center">
                <label htmlFor="is_discount" className="font-bold">
                    <span className="text-red-500">*</span>
                    {i18n.t("billing.custom_services.create_custom_service.is_discount")}
                </label>
                <input id="is_discount" type="checkbox" onChange={event => setIsDiscount(!isDiscount)} checked={isDiscount}></input>
            </div>

            <div className="flex flex-col gap-1 items-center">
                <label htmlFor="title" className="font-bold self-start">
                    <span className="text-red-500">*</span>
                    {i18n.t("billing.custom_services.create_custom_service.title")}
                </label>
                <div className="w-full">
                    <input
                        id="title"
                        type="text"
                        onChange={handleOnChangeTitle}
                        value={title || ""}
                        placeholder={i18n.t("billing.custom_services.create_custom_service.title_placeholder")}
                        className={`w-full ${!isTitleValid ? "border-red-500" : ""}`}>
                    </input>
                    <div className="text-xs mt-1">
                        {`${i18n.t("billing.custom_services.create_custom_service.remaining_text_length")} ${calculateRemainingTextLength(title, maxTitleLength)}`}
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-1">
                <label htmlFor="description" className="font-bold">
                    <span className="text-red-500">*</span>
                    {i18n.t("billing.custom_services.create_custom_service.description")}
                </label>
                <div className="w-full">
                    <textarea
                        id="description"
                        onChange={handleOnChangeDescription}
                        value={description || ""}
                        placeholder={i18n.t("billing.custom_services.create_custom_service.description_placeholder")}
                        className={`w-full m-0 ${!isDescriptionValid ? "border-red-500" : ""}`}
                    />
                    <div className="text-xs">
                        {`${i18n.t("billing.custom_services.create_custom_service.remaining_text_length")} ${calculateRemainingTextLength(description, maxDescriptionLength)}`}
                    </div>
                </div>
            </div>

            <div className="flex flex-col">
                <label className="font-bold">
                    <span className="text-red-500">*</span>
                    {isDiscount ? i18n.t("billing.custom_services.create_custom_service.amount_discount") : i18n.t("billing.custom_services.create_custom_service.amount_charge")}
                </label>

                <div>
                    <CurrencyInputField
                        value={amount === null ? "" : amount}
                        onChange={handleOnChangeAmount}
                        scale={8}
                        precision={16}
                        placeholder={isDiscount ? i18n.t("billing.custom_services.create_custom_service.amount_placeholder_discount") : i18n.t("billing.custom_services.create_custom_service.amount_placeholder_charge")}
                        customIcon={(
                            <div className="font-bold flex items-center gap-1">
                                {storeBillingConfiguration?.store_billing_configurations[0].currency || ""}
                                {!isNaN(parseFloat(amount)) && isDiscount && (
                                    <span className="text-lg font-normal">
                                        {"-"}
                                    </span>
                                )}
                            </div>)}
                        inputClassName={`pl-12 ml-1 ${!isAmountValid ? "border-red-500" : ""}`}
                    />

                    {amount === 0 && (
                        <div className="text-red-500 italic text-sm">
                            {i18n.t("billing.custom_services.create_custom_service.no_amount")}
                        </div>
                    )}
                </div>
            </div>

            <Button type="primary" onClick={handleOnCreate} className="w-fit" disabled={!areValid}>
                {i18n.t("billing.custom_services.create_custom_service.create_button")}
            </Button>

            <ConfirmDialog
                open={confirmCreateDialog.show}
                setOpen={showDialog => setConfirmCreateDialog({...confirmCreateDialog, show: showDialog})}
                title={confirmCreateDialog.title}
                description={confirmCreateDialog.description}
                confirmLabel={i18n.t("billing.custom_services.create_custom_service.confirm_create_dialog.confirm_button")}
                cancelLabel={i18n.t("billing.custom_services.create_custom_service.confirm_create_dialog.cancel_button")}
                onConfirm={handleOnCreateCustomService}
                onCancel={() => setConfirmCreateDialog({show: false, title: "", description: ""})}
                loading={isCreatingCustomService}
            />

            <Notification
                show={notificationMessage.show}
                title={notificationMessage.title}
                type={notificationMessage.type}
                setShow={showNotification => setNotificationMessage({...notificationMessage, show: showNotification})}
            />

            <Loader show={storeBillingConfigurationIsLoading || storeIsLoading || warehouseIsLoading || isCreatingCustomService}></Loader>
        </div>
    )
}

export default CreateCustomService