import { useEffect, useState, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { cloneDeep } from "lodash"
import { Tooltip } from 'antd'
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon } from "@heroicons/react/outline"

import BillingPriceConfigTable from "../BillingPriceConfigTable"
import { CurrencyInputField } from "../../CurrencyInputField"
import { SERVICE_TYPES, PRICE_MODES, STORAGE_BILLING_TYPES } from "../constants"
import { deleteBillingService, findOrCreateBillingService, getCurrency } from "../utils"
import RangeBillingPriceConfigTable from "../RangeBillingPriceConfigTable"
import { UNITS_IDS } from "../RangeBillingPriceConfigTable/constants"

const StorageService = ({ storeBillingServices, warehouseLocationVolumeCategories, onUpdateStoreBillingServices, onIsValid, storeId, warehouse }) => {
    const { i18n } = useTranslation()

    console.log("storeBillingServices")
    console.log(storeBillingServices)

    const tableHeaders = [
        { label: i18n.t("billing.table_headers.location_type"), id: "location_type" },
        { label: i18n.t("billing.table_headers.tariff"), id: "tariff" }
    ]

    const storageBillingTypes = [SERVICE_TYPES.SERVICE_STORAGE_BY_LOCATION, SERVICE_TYPES.SERVICE_STORAGE_BY_PRODUCT]

    const [storageBillingType, setStorageBillingType] = useState(storageBillingTypes[0])
    const [isStorageByUnitValid, setIsStorageByUnitValid] = useState(false)
    const [isStorageByLocationValid, setIsStorageByLocationValid] = useState(false)
    const [storageByUnitTableData, setStorageByUnitTableData] = useState(null)

    const [storageServiceLocation, setStorageServiceLocation] = useState(() => {
        return findOrCreateBillingService({
            serviceType: SERVICE_TYPES.SERVICE_STORAGE_BY_LOCATION,
            billingServices: storeBillingServices,
            currency: getCurrency(warehouse?.country),
            storeId: storeId,
            warehouseId: warehouse?.id
        })
    })

    const [storageServiceUnit, setStorageServiceUnit] = useState(() => {
        return findOrCreateBillingService({
            serviceType: SERVICE_TYPES.SERVICE_STORAGE_BY_PRODUCT,
            billingServices: storeBillingServices,
            currency: getCurrency(warehouse?.country),
            storeId: storeId,
            warehouseId: warehouse?.id
        })
    })

    const handleOnChangeStorageBillingType = (newBillingType) => {
        setStorageBillingType(newBillingType)
    }

    // LOCATION
    const calculateLocationTariff = (volumeCategory) => {
        const pickingStandardPrice = parseFloat(storageServiceLocation.price_configuration[0].price)
        let tariff = null

        if (!isNaN(pickingStandardPrice)) {
            const volumeCategoryRelativeVolume = parseFloat(volumeCategory.relative_volume)
            tariff = pickingStandardPrice * volumeCategoryRelativeVolume
        }

        return tariff
    }

    const handleOnChangeBasePrice = (newBasePrice) => {
        const newStorageService = cloneDeep(storageServiceLocation)

        newStorageService.price_configuration[0].price = isNaN(parseFloat(newBasePrice)) ? null : parseFloat(newBasePrice)
        newStorageService.price_configuration[0].price_mode = PRICE_MODES.PRICE_MODE_PER_UNIT

        setStorageServiceLocation(newStorageService)

        let newStoreBillingServices = cloneDeep(storeBillingServices)
        const serviceExists = newStoreBillingServices.some(({ service_type }) => service_type === newStorageService.service_type)

        if (serviceExists) {
            newStoreBillingServices = newStoreBillingServices.map(billingService => {
                if (billingService.service_type === newStorageService.service_type) {
                    billingService.price_configuration = cloneDeep(newStorageService.price_configuration)
                }
                return billingService
            })
        } else {
            let newService = cloneDeep(newStorageService)
            newStoreBillingServices.push(newService)
        }

        newStoreBillingServices = deleteBillingService({
            serviceType: SERVICE_TYPES.SERVICE_STORAGE_BY_PRODUCT,
            billingServices: newStoreBillingServices
        })

        onUpdateStoreBillingServices(newStoreBillingServices)
    }

    const pickingStandardCategory = useMemo(() => {
        return warehouseLocationVolumeCategories?.find(({ category_code }) => category_code === "PICKING_STANDARD")
    }, [warehouseLocationVolumeCategories])

    const priceModesStorage = [UNITS_IDS.PER_UNIT]
    
    useEffect(() => {
        if (storageBillingType === storageBillingTypes[0]) {
            const price = storageServiceLocation.price_configuration[0].price
            const newIsValid = !isNaN(parseFloat(price)) && pickingStandardCategory ? true : false
            setIsStorageByLocationValid(newIsValid)
        } else {
            const price = storageServiceUnit.price_configuration[0].price
            const newIsValid = !isNaN(parseFloat(price)) && pickingStandardCategory ? true : false
            setIsStorageByLocationValid(newIsValid)
        }

        const storageByUnitTableData = storageServiceUnit?.price_configuration.map(priceRange => {
            return {
                from: { value: priceRange.from, isDisabled: true },
                to: { value: priceRange.to, isDisabled: true },
                price: { value: priceRange.price },
                price_mode: { value: UNITS_IDS.PER_UNIT, isDisabled: true },
                excess: { value: priceRange.excess },
                range_exclusion: { value: priceRange.range_exclusion },
            }
        })

        setStorageByUnitTableData(storageByUnitTableData)

    }, [storageServiceLocation, storageServiceUnit, pickingStandardCategory, storageBillingType])

    useEffect(() => {
        if (storageBillingType === storageBillingTypes[0] && isStorageByLocationValid === true) {
            onIsValid(isStorageByLocationValid)
        } else if (storageBillingType === storageBillingTypes[1] && isStorageByUnitValid === true) {
            onIsValid(isStorageByUnitValid)
        } else {
            onIsValid(false)
        }
    }, [isStorageByUnitValid, isStorageByLocationValid, storageBillingType])

    const orderedWarehouseLocationVolumeCategories = useMemo(() => {
        if (!pickingStandardCategory) {
            return warehouseLocationVolumeCategories
        }
        const orderedCategories = [{ ...pickingStandardCategory }]

        warehouseLocationVolumeCategories.forEach(volumeCategory => {
            if (volumeCategory.category_code !== "PICKING_STANDARD") {
                orderedCategories.push({ ...volumeCategory })
            }
        })
        return orderedCategories
    }, [warehouseLocationVolumeCategories, pickingStandardCategory])

    if (!pickingStandardCategory) {
        return (
            <div className="text-2xl text-red-500">
                {i18n.t("billing.billing_storage_table.missing_picking_standard_error")}
            </div>
        )
    }

    //BY UNIT

    const tableHeadersStorageByUnit = [
        { label: i18n.t("billing.table_headers.from_piece"), id: 'from' },
        { label: i18n.t("billing.table_headers.to_piece"), id: 'to' },
        { label: i18n.t("billing.table_headers.price"), id: 'price' },
        { label: i18n.t("billing.table_headers.unit"), id: 'unit' }
    ]

    const unitOptionsLabels = {
        PER_UNIT: i18n.t("billing.billing_stroage_table_by_unit.unit_options.PER_UNIT")
    }


    const handleOnUpdateData = (serviceType, newTableData, service) => {
        let newStoreBillingServices = cloneDeep(storeBillingServices)

        const newPriceConfiguration = newTableData.map(priceRangeData => {
            return {
                from: priceRangeData.from.value,
                to: priceRangeData.to.value,
                price: priceRangeData.price.value,
                price_mode: priceRangeData.price_mode.value,
                excess: priceRangeData.excess.value,
                range_exclusion: priceRangeData.range_exclusion.value
            }
        })

        newStoreBillingServices = deleteBillingService({
            serviceType: SERVICE_TYPES.SERVICE_STORAGE_BY_LOCATION,
            billingServices: newStoreBillingServices
        })

        const serviceExists = newStoreBillingServices.some(({ service_type }) => service_type === serviceType)

        if (serviceExists) {
            newStoreBillingServices = newStoreBillingServices.map(billingService => {
                if (billingService.service_type === serviceType) {
                    billingService.price_configuration = newPriceConfiguration
                }
                setStorageServiceUnit(billingService)
                return billingService
            })
        } else {
            const newService = cloneDeep(service)
            newService.price_configuration = newPriceConfiguration
            newStoreBillingServices.push(newService)
            setStorageServiceUnit(newService)
        }

        onUpdateStoreBillingServices(newStoreBillingServices)
    }

    return (
        <>
            <div className="flex gap-7 mb-7">
                {Object.values(STORAGE_BILLING_TYPES).map(storageBillingTypeOption => (
                    <label key={`storage-billing-type-${storageBillingTypeOption}`}>
                        <input
                            type="radio"
                            value={storageBillingTypeOption}
                            name={"config-intervals"}
                            onChange={(e) => handleOnChangeStorageBillingType(e.target.value)}
                            checked={storageBillingTypeOption == storageBillingType}
                        />
                        {i18n.t(`billing.billing_storage_table.${storageBillingTypeOption}`)}
                    </label>
                ))}
            </div>
            {
                storageBillingType === SERVICE_TYPES.SERVICE_STORAGE_BY_LOCATION ?
                    (
                        <>
                            <div className="flex gap-1">
                                <h2 className="text-xl text-gray-500"><span className="text-red-500">*</span>{i18n.t("billing.billing_storage_table.STORAGE_BY_LOCATION")}</h2>

                                {isStorageByLocationValid ? (
                                    <CheckCircleIcon className="h-5 w-5 text-green-500" />
                                ) : (
                                    <ExclamationCircleIcon className="h-5 w-5 text-yellow-500" />
                                )}
                            </div>

                            <BillingPriceConfigTable
                                headers={tableHeaders}
                                rows={orderedWarehouseLocationVolumeCategories.map((volumeCategory, index) => (
                                    [
                                        {
                                            id: "location_type",
                                            cell: <div className="px-2">
                                                {i18n.t(`billing.billing_storage_table.category_codes.${volumeCategory.category_code}`)}
                                                <span className="text-gray-400 ml-2">
                                                    {`(${volumeCategory.relative_volume} ${i18n.t("billing.billing_storage_table.units")})`}
                                                </span>
                                            </div>,
                                            isDisabled: index > 0,
                                        },
                                        {
                                            id: "tariff",
                                            cell: index === 0 ? (
                                                <CurrencyInputField
                                                    value={storageServiceLocation.price_configuration[0].price === null ? "" : storageServiceLocation.price_configuration[0].price}
                                                    onChange={handleOnChangeBasePrice}
                                                    customIcon={<div className="font-bold">{getCurrency(warehouse?.country)}</div>}
                                                    inputClassName={"pl-12 border-none bg-transparent shadow-none focus:ring-0"}
                                                    scale={8}
                                                    precision={16}
                                                />
                                            ) : (
                                                <div className="flex gap-2 m-2">
                                                    <div className="font-bold">{getCurrency(warehouse?.country)}</div>
                                                    <div>{calculateLocationTariff(volumeCategory)}</div>
                                                    <Tooltip title={i18n.t("billing.billing_storage_table.calculated_tariff_tooltip")}>
                                                        <InformationCircleIcon className='ml-1 mb-1 w-4 h-4 inline' />
                                                    </Tooltip>
                                                </div>
                                            ),
                                            isDisabled: index > 0,
                                            isInvalid: index === 0 && isNaN(parseFloat(storageServiceLocation.price_configuration[0].price)),
                                            errorMessage: i18n.t("billing.billing_storage_table.tariff_error_message")
                                        }
                                    ]
                                ))}
                                className="w-full"
                                headerClassName="text-left text-gray-500 p-2 border-2 bg-white"
                                cellClassName=""
                            />
                        </>
                    ) : (
                        <div>
                            <div className="flex gap-1">
                                <h2 className="text-xl text-gray-500"><span className="text-red-500">*</span>{i18n.t("billing.billing_storage_table.STORAGE_BY_PRODUCT")}</h2>

                                {isStorageByUnitValid ? (
                                    <CheckCircleIcon className="h-5 w-5 text-green-500" />
                                ) : (
                                    <ExclamationCircleIcon className="h-5 w-5 text-yellow-500" />
                                )}
                            </div>

                            <RangeBillingPriceConfigTable
                                headers={tableHeadersStorageByUnit}
                                priceConfiguration={storageByUnitTableData}
                                currency={getCurrency(warehouse?.country)}
                                onUpdatePriceConfiguration={newTableData => handleOnUpdateData(storageBillingType, newTableData, storageServiceUnit)}
                                onIsValid={setIsStorageByUnitValid}
                                availableUnitOptions={priceModesStorage}
                                unitOptionsLabels={unitOptionsLabels}
                            />
                        </div>

                    )
            }

        </>

    )
}

export default StorageService