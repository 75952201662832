import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { Route, useHistory, useParams, Switch } from "react-router-dom"
import {
  ActionMenu,
  FormattedDate,
  NewTable,
  PageTopBar,
  PageView,
  SlidePanel,
} from "../../components"
import { Loader } from "../../components/Loader"
import { UserContext } from "../../hooks/UserContext"
import { BILLING, BILLING_STORE_CONFIGS, BILLING_STORE_CONFIG_CREATE, BILLING_STORE_CREATE_CUSTOM_SERVICE, STORE_BILLING_STATUS_PENDING, STORE_BILLING_STATUS_ACTIVE, STORE_BILLING_STATUS_DISABLED } from "../../navigation/constants"
import { ShowStoreBillingConfigsContainer } from "./ShowStoreBillingConfigsContainer"
import { useDispatch, useSelector } from "react-redux"
import { useQuery } from "react-query"
import { fetchStoresWithBillingConfigurations } from "../../services"
import { setBillingConfigStoresListPageSize } from "../../redux/pageSizeSlice"
import { useTranslation } from "react-i18next"
import { StatusPill } from "../../components/BasicTable/StatusPill"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import moment from "moment-timezone";
import CreateCustomService from "./CreateCustomService"

export const ListBillingStoresContainer = () => {
  const { user } = useContext(UserContext)
  const { t, i18n} = useTranslation()
  
  const history = useHistory()
  const {warehouse_id} = useParams()
  const tableRef = useRef()
  const dispatch = useDispatch()
  
  const pageSize = useSelector((state) => state.pageSize.storesList)

  const defaultSearchParams = {
    page: 1,
    per_page: pageSize,
    warehouse_id: isNaN(parseInt(warehouse_id)) ? null : parseInt(warehouse_id),
  }

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    isPreviousData,
    refetch,
  } = useQuery(['billing_stores', searchParams], () => fetchStoresWithBillingConfigurations(searchParams), { keepPreviousData: true })
  const [searchParams, setSearchParams] = useState({...defaultSearchParams})

  useEffect(() => {
    refetch()
  }, [searchParams])

  const handlerOnClickRow = (store) => {
    history.push(BILLING_STORE_CONFIGS.replace(':warehouse_id', warehouse_id).replace(':store_id', store.id))
  }

  const handleCreateBillingConfig = (event, store_id) => {
    event.stopPropagation()
    history.push(BILLING_STORE_CONFIG_CREATE.replace(':warehouse_id', warehouse_id).replace(':store_id', store_id))
  }

  const buildActions = function (store) {
    if([STORE_BILLING_STATUS_ACTIVE].includes(store.billing_status))
      return null 
    const actions = <div className="text-black hover:text-black">
        <button className="text-left whitespace-nowrap border border-gray-300 shadow-sm rounded-lg py-1 px-1 text-gray-700 font-medium hover:bg-gray-50 outline-none" onClick={(e) => handleCreateBillingConfig(e, store.id)}>
            <span className="text-xs">
                {t('billing.create')}
            </span>
        </button>
    </div>
    return actions
  }

  const getNextCutoffAt = (config_with_warehouse_ids) => {
    if(config_with_warehouse_ids){
      const warehouse_ids = config_with_warehouse_ids.warehouse_ids_with_services
      if(warehouse_ids.includes(parseInt(warehouse_id)))
        return <FormattedDate date={config_with_warehouse_ids.next_cutoff_at} shortDate/>
    }
    return t(`billing.stores.no_data`)
  }

  const isBillingActive = (store) => {
    const config_with_warehouse_ids = store.current_billing_configuration_with_warehouse_ids
    if(config_with_warehouse_ids){
      const warehouse_ids = config_with_warehouse_ids.warehouse_ids_with_services
      if(warehouse_ids.includes(parseInt(warehouse_id)))
        return true
    }
    return false
  }

  const getStatusCapsule = (store) => {
    let status = store.billing_status
    let color = status == STORE_BILLING_STATUS_PENDING ? "gray" :
                status == STORE_BILLING_STATUS_DISABLED ? "red" :
                status == STORE_BILLING_STATUS_ACTIVE ? "green" : "gray"
    return (
        <span className={`px-2 inline-flex text-xs leading-5 rounded-full bg-${color}-200 text-${color}-500`}>
            {t(`billing.status.${status}`)}
        </span>
    )
  }

  const getTableData = () => {
    return data?.stores.map((store)=>{  
      return {
          object: {...store},
          id: store.id,
          store_name: store.name + (store.account_type == "DEMO_ACCOUNT" ? " (Demo)" : ""),
          next_cutoff_at: <span className="text-xs leading-5">{getNextCutoffAt(store.current_billing_configuration_with_warehouse_ids)}</span>,
          status: getStatusCapsule(store),
          actions: buildActions(store)
          
      }
    }) || []
  }

  const callFilterShortcut = (filters) => {
    const newSearchParams = {...searchParams}
    filters.forEach(filter => {
      let currentFilter = filtersData.find(item => item.key == filter.key)
      if (filter.key == "next_cutoff_at") {
        currentFilter.data.value = {from: moment(filter.value.from), to: moment(filter.value.to)}
      }        
      else currentFilter.data.value = filter.value
      currentFilter.data.visible = true
      let filterKey = currentFilter.key_alias ? currentFilter.key_alias : currentFilter.key
      newSearchParams[filterKey] = filter.value
    })
    setSearchParams(newSearchParams)
}

  const filtersInitialData = [
    {
      key: "store_name",
      label: "generic.store_name",
      data: {
            visible: true,
            type: 'text',
            value: ''
      },
    },
    {
      key: "not_having_current_billing_configuration",
      label: "generic.not_having_current_billing_configuration",
      data: {
          visible: false,
          type: 'boolean',
          onClickValue: false
      },
    },
    {
      key: "having_current_billing_configuration",
      label: "generic.having_current_billing_configuration",
      data: {
          visible: false,
          type: 'boolean',
          onClickValue: true
      },
    },
    {
      key: "next_cutoff_at",
      label: "billing.stores.next_cutoff_at",
      data: {
          visible: false,
          type: 'date',
          value: null
      },
    },
  ]

  const [filtersData, setFiltersData] = useState(filtersInitialData)

  const updateFiltersData = (updatedData, filter) => {
    // Update filters with new data
    setFiltersData((prev) => {
      return prev.map((item) => {
          if (item.key == filter) 
              return {
                  ...item,
                  data: updatedData
              }
          else return item
      }).sort((a, b) => a.data.visible > b.data.visible ? -1 : 1) // Sort to preserve the order of the filters
    })

    // Set search params only if needed
    const filterItem = filtersData.find(item => item.key == filter)
    if ((Array.isArray(filterItem.data.value) && filterItem.data.value.length === 0 && updatedData.value.length == 0)) return
    if (!['boolean','text'].includes(filterItem.data.type) && filterItem.data.value === updatedData.value) return
    let value = updatedData.value
    const filter_name = filterItem.key_alias || filterItem.key

    switch (filter_name) {
        case "not_having_current_billing_configuration":
            if (!updatedData.visible) setSearchParams((prev) => ({...prev, ["having_current_billing_configuration"]: undefined, page: 1}))
            else setSearchParams((prev) => ({...prev, ["having_current_billing_configuration"]: value, page: 1}))
            break
        case "next_cutoff_at":
            if (value?.from && value?.to) {
                setSearchParams((prev) => ({...prev, 
                    [filter_name]: {
                        from: value.from.toDate().toISOString(),
                        to: value.to.toDate().toISOString()
                    }, page: 1
                }))
            }
            else {
                setSearchParams((prev) => ({...prev, [filter_name]: {}, page: 1}))
            }
            break
        default:
            if (!updatedData.visible) value = {}
            setSearchParams((prev) => ({...prev, [filter_name]: value, page: 1}))
            break
    }
  }

  const handleResetFilters = () => {
    setSearchParams(defaultSearchParams)
  }

  const table_columns = [
    {
        Header: t(`generic.store`),
        accessor: 'store_name',
        disableFilters: true,
        disableSortBy: true
    },
    {
        Header: t(`generic.status`),
        accessor: 'status',
        disableFilters: true,
        disableSortBy: true
    },
    {
        Header: t(`billing.stores.end_date`),
        accessor: 'next_cutoff_at',
        disableFilters: true,
        disableSortBy: true
    },
    {
        Header: "",
        accessor: 'actions',
        disableFilters: true,
        disableSortBy: true,
        fetchingIndicator : true,
        shrinkToContent: true
    },
  ]

  return (
    <>
      <PageView
        topMenu={
          <PageTopBar>
            <div className="text-lg font-semibold">Billing</div>
          </PageTopBar>
        }
        childrenFullWidth={true}
        topMenuFullWidth={true}
      >
        {(!isPreviousData && isLoading) ? (
          <>
            <Loader show={true}></Loader>
          </>
        ) : isError ? (
          <>Error: {error.message}</>
        ) :
          <>
            <div className="flex flex-col">
              <nav className='flex relative rounded-lg border bg-white py-2 mb-2 text-gray-700'>
                  <div className="flex flex-col lg:flex-row justify-start border-l-2 lg:border-0 ml-5 lg:ml-0">
                      <div className="lg:max-w-min ml-5 my-1 lg:my-2 text-base leading-4 py-2 pl-0">
                          {i18n.t("orders.list.requiring_monitoring")}
                      </div>
                      
                      <div className="max-w-min flex flex-col static ml-5 my-1 lg:my-2">
                          <button className="text-left whitespace-nowrap border border-gray-300 shadow-sm rounded-lg py-3 px-3 text-gray-700 font-medium hover:bg-gray-50 outline-none"
                              onClick={() => {
                                callFilterShortcut([{key: "not_having_current_billing_configuration", value: false}])
                              }}>
                                  <StatusPill status="pending" statuses={[{
                                      status: "pending",
                                      label: i18n.t("generic.not_having_current_billing_configuration"),
                                      classes: "bg-gray-50 text-gray-500 border-gray-50"
                                  }]}/>
                          </button>
                      </div>
                      <div className="max-w-min flex flex-col static ml-5 my-1 lg:my-2">
                          <button className="text-left whitespace-nowrap border border-gray-300 shadow-sm rounded-lg py-3 px-3 text-gray-700 font-medium hover:bg-gray-50 outline-none"
                              onClick={() => {
                                const today = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0}).toDate()
                                const nextWeek = moment().set({hour: 23, minute: 59, second: 59, millisecond: 999}).add(1, 'weeks').toDate()
                                callFilterShortcut([{key: "next_cutoff_at", value: {from: today, to: nextWeek}}])
                              }}>
                                  <StatusPill status="due_next_week" statuses={[{
                                      status: "due_next_week",
                                      label: i18n.t("billing.stores.due_next_week"),
                                      classes: "bg-gray-50 text-gray-500 border-gray-50"
                                  }]}/>
                          </button>
                      </div>
                  </div>
              </nav>
          </div>
          <NewTable
            showHeader
            showLoader={isFetching && isPreviousData}
            columns={table_columns}
            showPaginator
            data = {getTableData()}
            labelToPaginator={t(`generic.stores`)}
            paginationMeta={data?.meta || {}}
            onPaginationChange= { (page) => { 
                setSearchParams((prev) => ({...prev, page: page})) 
            }}
            onPageSizeChange = { (page) => {
                setSearchParams((prev) => ({...prev, per_page: page.id, page: 1}))
                dispatch(setBillingConfigStoresListPageSize(page.id))
            }}
            rowProps={row => ({
                onClick: () => handlerOnClickRow(row.original.object)
            })}
            showDataStatus
            isFetching={isFetching}
            emptyTableText={t(`billing.stores.empty`)}
            ref={tableRef}
            selectable={false}
            onSearchHandler={updateFiltersData}
            handleResetFilters={handleResetFilters}
            filtersData={filtersData}
          />
          </>
        }

      </PageView>
      <Switch>
        <Route path={BILLING_STORE_CONFIGS} exact>
          <SlidePanel title={t(`billing.stores.about`)} referrer={BILLING.replace(':warehouse_id', warehouse_id)} onClose={() => {refetch()}}>
            <ShowStoreBillingConfigsContainer/>
          </SlidePanel>
        </Route>

        <Route path={BILLING_STORE_CREATE_CUSTOM_SERVICE} exact>
          <SlidePanel title={t(`billing.custom_services.create_current_title`)} referrer={BILLING.replace(':warehouse_id', warehouse_id)}>
            <CreateCustomService/>
          </SlidePanel>
        </Route>

      </Switch>
    </>
  )
}
