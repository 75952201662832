import { useState } from "react"
import { useTranslation } from "react-i18next"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline"

import { LINE_TYPE_END, LINE_TYPE_MIDDLE, LINE_TYPE_START, LINE_TYPE_NONE, TimelineItem } from "./Timeline/TimelineItem"
import { FormattedDate } from "./FormattedDate"

export const CubboLastmileEvents = ({cubboLastmileEvents}) => {
    const { i18n } = useTranslation()
    const [isCollapsed, setIsCollapsed] = useState(true)
    const collapsedElementsToShow = 3

    const filteredCubboLastmileEvents = cubboLastmileEvents?.filter((event, index) => !isCollapsed ? true : index < collapsedElementsToShow)

    return (
        <div>
            {(!filteredCubboLastmileEvents || filteredCubboLastmileEvents.length === 0) && i18n.t("cubbo_lastmile_events.no_events")}

            {filteredCubboLastmileEvents?.length > 0 && filteredCubboLastmileEvents.map((event, eventIndex, events) => {
                const isFirstEvent = eventIndex === 0 ? true : false
                const isLastEvent = eventIndex === (events.length - 1) ? true : false

                return (
                    <TimelineItem
                        lineType={events.length === 1 ? LINE_TYPE_NONE : isFirstEvent ? LINE_TYPE_START : isLastEvent ? LINE_TYPE_END : LINE_TYPE_MIDDLE}
                    >
                        <div className="font-semibold">
                            {`${i18n.t(`cubbo_lastmile_events.event_types.${event.event_type}`)} `}

                            <span className="font-normal text-gray-500">
                                <FormattedDate date={event.created_at} shortDayMonthYearDateTime={true} />
                            </span>
                        </div>

                        <div className="text-gray-500 mb-2">
                            {!!event.cubbo_lastmile_xdock && !!event.created_by ?
                                i18n.t("cubbo_lastmile_events.created_email_xdock", {user_email: event.created_by.email, xdock_name: event.cubbo_lastmile_xdock.name}) :
                                !!event.created_by ?
                                i18n.t("cubbo_lastmile_events.created_email", {user_email: event.created_by.email})
                                : <></>
                            }

                            {event.event_errors?.length > 0 && (
                                <div className="text-red-500">
                                    {i18n.t("cubbo_lastmile_events.errors", {
                                        error_codes: event.event_errors.map(errorCode => i18n.t(`cubbo_lastmile_events.error_codes.${errorCode}`)).join(", ")
                                    })}
                                </div>
                            )}
                        </div>

                    </TimelineItem>
                )
            })}

            {cubboLastmileEvents?.length > collapsedElementsToShow && (
                <button className="cursor-pointer text-blue-400 hover:text-blue-300 ml-12 flex gap-2" onClick={() => setIsCollapsed(!isCollapsed)}>
                    {isCollapsed ? (
                        <>
                            {i18n.t("cubbo_lastmile_events.is_collapsed")}
                            <ChevronDownIcon className="w-6"/>
                        </>
                    ): (
                        <>
                            {i18n.t("cubbo_lastmile_events.is_not_collapsed")}
                            <ChevronUpIcon className="w-6"/>
                        </>
                    )}
                </button>
            )}
        </div>
    )
}
