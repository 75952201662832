import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { InformationCard } from "../../../../components";

const MissingInfoNotificationSection = ({isMissingInfo}) => {
    return (
        <InformationCard
            title={"Falta información"}
            description={"La orden presenta falta de información"}
            iconComponent={<ExclamationCircleIcon className="h-full" />}
            containerStyles={"bg-red-100 text-red-600"}
            isHidden={!isMissingInfo}
        />
    )
}

export default MissingInfoNotificationSection