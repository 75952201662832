import { setUserPreferences } from "../services/userService"

export const getCurrentStore = async (user) => {
    let current_store = null
    if (!user.permissions?.read_all_stores) {
        if (!user.default_store) {
            current_store = user.accounts[0]?.stores ? user.accounts[0].stores[0] : null
            if (current_store) {
                await setUserPreferences(user.id, {default_store_id: current_store.id})
            }
        }
        else {
            current_store = user.default_store
        }
    }
    return current_store
}