import { useTranslation } from "react-i18next";
import { ClipIcon } from "./Icons/ClipIcon";
import LogRocket from "logrocket";
import { useRef, useEffect, useState } from "react";

export const ProofOfDelivery = ({order, proofs = [], className = "", innerClassName = ""}) => {
    const { i18n} = useTranslation()
    const componentRef = useRef(null)

    const getFullUrl = (relativePath) => `${process.env.REACT_APP_PLATFORM_URL}${relativePath}`

    const getRecipient = () => {
        const proof_with_recipient = proofs.find(proof => proof?.recipient_name)
        const { recipient_name, recipient_relationship } = proof_with_recipient || {}
        if (!recipient_name) return null;
        const relationship = recipient_relationship ? `, ${recipient_relationship}.` : ''
        return (
            <div className="mb-3">
            {`${i18n.t("orders.pod.received_by")} `}
            <span className="font-normal text-gray-400">{`${recipient_name}${relationship}`}</span>
            </div>
        )
    }

    const handleFileClick = (index) => {
        console.log("POD file clicked")
        window.analytics.track('Orders - Clicks Proof of Delivery', {
            id: order.id,
            pod_index: index
        })
    }

      
    return (
        <div className={className} ref={componentRef}>
            {!proofs.some(proof => proof?.files) && !proofs.some(proof => proof?.recipient_name)
            ? `${i18n.t("orders.pod.unavailable")} `
            : (
                <div className={`font-medium text-gray-600 ${innerClassName}`}>
                    {getRecipient()}
                    <div className="flex space-x-4 mb-3">
                        {proofs.map((proof, pindex) => {
                            return proof?.files?.map((item, findex) => (
                                <a href={getFullUrl(item.path)} target="_blank" rel="noreferrer" key={pindex+findex}
                                onClick={() => handleFileClick(pindex + findex)}>
                                    {item.thumbnail_path 
                                    ? <img src={getFullUrl(item.thumbnail_path)} className="h-24 w-24 rounded-lg bg-gray-100" alt={i18n.t("orders.pod.proof")} />
                                    : <div className="w-24 h-24 flex flex-col text-gray-600 justify-center items-center rounded-lg bg-gray-100 text-sm">
                                        <ClipIcon className="w-6 h-6" />
                                        {i18n.t("orders.pod.proof")}
                                    </div>
                                
                                    }
                                </a>
                            ))  
                        })}
                    </div>
                </div>
            )
            }
        </div>
    )
}
