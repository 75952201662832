import React from "react"

export const BadgeText = ({
  children,
  backgroundColor = null,
  dark = true,
}) => {
  return (
    <span
      className={"rounded py-0.5 px-1 ml-1"}
      style={{
        backgroundColor: backgroundColor
          ? backgroundColor
          : dark
          ? "#ffffff4d"
          : "#0000004d",
        fontSize: '80%'
      }}
    >
      {children}
    </span>
  )
}
