import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    shipment: {
        orderId: "",
        status: "",
        store:"",
        order_nomber:"",
        tote:"",
        productsData: []
    },
    shippingDetails:{
        shippingMethod: {},
        shippingDate: "",
        description: "",
        package_id: "",
        packages:[{
            code:"xs",
            description:"Caja Cliente",
            type:"Envelope",
            length:10,
            width:10,
            heigth:10,
            qty:1000,
            weight:0.5
        }],
        packages_count: 1
    },
    shippingAddress:{
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        address: "",
        address1: "",
        address2: "",
        country: { value: "MX", label: 'Mexico' },
        city: "",
        province: "",
        zipCode: ""
    },
    items :[]
}

export const createShipmentSlice = createSlice({
    name: 'createShipment',
    initialState,
    reducers: {
        setShipmentData: (state, action) => {
            state.shipment = action.payload
        },
        setShippingDetails: (state, action) => {
            console.log("setShippingDetails", action.payload)
            state.shippingDetails = action.payload
        },
        setShippingAddress: (state, action) => {
            state.shippingAddress = action.payload
        },
        addItems: (state, action) => {
            if(Array.isArray(action.payload)){
                action.payload.forEach(item => {
                    state.items.push(item)
                })
            }
            else {
                state.items.push(action.payload)
            }
        },
        setData: (state, action) => {
            state.shippingAddress = action.payload.shippingAddress
            state.shippingDetails = action.payload.shippingDetails
            state.shipment = action.payload.shipment
            state.items = action.payload.items
        },
    }
})

export const {
    setShipmentData,
    setCustomInfo,
    setShippingDetails,
    setShippingAddress,
    setData,
    addItems
} = createShipmentSlice.actions

export const shipmentData = (state) => state.createShipment.shipment
export const customInfoData = (state) => state.createShipment.customInfo
export const orderShippingAddress = (state) => state.createShipment.shippingAddress
export const ordershippingDetails = (state) => state.createShipment.shippingDetails
export const customItems = (state) => state.createShipment.items

export default createShipmentSlice.reducer
