import {
    ActionMenu,
    BasicTable,
    FilterMenu,
    Notification,
    PageView,
    SlidePanel,
    FormattedDate
} from "../../components";
import {useQuery} from "react-query";
import {useContext, useRef, useState} from "react";
import { Loader } from "../../components/Loader";
import {UserContext} from "../../hooks/UserContext";
import { fetchWarehouses } from "../../services/warehouseSevices";
import { ORDERS, GENERATE_LABELS, ROOT, ORDERS_SHIPPED, LOCATIONS, BILLING } from "../../navigation/constants";
import {Route, Switch, useHistory} from "react-router-dom";
import { setWarehousesListPageSize } from "../../redux/pageSizeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
const columns = [
    {
        accessor: 'name',
        Header: 'Almacén',
        disableFilters: false,
        disableSortBy: false,
        searchAs: 'name'
    },
    {
        accessor: 'address',
        Header: 'Dirección',
        disableFilters: true,
        disableSortBy: true,
        shrinkToContent: true
    },
    {
        accessor: 'state',
        Header: 'Estado',
        disableFilters: true,
        disableSortBy: true,
        searchAs: 'account_name'
    },
    {
        accessor: 'country',
        Header: 'País',
        disableFilters: false,
        disableSortBy: true,
        searchAs: 'account_name'
    },
    {
        accessor: 'created_at',
        Header: 'Creada',
        disableFilters: true,
        disableSortBy: false
    },
    {
        accessor: 'actions',
        Header: '',
        fetchingIndicator : true,
        disableFilters: true,
        disableSortBy: true,
        shrinkToContent: true
    },
]


export const ListWarehousesContainer = () => {

    const history = useHistory()
    const {user, setUser} = useContext(UserContext)
    const { t, i18n } = useTranslation();
    const [showDefaultWarehouseSetNotif, setShowDefaultWarehouseSetNotif] = useState(false)

    if(user && !user.read_all_warehouses && user.all_warehouses_ids?.length<2) {
        history.replace('/')
    }
    
    const dispatch = useDispatch()
    const pageSize = useSelector((state) => state.pageSize.warehousesList)

    // omit warehouse_ids param if user can real all warehouses
    const [searchParams, setSearchParams] = useState({
        page: 1,
        per_page: pageSize,
        count_products: true,
        ...!user?.read_all_warehouses && {warehouse_ids: user?.all_warehouses_ids}
    })

    const tableRef = useRef()

    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData
    } = useQuery(['warehouses', searchParams], () => fetchWarehouses(searchParams), { keepPreviousData: true }) 

    const onSortChange = (orderBy) => {
        // console.log("ON_ORDER_BY",orderBy)
        if (orderBy.length > 0) {
            setSearchParams({
                ...searchParams,
                order_by: orderBy[0].id, 
                order: orderBy[0].desc ? 'desc' : 'asc'
            })
        }
        else {
            if ('order_by' in searchParams) {
                delete searchParams.order_by
                delete searchParams.order
                setSearchParams({...searchParams})
            }
        }
    }

    const onFilterChange = (activeFilters) => {
        columns.forEach(col => {
            if (searchParams[col.searchAs]) {
                delete searchParams[col.searchAs]
            }
        })
        let filters = []
        activeFilters.forEach(filter => {
            let column = columns.find((col)=>col.accessor === filter.id)
            filters[column.searchAs] = filter.value
        })
        
        tableRef.current.resetPagination()
        setSearchParams({...searchParams, ...filters, page:1})
        // console.log("searchParams",searchParams)
    }

    

    const getMenuOptions = (warehouse) => {
        let options = [
            {
                title: "Ir al Almacen",
                clickHandler: () => {
                    onOpenWarehouseClick({...warehouse})
                }
            },
            {
                title: "Ir Dashboard",
                clickHandler: () => {
                    onOpenDashboardClick({...warehouse})
                }
            },
            {
                title: "Ver detalle",
                clickHandler: () => {
                    onDetailClick(warehouse.id)
                }
            },
        ]
        return options
    }
    const changeLanguage = (country) => {
        if(country === "BR") {
            i18n.changeLanguage("pt")
        }
        else{
            i18n.changeLanguage("es")
        }
    }
    const onOpenWarehouse = (warehouse) => {
        onOpenWarehouseClick({...warehouse})
        changeLanguage(warehouse.country)
    }
    const getTableData = () => {
        return data.warehouses.map(warehouse => {
            return {
                ...warehouse,
                name: <div className="hover:underline cursor-pointer" onClick={() => {onOpenWarehouse(warehouse)}}>{String(warehouse.name)+(user?.current_warehouse?.id === warehouse.id ? '*' : '')}</div>,
                address: warehouse.address1,
                state: warehouse.state,
                country: warehouse.country,
                created_at: (
                    <FormattedDate date={warehouse.created_at} shortDate />
                ),
                // created_at: new Date(warehouse.created_at),
                actions: (
                    <ActionMenu className="float-right" items={getMenuOptions(warehouse)}/>
                )
            }
        })
    }

    const onDetailClick = (warehouseId) => {
        //history.push(STORE_DETAIL.replace(':id', warehouseId))
    }

    const onOpenWarehouseClick = (warehouse) => {
        setUser({...user, current_warehouse:warehouse})
        if (user.roles.find((rol) => rol === "ROLE_LAST_MILE_SUPERVISOR") || user.roles.find((rol) => rol === "ROLE_REVERSE_LOGISTICS_OPERATOR")) {
            history.push(ORDERS_SHIPPED.replace(':warehouse_id', warehouse.id))
        }else if (user.roles.find((rol) => rol === "ROLE_INVENTORY_SUPERVISOR")) {
            history.push(LOCATIONS.replace(':warehouse_id', warehouse.id))
        }else if (user.roles.find((rol) => rol === "ROLE_BILLING_CONFIGURATION_EDITOR")) {
            history.push(BILLING.replace(':warehouse_id', warehouse.id))
        }else if (user.roles.length == 1 && user.roles.find((rol) => rol === "ROLE_OPERATOR")) {
            history.push(GENERATE_LABELS.replace(':warehouse_id', warehouse.id))
        }else{
            history.push(ORDERS.replace(':warehouse_id', warehouse.id))
        }
    }
    const onOpenDashboardClick = (warehouse) => {
        setUser({...user, current_warehouse:warehouse})
        history.push(ROOT.replace(':warehouse_id', warehouse.id))
    }

    return (
        <>
            <Notification show={showDefaultWarehouseSetNotif} setShow={setShowDefaultWarehouseSetNotif} type="success" title="Guardado" description="Se ha guardado tu preferencia de tienda predeterminada."/>
            <PageView
                topMenu={null}
            >
                {!isPreviousData && isLoading ? (
                    <><Loader show={true}></Loader></>
                ) : isError ? ( 
                    <>Error: {error.message}</>
                ) : (
                    <BasicTable
                        onFilterChange={onFilterChange}
                        onSortChange={onSortChange}
                        columns={columns}
                        showPaginator
                        showHeader
                        filterable
                        sortable
                        paginationMeta={data?.meta}
                        showCount = {true}
                        labelToPaginator="Almacenes"
                        pagesSize = {searchParams.per_page}
                        onPaginationChange= { (requestedPage) => { setSearchParams({...searchParams, page: requestedPage}) } }
                        onPageSizeChange= { (pageSize) => {
                            setSearchParams({...searchParams, per_page: pageSize, page: 1})
                            dispatch(setWarehousesListPageSize(pageSize))
                            tableRef.current.resetPagination()
                        }}
                        showLoader={isFetching && isPreviousData}
                        isFetching = {isFetching}
                        data = {getTableData()}

                        emptyTableText="No hay productos que mostrar."
                        ref = {tableRef}
                    />
                )}
            </PageView>
        </>
    )
}