import i18n from "../../translations/index"

export const CLAIM_AREAS = [
  {
    key: "OPERATIONS",
    label: "CUBBO / Operaciones",
    categories: [
     {
       key: "UNWANTED_PRODUCTS",
       description:i18n.t("claims.operations.unwanted_products.description"),
       recomendation:i18n.t("claims.operations.unwanted_products.recomendation"),
       reimbursable: [
        "cost_return_shipping"
       ]
     },
     {
      key: "MISSING_PRODUCTS",
      description: i18n.t("claims.operations.missing_products.description"),
      recomendation:i18n.t("claims.operations.missing_products.recomendation"),
      reimbursable: [
        "cost_cubbo",
        "cost_shipping"
       ]
    },
    {
      key: "WRONG_ORDER",
      description: i18n.t("claims.operations.wrong_order.description"),
      recomendation:i18n.t("claims.operations.wrong_order.recomendation"),
      reimbursable: [
        "cost_cubbo",
        "cost_shipping",
        "cost_return_shipping",
        "product_value"
       ]
    },
    {
      key: "DAMAGED_PRODUCT",
     description: i18n.t("claims.delivery.damaged_product.description"),
      recomendation:i18n.t("claims.delivery.damaged_product.recomendation"),
       reimbursable: [
        "cost_cubbo",
        "cost_shipping",
        "product_value"
      ]
    },
    /*
    ,{
      key: "OPERATIONS_OTHERS",
      description: i18n.t("claims.operations.operations_others.description"),
      //recomendation:i18n.t("claims.operations.other.recomendation"),
      reimbursable: [ 
        "cost_cubbo",
        "cost_shipping",
        "cost_shipping_replace",
        "product_value"
       ]
    }
    */
    ],
  },
  {
    key: "DELIVERY",
    label: "PAQUETERIA",
    categories: [
      {
        key: "PACKAGE_LOST",
        description: i18n.t("claims.delivery.package_lost.description"),
        recomendation:i18n.t("claims.delivery.package_lost.recomendation"),
        reimbursable: [
          "cost_cubbo",
          "cost_shipping",
          "product_value"
        ]
      },
      {
        key: "MISSING_PRODUCTS",
        description: i18n.t("claims.operations.missing_products.description"),
        recomendation:i18n.t("claims.operations.missing_products.recomendation"),
        reimbursable: [
          "cost_cubbo",
          "cost_shipping",
          "product_value"
         ]
      },
      {
        key: "DAMAGED_PRODUCT",
       description: i18n.t("claims.delivery.damaged_product.description"),
        recomendation:i18n.t("claims.delivery.damaged_product.recomendation"),
         reimbursable: [
          "cost_cubbo",
          "cost_shipping",
          "product_value"
        ]
      },
      {
        key: "DELIVERY_DELAY",
       description: i18n.t("claims.delivery.delivery_delay.description"),
        recomendation:i18n.t("claims.delivery.delivery_delay.recomendation"),
         reimbursable: [
          "cost_cubbo",
          "cost_shipping",
          "cost_return_shipping"
         ]
      },
      {
        key: "FALSE_DELIVERY",
       description: i18n.t("claims.delivery.false_delivery.description"),
        recomendation:i18n.t("claims.delivery.false_delivery.recomendation"),
         reimbursable: [
          "cost_cubbo",
          "cost_shipping",
          "product_value"
         ]
      },
      {
        key: "PACKAGE_STOLEN",
       description: i18n.t("claims.delivery.package_stolen.description"),
        recomendation:i18n.t("claims.delivery.package_stolen.recomendation"),
         reimbursable: []
      },
    ]
  },
  {
    key: "COMMERCIAL",
    label: "Customer Success",
    categories: [
      {
        key: "PACKAGE_LOST",
        description: i18n.t("claims.commercial.package_lost.description"),
        recomendation:i18n.t("claims.commercial.package_lost.recomendation"),
        reimbursable: [
          "cost_cubbo",
          "cost_shipping",
          "product_value"
        ]
      },
      {
        key: "MISSING_PRODUCTS",
        description: i18n.t("claims.commercial.missing_products_delivery.description"),
        recomendation:i18n.t("claims.commercial.missing_products_delivery.recomendation"),
        reimbursable: [
          "cost_cubbo",
          "cost_shipping",
          "product_value"
         ]
      },
      {
        key: "MISSING_PRODUCTS",
        description: i18n.t("claims.commercial.missing_products_operations.description"),
        recomendation:i18n.t("claims.commercial.missing_products_operations.recomendation"),
        reimbursable: [
          "cost_cubbo",
          "cost_shipping"
         ]
      },
      {
        key: "DAMAGED_PRODUCT",
       description: i18n.t("claims.commercial.damaged_product.description"),
        recomendation:i18n.t("claims.commercial.damaged_product.recomendation"),
         reimbursable: [
          "cost_cubbo",
          "cost_shipping",
          "product_value"
        ]
      },
      {
        key: "DELIVERY_DELAY",
       description: i18n.t("claims.commercial.delivery_delay.description"),
        recomendation:i18n.t("claims.commercial.delivery_delay.recomendation"),
         reimbursable: [
          "cost_cubbo",
          "cost_shipping",
          "cost_return_shipping"
         ]
      },
      {
        key: "FALSE_DELIVERY",
       description: i18n.t("claims.commercial.false_delivery.description"),
        recomendation:i18n.t("claims.commercial.false_delivery.recomendation"),
         reimbursable: [
          "cost_cubbo",
          "cost_shipping",
          "product_value"
         ]
      },
      {
        key: "PACKAGE_STOLEN",
       description: i18n.t("claims.commercial.package_stolen.description"),
        recomendation:i18n.t("claims.commercial.package_stolen.recomendation"),
         reimbursable: []
      },
      {
        key: "UNWANTED_PRODUCTS",
        description:i18n.t("claims.commercial.unwanted_products.description"),
        recomendation:i18n.t("claims.commercial.unwanted_products.recomendation"),
        reimbursable: [
         "cost_return_shipping"
        ]
      },
     {
       key: "WRONG_ORDER",
       description: i18n.t("claims.commercial.wrong_order.description"),
       recomendation:i18n.t("claims.commercial.wrong_order.recomendation"),
       reimbursable: [
         "cost_cubbo",
         "cost_shipping",
         "cost_return_shipping",
         "product_value"
        ]
     }
    ]
  },
 
]

export const findClaimAreaLabel = (key) => {
  let index = CLAIM_AREAS.findIndex(area => area.key === key)
  if (index === -1) {
    return "Área Desconocida"
  }
  return CLAIM_AREAS[index].label
}

export const findClaimAreaByCategory = (key) => {

  let category = ""
  CLAIM_AREAS.forEach(area => {
      let index = area.categories.findIndex(cat => cat.key === key)
      if (index != -1) {
        category = area.key
      }
    });
    return category

}

export const findClaimCategory = (key, category) => {
  let index = CLAIM_AREAS.findIndex(area => area?.key === key)
  let index_category = CLAIM_AREAS[index]?.categories?.findIndex(cat => cat.key === category)
  return CLAIM_AREAS[index]?.categories[index_category]?.description || CLAIM_AREAS[index]?.categories[0]?.description
}


export const findClaimCategoryReimbursables = (key, category) => {
  let index = CLAIM_AREAS.findIndex(area => area?.key === key)
  let index_category = CLAIM_AREAS[index]?.categories?.findIndex(cat => cat.key === category)
  return CLAIM_AREAS[index]?.categories[index_category]?.reimbursable || CLAIM_AREAS[index]?.categories[0]?.reimbursable
}

export const findClaimCategoryRecomendation = (key, category) => {
  let index = CLAIM_AREAS.findIndex(area => area?.key === key)
    let index_category = CLAIM_AREAS[index]?.categories?.findIndex(cat => cat?.key === category)
    let categoy = CLAIM_AREAS[index]?.categories[index_category]?.key || CLAIM_AREAS[index]?.categories[0]?.key
    let area = CLAIM_AREAS[index]?.key
    return "claims."+ area?.toLowerCase()+"."+categoy?.toLowerCase()+ ".recomendation" || ""
}

export const getAllCategories = () => {
  let categories = []
  let seenKeys = {}

  CLAIM_AREAS.forEach(area => {
    area.categories.forEach(category => {
      if (!seenKeys[category.key]) {
        categories.push(category)
        seenKeys[category.key] = true
      }
    })
  })

  return categories
}

export const getAreaCategories = (keyArea) => {
  let categories = []
  
  CLAIM_AREAS.forEach(area => {
    if (area.key === keyArea) categories = categories.concat(area.categories)
  })
  
  return categories
}