import { ShoppingBagIcon } from "@heroicons/react/solid"
import { getSkus } from "../../components"

export const OrderLineItem = ({ orderLine }) => {

  const showProductName = (orderLine) => {
    // console.log(orderLine)
    return orderLine.product?.name
  }

  const showisDropshipping = (orderLine) => {
    if (
      orderLine.product?.is_dropshipping !== null &&
      orderLine.product?.is_dropshipping === true
    ) {
      return (
        <span
          className={
            "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-red-100 text-red-800"
          }
        >
          Dropshipping - este producto no es enviado por Cubbo
        </span>
      )
    } else {
    }
    return ""
  }

  const showProductSkus = (orderLine) => {
    if (orderLine.has_missing_info == false || orderLine.has_missing_info == undefined) {
      return getSkus(orderLine.product?.product_codes)
    } else {
      return (
        <>
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
            {orderLine.missing_info}
          </span>
        </>
      )
    }
  }

  const showisKit = (orderLine) => {
    // console.log(product)
    if (orderLine.product?.is_kit !== null && orderLine.product?.is_kit === true) {
      return (
        <span
          className={
            "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-blue-100 text-blue-800"
          }
        >
          Kit
        </span>
      )
    } else {
    }
    return ""
  }

  return (
    <div className="flex items-center justify-between">
      <div className="w-0 flex-1 flex items-center">
        <ShoppingBagIcon
          className="flex-shrink-0 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        <span className="ml-2 flex-1 w-0">
          {showProductName(orderLine)}
          {showisKit(orderLine)}
          {orderLine.damaged && (
            <span
              className={
                "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-red-500 text-white"
              }
            >
              Dañado
            </span>
          )}
          <div className="mt-2">{showProductSkus(orderLine)}</div>

          <div className="mt-3">{showisDropshipping(orderLine)}</div>
        </span>
      </div>
      <div className="ml-4 flex-shrink-0">
        Cantidad <span className="font-medium">{orderLine.quantity}</span>
      </div>
    </div>
  )
}
