import { Timeline } from "antd"
import { useEffect, useState } from "react";
import { MinusCircleIcon } from "@heroicons/react/outline"
import { FormattedRelativeTime } from './FormattedRelativeTime';
import { FormattedDate } from './FormattedDate';

export function ShippingIncidents({shippingIncidents, t}){

    const [showDescriptions, setShowDescriptions] = useState([/* {id: int, isShow: boolean} */])

    useEffect(() => {
        const newShowDescriptions = shippingIncidents?.map(({id}) => ({
            id: id,
            isShow: false
        }))

        setShowDescriptions(newShowDescriptions)
    },[shippingIncidents])

    const toggleShowDescription = (arrayId) => {
        const newShowDescriptions = [...showDescriptions]

        newShowDescriptions[arrayId].isShow = !newShowDescriptions[arrayId].isShow

        setShowDescriptions(newShowDescriptions)
    }

    if(!showDescriptions?.length) {return <></>}

    const buildIncidentsDesription = (incident) => {

        if (incident.category === 'CHANGE_ADDRESS'){
            const changeAddressIncident = JSON.parse(incident?.description)
            return (
                <>
                    <div className="flex flex-row gap-3 font-medium text-black mb-2">
                        <div>{t("orders.incidents_container.new_address")}</div>
                    </div>
                        <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                            {t("orders.incidents_container.address")}</span>
                            {changeAddressIncident?.shipping_address_1 + " " + changeAddressIncident?.shipping_address_2}</p>
                    <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                        {t("orders.incidents_container.state")}</span>{(changeAddressIncident?.state || "")}</p>
                    <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                            {t("orders.incidents_container.city")}</span>{(changeAddressIncident?.city || "")}</p>
                    <p className="text-gray-600">
                        <span className="text-gray-800 font-medium">
                            {t("orders.incidents_container.zip_code")}</span>{(changeAddressIncident?.zip_code || "")}</p>
                </>
            )
        }else if (incident.category === 'RESCHEDULE'){
            const rescheduleIncident = JSON.parse(incident?.description)
            return (
                <>
                        <div className="flex flex-row gap-3 font-medium text-black mb-2">
                            <div>{t("orders.incidents_container.previous_delivery_date")}</div>
                        </div>
                        <p>
                            <FormattedRelativeTime
                                className="font-semibold"
                                forceDays={true}
                                date={rescheduleIncident?.estimated_time_arrival}
                                time_zone={'America/Mexico_City'}
                            />
                         </p>
                </>
            )
        } else {
            return <> {incident?.description} </>
        }
    }

    return (
        <div >
            {shippingIncidents?.map( (incident, index) => {

                const title = t(`orders.show_shipping_incidents.categories.${incident?.category}`)
                const status = t(`orders.show_shipping_incidents.status.${incident?.status}`)

                const resolution = t(`orders.shipping_incident.resolution.${incident?.resolution}`)
                const descriptionTitle = incident?.category == "MISSING_REFERENCES" ? t(`orders.show_shipping_incidents.description_title.${incident?.category}`) : t(`orders.show_shipping_incidents.description_title.OTHER`)

                const isResolved = !!incident?.resolved_at
                const hasDescription = !!incident?.description?.length
                const {isShow: isDescriptionShown} = showDescriptions?.find(({id}) => id === incident.id)

                return(
                    <div className={`rounded-md text-gray-700 px-5 py-3 font-normal text-xs bg-gray-50 ${index >= 1 ? "mt-3" : ""}`}>
                        <p className="text-gray-800 font-medium text-base m-0 pb-3">{title}</p>

                        <div>
                            <Timeline>
                                <Timeline.Item color='gray' >
                                    <p className="text-gray-600 font-normal text-base m-0">

                                        {t(`orders.show_shipping_incidents.created_by.${incident?.origin}`)}

                                        <span className="lowercase">
                                            <FormattedRelativeTime date={incident.created_at}/>
                                        </span>

                                        {t(`orders.show_shipping_incidents.from_date`)}

                                        <FormattedDate date={incident.created_at} onlyDate={true}/>

                                        {"."}
                                    </p>
                                </Timeline.Item>

                                <Timeline.Item color='gray'>
                                    <p className="text-gray-600 font-normal text-base m-0">

                                
                                {incident?.status === "RESOLVED" ?
                                    <label className="text-base font-medium text-green-600">
                                        {t(`${status}`)}:{resolution} 
                                    </label>
                                    :
                                    <label className={`text-base font-medium ${incident?.status === "PENDING" ? 'text-gray-600' :  'text-pink-600' }`}>
                                        {t(`${status}`)}
                                    </label>
                                }

                                        <span className="lowercase">
                                            {" "}
                                            <FormattedRelativeTime date={incident.created_at}/>
                                        </span>

                                        {`.`}
                                    </p>
                                </Timeline.Item>
                            </Timeline>
                        </div>

                        {hasDescription && (
                            <div className="text-sm">
                                <label className="text-blue-500"  onClick={() => {toggleShowDescription(index)}} > {isDescriptionShown ? t(`orders.show_shipping_incidents.toggle_description.hide`) : t(`orders.show_shipping_incidents.toggle_description.show`)}
                                <label className="cursor-pointer">{descriptionTitle} </label>  </label>
                                {isDescriptionShown && (
                                    <>
                                    <div className="text-gray-400">
                                        {buildIncidentsDesription(incident)}   
                                    </div>
                                    {incident?.file_paths && (
                                        <>
                                         {Object.keys(incident.file_paths).map(key => (
                                             <div className="items-top gap-2 p-2">
                                             <p className="pm-1">{t(`orders.show_shipping_incidents.evidence_type.${key}`) }</p>
                                                <div className="bg-gray-50 py-1 px-1 sm:grid sm:grid-cols-6 sm:gap-2">
                                                    {incident.file_paths[key].map((file) => (
                                                            <a
                                                                href={process.env.REACT_APP_PLATFORM_URL + file.path}
                                                                target="_blank"
                                                                key={String(file.path)}
                                                                rel="noopener noreferrer"
                                                            >
                                                                <img
                                                                    src={process.env.REACT_APP_PLATFORM_URL + file.path}
                                                                    href={process.env.REACT_APP_PLATFORM_URL + file.path}
                                                                    alt="Incident file"
                                                                    class="h-24 w-24 rounded-lg bg-gray-100"
                                                                />
                                                            </a>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}

                                        </>
                                        
                                    )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                )
            })}
        </div>
    )
}