import cubboLogo from "../../assets/logo-2.png"
import {useState} from "react";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {Notification} from "../../components";
import useAuth from "../../hooks/useAuth";

export const Login = () => {

    const {register, handleSubmit, formState: { errors }} = useForm({})

    const history = useHistory()

    const [showNotification, setShowNotification] = useState(false)

    const [loginUser, isLoading, isError] = useAuth()

    const onSubmit = (data) => {
        const {email, password} = data
        loginUser({email, password}).then((user)=>{
            history.push("/warehouses")
        }).catch((e)=>{
            setShowNotification(true)
        })
    }

    return (
        <div className="min-h-screen bg-secondary-orange-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src={cubboLogo}
          alt="Workflow"
        />
        
      </div>

      <div className="mt-16 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <h2 className="mt-3 mb-3 text-center text-2xl text-gray-900">Ingresa al WMS</h2>
        <p className="text-center text-gray-500 mb-12">Por favor ingresa los datos a continuación</p>
        <form action="#" method="POST" className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Usuario o email 
              </label>
              <div className="mt-1">
              <input
                    name="email"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    {...register("email", {
                        required: "Este campo es requerido",
                    })}
                />
                <p className="mt-2 text-sm text-red-600">
                    {errors.email && errors.email.message}
                </p>
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Contraseña
              </label>
              <div className="mt-1">
                <input
                    name="password"
                    type="password"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    {...register("password", {
                        required: "Este campo es requerido",
                    })}
                />
                <p className="mt-2 text-sm text-red-600">
                    {errors.password && errors.password.message}
                </p>
            </div>
            </div>

            <div className="flex items-center justify-between">

              {/* <div className="text-sm">
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Recupera tu contraseña
                </a>
              </div> */}
            </div>

            <div>
              {/* <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign in
              </button> */}
              {isLoading ? (
                                            'Ingresando...'
                                        ) : (
                                            <>
                                                {isError && showNotification? (
                                                    <>
                                                        <Notification show={showNotification} setShow={setShowNotification} type="error" title="Error" description="usuario/contraseña inválidos"/>
                                                    </>
                                                ) : null}
                                                <button
                                                    type="submit"
                                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    Ingresa
                                                </button>
                                            </>
                                        )}
            </div>
          </form>


        </div>
      </div>
    </div>
    )
}