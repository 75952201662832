import axiosInstance from "../helpers/axiosInstance";
import { validateTransactionStatus, TRANSACTION_STATUS_SUCCESS } from "../services/concurrencyService"

export const fetchReturn = async (returnId) => {
    if(returnId===undefined){
        return null
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/returns/${returnId}`)
    return res.data
}

export const fetchReturnID = async (orderId) => {
    if(orderId===undefined){
        return null
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/returns/${orderId}/return-by-id`)
    return res.data
}

export const fetchReturns = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/returns`, { params })
    return res.data
}

export const createReturn = async (data) => {
    try {
        const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/returns`, data )
        if (res.status === 202 && res.data.transaction_id) {
            const transactionStatus = await validateTransactionStatus(res.data.transaction_id)
            console.log("transactionStatus: ", transactionStatus)
            if (transactionStatus != TRANSACTION_STATUS_SUCCESS) {
                throw new Error('Error')
            }
        }
        return res.data
    } catch (error) {
        throw error
    }
}

export const receiveReturn = async (returnId, data) => {
    try {
        const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/returns/${returnId}/receive`, data )
        if (res.status === 202 && res.data.transaction_id) {
            const transactionStatus = await validateTransactionStatus(res.data.transaction_id)
            console.log("transactionStatus: ", transactionStatus)
            if (transactionStatus != TRANSACTION_STATUS_SUCCESS) {
                throw new Error('Error')
            }
        }
        return res.data
    } catch (error) {
        throw error
    }
}

export const fetchCountPendingReturns = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/returns/count-pending-returns`, { params })
    return res.data
}

export const receiveUnidentifiedReturn = async (data) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/returns/unidentified-return`, data )
    return res.data
}
export const fetchArrivedReturn = async (scanned_return_label) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/arrived-return-label/${scanned_return_label}`)
    return res.data
}