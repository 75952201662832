import { PACKAGE_OPTIONS, PACKING_OPTIONS_PRIORITIES, FRAGILE_OPTIONS } from './constants';

export const getAvailablePackingOptions = (storePackingOptions, isPackagelessOrder, isFragileOrder, excludedPackingOptions = [], returnUppercaseKeys=false, orderByPriority=true, ignoreFragileType=false) => {
    if (!storePackingOptions){
        return []
    }

    let availablePackageOptions = Object.entries(storePackingOptions).filter(([option, fragile]) => {
        // Is packageless: Only packageless options
        if(isPackagelessOrder){
            return option === PACKAGE_OPTIONS.PACKAGELESS
        }

        // Validate if the option must be excluded
        if(excludedPackingOptions.includes(option)){
            return false
        }

        if(ignoreFragileType){
            return true
        }

        // Is fragile: Only fragile options
        if(isFragileOrder){
            return [FRAGILE_OPTIONS.FRAGILE_ONLY, FRAGILE_OPTIONS.BOTH].includes(fragile)
        }

        // Not fragile: Only not fragile options
        return [FRAGILE_OPTIONS.NON_FRAGILE_ONLY, FRAGILE_OPTIONS.BOTH].includes(fragile)

    }).map(([option, fragile]) => option.toLowerCase())

    if(orderByPriority){
        availablePackageOptions = getPrioritizedPackingOptions(availablePackageOptions)
    }

    return !returnUppercaseKeys ? availablePackageOptions : availablePackageOptions?.map(option => option.toUpperCase())
}

export const getPrioritizedPackingOptions = (packingOptions) => {
    return packingOptions.sort((a,b) => {
        const aIndex = Object.keys(PACKING_OPTIONS_PRIORITIES).findIndex(optionPriority => PACKING_OPTIONS_PRIORITIES[optionPriority] === a)
        const bIndex = Object.keys(PACKING_OPTIONS_PRIORITIES).findIndex(optionPriority => PACKING_OPTIONS_PRIORITIES[optionPriority] === b)
        return aIndex - bIndex
    })
}
