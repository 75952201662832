const getSelectedElementsFromIds = (selectedRowIds, selectedFlatRows) => {
    let findId = (rowArray, key) => {
        let result = rowArray.find(elem => elem.id === key)
        if (!result) {
            for (let i = 0; i < rowArray.length; i++) {
                result = findId(rowArray[i].subRows, key)
                if (result) return result
            }
        } else {
            return result
        }
    }
    let selectedElements = []
    for (const key in selectedRowIds) {
        if (Object.hasOwnProperty.call(selectedRowIds, key)) {
            if (!selectedRowIds[key]) break
            let element = findId(selectedFlatRows, key)
            if (element) selectedElements.push(element)
            else {
                console.log("selected Element not found")
                console.log("selectedRowIds",selectedRowIds)
                return
            }
        }
    }
    return selectedElements;
}

const toggleAllPreviouslySelected = (index, pageSelectedElements, toggleRowSelected) => {
    if (!pageSelectedElements[index]) return
    let depthIndex = 0
    let toggledElements = 0
    while (pageSelectedElements[index].find((elem)=>elem.depth === depthIndex) || toggledElements !== pageSelectedElements[index].length) {
        pageSelectedElements[index].forEach(element => {
            if (element.depth !== depthIndex) return
            // console.log("element", element)
            toggleRowSelected(element.id, true)
            toggledElements += 1
            if (element.subRows.length > 0) {
                element.subRows.forEach(subelement => {
                    toggleRowSelected(subelement.id, false)
                });
            }
        })
        depthIndex += 1
    }
}

export {getSelectedElementsFromIds, toggleAllPreviouslySelected}