import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from "lodash"

import { CONFIG_CUTOFF_INTERVALS } from "./constants"

const StoreBillingConfig = ({
    storeBillingConfiguration,
    onUpdateConfig,
    onIsValid
}) => {
    const { i18n } = useTranslation()
    const [localConfig, setLocalConfig] = useState(storeBillingConfiguration)

    const handleOnChangeCutoffInterval = (newCutoffInterval) => {
        const localConfigCopy = cloneDeep(localConfig)
        localConfigCopy.cutoff_interval = newCutoffInterval
        setLocalConfig(localConfigCopy)

        const configCopy = cloneDeep(localConfigCopy)
        onUpdateConfig(configCopy)
    }

    useEffect(() => {
        const isValid = Object.values(CONFIG_CUTOFF_INTERVALS).some(cutoffInterval => cutoffInterval === localConfig.cutoff_interval)
        onIsValid(isValid)
    }, [localConfig])

    return (
        <div>
            <div>
                <h2 className="text-xl text-gray-500"><span className="text-red-500">*</span>{i18n.t("billing.store_billing_config.cutoff_interval.title")}</h2>

                <div className="flex gap-4">
                    {Object.values(CONFIG_CUTOFF_INTERVALS).map(cutoffInterval => (
                        <label key={`cutoff-interval-${cutoffInterval}`}>
                            <input
                                type="radio"
                                value={cutoffInterval}
                                name={"config-intervals"}
                                onChange={(e) => handleOnChangeCutoffInterval(e.target.value)}
                                checked={cutoffInterval === localConfig.cutoff_interval}
                            />
                            {i18n.t(`billing.store_billing_config.cutoff_interval.options.${cutoffInterval}`)}
                        </label>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default StoreBillingConfig