import CollapseToggle from "./CollapseToggle";

const CollapseHeader = ({isCollapsed, setIsCollapsed, onCollapsedText, onNotCollapsedText, headerComponent, showToggle=true, customStyles}) => {
    if(!headerComponent && !showToggle){
        return <></>
    }

    return (
        <div className={`flex ${showToggle ? "justify-between" : ""} ${customStyles ? customStyles : ""}`}>
            {headerComponent && <div className={`${showToggle ? "" : "w-full"}`}>{headerComponent}</div>}

            {showToggle && (
                <CollapseToggle
                        isCollapsed={isCollapsed}
                        setIsCollapsed={setIsCollapsed}
                        onCollapsedText={onCollapsedText}
                        onNotCollapsedText={onNotCollapsedText}
                        isUpIcon={!isCollapsed}
                        onClick={() => setIsCollapsed(!isCollapsed)}
                />
            )}
        </div>
    )
}

export default CollapseHeader
