export const getVideoDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices()
    const videoDevices = devices.filter(device => device.kind === 'videoinput')
    return videoDevices
}

export const getVideoMediaStream = async (videoDeviceId = null) => {
    const videoConstraints = {}
    if (videoDeviceId) videoConstraints.deviceId = videoDeviceId
    
    const constraints = {
        video: Object.keys(videoConstraints).length > 0 ? videoConstraints : true,
    }
    const mediaStream = await navigator.mediaDevices.getUserMedia(constraints)
    return mediaStream
}

export const stopTracks = (mediaStream) => {
    mediaStream.getTracks().forEach(track => track.stop())
}

export const getCameraPermissionStatus = async () => {
    const permissionStatus = await navigator.permissions.query({ name: 'camera' })
    return permissionStatus.state
}

// export const downloadVideo = () => {
//     if (recordedChunks.length === 0) return
//     const blob = new Blob(recordedChunks, { type: 'video/webm' })
//     const url = URL.createObjectURL(blob)
//     const a = document.createElement('a')
//     document.body.appendChild(a)
//     a.style = 'display: none'
//     a.href = url
//     a.download = 'capture.webm'
//     a.click()
//     window.URL.revokeObjectURL(url)
//     setRecordedChunks([])
// }

export const generateRandomId = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}