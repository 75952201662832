export const TABLE_COLUMNS = [
    {
        Header: <div className="flex justify-center">Nombre</div>,
        accessor: 'name'
    },
    {
        Header: <div className="flex justify-center">Tipo de Impresión</div>,
        accessor: 'printing_type'
    },
    {
        Header: <div className="flex justify-center">Documento</div>,
        accessor: 'actions'
    }
]

export const DOCUMENT_TYPES = {
    LABEL: "label",
    DOCUMENT: "document"
}
