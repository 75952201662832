import { useMemo } from 'react';

import { PackageOption } from './PackageOption';
import { PACKAGE_OPTIONS_IMAGES, PACKAGE_OPTIONS_TITLES, PACKAGE_SELECTION_TITLES, RESERVED_PACKAGE_OPTIONS, UNAVAILABLE_RECOMMENDED_PACKAGE_OPTIONS } from './constants';
import { getAvailablePackingOptions } from './utils';

const RecommendedPackageSection = ({availablePackageOptions, isPackagelessOrder, isFragileOrder}) => {

    const recommendedOptions = useMemo(() => {
        const excludedRecommendedOptions = [...RESERVED_PACKAGE_OPTIONS, ...UNAVAILABLE_RECOMMENDED_PACKAGE_OPTIONS]
        return getAvailablePackingOptions(availablePackageOptions, isPackagelessOrder, isFragileOrder, excludedRecommendedOptions, true)
    },[availablePackageOptions, isFragileOrder, isPackagelessOrder])

    return (
        <section className='bg-white rounded-lg p-4'>
            <h2 className="mb-0 text-2xl font-bold">{'Empaque recomendado'}</h2>
            <p className="mb-0 text-lg">
                {'Sólo si no viene '}
                <span className="font-bold">{'caja cliente'}</span>
                {' en los injertos, utiliza:'}
            </p>

            <div className='flex gap-2 mt-4'>
                {recommendedOptions.map((packageKey, index) => {
                    return(
                        <PackageOption
                            key={`packageOption-${packageKey}`}
                            image={PACKAGE_OPTIONS_IMAGES[packageKey]}
                            name={PACKAGE_OPTIONS_TITLES[packageKey]}
                            selectionTitle={PACKAGE_SELECTION_TITLES[index]}
                            isSelected={index === 0}
                        />
                    )
                })}
            </div>
        </section>
    )
}

export default RecommendedPackageSection
