import { useQuery } from "react-query"
import { Link, useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import {
    Button,
    FormattedDate,
    Notification,
    PageView,
    PageTopBar,
    TextareaField,
    InputQuantity,
} from "../../components"
import { Loader } from "../../components/Loader"
import { DownloadIcon, TrashIcon } from "@heroicons/react/outline"
import { WORK_ORDERS } from "../../navigation/constants"
import { useEffect, useState, React, useRef } from "react"
import LogRocket from "logrocket"
import {
    LeftOutlined
} from '@ant-design/icons'
import { fetchWorkOrders, updateWorkOrderStatusCompleted, updateWorkOrderStatusInProgress, updateWorkOrderStatusInValidation, updateWorkOrderStatusRejected, updateWorkOrderStatusScheduled } from "../../services/workOrderServices"
import { COMPLETED, IN_PROGRESS, IN_VALIDATION, REJECTED, REQUESTED, SCHEDULED } from "./constants"
import pageSizeSlice from "../../redux/pageSizeSlice"
import { FileInputField, fileToBase64 } from "../../components/FileInputField"
import { StatusHighlighted } from "../../components/NewTable/StatusHighlighted"
import { WORK_ORDER_STATUSES } from "../../components/NewTable/StatusHighlightedDefinitions"
import { Radio } from "antd"
import { LINE_TYPE_END, LINE_TYPE_MIDDLE, LINE_TYPE_NONE, LINE_TYPE_START, TimelineItem } from "../../components/Timeline/TimelineItem"

export const WorkOrderDetailContainer = () => {
    let { id } = useParams()
    const { warehouse_id } = useParams()
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const [showUpdateNotification, setShowUpdateNotification] = useState(false)
    const [showNotificationError, setShowNotificationError] = useState(false)
    const [isValidationChecked, setIsValidationChecked] = useState(true)
    const [loadingDocuments, setLoadingDocuments] = useState(false)
    const [updatingState, setUpdatingState] = useState(false)
    const [fileError, setFilesError] = useState(null)
    const [files, setFiles] = useState([])

    const filesChangeHandler = (pdfs) => {
        if (pdfs.length > 0) {
            setFilesError(null)
            setFiles([...files, ...pdfs])
        }
    }

    const removeFiles = (id) => {
        setFiles(files.filter((document) => (document.id != id && document.document_id != id)))
    }

    const searchParams = {
        id: id,
        page: 1,
        per_page: pageSizeSlice,
        order_by: "created_at"
    }
    const [workOrder, setWorkOrder] = useState()

    const { isLoading,
        isFetching,
        isError,
        error,
        data,
        refetch
    } = useQuery(`work_order_${id}`, () => fetchWorkOrders(searchParams),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false
        }
    )

    useEffect(() => {
        if (!data) return
        if (data.work_orders) setWorkOrder(data.work_orders[0])
    }, [data])

    const buildStatusHighlighted = (workOrder) => {
        return (
            <div workOrder={workOrder}>
                <StatusHighlighted
                    className=""
                    status={workOrder.status}
                    statuses={WORK_ORDER_STATUSES}
                />
                {!!workOrder.created_at && (
                    <div className="mt-3 ml-0.5">
                        <div className="mt-2 ml-0.5 text-gray-400 text-sm">
                            {i18n.t("work_orders.work_order_details.on_date")} <FormattedDate date={workOrder.created_at} shortDate={true} />
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const [operatorQuantity, setOperatorQuantity] = useState(1)
    const [laborDaysQuantity, setLaborDaysQuantity] = useState(1)
    const notesRef = useRef(null)

    useEffect(() => {
        setFiles([])
        if (notesRef.current && notesRef.current.value !== null) notesRef.current.value = ''
    }, [isValidationChecked])

    const AttachImage = ({areNotesRequired = false}) => {
        return (
            <div className="mt-4">
                <div>
                    {areNotesRequired &&
                        <span className="text-red-500">{" * "}</span>
                    }
                    <span className="text-sm font-medium text-gray-700 mb-1"> {i18n.t("work_orders.work_order_details.comments_to_the_brand")}</span>
                </div>
                <div className="mt-3 mb-3 relative">
                    {loadingDocuments && <Loader show={true} />}
                    <FileInputField
                        onChange={(files) => filesChangeHandler(files)}
                        onError={(error, file, message) => setFilesError(message)}
                        maxFiles={10}
                        placeholder={i18n.t("work_orders.work_order_details.work_order_card.files_placeholder")}
                        accepts={["application/pdf", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "image/png", "image/jpg", "image/jpeg"]}
                    />
                    {fileError && (
                        <div className="text-red-500 text-center italic mt-2">
                            {fileError}
                        </div>
                    )}
                    <div className="flex flex-wrap justify-center flex-col">
                        {files.map((file, x) => (
                            <div className="mt-3 grid grid-cols-4 border-b border-gray-200" key={String(file.id || file.document_id)}>
                                <p className="col-span-2">{file.name || file.filename}</p>
                                <div onClick={() => removeFiles(file.id || file.document_id)}
                                    className="bg-white color-red cursor-pointer justify-self-center">
                                    <TrashIcon className="h-5 w-5 text-red-500" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }

    const optionsWithDisabled = [
        { label: i18n.t("work_orders.work_order_details.work_order_card.confirm_validation"), value: true },
        { label: i18n.t("work_orders.work_order_details.work_order_card.reject_validation"), value: false },
    ]


    const handleValidationChange = (val) => {
        setIsValidationChecked(val)
    }

    const resolveWorkOrderStatusCard = () => {
        if (workOrder?.status === REQUESTED) {
            return (
                <div>
                    {i18n.t("work_orders.work_order_details.work_order_card.complete_the_information_requested")}
                    <div className="mt-5">
                        <span className="text-red-500">{" * "}</span>
                        <span className="font-semibold">
                            {i18n.t("work_orders.work_order_details.work_order_card.validate_order")}
                        </span>
                    </div>
                    <div className="mt-5 mb-7">
                        <Radio.Group
                            defaultValue={isValidationChecked}
                            options={optionsWithDisabled}
                            onChange={(e) => handleValidationChange(e.target.value)}
                            value={isValidationChecked}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </div>
                    {isValidationChecked ? (
                        <>
                            <AttachImage />
                            <textarea
                                maxLength={200}
                                ref={notesRef}
                                rows={3}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                            <Button onClick={handleOnUpdateWorkOrderStatusInValidation} className="w-1/2 mt-7" loading={updatingState} disabled={updatingState}>
                                {i18n.t("work_orders.work_order_details.work_order_card.update_work_order_status")}&nbsp;<i><b>{i18n.t(`work_orders.work_order_details.work_order_statuses.${IN_VALIDATION}`)} </b></i>
                            </Button>
                        </>
                    ) : (
                        <>
                            <AttachImage areNotesRequired={true}/>
                            <textarea
                                maxLength={200}
                                ref={notesRef}
                                rows={3}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                            <Button onClick={handleOnUpdateWorkOrderStatusRejected} className="w-1/2 mt-7" loading={updatingState} disabled={updatingState}>
                                {i18n.t("work_orders.work_order_details.work_order_card.update_work_order_status")}&nbsp;<i><b>{i18n.t(`work_orders.work_order_details.work_order_statuses.${REJECTED}`)} </b></i>
                            </Button>

                        </>
                    )}
                </div>
            )
        } else if (workOrder?.status === IN_VALIDATION) {
            return (
                <div>
                    {i18n.t("work_orders.work_order_details.work_order_card.complete_the_information_in_validation")}
                    <div className="mt-5">
                        <span className="text-red-500">{" * "}</span>
                        <span className="font-semibold">{i18n.t("work_orders.work_order_details.work_order_card.necessary_pieces")}  </span>
                    </div>
                    <div className="grid grid-col-2 mt-3">
                        <div className="mt-5 mb-7">
                            <Radio.Group
                                defaultValue={isValidationChecked}
                                options={optionsWithDisabled}
                                onChange={(e) => handleValidationChange(e.target.value)}
                                value={isValidationChecked}
                                optionType="button"
                                buttonStyle="solid"
                            />
                        </div>
                        {isValidationChecked ? (
                            <div className="flex flex-col">
                                <div className="mt-4">
                                    <div className="mb-3">
                                        <span className="text-red-500">{" * "}</span>
                                        <span className="font-semibold">{i18n.t("work_orders.work_order_details.work_order_card.estimated_operator_quantity")}</span>
                                    </div>
                                    <InputQuantity initialValue={operatorQuantity} updateData={(value) => { setOperatorQuantity(value) }} />
                                </div>

                                <div className="mt-4">
                                    <div className="mb-3">
                                        <span className="text-red-500">{" * "}</span>
                                        <span className="font-semibold">{i18n.t("work_orders.work_order_details.work_order_card.estimated_labor_days_quantity")}</span>
                                    </div>
                                    <InputQuantity initialValue={laborDaysQuantity} updateData={(value) => { setLaborDaysQuantity(value) }} />
                                </div>
                                <AttachImage />
                                <textarea
                                    maxLength={200}
                                    ref={notesRef}
                                    rows={3}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                <Button onClick={handleOnUpdateWorkOrderStatusScheduled} className="w-1/2 mt-7" loading={updatingState} disabled={updatingState}>
                                    {i18n.t("work_orders.work_order_details.work_order_card.update_work_order_status")}&nbsp;<i><b>{i18n.t(`work_orders.work_order_details.work_order_statuses.${SCHEDULED}`)} </b></i>
                                </Button>

                            </div>
                        ) : (
                            <div className="flex flex-col">
                                <AttachImage areNotesRequired={true}/>
                                <textarea
                                    maxLength={200}
                                    ref={notesRef}
                                    rows={3}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                <Button onClick={handleOnUpdateWorkOrderStatusRejected} className="w-1/2 mt-7" loading={updatingState} disabled={updatingState}>
                                    {i18n.t("work_orders.work_order_details.work_order_card.update_work_order_status")}&nbsp;<i><b>{i18n.t(`work_orders.work_order_details.work_order_statuses.${REJECTED}`)} </b></i>
                                </Button>

                            </div>
                        )}
                    </div>
                </div>
            )
        } else if (workOrder?.status === SCHEDULED) {
            return (
                <div>
                    {i18n.t("work_orders.work_order_details.work_order_card.scheduled_order")}
                    <>
                        <AttachImage />
                        <textarea
                            maxLength={200}
                            ref={notesRef}
                            rows={3}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                        <Button onClick={handleOnUpdateWorkOrderStatusInProgress} className="w-1/2 mt-7" loading={updatingState} disabled={updatingState}>
                            {i18n.t("work_orders.work_order_details.work_order_card.update_work_order_status")}&nbsp;<i><b>{i18n.t(`work_orders.work_order_details.work_order_statuses.${IN_PROGRESS}`)} </b></i>
                        </Button>
                    </>
                </div>
            )
        } else if (workOrder?.status === IN_PROGRESS) {
            return (
                <div>
                    {i18n.t("work_orders.work_order_details.work_order_card.complete_the_information_in_progress")}
                    <div className="grid grid-col-2 mt-2">
                        <div className="mt-4">
                            <div className="mb-3">
                                <span className="text-red-500">{" * "}</span>
                                <span className="font-semibold">{i18n.t("work_orders.work_order_details.work_order_card.confirm_operator_quantity")}</span>
                            </div>
                            <InputQuantity initialValue={operatorQuantity} updateData={(value) => { setOperatorQuantity(value) }} />
                        </div>

                        <div className="mt-4">
                            <div className="mb-3">
                                <span className="text-red-500">{" * "}</span>
                                <span className="font-semibold">{i18n.t("work_orders.work_order_details.work_order_card.estimated_labor_days_quantity")}</span>
                            </div>
                            <InputQuantity initialValue={laborDaysQuantity} updateData={(value) => { setLaborDaysQuantity(value) }} />
                        </div>
                    </div>
                    <AttachImage />
                    <textarea
                        maxLength={200}
                        ref={notesRef}
                        rows={3}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                    <Button onClick={handleOnUpdateWorkOrderStatusCompleted} className="w-1/2 mt-7" loading={updatingState} disabled={updatingState}>
                        {i18n.t("work_orders.work_order_details.work_order_card.update_work_order_status")}&nbsp;<i><b>{i18n.t(`work_orders.work_order_details.work_order_statuses.${COMPLETED}`)} </b></i>
                    </Button>
                </div>
            )
        } else if (workOrder?.status === COMPLETED) {
            return (
                <div className="font-bold">
                    {i18n.t("work_orders.work_order_details.work_order_card.completed_work_order")}
                </div>
            )
        } else if (workOrder?.status === REJECTED) {
            return (
                <div className="font-bold">
                    {i18n.t("work_orders.work_order_details.work_order_card.rejected_work_order")}
                </div>
            )
        } else {
            return (
                <div className="font-bold">
                    {i18n.t("work_orders.work_order_details.work_order_card.canceled_work_order")}
                </div>
            )
        }
    }

    const formatPdfFilesData = async () => {
        let processedPdfDocuements = []
        if (files.length > 0) {
            for (const file of files) {
                processedPdfDocuements.push({
                    filename: file.name,
                    base64_data: await fileToBase64(file),
                    content_type: file.type
                })
            }
        }
        return processedPdfDocuements
    }

    const handleOnUpdateWorkOrderStatusInValidation = async () => {
        LogRocket.track('WorkOrderUpdateStatus', {
            warehouse_id: warehouse_id,
            stauts: IN_VALIDATION
        })
        setUpdatingState(true)
        const data = {
            notes: notesRef.current.value,
            files: await formatPdfFilesData()
        }
        try {
            await updateWorkOrderStatusInValidation(workOrder.id, data)
            setShowUpdateNotification(true)
            history.push(WORK_ORDERS.replace(':warehouse_id', warehouse_id))
        } catch (error) {
            setShowNotificationError(true)
        }
    }

    const handleOnUpdateWorkOrderStatusScheduled = async () => {
        LogRocket.track('WorkOrderUpdateStatus', {
            warehouse_id: warehouse_id,
            stauts: SCHEDULED
        })
        setUpdatingState(true)
        const data = {
            notes: notesRef.current.value,
            labor_days_quantity: laborDaysQuantity,
            operator_quantity: operatorQuantity,
            files: await formatPdfFilesData()
        }
        try {
            await updateWorkOrderStatusScheduled(workOrder.id, data)
            setShowUpdateNotification(true)
            history.push(WORK_ORDERS.replace(':warehouse_id', warehouse_id))
        } catch (error) {
            setShowNotificationError(true)
        }
    }

    const handleOnUpdateWorkOrderStatusInProgress = async () => {
        LogRocket.track('WorkOrderUpdateStatus', {
            warehouse_id: warehouse_id,
            stauts: IN_PROGRESS
        })
        setUpdatingState(true)
        const data = {
            notes: notesRef.current.value,
            files: await formatPdfFilesData()
        }
        try {
            await updateWorkOrderStatusInProgress(workOrder.id, data)
            setShowUpdateNotification(true)
            history.push(WORK_ORDERS.replace(':warehouse_id', warehouse_id))
        } catch (error) {
            setShowNotificationError(true)
        }
    }

    const handleOnUpdateWorkOrderStatusCompleted = async () => {
        LogRocket.track('WorkOrderUpdateStatus', {
            warehouse_id: warehouse_id,
            stauts: COMPLETED
        })
        setUpdatingState(true)
        const data = {
            notes: notesRef.current.value,
            labor_days_quantity: laborDaysQuantity,
            operator_quantity: operatorQuantity,
            files: await formatPdfFilesData()
        }
        try {
            await updateWorkOrderStatusCompleted(workOrder.id, data)
            setShowUpdateNotification(true)
            history.push(WORK_ORDERS.replace(':warehouse_id', warehouse_id))
        } catch (error) {
            setShowNotificationError(true)
        }
    }

    const handleOnUpdateWorkOrderStatusRejected = async () => {
        LogRocket.track('WorkOrderUpdateStatus', {
            warehouse_id: warehouse_id,
            stauts: REJECTED
        })
        setUpdatingState(true)
        const data = {
            notes: notesRef.current.value,
            files: await formatPdfFilesData()
        }
        try {
            await updateWorkOrderStatusRejected(workOrder.id, data)
            setShowUpdateNotification(true)
            history.push(WORK_ORDERS.replace(':warehouse_id', warehouse_id))
        } catch (error) {
            setShowNotificationError(true)
        }
    }

    const renderTimelineSection = (workOrder) => {
        return (
            <div className="relative text-gray-700">
                {workOrder?.work_order_histories.map((workOrderHistory, index) => {
                    return (
                        <TimelineItem lineType={workOrder?.work_order_histories.length === 1 ? LINE_TYPE_NONE : index === 0 ? LINE_TYPE_START : index === workOrder?.work_order_histories.length - 1 ? LINE_TYPE_END : LINE_TYPE_MIDDLE} className="text-lg">
                            {
                                <>
                                    <div className='inline-block mr-1 text-sm text-gray-900'>
                                        {i18n.t(`work_orders.work_order_details.work_order_statuses.${workOrderHistory.new_status}`)}
                                    </div>

                                    {workOrderHistory.created_at && <>
                                        <div className="inline-block mr-1 text-sm">
                                            {i18n.t("work_orders.work_order_details.on_date")}&nbsp;
                                            {new Date(workOrderHistory.created_at).toLocaleDateString('en-GB',
                                                {
                                                    year: "2-digit",
                                                    month: "2-digit",
                                                    day: "2-digit"
                                                })
                                            }
                                        </div>

                                    </>}

                                    {workOrderHistory.notes && <>
                                        <div className="ml-3 text-sm text-gray-400 italic mt-[3px]">
                                            {i18n.t("work_orders.work_order_details.notes")}: {workOrderHistory.notes}
                                        </div>
                                    </>}

                                    {workOrderHistory.files?.map((file, index) => (
                                        <div key={index} className="ml-3 text-sm mt-[3px]">
                                            <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_PLATFORM_URL}${file.path}`}>
                                                {file.filename} &nbsp;<DownloadIcon className="inline mb-1.5 mr-1 h-4 w-4" aria-hidden="true" />
                                            </a>
                                        </div>
                                    ))}</>
                            }
                        </TimelineItem>
                    )
                })}
            </div>
        )
    }

    return (
        <>
            {isLoading || isFetching || workOrder === null ? (
                <><Loader show={true}></Loader></>
            ) : isError ? (
                <>Error: {error.message}</>
            ) : (
                <PageView
                    topMenu={
                        <PageTopBar>
                            <h1 className="text-lg font-medium text-gray-900">
                                <Link to={WORK_ORDERS.replace(':warehouse_id', warehouse_id)} className="text-black hover:text-black">
                                    <LeftOutlined className="mr-3" />
                                </Link>
                                <label className="text-gray-300">{i18n.t("work_orders.work_order_details.work_orders")}&nbsp;&rarr;&nbsp;</label>{i18n.t("work_orders.work_order_details.processing_work_order")} #{data?.work_orders[0].id}</h1>
                        </PageTopBar>
                    }
                >
                    <div>

                        <div className="flex-1 flex items-stretch overflow-hidden relative">
                            <main className="flex-1">
                                <div className="flex-1 bg-white h-full p-8 relative w-full">
                                    <dt className="text-xl font-medium w-full mb-3">
                                        {i18n.t("work_orders.work_order_details.work_order_details")}
                                    </dt>

                                    <div className="w-full border rounded-xl p-3 mb-5">
                                        <table className="w-full">
                                            <tbody className="w-full p-5">
                                                <tr className="border-b">
                                                    <td className="flex flex-col pb-3">
                                                        <span>{i18n.t("work_orders.work_order_details.work_order_number")}</span>
                                                        <span>{i18n.t("work_orders.work_order_details.solicitant")}</span>
                                                    </td>
                                                    <td className="pb-3"><span>{i18n.t("work_orders.work_order_details.work_order_status")}</span></td>
                                                </tr>
                                                <tr className="w-full">
                                                    <td className="w-1/2 border-r pt-3">
                                                        <div className="flex flex-col">
                                                            <span>#{data?.work_orders[0].id}</span>
                                                            <span className="mt-2">{data?.work_orders[0].work_order_histories[0]?.created_by.email}</span>
                                                        </div>
                                                    </td>
                                                    <td className="w-1/2 pt-3">
                                                        <div className="flex flex-col m-2 mt-3">
                                                            {buildStatusHighlighted(data?.work_orders[0])}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <dt className="text-lg font-medium w-full mb-3">
                                        {i18n.t("work_orders.work_order_details.product_origin")}
                                    </dt>

                                    <div className="w-full border rounded-xl p-3 mb-5">
                                        <table className="w-full">
                                            <tbody className="w-full p-5">
                                                <tr className="border-b">
                                                    <td className="w-1/3 pb-3">
                                                        <span>{i18n.t("work_orders.work_order_details.product_origin")}</span>
                                                    </td>
                                                    <td className="w-2/3 pb-3"><span>{i18n.t(`work_orders.work_order_details.work_order_product_origin.${data?.work_orders[0].product_origin}`)}</span></td>
                                                </tr>
                                                <tr>
                                                    <td className="w-1/3 pt-3">
                                                        <span>{i18n.t("work_orders.work_order_details.rem_order")}</span>
                                                    </td>
                                                    {
                                                        (data?.work_orders[0].order_references) ? (
                                                            <td className="w-2/3 pt-3"><span>#{data?.work_orders[0].order_references}</span></td>
                                                        ) : (
                                                            <td className="w-2/3 pt-3 text-gray-500 italic"><span>{i18n.t("work_orders.work_order_details.no_rem_order")}</span></td>
                                                        )
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                    <dt className="text-lg font-medium w-full mb-3">
                                        {i18n.t("work_orders.work_order_details.work_order")}
                                    </dt>

                                    <div className="w-full border rounded-xl p-3 mb-5">
                                        <table className="w-full">
                                            <tbody className="w-full p-5">
                                                <tr className="border-b">
                                                    <td className="w-1/3 pb-3">
                                                        <span>{i18n.t("work_orders.work_order_details.work_order_type")}</span>
                                                    </td>
                                                    <td className="w-2/3 pb-3"><span>{i18n.t(`work_orders.work_order_details.work_order_types.${data?.work_orders[0].work_order_type}`)}</span></td>
                                                </tr>
                                                <tr className="border-b">
                                                    <td className="w-1/3 pt-3 pb-3">
                                                        <span>{i18n.t("work_orders.work_order_details.notes")}</span>
                                                    </td>
                                                    <td className="w-2/3 pt-3 pb-3"><span>{data?.work_orders[0].notes}</span></td>
                                                </tr>
                                                <tr className="border-b">
                                                    <td className="w-1/3 pt-3 pb-3">
                                                        <span >{i18n.t("work_orders.work_order_details.instruction")}</span>
                                                    </td>
                                                    <td className="w-2/3 pt-3 pb-3">
                                                        {data?.work_orders[0]?.files.map((file, index) => (
                                                            <div key={index}>
                                                                <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_PLATFORM_URL}${file.path}`}>
                                                                    {file.filename} &nbsp;<DownloadIcon className="inline mb-1.5 mr-1 h-4 w-4" aria-hidden="true" />
                                                                </a>
                                                            </div>
                                                        ))}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="w-1/3 pt-3">
                                                        <span >{i18n.t("work_orders.work_order_details.activity")}</span>
                                                    </td>
                                                    <td className="w-2/3 pt-3">{renderTimelineSection(data?.work_orders[0])}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                    <dt className="text-lg font-medium w-full mb-3">
                                        {i18n.t("work_orders.work_order_details.replenishment")}
                                    </dt>

                                    <div className="w-full border rounded-xl p-3 mb-5">
                                        <table className="w-full">
                                            <tbody className="w-full p-5">
                                                <tr>
                                                    <td className="w-1/3 pt-3 pb-3">
                                                        <span>{i18n.t("work_orders.work_order_details.replenishment_id")}</span>
                                                    </td>
                                                    <td className="w-2/3 vpt-3 pb-3">
                                                        <div className="flex flex-col">
                                                            <span>#{data?.work_orders[0].replenishment_references}</span>
                                                            <span>{i18n.t("work_orders.work_order_details.replenishment_note")}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </main>

                            <main className="flex-1 ml-5">
                                <main className="flex-1 bg-white h-full p-8">
                                    <>
                                        <p className="mb-7 text-base font-medium">{i18n.t("work_orders.work_order_details.resolve_order")}</p>
                                        {resolveWorkOrderStatusCard()}
                                    </>
                                </main>
                            </main>
                        </div>
                    </div>
                </PageView>
            )}

            <Notification
                show={showUpdateNotification}
                setShow={setShowUpdateNotification}
                type="success"
                title={i18n.t("work_orders.work_order_details.notification_message.success_update_message")}
            />
            <Notification
                show={showNotificationError}
                setShow={setShowNotificationError}
                type="error"
                title={i18n.t("work_orders.work_order_details.notification_message.error_update_message")}
            />
        </>
    )
}
