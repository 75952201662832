import { ActionMenu } from "../components"

export const getSkus = (productCodes, alternStyle = false) => {
    return (productCodes ? <>
        {/* {productCodes.map((code, index) => <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 "+ (alternStyle ? "bg-gray-200 text-gray-500":"bg-blue-100 text-blue-800")} key={index}>
        {code.sku}</span>)} */}
        {productCodes.map((code, index) => <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-blue-100 text-blue-800"} key={index}>
        {code.sku}</span>)}
    </>: <>-</>)
}

// const getVariantName = (name, parentName) => {
//     return (
//         parentName ? 
//         <>
//             {name}<i>({parentName.length > 20 ? (parentName.substring(0, 15)+"...") : parentName})</i>
//         </> : <>{name}</>
//     )
// }

    export const getParentTotalStock = (variants) => {
        // console.log('v', variants)
        if (variants.length === 1) {
            return {"DAMAGED": variants[0].stock.DAMAGED, AVAILABLE: <span className={(variants[0].stock.AVAILABLE === 0 ? "text-red-400 font-semibold" : null)}>{variants[0].stock.AVAILABLE}</span>, "OUTGOING": variants[0].stock.OUTGOING}
        }
        const stock = variants.reduce(function (a, b) {
            // console.log('a', a)
            // console.log('b', b)
            let a_s = false
            let b_s = false
            if (a.stock){
                a_s = a.stock
            } else {
                a_s = a
            }
            if (b.stock){
                b_s = b.stock
            } else {
                b_s = b
            }
            return {"DAMAGED": a_s.DAMAGED + b_s.DAMAGED, AVAILABLE: a_s.AVAILABLE + b_s.AVAILABLE, "OUTGOING": a_s.OUTGOING + b_s.OUTGOING}; // returns object with property x
        })

        const childrenOutOfStock = getChildsOutofStock(variants)
        if (childrenOutOfStock > 0) {
            stock.AVAILABLE = <span className="text-red-400 font-semibold">{childrenOutOfStock + " variantes sin stock"}</span>
        } else {
            stock.AVAILABLE = <span className={(stock.AVAILABLE === 0 ? "text-red-400 font-semibold" : null)}>{stock.AVAILABLE}</span>
        }

        return stock
    }

   export const getChildsOutofStock = (variants) => {
       const outOfStock = variants.filter(variant => variant.stock.AVAILABLE === 0);

       return outOfStock.length
   }

   export const getStockContent = (product) => {
       if (product.variants.length > 0) {
            return getParentTotalStock(product.variants)  
       } else {
           if (product.stock.AVAILABLE === 0){
             product.stock.AVAILABLE = <span className="bg-red-400 text-white font-semibold">{product.stock.AVAILABLE}</span>  
           } 
           return product.stock
       }
   }

   export const getChildStock = (childStock)  => {
       console.log('child', childStock)
       if (childStock.AVAILABLE === 0){
            childStock.AVAILABLE = <span className="bg-red-400 text-white font-semibold">{childStock.AVAILABLE}</span>  
        } 
       return childStock
   }

   const getVariantMenuOptions = (product) => {
    const options = [
      {
        title: "Ver detalles",
        clickHandler: () => {},
        //   history.push(SHOW_RETURN.replace(":id", product.id)),
      },
    ]
    return options
  }

export const getVariants = (variants, parentName, searchSku, multiSearch) => {
    if (!variants)
        variants = []

    if (searchSku) {
        variants = variants.filter((v)=>v.product_codes.find((pc) => pc.sku.search(searchSku)>=0 ))
    }
    else if (multiSearch) {
        let matches = variants.filter((v)=>v.product_codes.find((pc) => pc.sku.search(multiSearch)>=0 )||v.name.search(multiSearch)>=0 )
        variants = matches.length > 0 ? matches : variants
    }
    return variants.map((product) => {
        return {
            ...product,
            id: product.id,
            store: <></>,
            variantId: product.variant_id,
            // name: getVariantName(product.name, parentName),
            stock: product.stock,
            name: product.name,
            sku: getSkus(product.product_codes, true),
            parentName: parentName,
            actions: <ActionMenu className="float-right" items={getVariantMenuOptions(product)} />
        }
    })
}

export const getProducts = (product) => {
    return product.map((product) => {
        return {
            ...product,
            id: product.id,
            store: product.name,
            pz:product.quantity
        }
    })
}

