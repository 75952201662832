import { CurrencyDollarIcon } from '@heroicons/react/outline';
import NumberFormat from 'react-number-format';

import { InformationCard } from "../../../../components";

const CodNotificationSection = ({isCodOrder, totalOrderPrice, currency}) => {
    return (
        <InformationCard
            title={"Pago contra reembolso"}
            description={
                <>
                    {"El tipo de envío es Contra Reembolso "}
                    <NumberFormat value={totalOrderPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />  - {currency}
                </>
            }
            iconComponent={<CurrencyDollarIcon className="h-full" />}
            containerStyles={"bg-yellow-100 text-yellow-600"}
            isHidden={!isCodOrder}
        />
    )
}

export default CodNotificationSection