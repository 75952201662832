import { useState } from "react"

const InsertPreview = ({insertQuantity, thumbnailImagePath, fullScreenImagePath}) => {
    const [notImageFound, setNotImageFound] = useState(false)

    return (
        <div className={`rounded-lg bg-gray-100 w-40 h-48 flex flex-col ${notImageFound ? "cursor-default" : "cursor-pointer"}`}>
            <div className='rounded-lg bg-white border p-1 flex justify-center h-5/6'>

                {notImageFound && <div className="flex flex-col justify-center text-red-500 text-2xl font-bold">{'Sin imagen'}</div>}

                {!notImageFound && (
                    <img
                        src={thumbnailImagePath}
                        alt="Insert preview"
                        onError={() => setNotImageFound(true)}
                        onClick={() => window.open(fullScreenImagePath, "_blank")}
                    />
                )}
            </div>
            <div className='text-center font-medium pt-1 h-4'>
                {`${insertQuantity} ${insertQuantity > 1 ? "piezas" : "pieza"}`}
            </div>
        </div>
    )
}

export default InsertPreview