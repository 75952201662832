import { useTranslation } from "react-i18next";

import bubble_wrap from "../assets/bubble_wrap.png";
import kraft_paper from "../assets/kraft_paper.png";
import corn_extrudate from "../assets/corn_extrudate.png";

const FragileInformationCard = ({title, description, iconComponent, containerStyles, fragilePackingMaterial, isHidden=false}) => {
    const { i18n } = useTranslation()

    const MATERIALS_IMAGES = {
        BUBBLE_WRAP: bubble_wrap,
        KRAFT_PAPER: kraft_paper,
        CORN_EXTRUDATE: corn_extrudate
    }

    if(isHidden){
        return <></>
    }

    return (
        <div className={`p-4 rounded-lg flex ${iconComponent ? "justify-between" : "w-full"} ${containerStyles ? containerStyles : "bg-white" }`}>
            <div>
                <h2 className="mb-0 text-2xl font-bold text-current">{title}</h2>
                {!fragilePackingMaterial && (
                    <p className="mb-0 text-lg font-medium">{description}</p>
                )}

                {!!fragilePackingMaterial && (
                    <div className="flex gap-4 mt-4">
                        <div
                            className="bg-red-200 flex gap-2 items-center p-2 text-gray-800 font-semibold rounded-lg cursor-pointer"
                            onClick={() => window.open(MATERIALS_IMAGES[fragilePackingMaterial.code], "_blank")}
                            key={`fragile-material-${fragilePackingMaterial.code}`}
                        >
                            <img
                                src={MATERIALS_IMAGES[fragilePackingMaterial.code]}
                                alt="Packing material preview"
                                onError={() => {}}
                                className="h-10 w-10"
                            />

                            <div>
                                {i18n.t(`orders.packing_material.${fragilePackingMaterial.code}`)}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {iconComponent && (
                <div className="h-full mr-4">
                    {iconComponent}
                </div>
            )}
        </div>
    )
}

export default FragileInformationCard
