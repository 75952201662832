import { useState, useContext } from "react"
import { Route, Switch, useHistory,useParams } from "react-router-dom"
import { useQuery } from "react-query"
import {
  ActionMenu,
  BasicTable,
  FormattedDate,
  Notification,
  PageTopBar,
  PageView,
  SlidePanel,
} from "../../components"
import { StatusPill } from "../../components/BasicTable/StatusPill"
import { CLAIMS_STATUSES } from "../../components/BasicTable/StatusPillDefinitions"
import { Loader } from "../../components/Loader"
import { SelectColumnFilter } from "../../components/BasicTable/modules/ColumnFiltersUI"
import { UserContext } from "../../hooks/UserContext"
import { fetchClaims, resetClaim } from "../../services/claimServices"
import { CLAIMS, SHOW_CLAIM, SHOW_ORDERS } from "../../navigation/constants"
import { ClaimDetailContainer } from "./ClaimDetailContainer"
import { CLAIM_AREAS, findClaimAreaLabel, findClaimCategory, getAllCategories } from "./ClaimCategoryMapping"
import { setClaimsPageSize } from '../../redux/pageSizeSlice';
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react"
import { CLAIM_STATUSES } from "./constants"

const columns = [
  {
    Header: "Nº DE ORDEN",
    accessor: "order_number", // accessor is the "key" in the data
    disableFilters: false,
    disableSortBy: true,
  },
  {
    Header: "TIENDA",
    accessor: "store_name", // accessor is the "key" in the data
    disableFilters: false,
    disableSortBy: true,
  },
  {
    Header: "ESTADO",
    accessor: "status", // accessor is the "key" in the data
    Filter: SelectColumnFilter,
    selectFilterOptions: CLAIMS_STATUSES.filter(
      (status) => status.filterable !== false
    ).map((status) => {
      return status.filterable === false
        ? null
        : { id: status.status, label: status.label }
    }),
    disableFilters: false,
    disableSortBy: false,
  },
  {
    Header: "VALOR ACEPTADO",
    accessor: "accepted_value",
    disableFilters: true,
    disableSortBy: false,
  },
  {
    Header: "ÁREA",
    accessor: "area", // accessor is the "key" in the data
    Filter: SelectColumnFilter,
    selectFilterOptions: CLAIM_AREAS.map((cat) => {
      return { id: cat.key, label: cat.label }
    }),
    disableFilters: false,
    disableSortBy: false,
  },
  {
    Header: "CATEGORÍA",
    accessor: "category", // accessor is the "key" in the data
    Filter: SelectColumnFilter,
    selectFilterOptions: getAllCategories().map((cat) => {
      return { id: cat.key, label: cat.description }
    }),
    disableFilters: false,
    disableSortBy: false,
  },
  {
    Header: "Fecha de Creación",
    accessor: "created_at", // accessor is the "key" in the data
    disableFilters: true,
    disableSortBy: false,
  },
  {
    Header: "",
    accessor: "actions", // accessor is the "key" in the data
    disableFilters: true,
    disableSortBy: true,
    fetchingIndicator: true,
    shrinkToContent: true,
  },
]

export const ListClaimsContainer = () => {
  const { user } = useContext(UserContext)
  const tableRef = useRef()
  const history = useHistory()
  const {warehouse_id} = useParams()
  const dispatch = useDispatch()
  const pageSize = useSelector((state) => state.pageSize.inventoryHistoryList)
  const [searchParams, setSearchParams] = useState({
    page: 1,
    per_page: pageSize,
    warehouse_id:warehouse_id,
    order_by: "created_at"
  })
  const [showClaimResetNotif, setShowClaimResetNotif] = useState(false)

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    isPreviousData,
    refetch,
  } = useQuery(["claims", searchParams], () => fetchClaims(searchParams), {
    keepPreviousData: true,
  })

  const handlerOnClickStatus = (claim) => {
    history.push(SHOW_CLAIM.replace(":id", claim.id).replace(':warehouse_id', warehouse_id))
  }

  const onSortChange = (orderBy) => {
    if (orderBy.length > 0) {
      setSearchParams({
        ...searchParams,
        order_by: orderBy[0].id,
        order: orderBy[0].desc ? "DESC" : "ASC",
      })
    } else {
      if ("order_by" in searchParams) {
        searchParams.order_by = "created_at"
        delete searchParams.order
        setSearchParams({ ...searchParams })
      }
    }
  }

  const onFilterChange = (activeFilters) => {
    columns.forEach((col) => {
      if (searchParams[col.accessor]) {
        delete searchParams[col.accessor]
      }
    })
    let filters = []
    activeFilters.forEach((filter) => {
      filters[filter.id] = filter.value
    })
    setSearchParams({ ...searchParams, ...filters, page: 1 })
  }

  const getMenuOptions = (claim) => {
    const options = [
      {
        title: "Ver detalle",
        clickHandler: () => history.push(SHOW_CLAIM.replace(":id", claim.id).replace(':warehouse_id', warehouse_id)),
      },
      // {
      //   title: "Ver orden",
      //   clickHandler: () =>
      //     history.push(SHOW_ORDERS.replace(":id", claim.order.id).replace(':warehouse_id', warehouse_id)),
      // },
    ]
    if (claim.status === CLAIM_STATUSES.REJECTED) {
      options.push({
        title: "Reiniciar Disputa",
        clickHandler: () => onResetClaim(claim.id),
      })
    }

    return options
  }

  const onResetClaim = async (id) => {
    try {
      await resetClaim(id)
      setShowClaimResetNotif(true)
      refetch()
    } catch (error) {
      console.log(error)
      window.alert(error)
    }
  }

  const getTableData = () => {
    return data.claims.map((claim) => {
      return {
        order_number: (
          <div
            className="hover:underline cursor-pointer "
            onClick={() => handlerOnClickStatus(claim)}
          >
            #{String(claim.order.order_number)}
          </div>
        ),
        store_name: claim.order.store.name,
        status: (
          <StatusPill
            className="cursor-pointer"
            status={claim.status}
            statuses={CLAIMS_STATUSES}
            onClick={() => handlerOnClickStatus(claim)}
          />
        ),
        value: <div>{String(`$${claim.value}`)}</div>,
        accepted_value: (
          <div>
            {claim.status === "PENDING" ? (
              <span className="italic">Pendiente</span>
            ) : claim.status === "REJECTED" ? (
              <span className="italic">Rechazada</span>
            ) : (
              String(`$${claim.accepted_value ? claim.accepted_value : " -"}`)
            )}
          </div>
        ),
        area: <div>{claim.area ? findClaimAreaLabel(claim.area) : '-'}</div>,
        category: <div>{findClaimCategory(claim.area, claim.category)}</div>,
        created_at: <FormattedDate date={claim.created_at} shortDate />,
        actions: (
          <ActionMenu className="float-right" items={getMenuOptions(claim)} />
        ),
      }
    })
  }

  return (
    <>
      <Notification
        show={showClaimResetNotif}
        setShow={setShowClaimResetNotif}
        type="success"
        title="Disputa Reiniciada"
      />
      <PageView
        topMenu={
          <PageTopBar>
            <div className="text-lg font-semibold">Disputas</div>
            {/* {user.current_store && (
              <Button
                className="whitespace-nowrap"
                onClick={() => {
                }}
              >
                Crear Disputa
              </Button>
            )} */}
          </PageTopBar>
        }
        childrenFullWidth={true}
        topMenuFullWidth={true}
      >
        {!isPreviousData && isLoading ? (
          <>
            <Loader show={true}></Loader>
          </>
        ) : isError ? (
          <>Error: {error.message}</>
        ) : (
          <BasicTable
            ref={tableRef}
            showHeader
            showLoader={isFetching && isPreviousData}
            columns={columns}
            filterable
            sortable
            showPaginator
            data={getTableData()}
            onFilterChange={onFilterChange}
            onSortChange={onSortChange}
            paginationMeta={data?.meta}
            labelToPaginator="Disputas"
            pagesSize = {searchParams.per_page}
            onPaginationChange= { (requestedPage) => { setSearchParams({...searchParams, page: requestedPage}) } }
            onPageSizeChange= { (pageSize) => {
                setSearchParams({...searchParams, per_page: pageSize, page: 1})
                dispatch(setClaimsPageSize(pageSize))
                tableRef.current.resetPagination()
            }}
            showDataStatus
            isFetching={isFetching}
            emptyTableText="No hay disputas que mostrar."
          />
        )}
      </PageView>
      <Switch>
        <Route exact path={SHOW_CLAIM}>
          <SlidePanel
            title="Ver Disputa"
            referrer={CLAIMS.replace('warehouse_id', warehouse_id)}
            onClose={() => refetch()}
          >
            <ClaimDetailContainer />
          </SlidePanel>
        </Route>
      </Switch>
    </>
  )
}
