export const getOrderStatusDate = (order) => {
    const status = order.virtual_status
    const isPickupOrder = order?.shipping_method?.shipping_type === "pickup"

    switch (true) {

        case status === 'initial':
        case status === 'empty':
        case status === 'entirely_dropshipping':
        case status === 'cancelation_window':
        case status === 'unpaid':
        case status === 'backorder':
        case status === 'error':
            return order.order_date

        case status === 'pending' && order.pending_at:
            return order.pending_at

        case status === 'hold':
        case status === 'holded':
        case status === 'returning':
        case status === 'returned':
            return null

        case status === 'shipped' && isPickupOrder:
        case status === 'waiting_pick_up':
            return order.shipping_date

        case status === 'canceled':
            return order.updated_at

        case status === 'shipped' && !isPickupOrder:
            return order.dispatched_at

        default:
            return null
    }
}

export const validateIsPackagelessOrder = orderLines => (
    orderLines?.length === 1 && Number(orderLines[0].quantity) === 1 && orderLines[0]?.product?.is_packageless === true
)

export const validateHasPackagelessProduct = orderLines => (
    orderLines?.some(orderLine => orderLine?.product?.is_packageless === true) ? true : false
)

export const validateIsFragileOrder = (orderLines) => {
    const isFragile = !orderLines ? false : orderLines?.some(orderLine => {
        if(orderLine.product.is_fragile){
            return true
        }

        if(orderLine.product.is_kit && orderLine.product.kit_childs.some(productKit => productKit.child_product.is_fragile)){
            return true
        }

        return false
    })

    return isFragile
}
