import { S3Client as s3c, CreateMultipartUploadCommand, UploadPartCommand, CompleteMultipartUploadCommand} from '@aws-sdk/client-s3'
export default class S3Client {
  constructor({ region = 'us-east-2', accessKeyId, secretAccessKey, bucketName })
  {
    this.s3Client = new s3c({
      region: region,
      credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
      },
    })
    this.resetMultiPartUpload()
    this.Bucket = bucketName
  }

  resetMultiPartUpload() {
    this.currentMultipartUpload = {
      object: null,
      uploadId: null,
      parts: [],
      uploadedParts: [],
      fileKey: null,
    }
  }

  async createMultipartUpload(fileKey) {
    // TO DO: check if key exists and append a number to it if it does
    let response = await this.s3Client.send(
      new CreateMultipartUploadCommand({
        Bucket: this.Bucket,
        Key: fileKey,
      }),
    )
  
    this.currentMultipartUpload.uploadId = response.UploadId
    this.currentMultipartUpload.fileKey = fileKey
    return true
  }

  async uploadPart(part, partIndex) {
    if (!this.currentMultipartUpload.uploadId) throw new Error('Must initialize multipart upload first')
    this.currentMultipartUpload.parts[partIndex] = part

    let responseUploadPart = await this.s3Client.send(
      new UploadPartCommand({
        Body: part,
        Bucket: this.Bucket,
        Key: this.currentMultipartUpload.fileKey,
        PartNumber: partIndex + 1,
        UploadId: this.currentMultipartUpload.uploadId,
      }),
    )
    this.currentMultipartUpload.uploadedParts[partIndex] = responseUploadPart
    return responseUploadPart
  }

  // check if all requested parts have been uploaded
  allPartsUploaded() {
    if (!this.currentMultipartUpload?.parts?.length) return false
    return this.currentMultipartUpload.parts.length === this.currentMultipartUpload.uploadedParts?.length
  }

  async completeMultipartUpload() {
    if (this.currentMultipartUpload.parts.length === 0) throw new Error('No parts to complete')
    if (!this.currentMultipartUpload.uploadId) throw new Error('No multipart update to complete')
    if (!this.allPartsUploaded()) throw new Error('Not all parts have been uploaded')

    const completedParts = this.currentMultipartUpload.uploadedParts.map((part, index) => ({
      ETag: part.ETag,
      PartNumber: index + 1,
    }))

    // throw error if any uploaded parts have no etag
    if (completedParts.some((part) => !part.ETag)) throw new Error('Not all uploaded parts have an ETag')

    let responseCompleteMultipartUpload = await this.s3Client.send(
      new CompleteMultipartUploadCommand({
        Bucket: this.Bucket,
        Key: this.currentMultipartUpload.fileKey,
        MultipartUpload: {
          Parts: completedParts,
        },
        UploadId: this.currentMultipartUpload.uploadId,
      }),
    )
    this.resetMultiPartUpload()
    return responseCompleteMultipartUpload
  }
}
