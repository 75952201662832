import { useMemo } from 'react';

const ProductsTotal = ({productsWithStock, pendingItemsToBeReviewed}) => {
    const totalItemsToBeReviewed = useMemo(() => productsWithStock.reduce((accumulator, {quantity}) => accumulator + quantity, 0),[productsWithStock])

    return (
        <div className='w-full'>
            {pendingItemsToBeReviewed === 0 && (
                <div className='text-green-700 text-lg font-bold p-2 rounded-b-lg pl-4'>
                    {`Total: Se revisaron ${totalItemsToBeReviewed} piezas`}
                </div>
            )}

            {pendingItemsToBeReviewed > 0 && (
                <div className='text-yellow-700 text-lg font-bold p-2 rounded-b-lg pl-4'>
                    {`Total: ${pendingItemsToBeReviewed} ${pendingItemsToBeReviewed > 1 ? "piezas" : "pieza"} pendiente de revisar`}
                </div>
            )}
        </div>
    )
}

export default ProductsTotal