import { useQuery, useQueryClient } from "react-query"
import ReactHtmlParser from 'react-html-parser'
import { Link, useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import {
  Button,
  Checkbox,
  ConfirmDialog,
  FormattedDate,
  Notification,
  PageView,
  SelectInput,
  PageTopBar,
  TextareaField,
  InputQuantity,
} from "../../components"
import { Loader } from "../../components/Loader"
import { fetchWarehouse } from "../../services/warehouseSevices";
import { SearchIcon, TagIcon, TrashIcon } from "@heroicons/react/outline"
import { OrderLineItem } from '../Orders/OrderLineItem';
import { StatusPill } from "../../components/BasicTable/StatusPill"
import { ORDER_STATUSES, TRACKING_STATUSES } from '../../components/BasicTable/StatusPillDefinitions';
import { Timeline } from 'antd';
import { ImageInputField } from "../../components/ImageInputField"
import { CLAIMS_STATUSES } from "../../components/BasicTable/StatusPillDefinitions"
import { CLAIMS } from "../../navigation/constants"
import {
  fetchClaim,
  fetchUsages,
  updateClaim,
  updateClaimArea
} from "../../services/claimServices"
import { useEffect, useMemo, useState, React } from "react"
import { findClaimAreaLabel, findClaimCategory, findClaimCategoryReimbursables, getAllCategories, CLAIM_AREAS, findClaimCategoryRecomendation, getAreaCategories } from "./ClaimCategoryMapping"
import { Tabs, Tooltip } from 'antd';
import {
  FileExclamationOutlined,
  LeftOutlined,
  FileTextOutlined,
  CodeSandboxOutlined
} from '@ant-design/icons';
import { ProofOfDelivery } from "../../components/ProofOfDelivery";
import { CurrencyInputField } from "../../components/CurrencyInputField"

const UMA_PRICE_APRIL_2024 = 108.57

export const ClaimDetailContainer = () => {
  let { id } = useParams()
  const history = useHistory()
  const queryClient = useQueryClient()
  const { warehouse_id } = useParams()
  const [usages, setUsages] = useState(null)

  const { isLoading, isFetching, isError, error, data, refetch } = useQuery(
    "claim",
    () => fetchClaim(id),
    {
      refetchOnWindowFocus: false,
      onSuccess: async (claimData) => {
        const usagesData = await fetchUsages(claimData?.order?.id)
        setUsages(usagesData)
      }
    }
  )

  const {
    isLoading: isLoadingWarehouse,
    isError: isErrorWarehouse,
    error: errorWarehouse,
    data: warehouse,
    isPreviousData: isPreviousDataWarehouse,
  } = useQuery('warehouse', () => fetchWarehouse(warehouse_id), { keepPreviousData: true })

  const { t, i18n } = useTranslation();
  const [imageError, setImageError] = useState(null)
  const [images, setImages] = useState([])
  const [selectedArea, setSelectedArea] = useState(null)
  const [claim, setClaim] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [resolution, setResolution] = useState(null)
  const [showNotification, setShowNotification] = useState(false)
  const [showUpdateNotification, setShowUpdateNotification] = useState(false)
  const [showNotificationError, setShowNotificationError] = useState(false)
  const [showSubmitConfirmDialog, setShowSubmitConfirmDialog] = useState(false)
  const [loadingSubmitClaim, setLoadingSubmitClaim] = useState(false)
  const [accepted, setAccepted] = useState(null)
  const [acceptedValue, setAcceptedValue] = useState(null)
  const [allProducts, setAllProducts] = useState(true)
  const [allProductsSelected, setAllProductsSelected] = useState(false)
  const [isPriceFromIntegration, setIsPriceFromIntegration] = useState(true)

  useEffect(() => {
    if (warehouse != null) {
      changeLanguage(warehouse.country)
      setClaim({
        ...claim,
        language: warehouse.country == "BR" ? "PT" : "ES"
      })
    }
  }, [warehouse])
  useEffect(() => {
    if (warehouse != null) {
      changeLanguage(warehouse.country)
      setClaim({
        ...claim,
        language: warehouse.country == "BR" ? "PT" : "ES"
      })
    }
  }, [warehouse])

  const changeLanguage = (country) => {
    if (country === "BR") {
      i18n.changeLanguage("pt")
    }
    else {
      i18n.changeLanguage("es")
    }
  }
  const filesChangeHandler = (files) => {
    if (files.length > 0) {
      setImageError(null)
      setImages([...images, ...files])
    }
  }

  const removeImage = (id) => {
    setImages(images.filter((img) => img.id != id))
  }

  const areas = useMemo(() => {
    let all_areas = []
    all_areas = all_areas.concat(CLAIM_AREAS.map((cat) => { return { name: cat.label, id: cat.key } }))
    return all_areas
  }, [true])

  const findArea = (area) => {
    let selected_area = areas?.find((a) => a.id == area)
    return selected_area
  }

  const categories = useMemo(() => {
    let all_categories = []
    const area = findArea(claim?.area)
    all_categories = all_categories.concat(
      getAreaCategories(area?.id).map((cat, index) => {
        return { name: cat.description, id: cat.key }
      })
    )
    return all_categories
  }, [claim?.area, areas])

  const onChangeArea = async (val) => {
    try {
      await updateClaimArea(data.id, { area: val.id })
      setShowUpdateNotification(true)
      refetch()
    } catch (error) {
      setShowNotificationError(true)
    }
  }

  const reimbursables = useMemo(() => {
    if (claim) {
      return findClaimCategoryReimbursables(claim?.area, claim?.category)
    }
  }, [claim, claim?.category, claim?.area])

  useEffect(() => {
    setSelectedCategory(findCategory(data?.category))
    setClaim({
      ...claim,
      category: data?.category,
      area: data?.area,
      reimbursables: data?.reimbursables?.split(',') || reimbursables,
      status: data?.status,
      recommendation: data?.recommendation,
      resolution: data?.resolution,
      accepted_value: data?.accepted_value || null,
      claim_message: data?.claim_message
    })
  }, [data, warehouse, selectedCategory])

  const [orderLines, setOrderLines] = useState([])
  const [allProductsValue, setAllProductsValue] = useState(null)

  const calculateAdjustedPrice = (orderLine, claimedProductsValue, warehouseCountry) => {
    let originalPrice = parseFloat(orderLine.price_per_item) - parseFloat(orderLine?.discount_per_item)
    // Check if the warehouse country is MX (Mexico) and discount IVA
    if (warehouseCountry === 'MX') {
      if (originalPrice > 0) originalPrice *= 0.84
      if (claimedProductsValue != null) {
        const claimedProduct = claimedProductsValue.find(product => product.id === orderLine.id)
        if (claimedProduct && parseFloat(claimedProduct.price) > 0) {
          originalPrice = parseFloat(claimedProduct.price)
        }
      }
      const fixedProductValue = (Number(originalPrice) || 0).toFixed(2)
      return fixedProductValue
    } else {
      const fixedProductValue = (Number(originalPrice) || 0).toFixed(2)
      return fixedProductValue
    }
  }

  useEffect(() => {
    if (data) {
      const newOrderLines = data?.order?.order_lines?.map(orderLine => {
        if (orderLine.price_per_item === null) setIsPriceFromIntegration(false)
        return {
          ...orderLine,
          price_per_item: (() => {
            return calculateAdjustedPrice(orderLine, data?.claimed_products_value, warehouse?.country)
          })(),
          requested_quantity: data?.claimed_products_value !== null ? ((data?.claimed_products_value?.filter(product => product.id === orderLine.id))[0]?.reimbursable_quantity || 0) : orderLine?.quantity,
          reimbursable_quantity: data?.claimed_products_value !== null ? ((data?.claimed_products_value?.filter(product => product.id === orderLine.id))[0]?.reimbursable_quantity || 0) : orderLine?.quantity,
          is_selected: data?.claimed_products_value !== null ? (data?.claimed_products_value?.filter(product => product.id === orderLine.id)?.length > 0 ? true : false) : true,
          matches_filter: true
        }
      })
      setOrderLines(newOrderLines)
      setAllProductsValue(data?.value)
    }
  }, [data])

  const handleFilter = (value) => {
    if (value.length > 0) {
      const newOrderLines = orderLines?.map(orderLine => {
        if ((orderLine?.product?.name)?.toLowerCase().includes(value?.toLowerCase()) || (orderLine?.sku)?.toLowerCase().includes(value?.toLowerCase())) {
          return {
            ...orderLine,
            matches_filter: true
          }
        } else {
          return {
            ...orderLine,
            matches_filter: false
          }
        }
      })
      setOrderLines(newOrderLines)
    } else {
      const newOrderLines = orderLines?.map(orderLine => {
        return {
          ...orderLine,
          matches_filter: true
        }
      }
      )
      setOrderLines(newOrderLines)
    }
  }


  useEffect(() => {
    setAllProducts(true)
    setClaim({
      ...claim,
      reimbursables: reimbursables,
    })
  }, [claim?.category, accepted])

  const findCategory = (category) => {
    const selected_category = categories.find((c) => c.id == category)
    if (!selected_category) return categories[0]
    return selected_category
  }

  const onAcceptanceRadioChange = (val) => {
    let status_translation = null
    if (val === "ACCEPTED") {
      setAccepted(true)
      status_translation = i18n.t("claims.status.accepted")
    } else {
      setAccepted(false)
      status_translation = i18n.t("claims.status.rejected")
    }

    setClaim({
      ...claim,
      status: val,
      status_translation: status_translation
    })
  }

  const handleChangeCategory = (v) => {
    setAccepted(null)
    setClaim({
      ...claim,
      category: v.id,
      area: v.id == 0 ? data.area : claim.area
    })
  }

  const onAllProductsRadioChange = (val) => {
    if (val === "ACCEPTED") setAllProducts(true)
    else setAllProducts(false)
  }

  const onSubmit = async () => {
    setLoadingSubmitClaim(true)

    let fd = new FormData()

    fd.append("html_message", htmlMessage)
    fd.append("area", claim.area)
    fd.append("category", claim.category)
    fd.append("status", claim.status)
    fd.append("status_translation", claim.status_translation)
    fd.append("language", warehouse?.country == "BR" ? "PT" : "ES")
    images.forEach((image) => fd.append("resolution_files[]", image))
    if (claim.accepted_value && claim.status == "ACCEPTED") {
      fd.append("accepted_value", claim.accepted_value)
      fd.append("reimbursables", claim.reimbursables.toString())
    }
    if (claim.status == "REJECTED") {
      fd.append("recommendation", claim.recommendation)
      fd.append("resolution", claim.resolution)
    }
    try {
      await updateClaim(data.id, fd)
      setShowNotification(true)
      refetch()
    } catch (error) {
      console.log(error)
      setShowNotificationError(true)

    }
    setShowSubmitConfirmDialog(false)
    setLoadingSubmitClaim(false)
  }

  const addReimbursables = (val) => {
    let index = claim.reimbursables.findIndex(reimbursable => reimbursable === val)
    if (index === -1) {
      setClaim({
        ...claim,
        reimbursables: [...claim.reimbursables, val]
      })
    }
    else {
      let reimbursables_filter = claim.reimbursables.filter((r) => r != val)
      setClaim({
        ...claim,
        reimbursables: [...reimbursables_filter]
      })
    }
  }

  const [usagePrices, setUsagePrices] = useState({
    cost_cubbo: 0,
    cost_shipping: 0,
    cost_return_shipping: 0,
    product_value: 0,
    total: 0
  })

  const [hasNoBilling, setHasNoBilling] = useState(false)

  const pricing = useMemo(() => {
    if (usages) {
      if (usages.length < 1 || warehouse.country == "BR") {
        setHasNoBilling(true)
      }
      const orderUsages = usages?.store_billing_service_usages?.filter(usage => usage?.billable_type === "Order") || null
      const shippingLabelUsages = usages?.store_billing_service_usages?.filter(usage => (usage?.billable_type === "ShippingLabel" && usage?.store_billing_service?.service_type != "RETURN_SHIPPING_LABEL")) || null
      let returnLabelUsages = usages?.store_billing_service_usages?.filter(usage => usage?.billable_type === "ShippingLabel" && usage?.store_billing_service?.service_type === "RETURN_SHIPPING_LABEL") || null
      let productValue = parseFloat(data?.value) || 0

      let orderTotalPrice = 0
      let shippingLabelTotalPrice = shippingLabelUsages === null ? null : shippingLabelUsages?.reduce((total, usage) => total + parseFloat(usage.billed_total_price), 0)

      let returnLabelTotalPrice = 0
      if (usages.length < 1 || warehouse.country == "BR") {
        returnLabelTotalPrice = 0
      } else {
        returnLabelTotalPrice = returnLabelUsages === null ? null : returnLabelUsages?.reduce((total, usage) => total + parseFloat(usage.billed_total_price), 0)
      }

      if (claim?.category === "MISSING_PRODUCTS" || claim?.category === "DAMAGED_PRODUCT") {
        if (data?.claimed_products_value !== null && !allProducts) {
          productValue = orderLines?.reduce((total, product) => {
            if (!product?.is_selected) {
              return total
            }
            const price = parseFloat(product?.price_per_item);
            const quantity = parseFloat(product?.reimbursable_quantity)
            if (isNaN(price) || isNaN(quantity)) {
              return total
            }
            return total + (price * quantity);
          }, 0) || 0
        } else if (data?.claimed_products_value === null && !allProducts) {
          productValue = orderLines?.reduce((total, product) => total + (product?.is_selected && parseFloat(product.price_per_item) ? ((parseFloat(product.price_per_item)) * parseFloat(product.reimbursable_quantity)) : 0), 0) || 0
        } else if (allProducts) {
          productValue = allProductsValue || 0
        }
        const pickAndPackUsage = usages?.store_billing_service_usages?.filter(usage => usage?.store_billing_service?.service_type === "PICK_AND_PACK") || null

        const pickAndPackPricePerItem = pickAndPackUsage === null ? null : parseFloat(pickAndPackUsage[0]?.billed_total_price) / parseFloat(pickAndPackUsage[0]?.billed_usage)

        const totalProducts = orderLines?.reduce((total, product) => total + (product?.is_selected ? (parseFloat(product.reimbursable_quantity)) : 0), 0) || 0

        orderTotalPrice = 0
        if (pickAndPackUsage != null && pickAndPackUsage.length > 0) orderTotalPrice += (totalProducts * pickAndPackPricePerItem)
        else orderTotalPrice += 0
      } else {
        if (!isPriceFromIntegration || data?.value < 1) productValue = allProductsValue || 0
        productValue = allProductsValue || 0
        orderTotalPrice = orderUsages === null ? null : orderUsages?.reduce((total, usage) => total + (parseFloat(usage.billed_total_price)), 0) || 0
      }
      productValue = productValue ? parseFloat(productValue) : 0

      const maxReimbursement = 30 * UMA_PRICE_APRIL_2024
      const proposedProductValue = productValue
      let maxProductValue = proposedProductValue
      if (warehouse.country == "MX") maxProductValue = Math.min(proposedProductValue, maxReimbursement)
      setUsagePrices(prevState => {
        const newState = {
          ...prevState,
          cost_cubbo: orderUsages === null ? null : orderTotalPrice.toFixed(2),
          cost_shipping: shippingLabelUsages === null ? null : shippingLabelTotalPrice.toFixed(2),
          cost_return_shipping: returnLabelUsages === null ? null : returnLabelTotalPrice.toFixed(2),
          product_value: maxProductValue?.toFixed(2)
        }

        const total = claim?.reimbursables.reduce((total, reimbursable) => {
          if (!isNaN(parseFloat(newState[reimbursable]))) {
            return total + parseFloat(newState[reimbursable])
          }
          return total
        }, 0)

        newState.total = total?.toFixed(2)

        setClaim({
          ...claim,
          accepted_value: total?.toFixed(2)
        })

        return newState
      })
    }
  }, [usages, claim?.reimbursables, claim?.category, orderLines, allProductsValue, allProducts, accepted])

  //TEMPORAL UNTIL BR BILLING
  const handleManualConceptsReimbursement = (reimbursable, value) => {
    setUsagePrices(prevState => {
      const newState = {
        ...prevState,
        [reimbursable]: value
      }

      const total = claim?.reimbursables.reduce((total, reimbursable) => {
        if (!isNaN(parseFloat(newState[reimbursable]))) {
          return total + parseFloat(newState[reimbursable])
        }
        return total
      }, 0)

      newState.total = total?.toFixed(2)

      setClaim({
        ...claim,
        accepted_value: total?.toFixed(2)
      })

      return newState
    })
  }

  const htmlMessageWithoutConceptPrices = () => {

    let html = "<div>"
    if (claim?.status === "ACCEPTED") {
      html = html + "<p>" + i18n.t("claims.html.accepted.message_initial") + "<b> #" + data.order.order_number + "</b> " + i18n.t("claims.html.accepted.category") + " <b>" + findClaimCategory(claim.area, claim.category) + "</b>, " + i18n.t("claims.html.accepted.apologies") + "</p>"
      let url = i18n.t("claims." + claim?.area?.toLowerCase() + "." + claim?.category?.toLowerCase() + ".url")
      if (claim?.reimbursables?.length > 0) {
        html = html + "<p>" + i18n.t("claims.html.accepted.resolution") + "<p>"
        html = html + "<ul class='list-disc space-y-1 pl-3'>"
        claim.reimbursables.forEach(element => {
          html = html + "<li> <b>"
          let translation = "claims.reimbursables." + element
          html = html + i18n.t(translation)
          html = html + "</b></li>"
        });
        html = html + "</ul> <br>"
      }
      html = html + "<p>" + i18n.t(findClaimCategoryRecomendation(claim.area, claim.category)) + "</p>"
      html = html + "<p> Cubbo.</p>"

    } else if (claim.status === "REJECTED") {

      html = html + "<p>" + i18n.t("claims.html.rejected.message_initial") + "<b> #" + data.order.order_number + "</b> " + i18n.t("claims.html.rejected.category") + " <b>" + findClaimCategory(claim.area, claim.category) + "</b>, " + i18n.t("claims.html.rejected.apologies") + "</p>"

      if (claim?.resolution) {
        html = html + "<p>" + i18n.t("claims.html.rejected.reazon") + " <b>" + claim?.resolution + "</b>"
      }

      if (images.length > 0) {
        html = html + ". " + i18n.t("claims.html.rejected.evidence") + "</p>"
      } else {
        html = html + ".</p>"
      }

      if (claim.recommendation) {
        html = html + "<p> " + i18n.t("claims.html.rejected.recommendation") + " <b>" + claim.recommendation + "</b>.</p>"
      }
      html = html + "<p> Cubbo.</p>"

      html = html + "</div>"
    }

    return html
  }

  const htmlMessage = useMemo(() => {
    let html = "<div>"
    if (claim?.status === "ACCEPTED") {
      html = html + "<p>" + i18n.t("claims.html.accepted.message_initial") + "<b> #" + data?.order?.order_number + "</b> " + i18n.t("claims.html.accepted.category") + " <b>" + findClaimCategory(claim?.area, claim?.category) + "</b>, " + i18n.t("claims.html.accepted.apologies") + "</p>"
      let url = i18n.t("claims." + claim?.area?.toLowerCase() + "." + claim?.category?.toLowerCase() + ".url")
      if (claim?.reimbursables?.length > 0) {
        html = html + "<p>" + i18n.t("claims.html.accepted.resolution") + "<p>"
        html = html + "<ul class='list-disc space-y-1 pl-3'>"
        html = html + "<div style='border-bottom: 1px solid black; display: flex; justify-content: space-between;'>"
        html = html + "<div>"
        html = html + "<b>" + "Concepto" + "</b>"
        html = html + "</div>"
        html = html + "<div style='margin-left: auto;'>"
        html = html + "<b>" + "Monto" + "</b>"
        html = html + "</div>"
        html = html + "</div>"
        claim?.reimbursables.forEach(element => {
          if (usagePrices[element] != null) {
            html = html + "<div style='border-bottom: 1px solid black; display: flex; justify-content: space-between;'>"
            html = html + "<div>"
            let translation = "claims.reimbursables." + element
            html = html + i18n.t(translation)
            html = html + "</div>"
            html = html + "<div style='margin-left: auto;'>"
            if (warehouse.country == "MX") html = html + "$" + (usagePrices[element] !== "" ? usagePrices[element] : "0") + " MXN"
            else html = html + "R$" + (usagePrices[element] !== "" ? usagePrices[element] : "0")
            html = html + "</div>"
            html = html + "</div>"
          } else {
            html = html + "<div style='border-bottom: 1px solid black; display: flex; justify-content: space-between;'>"
            html = html + "<div>"
            let translation = "claims.reimbursables." + element
            html = html + i18n.t(translation)
            html = html + "</div>"
            html = html + "<div style='margin-left: auto;'>"
            if (warehouse.country == "MX") html = html + "$" + "0" + " MXN"
            else html = html + "R$" + "0"
            html = html + "</div>"
            html = html + "</div>"
          }
        })
        html = html + "<div style='display: flex; justify-content: space-between;'>"
        html = html + "<div>"
        html = html + "<b>" + "Total" + "</b>"
        html = html + "</div>"
        html = html + "<div style='margin-left: auto;'>"
        if (warehouse.country == "MX") html = html + "<b>" + "$" + usagePrices["total"] + " MXN" + "</b>"
        else html = html + "<b>" + "R$" + usagePrices["total"] + "</b>"
        html = html + "</div>"
        html = html + "</div><br/>"
      }
      //html = html + "<p>" + i18n.t(findClaimCategoryRecomendation(claim.area, claim.category))+" <a href='"+url+"' target='_blank'>"+i18n.t("claims."+ claim.area.toLowerCase()+"."+claim.category.toLowerCase()+ ".url")+"/a></p>"
      html = html + "<p>" + i18n.t(findClaimCategoryRecomendation(claim?.area, claim?.category)) + "</p>"
      html = html + "<p> Cubbo.</p>"

    } else if (claim?.status === "REJECTED") {

      html = html + "<p>" + i18n.t("claims.html.rejected.message_initial") + "<b> #" + data?.order.order_number + "</b> " + i18n.t("claims.html.rejected.category") + " <b>" + findClaimCategory(claim?.area, claim?.category) + "</b>, " + i18n.t("claims.html.rejected.apologies") + "</p>"

      if (claim?.resolution) {
        html = html + "<p>" + i18n.t("claims.html.rejected.reazon") + " <b>" + claim?.resolution + "</b>"
      }

      if (images.length > 0) {
        html = html + ". " + i18n.t("claims.html.rejected.evidence") + "</p>"
      } else {
        html = html + ".</p>"
      }

      if (claim?.recommendation) {
        html = html + "<p> " + i18n.t("claims.html.rejected.recommendation") + " <b>" + claim?.recommendation + "</b>.</p>"
      }
      html = html + "<p> Cubbo.</p>"

      html = html + "</div>"
    }

    return html
  }, [claim, usagePrices, accepted])

  const validateInputs = () => {
    if (accepted === null) return true
    if (claim?.status === "ACCEPTED") {
      return false
    }
    if (claim?.status === "REJECTED" && claim?.resolution) {
      return false
    }
    return true
  }

  const allowacceptclaim = useMemo(() => {
    if (hasNoBilling) return true
    if (claim?.category === "ORIGIN_ERROR" || claim?.category === 0 || claim?.accepted_value < 1) return false
    return true
  }, [claim])

  const handleSelectedProducts = (orderLine, isChecked) => {
    const index = orderLines.findIndex(product => product.id === orderLine.id)
    if (index !== -1) {
      if (isChecked) {
        orderLines[index].reimbursable_quantity = 1
        orderLines[index].is_selected = true
      } else {
        orderLines[index].reimbursable_quantity = 0
        orderLines[index].is_selected = false
      }
      setOrderLines([...orderLines])
    }
  }

  const handleInputQuantityUpdate = (orderLine, value) => {
    const index = orderLines.findIndex(product => product.id === orderLine.id)
    if (index !== -1) {
      if (value === 0) {
        orderLines[index].reimbursable_quantity = 0
        orderLines[index].is_selected = false
      } else {
        orderLines[index].reimbursable_quantity = value
        orderLines[index].is_selected = true
      }
      setOrderLines([...orderLines])
    }
  }

  const handleAllProductsSelected = (value) => {
    if (value) {
      setAllProductsSelected(true)
      const orderLinesWithReimbursableQuantity = orderLines?.map(orderLine => ({
        ...orderLine,
        reimbursable_quantity: orderLine?.requested_quantity,
        is_selected: true
      }))
      setOrderLines(orderLinesWithReimbursableQuantity)
    } else {
      setAllProductsSelected(false)
      const orderLinesWithReimbursableQuantity = orderLines?.map(orderLine => ({
        ...orderLine,
        reimbursable_quantity: orderLine?.requested_quantity,
        is_selected: false
      }))
      setOrderLines(orderLinesWithReimbursableQuantity)
    }
  }

  const handleUpdateProductsPrices = (orderLine, price) => {
    const newProductsPricesList = orderLines?.map(product => {
      if (product.id === orderLine.id) {
        return {
          ...orderLine,
          price_per_item: price != null ? price : product.price_per_item
        }
      }
      return product
    })
    setOrderLines(newProductsPricesList)
  }

  const calculateSelectedProducts = useMemo(() => {
    const selectedProducts = orderLines?.reduce((total, orderLine) => total + (orderLine.is_selected ? 1 : 0), 0)
    return selectedProducts
  }, [orderLines])

  const getRefundAmount = (status, acceptedValue) => {
    if (status === "PENDING") return "Pendiente"
    else if (status === "REJECTED") {
      if (warehouse.country == "MX") return "$0.0 MXN"
      return "R$ 0.0"
    }
    if (warehouse.country == "MX") return `$${acceptedValue} MXN`
    return `R$ ${acceptedValue}`
  }

  return (
    <>

      <PageView
        topMenu={
          <PageTopBar>
            <h1 className="text-lg font-medium text-gray-900">
              <Link to={CLAIMS.replace(':warehouse_id', warehouse_id)} className="text-black hover:text-black">
                <LeftOutlined className="mr-3" />
              </Link>
              <label className="text-gray-300">Disputas {" ->"} </label>{" Resolviendo disputa de " + data?.order.store.name}</h1>
          </PageTopBar>
        }
      >
        {isLoading || isFetching || claim === null || usages === null || pricing === null ? (
          <><Loader show={true}></Loader></>
        ) : isError ? (
          <>Error: {error.message}</>
        ) : (
          <div >
            <div className="flex-1 flex items-stretch overflow-hidden">
              <main className="flex-1">
                <div className="card-container mr-3">
                  <Tabs type="card">
                    <Tabs.TabPane tab={<label className="text-base font-medium"> <span><FileExclamationOutlined /> </span>Información de la disputa</label>} key="item-1">
                      <div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-2 border-gray-300">
                            <dt className="text-sm font-medium ">
                              Disputas adicionales relacionadas con este pedido
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              {data?.related_claims?.length > 0 ? (
                                <>
                                  <div className="flex flex-row gap-10 mb-7">
                                    <div className="w-1/3 font-bold">Categoría</div>
                                    <div className="w-1/3 font-bold">Estado</div>
                                    <div className="w-1/3 font-bold">Reembolsado</div>
                                  </div>
                                  {data.related_claims.map((related_claim, index) => (
                                    <div className="flex flex-row gap-10 mb-7" key={index}>
                                      <div className="w-1/3">
                                        {i18n.t(`claims.motive_names.${related_claim?.category?.toLowerCase()}`)}
                                      </div>
                                      <div className="w-1/3">
                                        <StatusPill status={related_claim?.status} statuses={CLAIMS_STATUSES} />
                                      </div>
                                      <div className="w-1/3">
                                        {getRefundAmount(related_claim?.status, related_claim?.accepted_value)}
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div>No hay disputas adicionales relacionadas a este pedido</div>
                              )}
                            </dd>

                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium ">
                              Tienda
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              {data?.order.store.name}
                            </dd>

                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium">
                              Creada desde
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <FormattedDate
                                date={data?.created_at ? data.created_at : null} shortDate
                              />
                            </dd>
                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium">
                              Estatus
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <StatusPill
                                status={data?.status}
                                statuses={CLAIMS_STATUSES}
                              />
                            </dd>
                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium">
                              Valor solicitado
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              ${data?.requested_value || claim?.accepted_value}
                            </dd>
                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium">
                              Motivo
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              {data?.motive != null ? i18n.t("claims.motives." + data?.motive.toLowerCase()) : i18n.t("claims.messages.without_reason")}
                            </dd>
                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium">
                              Categoria
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              {findClaimCategory(claim.area, claim.category)}
                            </dd>
                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium">
                              Área
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                              {claim.status == 'PENDING' ? (
                                <SelectInput
                                  label=""
                                  options={areas}
                                  selectedInitial={findArea(claim.area)}
                                  value={findArea(claim.area)}
                                  onChange={(v) => {
                                    onChangeArea(v)
                                  }}
                                />
                              ) : (
                                <label>{findClaimAreaLabel(claim.area)}</label>
                              )}

                            </dd>
                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium">
                              Notas
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              {data.description}
                            </dd>
                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="">
                          <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <p className="text-sm font-medium">  Evidencia adjuntada </p>
                          </div>
                          <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 justify-items-center items-center">


                            {data.file_paths.map((file, index) => {

                              if (file.content_type === 'application/pdf' || file.content_type === 'application/xml') {
                                return (
                                  <Tooltip title={file.filename}>

                                    <a className="has-tooltip" href={process.env.REACT_APP_PLATFORM_URL + file.path} target="_blank">  <FileTextOutlined style={{ fontSize: '32px', color: '#08c' }} /> </a>
                                  </Tooltip>
                                )
                              } else {
                                return (

                                  <a href={process.env.REACT_APP_PLATFORM_URL + file.path} target="_blank">

                                    <div className="flex flex-row flex-wrap"
                                      className="w-32 h-32 m-2 bg-cover bg-no-repeat bg-center rounded shadow relative"
                                      style={{
                                        backgroundImage: `url('${process.env.REACT_APP_PLATFORM_URL + file.path}')`,
                                      }}
                                      key={index}
                                    ></div>
                                  </a>
                                )

                              }
                            }

                            )}


                          </div>
                          <div className="space-y-0 divide-y divide-gray-200">
                            <div className=""></div>
                          </div>

                        </div>


                      </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<label className="text-base font-medium"><span><CodeSandboxOutlined /> </span>Detalles del pedido reclamado</label>} key="item-2">
                      <div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium ">
                              No° de pedido
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              {data?.order.order_number}
                            </dd>

                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium ">
                              Creado desde
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              {new Intl.DateTimeFormat("es", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                                hour: 'numeric',
                                minute: 'numeric'
                              }).format(new Date(data.order.channel_created_at))}
                            </dd>

                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium ">
                              Estado de pedido
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <StatusPill
                                status={data.order.status}
                                statuses={ORDER_STATUSES}
                              />
                            </dd>

                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium ">
                              Última actualización en estado de pedido
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              {new Intl.DateTimeFormat("es", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                                hour: 'numeric',
                                minute: 'numeric'
                              }).format(new Date(data.order.channel_created_at))}
                            </dd>

                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium ">
                              Paquetería utilizada / No° de guía
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              {data.order.shipping_labels[0]?.shipping_method} / {data.order.shipping_labels[0]?.shipping_number}
                            </dd>

                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium ">
                              Estado de envío
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              {data.order.shipping_status ?
                                <StatusPill
                                  status={data.order.shipping_status}
                                  statuses={TRACKING_STATUSES}
                                />
                                :
                                <div className="italic"> Sin información </div>
                              }
                            </dd>

                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium ">
                              Última actualización en estado de envío
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              {new Intl.DateTimeFormat("es", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                                hour: 'numeric',
                                minute: 'numeric'
                              }).format(new Date(data.order.channel_created_at))}
                            </dd>

                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium ">
                              {i18n.t("orders.pod.title")}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <ProofOfDelivery order={data?.order?.id} proofs={data?.order?.proof_of_delivery} innerClassName="text-sm" />
                            </dd>

                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium ">
                              Información de envío
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <table>
                                <tbody>
                                  <tr>
                                    <td> Nombre:</td>
                                    <td className='text-gray-500'> {data.order.shipping?.first_name} {data.order.shipping?.last_name}{data.order.shipping?.last_name && <br />} </td>
                                  </tr>
                                  <tr>
                                    <td> Teléfono:</td>
                                    <td className='text-gray-500'> {data.order.shipping?.phone}{data.order.shipping?.phone && <br />} </td>
                                  </tr>
                                  <tr>
                                    <td> Email:</td>
                                    <td className='text-gray-500'> {data.order.shipping?.email}{data.order.shipping?.email && <br />} </td>
                                  </tr>
                                  <tr>
                                    <td> Dirección:</td>
                                    <td className='text-gray-500'> {data.order.shipping?.address_1} {data.order.shipping?.address_2}{data.order.shipping?.address_2 && <br />} </td>
                                  </tr>
                                  <tr>
                                    <td> País:</td>
                                    <td className='text-gray-500'> {data.order.shipping?.country}{data.order.shipping?.country && <br />} </td>
                                  </tr>
                                  <tr>
                                    <td> Estado:</td>
                                    <td className='text-gray-500'> {data.order.shipping?.state}{data.order.shipping?.state && <br />} </td>
                                  </tr>
                                  <tr>
                                    <td> Ciudad:</td>
                                    <td className='text-gray-500'> {data.order.shipping?.city}{data.order.shipping?.city && <br />} </td>
                                  </tr>
                                  <tr>
                                    <td> CP:</td>
                                    <td className='text-gray-500'> {data.order.shipping?.zip_code}{data.order.shipping?.zip_code && <br />} </td>
                                  </tr>
                                </tbody>

                              </table>
                            </dd>

                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium ">
                              Productos
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                {data?.order.order_lines.map((order_line) =>
                                  <li className="pl-3 pr-4 py-3 text-md" key={order_line.id}>
                                    <OrderLineItem
                                      orderLine={order_line}
                                    />
                                  </li>
                                )}
                              </ul>
                            </dd>

                          </div>
                          <div className="">

                          </div>
                        </div>
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium ">
                              Información de facturación
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <table>
                                <tr>
                                  <td> Nombre:</td>
                                  <td className='text-gray-500'> {data.order.billing?.first_name} {data.order.billing?.last_name}{data.order.billing?.last_name && <br />} </td>
                                </tr>
                                <tr>
                                  <td> Dirección:</td>
                                  <td className='text-gray-500'> {data.order.billing?.address_1} {data.order.billing?.address_2}{data.order.billing?.address_2 && <br />} </td>
                                </tr>
                                <tr>
                                  <td> País:</td>
                                  <td className='text-gray-500'> {data.order.billing?.country}{data.order.billing?.country && <br />} </td>
                                </tr>
                                <tr>
                                  <td> Estado:</td>
                                  <td className='text-gray-500'> {data.order.billing?.state}{data.order.billing?.state && <br />} </td>
                                </tr>
                                <tr>
                                  <td> Ciudad:</td>
                                  <td className='text-gray-500'> {data.order.billing?.city}{data.order.billing?.city && <br />} </td>
                                </tr>
                                <tr>
                                  <td> CP:</td>
                                  <td className='text-gray-500'> {data.order.billing?.zip_code}{data.order.billing?.zip_code && <br />} </td>
                                </tr>


                              </table>
                            </dd>

                          </div>
                          <div className="">

                          </div>
                        </div>
                        {data.picking_order && (
                          <div className="space-y-0 divide-y divide-gray-200">
                            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium ">
                                Picking
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <table>
                                  <tr>
                                    <td> Picker:</td>
                                    <td className='text-gray-500'>{data.order.picking_order.assigned_to?.first_name} {data.order.picking_order.assigned_to?.last_name}</td>
                                  </tr>
                                  <tr>
                                    <td>Asignado:</td>
                                    <td className='text-gray-500'><FormattedDate date={data.order.picking_order.assigned_at} /></td>
                                  </tr>
                                  <tr>
                                    <td>Iniciado:</td>
                                    <td className='text-gray-500'><FormattedDate date={data.order.picking_order.tote_assigned_at} /></td>
                                  </tr>
                                  <tr>
                                    <td>Tote:</td>
                                    <td className='text-gray-500'>{data.order.picking_order.tote?.unique_code}</td>
                                  </tr>
                                  <tr>
                                    <td>Completado:</td>
                                    <td className='text-gray-500'><FormattedDate date={data.order.picking_order.completed_at} /></td>
                                  </tr>
                                </table>
                              </dd>

                            </div>
                            <div className="">

                            </div>
                          </div>
                        )}
                        <div className="space-y-0 divide-y divide-gray-200">
                          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium ">
                              Packing
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <table>
                                <tr>
                                  <td> Packer:</td>
                                  <td className='text-gray-500'> {data.order.shipping_labels[0]?.created_by?.first_name} {data.order.shipping_labels[0]?.created_by?.last_name}</td>
                                </tr>
                                <tr>
                                  <td>Guía impresa:</td>
                                  <td className='text-gray-500'><FormattedDate date={data.order.shipping_date} /></td>
                                </tr>
                              </table>
                            </dd>

                          </div>
                          <div className="">

                          </div>
                        </div>
                      </div>
                    </Tabs.TabPane>
                  </Tabs>
                </div>
              </main>
              <main className="flex-1">
                <p className="mt-1 mb-4 text-base font-medium">Resolver Disputa</p>
                <main className="flex-1 bg-white h-full p-8">
                  {data.status === "PENDING" ? (

                    <Timeline>
                      <Timeline.Item color="gray">
                        <p className="font-semibold">Categoría de disputa definida con base en el problema seleccionado por la marca</p>
                        {data && selectedCategory !== null && data?.category && (
                          <SelectInput
                            label=""
                            options={categories}
                            value={findCategory(data.category)}
                            onChange={(v) => {
                              handleChangeCategory(v)
                            }}
                          />
                        )}
                      </Timeline.Item>
                      <Timeline.Item color="gray">
                        <p className="font-semibold mt-3"> <span className="text-red-500"> *</span>¿Cómo deseas proceder?</p>
                        <div className="mt-1 flex ">

                          <label className="inline-flex items-center">
                            <input
                              type="radio"
                              className="form-radio"
                              value="REJECTED"
                              checked={accepted === false}
                              onChange={(e) =>
                                onAcceptanceRadioChange(e.target.value)
                              }
                            />
                            <span className="ml-2">Rechazar disputa</span>
                          </label>

                          {allowacceptclaim ? (

                            <label className="inline-flex items-center ml-6">
                              <input
                                type="radio"
                                className="form-radio"
                                value="ACCEPTED"
                                checked={accepted === true}
                                onChange={(e) =>
                                  onAcceptanceRadioChange(e.target.value)
                                }
                              />
                              <span className={"ml-2 " + (true ? "" : "text-gray-400")}>Aceptar disputa</span>
                            </label>
                          ) : (<p></p>)}


                        </div>
                      </Timeline.Item>
                      {accepted === true ? (
                        <>
                          {reimbursables.length > 0 &&

                            <Timeline.Item color="gray">
                              <p className="font-semibold mt-5">Conceptos considerados para el reembolso</p>
                              {reimbursables.map((reimbursable, index) => {
                                //if (warehouse.country === "BR" && reimbursable !== "product_value") {
                                //  return null
                                //}

                                return (
                                  <>
                                    <Checkbox
                                      key={index}
                                      disabled={true}
                                      checked={claim?.reimbursables.includes(reimbursable)}
                                      label={i18n.t("claims.reimbursables." + reimbursable)}
                                      onChange={(isChecked) => {
                                        addReimbursables(reimbursable)
                                      }}
                                    />

                                    {hasNoBilling && (
                                      (claim?.category === "DAMAGED_PRODUCT" || claim?.category === "MISSING_PRODUCTS") ?
                                        (
                                          reimbursable !== "product_value" && (
                                            <CurrencyInputField
                                              icon={<TagIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
                                              required
                                              className="sm:col-span-6 max-h-5 w-[70%] mb-10 mt-4 text-sm pr-7"
                                              onChange={(value) => handleManualConceptsReimbursement(reimbursable, value)}
                                              value={usagePrices[reimbursable]}
                                            />
                                          )
                                        )
                                        :
                                        (
                                          <CurrencyInputField
                                            icon={<TagIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
                                            required
                                            className="sm:col-span-6 max-h-5 w-[70%] mb-10 mt-4 text-sm pr-7"
                                            onChange={(value) => handleManualConceptsReimbursement(reimbursable, value)}
                                            value={usagePrices[reimbursable]}
                                          />

                                        )
                                    )
                                    }
                                  </>
                                )
                              })}

                              {
                                (((claim?.category === "MISSING_PRODUCTS" && claim?.area === "DELIVERY") || claim?.category === "DAMAGED_PRODUCT")) ? (
                                  <div className="mt-2">
                                    <p className="font-semibold"><span className="text-red-500"> *</span> Costo de producto</p>
                                    <div className="mt-1 flex ">

                                      <label className="inline-flex items-center">
                                        <input
                                          type="radio"
                                          className="form-radio"
                                          value="ACCEPTED"
                                          checked={allProducts === true}
                                          onChange={(e) =>
                                            onAllProductsRadioChange(e.target.value)
                                          }
                                        />
                                        <span className="ml-2">Todos los productos</span>
                                      </label>

                                      <label className="inline-flex items-center ml-6">
                                        <input
                                          type="radio"
                                          className="form-radio"
                                          value="REJECTED"
                                          checked={allProducts === false}
                                          onChange={(e) =>
                                            onAllProductsRadioChange(e.target.value)
                                          }

                                        />
                                        <span className={"ml-2 " + (true ? "" : "text-gray-400")}>Algunos productos</span>
                                      </label>

                                    </div>
                                    <div className="flex gap-20 mt-5">
                                      <p>Productos seleccionados: {calculateSelectedProducts}</p>
                                      <p>{`Monto de productos a reembolsar: ${warehouse.country == "MX" ? `$${usagePrices["product_value"]} MXN` : `R$${usagePrices["product_value"]}`}`}</p>
                                    </div>

                                    {allProducts && (

                                      <CurrencyInputField
                                        icon={<TagIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                        required
                                        help={warehouse.country === "MX" && "IMPORTANTE: Monto sin IVA"}
                                        className="sm:col-span-6 max-h-5 w-[3%] pb-10 mb-10"
                                        onChange={_value => setAllProductsValue(_value)}
                                        value={allProductsValue}
                                      />
                                    )}

                                    {!allProducts && (
                                      <>
                                        <div className="flex">
                                          <SearchIcon className="h-5 w-5 text-gray-400 mt-4" aria-hidden="true" />
                                          <div className="border-b border-gray-400 mb-4 ml-2 w-[100%]">
                                            <input
                                              type="text"
                                              className="block w-full border-none focus:outline-none bg-gray-50 text-sm"
                                              placeholder="Buscar por nombre o SKU"
                                              onChange={(e) => handleFilter(e.target.value)}
                                            />
                                          </div>

                                        </div>

                                        <table className="w-full">
                                          <thead className="border-b">
                                            <tr>
                                              <th className="w-2/6">
                                                <div className="flex items-center">
                                                  <Checkbox
                                                    checked={allProductsSelected}
                                                    onChange={(isChecked) => handleAllProductsSelected(isChecked)}
                                                  />
                                                  <div className="ml-2 flex flex-col">
                                                    <p className="mb-1">
                                                      Nombre
                                                    </p>
                                                    <p className="text-gray-500 font-medium">
                                                      SKU
                                                    </p>
                                                  </div>
                                                </div>
                                              </th>
                                              <th className="w-2/6">Precio</th>
                                              <th className="w-1/6">
                                                <div className="flex flex-col">
                                                  <p>
                                                    Solicitados
                                                  </p>
                                                  <p className="-mt-2 text-gray-500 font-medium">
                                                    Enviados
                                                  </p>
                                                </div>
                                              </th>
                                              <th className="w-1/6">Reembolsados</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {orderLines?.map((order_line, index) => {
                                              const isChecked = order_line?.is_selected
                                              const inputValue = order_line?.reimbursable_quantity
                                              const price = order_line?.price_per_item
                                              const matchesFilter = order_line?.matches_filter
                                              if (matchesFilter) {
                                                return (
                                                  <tr key={index}>
                                                    <td>
                                                      <div className="flex items-center">
                                                        <Checkbox
                                                          key={index}
                                                          checked={isChecked}
                                                          onChange={(isChecked) => handleSelectedProducts(order_line, isChecked)}

                                                        />
                                                        <div className="ml-2 flex flex-col">
                                                          <span className="ml-2">
                                                            {order_line?.product?.name}
                                                          </span>
                                                          {
                                                            order_line?.sku && (
                                                              <span className="px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-blue-100 text-blue-800">
                                                                {order_line?.sku}
                                                              </span>
                                                            )
                                                          }
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <CurrencyInputField
                                                        icon={<TagIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                                        required
                                                        className="sm:col-span-6 max-h-5 w-[70%] mb-10 mt-4 text-sm pr-7"
                                                        onChange={(value) => handleUpdateProductsPrices(order_line, value)}
                                                        help={warehouse.country == "MX" && "IMPORTANTE: Monto sin IVA"}
                                                        value={!isPriceFromIntegration ? (price ? price : 0) : (price ? price : "")}
                                                        isDisabled={!isChecked}
                                                      />
                                                    </td>
                                                    <td>
                                                      <div className="flex flex-col">
                                                        <p>
                                                          {order_line?.requested_quantity} solicitados
                                                        </p>
                                                        <p className="text-gray-500">
                                                          {order_line?.quantity} enviados
                                                        </p>
                                                      </div>
                                                    </td>
                                                    <td><InputQuantity initialValue={inputValue} updateData={(value) => { handleInputQuantityUpdate(order_line, value) }} maxValue={order_line?.quantity} /></td>
                                                  </tr>
                                                )

                                              }
                                              else {
                                                return null
                                              }
                                            }
                                            )}
                                          </tbody>
                                        </table>
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  reimbursables?.includes("product_value") && (
                                    !hasNoBilling && (
                                      <CurrencyInputField
                                        icon={<TagIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                        required
                                        help={warehouse.country === "MX" && "IMPORTANTE: Monto sin IVA"}
                                        className="sm:col-span-6 max-h-5 w-[3%] pb-10 mb-10 mt-3"
                                        onChange={_value => setAllProductsValue(_value)}
                                        value={allProductsValue}
                                      />

                                    )
                                  )
                                )

                              }

                            </Timeline.Item>
                          }


                        </>
                      ) : accepted === false ? (
                        <>
                          <Timeline.Item color="gray">
                            <p className="font-semibold mt-3">Completa el mensaje que se mostrará a la tienda una vez resuelta la disputa</p>
                            <label> <span className="text-red-500"> *</span> El motivo del rechazo se debe a que...</label>
                            <TextareaField
                              onChange={(val) => {
                                setClaim({
                                  ...claim,
                                  resolution: val,
                                })

                              }} />
                            <p className="mt-6 mb-0">Te compartimos la evidencia que respalda la decisión del rechazo:</p>
                            <ImageInputField

                              onChange={(files) => filesChangeHandler(files)}
                              onError={(error, file, message) => setImageError(message)}
                            />
                            {imageError && (
                              <div className="text-red-500 text-center italic mt-2">
                                {imageError}
                              </div>
                            )}
                            <div className="flex flex-wrap justify-center">
                              {images.map((image) => (
                                <div
                                  className="w-32 h-32 m-2 bg-cover bg-no-repeat bg-center rounded shadow relative"
                                  style={{
                                    backgroundImage: `url('${image.preview?.url}')`,
                                  }}
                                  key={String(image.id)}
                                >
                                  <div
                                    onClick={() => removeImage(image.id)}
                                    className="absolute -right-1 -top-1 rounded-full bg-white border border-gray-600 p-1 cursor-pointer"
                                  >
                                    <TrashIcon className="h-4 w-4 text-gray-600" />
                                  </div>
                                </div>
                              ))}
                            </div>
                            <p className="mt-6 mb-0">Para brindar la mejor experiencia posible a tu cliente, te recomendamos...</p>
                            <TextareaField
                              onChange={(val) => {
                                setClaim({
                                  ...claim,
                                  recommendation: val,
                                })
                              }} />
                          </Timeline.Item>
                        </>
                      ) : (<></>)}
                      {accepted != null &&
                        <Timeline.Item color="gray">
                          <p className="mt-5 font-semibold">Mensaje que recibirá la marca una vez resuelta la disputa</p>

                          <div>{ReactHtmlParser(htmlMessage)}</div>

                        </Timeline.Item>
                      }
                      <Timeline.Item color="gray">
                        <Button type="primary" className="mt-3" disabled={validateInputs()} onClick={() => { setShowSubmitConfirmDialog(true) }}>
                          Guardar Resolución
                        </Button>
                      </Timeline.Item>
                    </Timeline>
                  ) : (
                    <>
                      <p className="mb-14 text-base font-medium">¡Disputa resuelta!</p>
                      <Timeline>
                        <Timeline.Item color="gray">
                          <p className="font-semibold">Categoría de disputa definida con base en el problema seleccionado por la marca</p>
                          {findClaimCategory(claim.area, claim.category)}
                        </Timeline.Item>

                        <Timeline.Item color="gray">
                          <p className="font-semibold">Resolución</p>
                          {data.status == "ACCEPTED" ? <label>{i18n.t("claims.status.accepted")} <label><b>Valor aceptado: </b> ${data.accepted_value}</label></label> : i18n.t("claims.status.rejected")}
                        </Timeline.Item>
                        <Timeline.Item color="gray">
                          <p className="font-semibold">Mensaje enviado a la marca</p>
                          <div>
                            {
                              claim?.claim_message != null
                                ?
                                ReactHtmlParser(claim?.claim_message)
                                :
                                ReactHtmlParser(htmlMessageWithoutConceptPrices())
                            }

                          </div>
                        </Timeline.Item>
                        {data.resolutions_file_paths.length > 0 &&
                          <Timeline.Item color="gray">
                            <p className="font-semibold">Evidencia adjunta</p>
                            <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">

                              {data.resolutions_file_paths.map((file, index) => (

                                <div className="flex flex-row flex-wrap"
                                  className="w-32 h-32 m-2 bg-cover bg-no-repeat bg-center rounded shadow relative"
                                  style={{
                                    backgroundImage: `url('${process.env.REACT_APP_PLATFORM_URL + file.path}')`,
                                  }}
                                  key={index}
                                >
                                </div>

                              ))}


                            </div>
                          </Timeline.Item>
                        }
                      </Timeline>
                    </>
                  )}
                </main>
              </main>
            </div>

          </div>
        )}
      </PageView>
      <Notification
        show={showNotification}
        setShow={setShowNotification}
        type="success"
        title={i18n.t("claims.messages.success_message")}
      />
      <Notification
        show={showUpdateNotification}
        setShow={setShowUpdateNotification}
        type="success"
        title={i18n.t("claims.messages.success_update_message")}
      />
      <Notification
        show={showNotificationError}
        setShow={setShowNotificationError}
        type="error"
        title={i18n.t("claims.messages.error_message")}
      />
      <ConfirmDialog
        open={showSubmitConfirmDialog}
        setOpen={setShowSubmitConfirmDialog}
        title="Confirma Guardar Disputa"
        confirmLabel="Confirmar"
        cancelLabel="Cancel"
        onConfirm={() => onSubmit()}
        loading={loadingSubmitClaim}
      />
    </>
  )
}
