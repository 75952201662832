import React, { useEffect, useMemo, useState } from 'react'
import { DialogView } from '../DialogView'
import { Loader } from '../Loader'
import { Button } from '../Button'
import { createShippingIncidents, resolveShippingIncidents,reviewShippingIncidents, resetShippingIncidents, updateShippingStatus } from '../../services'
import { useTranslation } from 'react-i18next'
import { QuestionMarkCircleIcon } from '@heroicons/react/outline'
import { cloneDeep } from 'lodash'
import { Tooltip } from 'antd'
import { useRef } from 'react'
import { INCIDENT_STATUSES } from './IncidentStatusDefinitions'

export const ACTIONS = {
  CREATE_INCIDENT: 'CREATE_INCIDENT',
  RESOLVE_INCIDENT: 'RESOLVE_INCIDENT',
  UPDATE_STATUS_INCIDENT: 'UPDATE_STATUS_INCIDENT',
  UPDATE_SHIPPING_STATUS: 'UPDATE_SHIPPING_STATUS',
}

// const ACTION_MODAL_STATES = {
//   CONFIRMATION: 'CONFIRMATION',

export const IncidentActionModal = ({ open = false, action = null, orders, onCancel = () => {}, onSuccess = () => {}, actionParameters = {} }) => {
  const { i18n } = useTranslation()

  const [visible, setVisible] = useState(open)
  const [busy, setBusy] = useState(false)
  const [hasConfirmed, setHasConfirmed] = useState(false)
  const [unexpectedErrorMessage, setUnexpectedErrorMessage] = useState(null)
  const [ordersWithErrors, setOrdersWithErrors] = useState(null)

  const modalMainContainerRef = useRef(null)

  useEffect(() => {
    setVisible(open)
    if (open === true) {
      reset()
    }
  }, [open])

  const handleClose = () => {
    if (busy) return
    onCancel()
  }

  const reset = () => {
    setHasConfirmed(false)
    setUnexpectedErrorMessage(null)
    setOrdersWithErrors(null)
  }

  const onActionConfirm = async (ordersToProcess = null) => {
    setBusy(true)
    setUnexpectedErrorMessage(null)
    if (!ordersToProcess) ordersToProcess = orders
    try {
      switch (action) {
        case ACTIONS.CREATE_INCIDENT:
          await onCreateIncidents(ordersToProcess.map(order => order.id))
          break
        case ACTIONS.RESOLVE_INCIDENT:
          await onResolveIncidents(ordersToProcess.map(order => {
            return order.shipping_incidents.filter(({status}) => status !== "RESOLVED").map(shipping_incident => shipping_incident.id)
          }))
          break
        case ACTIONS.UPDATE_STATUS_INCIDENT:
          await onUpdateStatusIncidents(ordersToProcess.map(order => {
            return order.shipping_incidents.map(shipping_incident => shipping_incident.id)
          }))
          break
        case ACTIONS.UPDATE_SHIPPING_STATUS:
          await onUpdateShippingStatus(ordersToProcess.map(order => order.id))
          break
        default:
          console.warn('No action selected')
          break
      }
    } catch (error) {
      console.log(error)
      if (error.response?.status === 500) {
        setUnexpectedErrorMessage(error.response?.data?.error)
      } else if (error.response?.status === 422) {
        if(error.response?.data?.error){
          setUnexpectedErrorMessage(error.response?.data?.error)
        }else{
          handleIncidentErrors(error.response?.data?.errors)
        }
      } else {
        setUnexpectedErrorMessage(error.message)
      }
    }
    setBusy(false)
  }

  const handleIncidentErrors = errors => {
    // console.log(errors)
    const errorsThatAreStrings = errors.filter(error => typeof error === 'string')
    if (errorsThatAreStrings.length > 0) {
      setUnexpectedErrorMessage(errorsThatAreStrings[0])
      return
    }
    let newOrdersWithErrors = []
    let orderIdsWithError = errors.map(error => error.context?.order_id).filter(order_id => order_id !== undefined);
    let incidentsIdsWithError = errors.map(error => error.context?.shipping_incident_id).filter(shipping_incident_id => shipping_incident_id !== undefined);
    if(orderIdsWithError.length){
      newOrdersWithErrors = orders.filter(order => orderIdsWithError.includes(order.id))
      newOrdersWithErrors = newOrdersWithErrors.map(order => {
        return {
          ...cloneDeep(order),
          error: errors.find(error => error.context?.order_id === order.id),
        }
      })
    }else if (incidentsIdsWithError.length) {
      newOrdersWithErrors = orders.filter(order => {
        let incidentsIds = order.shipping_incidents.map(incident => incident.id)
        return incidentsIdsWithError.some(incidentsIdWithError => incidentsIds.includes(incidentsIdWithError));
      })
      newOrdersWithErrors = newOrdersWithErrors.map(order => {
        return {
          ...cloneDeep(order),
          error: errors.find(error => {
            let incidentsIds = order.shipping_incidents.map(incident => incident.id)
            return incidentsIds.includes(error.context?.shipping_incident_id)
          
          }),
        }
      })
    }
    if (action == ACTIONS.RESOLVE_INCIDENT || action == ACTIONS.UPDATE_STATUS_INCIDENT ){
      let OrdersWithErrors = orders.filter(order => !order.shipping_incidents.length)
      OrdersWithErrors = OrdersWithErrors.map(order => {
        return {
          ...cloneDeep(order),
          error:{code: "NO_INCIDENT_CREATED"},
        }
      })
      newOrdersWithErrors = newOrdersWithErrors.concat(OrdersWithErrors);
    }
    setHasConfirmed(true)
    setOrdersWithErrors(newOrdersWithErrors)
  }

  const onCreateIncidents = async orderIds => {
    let newIncidents = await createShippingIncidents(orderIds, actionParameters?.category)
    onSuccess(newIncidents)
  }
  const onUpdateShippingStatus = async orderIds => {
    let updatedOrders = await updateShippingStatus(orderIds, actionParameters?.status, actionParameters?.description)
    onSuccess(updatedOrders)
  }

  const onResolveIncidents = async incidentsIds => {
    let resolvedIncidents = await resolveShippingIncidents(incidentsIds.flat(), actionParameters?.resolution)
    onSuccess(resolvedIncidents)
  }

  const onUpdateStatusIncidents = async incidentsIds => {
    let updatedIncidents = []
    if(actionParameters?.status == INCIDENT_STATUSES.PENDING){
      updatedIncidents = await resetShippingIncidents(incidentsIds.flat())
    }else if (actionParameters?.status == INCIDENT_STATUSES.IN_REVIEW) {
      updatedIncidents = await reviewShippingIncidents(incidentsIds.flat())
    }
    onSuccess(updatedIncidents)
  }

  const ConfirmationTab = () => {
    const confirmationTitle = useMemo(() => {
      switch (action) {
        case ACTIONS.CREATE_INCIDENT:
          return i18n.t('orders.list.create_incident')
        case ACTIONS.RESOLVE_INCIDENT:
          return i18n.t('orders.list.resolve_incident')
        case ACTIONS.UPDATE_STATUS_INCIDENT:
          return i18n.t('orders.list.update_status_incident')
        case ACTIONS.UPDATE_SHIPPING_STATUS:
          return i18n.t('orders.list.update_shipping_status')
        default:
          return ''
      }
    }, [action, i18n.language])
    const confirmationMessage = useMemo(
      () =>
        i18n
          .t('orders.shipping_incident.errors.error_modal.confirmations.' + action)
          .replace('{shipping_status}', i18n.t('statuses.tracking_statuses.' + actionParameters.status))
          .replace('{incident_type}', i18n.t('orders.shipping_incident.category.' + actionParameters.category))
          .replace('{status}', i18n.t('orders.shipping_incident.status.' + actionParameters.status))
          .replace('{order_count}', orders?.length),
      [action, i18n.language, actionParameters],
    )

    return (
      <div className="max-w-sm text-gray-500">
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
          <QuestionMarkCircleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <div className="text-lg leading-6 font-medium text-gray-900">{confirmationTitle}</div>
          <div className="mt-2">
            <div className="text-base">{confirmationMessage}</div>
          </div>
        </div>
        {unexpectedErrorMessage && <div className="text-red-500 pt-2 w-full text-center">{unexpectedErrorMessage}</div>}
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <Button onClick={() => handleClose()} type="secondary">
            {i18n.t('dialog.no')}
          </Button>
          <Button onClick={() => onActionConfirm()} type="primary">
            {i18n.t('dialog.yes')}
          </Button>
        </div>
      </div>
    )
  }

  // const OrderErrorRow = ({ order }) => {
  //   // console.log(order)
  //   const labelToShow = order.shipping_labels?.reduce((prevShippingLabel, shippingLabel) => {
  //     return shippingLabel.id > prevShippingLabel.id ? shippingLabel : prevShippingLabel
  //   })
  //   return (
  //     <div>
  //       <div>
  //         {order.order_number} - {labelToShow?.shipping_number}
  //       </div>
  //       <div className="text-red-500">{i18n.t(`orders.shipping_incident.errors.create.${order.error?.code}`)}</div>
  //     </div>
  //   )
  // }

  const getRelevantShippingLabelFromOrder = order => {
    return order.shipping_labels?.reduce((prevShippingLabel, shippingLabel) => {
      return shippingLabel.id > prevShippingLabel.id ? shippingLabel : prevShippingLabel
    })
  }

  const OmitErrorsConfirmationTab = () => {
    const validOrders = useMemo(() => orders.filter(order => !ordersWithErrors?.map(order => order.id).includes(order.id)), [ordersWithErrors])
    const ordersWithErrorsByCode = useMemo(
      () =>
        ordersWithErrors?.reduce((acc, order) => {
          acc[order.error?.code] = (acc[order.error?.code] || []).concat(order)
          return acc
        }, {}),
      [ordersWithErrors],
    )
    console.log(ordersWithErrorsByCode)
    // const countTotalOrdersWithErrors = useMemo(() => {
    //   if (!ordersWithErrorsByCode) return []
    //   Object.values(ordersWithErrorsByCode).reduce((acc, orders) => acc + orders.length, 0)
    // }, [ordersWithErrorsByCode])

    return (
      <>
        <div className="text-red-500 font-bold text-xl text-center">
          {i18n.t(`orders.shipping_incident.errors.error_modal.messages.action_not_possible`).replace('{quantity}', orders?.length).replace('{quantity_errors}', ordersWithErrors?.length)}
        </div>

        {/* <div className="max-h-60vh overflow-y-auto">
          {ordersWithErrors?.length > 0 && ordersWithErrors.map(order => <OrderErrorRow order={order} key={order.id} />)}
        </div> */}

        <div className="flex justify-center my-8">
          <table className="text-lg">
            <thead>
              <tr className="font-semibold">
                <td>{i18n.t(`generic.error`)}</td>
                <td className="text-right pl-4">{i18n.t(`generic.orders`)}</td>
              </tr>
            </thead>
            <tbody>
              {ordersWithErrorsByCode &&
                Object.entries(ordersWithErrorsByCode)?.map(([errorCode, orders]) => {
                  return (
                    <tr>
                      <td>{i18n.t(`orders.shipping_incident.errors.codes.${errorCode}`)}</td>
                      <td className="text-right">{orders.length}</td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
        <div className="text-lg font-bold text-center">{i18n.t(`orders.shipping_incident.errors.error_modal.messages.omit_errors_and_submit_question`)}</div>
        <div className="flex justify-center my-8">
          <div className="sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <Button onClick={() => handleClose()} type="secondary">
              {i18n.t('dialog.no')}
            </Button>
            {validOrders?.length === 0 ? (
              <Button disabled type="primary">
                <Tooltip
                  title={i18n.t('orders.shipping_incident.errors.error_modal.messages.no_valid_elements_tooltip')}
                  getPopupContainer={() => modalMainContainerRef?.current}
                >
                  {i18n.t('orders.shipping_incident.errors.error_modal.confirmations.no_valid_elements')}
                </Tooltip>
              </Button>
            ) : (
              <Button onClick={() => onActionConfirm(validOrders)} type="primary">
                {i18n.t('orders.shipping_incident.errors.error_modal.confirmations.omit_errors_and_submit')}
              </Button>
            )}
          </div>
        </div>
        <div className="">
          <div className="mb-2 text-lg text-center font-semibold">
            {i18n.t(`orders.shipping_incident.errors.error_modal.messages.order_labels_with_errors`)}
          </div>
          <div className="max-h-32 overflow-y-auto text-justify">
            {ordersWithErrors?.map((order, index) => (
              <span key={index}>
                {getRelevantShippingLabelFromOrder(order)?.shipping_number}
                {index !== ordersWithErrors.length - 1 && <>, </>}
              </span>
            ))}
          </div>
        </div>
      </>
    )
  }

  return (
    <DialogView open={visible} setOpen={handleClose}>
      <div className="relative" ref={modalMainContainerRef}>
        <Loader show={busy} className="-inset-2" />
        {hasConfirmed ? <OmitErrorsConfirmationTab /> : <ConfirmationTab />}
      </div>
    </DialogView>
  )
}
