
import {useHistory, Link,useParams} from "react-router-dom";
import { useState, useMemo, useContext } from "react"
import {useQuery} from "react-query";
import {fetchChartPendingOrders} from "../../services";
import { StatusPill } from '../../components/BasicTable/StatusPill'
import { REPLENISHMENT_STATUSES } from '../../components/BasicTable/StatusPillDefinitions';
import {BasicTable, FormattedDate, PageView, SlidePanel, TableHeader, Loader,PageTopBar} from "../../components";
import {UserContext} from "../../hooks/UserContext";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export const Home = () => {
    const {warehouse_id} = useParams()
    const {user} = useContext(UserContext)
    const [searchParams, setSearchParams] = useState({
        warehouse_id:warehouse_id
    })
    const { data, status, isLoading, isError, error } = useQuery(["chart_total_pending_orders",searchParams], () => fetchChartPendingOrders(searchParams), { keepPreviousData: true })
    
    const getChartCategories = () => {
        return data.pending.map(function (obj) {
            return obj.store_name;
        })
    }
    
    const getChartSeriesData = () => {
        let series = []
        // Object.keys(data).forEach(key => series.push(data[key]));
        return data.pending.map(function (obj) {
            return obj.orders;
        })

        //return series
    }

    const getErrorChartCategories = () => {
        return data.missing_info.map(function (obj) {
            return obj.store_name;
        })
    }

    const getErrorChartSeriesData = () => {
        let series = []
        // Object.keys(data).forEach(key => series.push(data[key]));
        return data.missing_info.map(function (obj) {
            return obj.orders;
        })

        //return series
    }

    const getProcessingChartCategories = () => {
        return data.processing.map(function (obj) {
            return obj.store_name;
        })
    }

    const getProcessingChartSeriesData = () => {
        let series = []
        // Object.keys(data).forEach(key => series.push(data[key]));
        return data.processing.map(function (obj) {
            return obj.orders;
        })

        //return series
    }

    const getBackOrderChartCategories = () => {
        return data.backorder.map(function (obj) {
            return obj.store_name;
        })
    }

    const getBackOrderChartSeriesData = () => {
        let series = []
        // Object.keys(data).forEach(key => series.push(data[key]));
        return data.backorder.map(function (obj) {
            return obj.orders;
        })

        //return series
    }
    
    const getChartOptions = () => {

        const options = {
            chart: {
                type: 'bar',
                height: '550px',
            },
            xAxis: {
                categories: getChartCategories(),
              },
              yAxis: {
                min: 0,
                title: {
                    text: 'Número de pedidos',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            plotOptions: {
                bar: {
                  dataLabels: {
                    enabled: true
                  }
                }
            },
            title: {
              text: 'Pedidos Pendientes'
            },
            series: [{
            name: 'Pendientes',
              data: getChartSeriesData()
            }]
          }

        return options
    }

    const getProcessingChartOptions = () => {
        const options = {
            chart: {
                type: 'bar',
                height: '550px',
            },
            xAxis: {
                categories: getProcessingChartCategories(),
              },
              yAxis: {
                min: 0,
                title: {
                    text: 'Número de pedidos en Procesando',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            plotOptions: {
                bar: {
                  dataLabels: {
                    enabled: true
                  }
                }
            },
            colors:['#ccc7c7'],
            title: {
              text: 'Pedidos en Procesando'
            },
            series: [{
            name: 'Procesando',
              data: getProcessingChartSeriesData()
            }]
          }

        return options
    }

    const getWaitingPickUpChartOptions = () => {
        const options = {
            chart: {
                type: 'bar',
                height: '550px',
            },
            xAxis: {
                categories: data.waiting_pick_up.map(({carrier_name}) => carrier_name),
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Número de pedidos en Esperando Recolección',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            colors:['#3b82f6'],
            title: {
                text: 'Pedidos en Esperando Recolección'
            },
            series: [{
                name: 'Esperando Recolección',
                data: data.waiting_pick_up.map(({orders}) => orders)
            }]
        }

        return options
    }

    const getTotalPendingCharts = () => {
        let total = 0
        data.pending.forEach(function (obj) {
            total = total + obj.orders;
        })

        return total
    }

    const getTotalWaitingPickUpCharts = () => data.waiting_pick_up.reduce((total, {orders}) => total + orders, 0)

    const getTotalProcessingCharts = () => {
        let total = 0
        data.processing.forEach(function (obj) {
            total = total + obj.orders;
        })

        return total
    }

    const getErrorChartOptions = () => {

        const options = {
            chart: {
                type: 'bar',
                height: '400px',
            },
            xAxis: {
                categories: getErrorChartCategories(),
              },
              yAxis: {
                min: 0,
                title: {
                    text: 'Número de pedidos con Falta Información',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            plotOptions: {
                bar: {
                  dataLabels: {
                    enabled: true
                  }
                }
            },
            colors:['#f79191'],
            title: {
              text: 'Pedidos Falta Información'
            },
            series: [{
            name: 'Falta Información',
              data: getErrorChartSeriesData()
            }]
          }

        return options
    }

    const getBackOrderChartOptions = () => {

        const options = {
            chart: {
                type: 'bar',
                height: '400px',
            },
            xAxis: {
                categories: getBackOrderChartCategories(),
              },
              yAxis: {
                min: 0,
                title: {
                    text: 'Número de pedidos Sin Stock',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            plotOptions: {
                bar: {
                  dataLabels: {
                    enabled: true
                  }
                }
            },
            colors:['#cc1616'],
            title: {
              text: 'Pedidos Sin Stock'
            },
            series: [{
            name: 'Sin Stock',
              data: getBackOrderChartSeriesData()
            }]
          }

        return options
    }

    

    return (
        <PageView
        topMenu={<PageTopBar>
            <div className="text-lg font-semibold">Dashboard</div>
            
            
        </PageTopBar>}>
            {isLoading ? (
                    <><Loader show={true}></Loader></>
                ) : isError ? (
                    <>Error: {error.message}</>
                ) : (  
                        <>
                             <div className="rounded overflow-hidden shadow-lg bg-white">

                            <div className="px-6 py-4">
                                <div className="font-bold text-xl mb-2">{getTotalPendingCharts()} pedidos pendientes.</div>
                            </div>
                            </div>
                            <HighchartsReact
                            highcharts={Highcharts}
                            options={getChartOptions()}
                            />

                            <br></br>

                            {/* <div className="rounded overflow-hidden shadow-lg bg-white">
                                <div className="px-6 py-4">
                                    <div className="font-bold text-xl mb-2">{getTotalWaitingPickUpCharts()} pedidos esperando recolección.</div>
                                </div>
                            </div> */}

                            {/* <HighchartsReact
                                highcharts={Highcharts}
                                options={getWaitingPickUpChartOptions()}
                            /> */}

                            <br></br>
                            <div className="rounded overflow-hidden shadow-lg bg-white">

                            <div className="px-6 py-4">
                                <div className="font-bold text-xl mb-2">{getTotalProcessingCharts()} pedidos procesando.</div>
                            </div>
                            </div>
                            <HighchartsReact

                            highcharts={Highcharts}
                            options={getProcessingChartOptions()}
                            />
                            <br></br>
                            {/* <HighchartsReact
                            highcharts={Highcharts}
                            options={getErrorChartOptions()}
                            /> */}
                            <br></br>
                            <HighchartsReact
                            highcharts={Highcharts}
                            options={getBackOrderChartOptions()}
                            />
                        </> 
                        
                    
                
                ) }
            
        </PageView>
    )
}