import {
    REPLENISHMENTS,
    LOGIN,
    ORDERS,
    ROOT,
    ACCESS_DENIED,
    ORDERS_SHIPPED,
    PICK_N_SHIP,
    ORDERS_PROCESSING,
    GENERATE_ORDER_LABEL,
    GENERATE_LABELS,
    GENERATE_SHARED_LABEL,
    SHOW_REPLENISHMENT,
    CLAIMS,
    SHOW_CLAIM,
    OPERATORS,
    RETURNS,
    LOCATIONS,
    WAREHOUSES,
    INVENTORY_HISTORY,
    RECEIVE_RETURN,
    CREATE_RETURN,
    SEARCH_RETURN,
    RECEIVE_RETURNED_ORDER,
    BILLING,
    BILLING_STORE,
    BILLING_STORE_CONFIG_CREATE,
    BILLING_STORE_CONFIG_DUPLICATE,
    WORK_ORDERS,
    SHOW_WORK_ORDER,
    REASSING_CARRIER
} from './constants'
import { Route, Switch } from "react-router-dom";
import {Home} from "../pages/Home";
import {AccessDenied} from "../pages/Errors";
import {Layout1, TopMenu} from "../components";
import {Login} from "../pages/Login";
import {ListActiveReplenishments, ShowReplenishment} from "../pages/Replenishments"
import { ListOrderContainer, PickAndShipContainer } from "../pages/Orders"
import {ListOrdersShippedContainer} from "../pages/Orders"
import {ListOrdersReverseLogisticsContainer} from "../pages/Orders"
import {GenerateShipment} from "../pages/Orders/GenerateShipment/GenerateShipment"
import {GenerateShipmentView} from "../pages/Orders/GenerateShipment/GenerateShipmentView"
import {ListProcessingOrders} from "../pages/Orders"
import PublicRoute from "./Auth/PublicRoute";
import PrivateRoute from "./Auth/PrivateRoute";
import {ListClaimsContainer} from "../pages/Claims/ListClaimsContainer"
import {ClaimDetailContainer} from "../pages/Claims/ClaimDetailContainer"
import {ListOperatorsContainer} from "../pages/Operators/ListOperatorsContainer"
import { ListReturnContainer } from '../pages/Returns/ListReturnContainer';
import { ListProductsContainer } from '../pages/Inventory/ListProductsContainer';
import { ListWarehousesContainer } from '../pages/Warehouses/ListWarehousesContainer';
import { ReassingCarrier } from '../pages/Orders/ReassingCarrier/ReassingCarrier';
import { ListLocationsContainer } from '../pages/Locations/ListLocationsContainer';
import { ListInventoryHistoryContainer } from '../pages/InventoryHistory/ListInventoryHistoryContainer';
import { GenerateSharedLabel } from "../pages/Orders/GenerateShipment/GenerateSharedLabel"
import { ReceiveReturnContainer } from '../pages/Returns/ReceiveReturnContainer';
import { SearchReturnContainer } from '../pages/Returns/SearchReturnContainer';
import { ListBillingStoresContainer } from '../pages/Billing/ListBillingStoresContainer';
import { BillingStoreConfigTabs } from '../pages/Billing/BillingStoreConfigTabs';
import { ListWorkOrdersContainer } from '../pages/WorkOrders/ListWorkOrdersContainer';
import { WorkOrderDetailContainer } from '../pages/WorkOrders/WorkOrderDetailContainer';

export const RouterConfig = () => {

    return (
        <>
            <Switch>
                <PublicRoute exact path={LOGIN} component={Login}/>

                <Layout1 menu={<TopMenu />}>
                    <Switch>
                        <PrivateRoute exact path={BILLING_STORE_CONFIG_CREATE} component={BillingStoreConfigTabs}/>
                        <PrivateRoute exact path={BILLING_STORE_CONFIG_DUPLICATE} component={BillingStoreConfigTabs}/>
                        <PrivateRoute path={BILLING} component={ListBillingStoresContainer}/>
                        <PrivateRoute exact path={WAREHOUSES} component={ListWarehousesContainer}/>
                        <PrivateRoute exact path={ROOT} component={Home}/>
                <PrivateRoute exact path={ACCESS_DENIED} component={AccessDenied}/>
                        {/* <PrivateRoute path={INVENTORY}>
                            <ListProductsContainer />
                        </PrivateRoute> */}
                        <PrivateRoute  path={LOCATIONS}>
                            <ListLocationsContainer />
                        </PrivateRoute>
                        <PrivateRoute  path={PICK_N_SHIP}>
                            <PickAndShipContainer />
                        </PrivateRoute>
                        <PrivateRoute  path={ORDERS}>
                            <ListOrderContainer />
                        </PrivateRoute>
                        <PrivateRoute  path={ORDERS_PROCESSING}>
                            <ListProcessingOrders />
                        </PrivateRoute>
                        <PrivateRoute  path={GENERATE_ORDER_LABEL}>
                            <GenerateShipment />
                        </PrivateRoute>
                        <PrivateRoute  path={GENERATE_LABELS}>
                            <GenerateShipmentView />
                        </PrivateRoute>
                        <PrivateRoute path={CREATE_RETURN}>
                            <ReceiveReturnContainer />
                        </PrivateRoute>
                        <PrivateRoute path={SEARCH_RETURN}>
                            <SearchReturnContainer />
                        </PrivateRoute>
                        <PrivateRoute path={RECEIVE_RETURNED_ORDER}>
                            <SearchReturnContainer />
                        </PrivateRoute>
                        <PrivateRoute  path={ORDERS_SHIPPED}>
                            <ListOrdersShippedContainer />
                        </PrivateRoute>
                        <PrivateRoute exact path={REPLENISHMENTS}>
                            <ListActiveReplenishments />
                        </PrivateRoute>
                        <PrivateRoute exact path={SHOW_REPLENISHMENT}>
                            <ShowReplenishment />
                        </PrivateRoute> 
                        <PrivateRoute path={SHOW_CLAIM}>
                            <ClaimDetailContainer />
                        </PrivateRoute>
                        <PrivateRoute path={CLAIMS}>
                            <ListClaimsContainer />
                        </PrivateRoute>
                        <PrivateRoute path={SHOW_WORK_ORDER}>
                            <WorkOrderDetailContainer />
                        </PrivateRoute>
                        <PrivateRoute path={WORK_ORDERS}>
                            <ListWorkOrdersContainer />
                        </PrivateRoute>
                        <PrivateRoute path={OPERATORS}>
                            <ListOperatorsContainer />
                        </PrivateRoute>
                        <PrivateRoute path={RECEIVE_RETURN}>
                            <ReceiveReturnContainer />
                        </PrivateRoute>
                        <PrivateRoute path={RETURNS}>
                            <ListReturnContainer />
                        </PrivateRoute>
                        <PrivateRoute path={INVENTORY_HISTORY}>
                            <ListInventoryHistoryContainer />
                        </PrivateRoute>
                        <PrivateRoute  path={GENERATE_SHARED_LABEL}>
                            <GenerateSharedLabel />
                        </PrivateRoute>
                        <PrivateRoute path={REASSING_CARRIER}>
                            <ReassingCarrier />
                        </PrivateRoute>
                        <PrivateRoute path={WAREHOUSES}>
                            <ListWarehousesContainer />
                        </PrivateRoute>
                    </Switch>
                </Layout1>
            </Switch>
        </>
    )
}