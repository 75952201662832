/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {DialogView} from "./DialogView";
import _ from 'lodash';
import { ConfirmDialog } from './ConfirmDialog';
import { putPickingOrderInReview } from '../services/orderServices';
import { NoticeBanner } from './NoticeBanner';

export const PickingOrderToReviewDialog = ({
    open,
    setOpen,
    tote='',
    pickingOrderId = 0,
    productsToReviewById = [],
    onSuccess,
    onCancel,
    cancelLabel = 'Cancelar',
    confirmLabel = 'Continuar',
}) => {
    const cancelButtonRef = useRef()
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [openError, setOpenError] = useState(false)
    const qualityCheckedProducts = useMemo(() => {
        let products = {...productsToReviewById}
        Object.keys(products).forEach(id => {
            products[id].missing = products[id].quantity - (products[id].reviewedItemsCount || 0)
            products[id].all_scanned = products[id].missing == 0
        })
        setOpenError(false)
        return products
    }, [productsToReviewById])

    const getSku = product => {
        return product.product_codes.find(productCode => productCode.sku).sku
    }

    const product_table_columns = [
        {
            Header: <div className="flex justify-center">SKU</div>,
            accessor: 'sku'
        },
        {
            Header: <div className="flex justify-center">Estado picking</div>,
            accessor: 'all_scanned'
        },
        {
            Header: <div className="flex justify-center">Piezas en el tote</div>,
            accessor: 'reviewedItemsCount',
        },
        {
            Header: <div className="flex justify-center">Piezas faltantes</div>,
            accessor: 'missing'
        }
    ]

    const onConfirmHandler = async () => {
        setConfirmLoading(true)
        try {
            const productsToRevert = Object.values(qualityCheckedProducts).map(product => {
                return {
                    product_id: product.id,
                    scanned: product.reviewedItemsCount || 0,
                    missing: product.missing
                }
            })
            const response = await putPickingOrderInReview(pickingOrderId, productsToRevert)
            onSuccess(response)
            
        } catch (e) {
            let error = e.response.data.error
            console.log(error)
            setOpenError({status: true, text: e.response.data.error})
        } finally {
            setConfirmLoading(false)
            setOpenConfirmDialog(false)
            //setOpen(false)
        }


        try {
            
        } catch (e) {
            
        }
    }

    const canReversePicking = () => Object.values(qualityCheckedProducts).some(product => product.missing > 0)

    return (
        <DialogView setOpen={setOpen} open={open} className="w-auto">
            <div>
                <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Enviar pedido del tote {tote} a revisión
                    </Dialog.Title>
                    <div>
                        ¿Seguro que deseas enviar el pedido de este tote a revisión?
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-6 px-4 overflow-y-auto max-h-96 h-3/5">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="sticky top-0 bg-gray-50">
                        <tr>
                            {product_table_columns.map((column, index) => (
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    key={column.accessor}
                                >
                                    {column['Header']}
                                </th>
                            ))}

                        </tr>
                    </thead>
                    <tbody>
                    {Object.values(qualityCheckedProducts).map((product, index) => (
                        <tr key={index}>
                            {product.quantity > 0 &&
                                <>
                                    <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-500 text-center">
                                        <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-blue-100 text-blue-800 select-none"}>
                                            {getSku(product)}
                                        </span>
                                    </td>
                                    <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-500 text-center">
                                        {product.all_scanned ?
                                            <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-green-100 text-green-800 select-none"}>
                                                Completo
                                            </span>
                                        :   <span className={"inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mx-1 bg-red-100 text-red-800 select-none"}>
                                                Incompleto
                                            </span>
                                        }
                                    </td>
                                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500 text-center">
                                        <b>{product.reviewedItemsCount}</b>
                                    </td>
                                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500 text-center">
                                        <b>{product.missing}</b>
                                    </td>
                                </>
                            }
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-center w-2/3 mx-auto mt-5">
                <button
                    type="button"
                    className="mr-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={onCancel}
                    ref={cancelButtonRef}
                >
                    {cancelLabel}
                </button>
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm disabled:opacity-50"
                    onClick={() => setOpenConfirmDialog(true)}
                    disabled={!canReversePicking()}
                >
                    {confirmLabel}
                </button>
            </div>
            <ConfirmDialog
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                title="Enviar pedio a revisión"
                description={`Deseas mandar este pedido a revisión?`}
                confirmLabel="Si"
                onConfirm={() => {onConfirmHandler()}}
                loading={confirmLoading}
            />
            <div className='mt-1'>
                {openError.status &&
                    <NoticeBanner text={openError.text} className="mb-2"/>
                }
            </div>
        </DialogView>
    )
}