import {useContext, useState} from "react";
import {UserContext} from "./UserContext";
import {getUser, login} from "../services/loginServices";
import userToken from "../redux/userToken";
import { getCurrentStore } from "../helpers/userHelper";

export default function useAuth() {
    const {setUser} = useContext(UserContext)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const setUserContext = async () => {
        try{
            const res = await getUser()
            let current_store = await getCurrentStore(res)
            let user = {
                ...res,
                isLogged: true,
                current_store: current_store
            }
            setUser(user)
            setIsLoading(false)
            return user
        } catch(e) {
            setIsLoading(false)
            setIsError(true)
            console.log(e)
            throw e
        }
    }

    const loginUser = async (data) => {
        try{
            const {email, password} = data
            setIsLoading(true)
            let res = await login(email, password)
            userToken.token = res.token
            let user = await setUserContext()
            return user
        } catch (e) {
            setIsLoading(false)
            setIsError(true)
            throw e
        }
    }

    return [
        loginUser,
        isLoading,
        isError,
    ]
}