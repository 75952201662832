import {UserContext} from "../hooks/UserContext";
import React, {useContext, useEffect} from "react";
import LogRocket from 'logrocket';
import useWebcamRecorder from "../hooks/useWebcamRecorder";

export function Layout1({menu, children}) {

    const {user} = useContext(UserContext)
    useWebcamRecorder()

    useEffect(() => {
        if(user !== null){
            // boot({ 
            //     user_id: user.id,
            //     email: user.email,
            //     name: user.first_name+" "+user.last_name
            // })

            LogRocket.identify(user.id, {
                name: user.first_name+" "+user.last_name,
                email: user.email
            });
        }
        
    }, [user])

    return (
        <div className="flex flex-col h-screen justify-between">
            <div className=" bg-red-500">{menu}</div>
            <div className="h-5/6 flex flex-col justify-between flex-grow bg-gray-100">
                {children}
            </div>
        </div>
    )
}