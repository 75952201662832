export const INCIDENT_CATEGORIES = {
    DELAYED: 'DELAYED',
    MISSING_REFERENCES: 'MISSING_REFERENCES',
    CANCELED: 'CANCELED',
    DAMAGED: 'DAMAGED',
    MISSING_PRODUCT: 'MISSING_PRODUCT',
    FALSE_DELIVERY: 'FALSE_DELIVERY',
    CHANGE_ADDRESS: 'CHANGE_ADDRESS',
    RESCHEDULE: 'RESCHEDULE'
}
  
export const INCIDENT_RESOLUTIONS = {
    STOLEN: 'STOLEN', // non-refundable
    LOST: 'LOST', // non-refundable
    RETURNED: 'RETURNED',
    DELIVERED: 'DELIVERED',
    REFUNDABLE: 'REFUNDABLE',
    NON_REFUNDABLE_DAMAGED: 'NON_REFUNDABLE_DAMAGED' // non-refundable
}

export const INCIDENT_STATUSES = {
    PENDING: 'PENDING',
    IN_REVIEW: 'IN_REVIEW',
    RESOLVED: 'RESOLVED'
}

export const SHIPPING_STATUS_UPDATE = [
    {
        status: "shipment_delivered",
        label: "shipment_delivered",
        description: ""
    },
    {
        status: "shipment_in_transit",
        label: "shipment_in_transit",
        description: ""
    },
    {
        status: "shipment_with_incident",
        label: "shipment_with_incident_stolen",
        description: "stolen"
    },
    {
        status: "shipment_with_incident",
        label: "shipment_with_incident_damaged",
        description: "damaged"
    },
    {
        status: "shipment_with_incident",
        label: "shipment_with_incident_lost",
        description: "lost"
    },
    {
        status: "shipment_returned",
        label: "shipment_returned",
        description: ""
    },
    {
        status: "shipment_to_be_self_collected",
        label: "shipment_to_be_self_collected",
        description: ""
    },
    {
        status: "shipment_canceled",
        label: "shipment_canceled",
        description: ""
    }
]