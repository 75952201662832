export const WAREHOUSE_MULTI_PACKAGE_SHIPPING_METHODS = {
    1: [
        {
            carrierName: 'ESTAFETA',
            shippingType: 'national',
            deliveryType: 'Ground',
        },
        {
            carrierName: 'DHL',
            shippingType: 'national',
            deliveryType: 'Ground',
        },
        {
            carrierName: 'DHL',
            shippingType: 'national',
            deliveryType: 'NextDay',
        }
    ],
    3: [    // Lapa
        {
            carrierName: 'JT Express',
            shippingType: 'national',
            deliveryType: 'Ground',
        }
    ],
    4: [    // Sao Paulo
        {
            carrierName: 'JT Express',
            shippingType: 'national',
            deliveryType: 'Ground',
        },
        {
            carrierName: 'CORREIOS',
            shippingType: 'national',
            deliveryType: 'Ground',
        },
        {
            carrierName: 'CORREIOS',
            shippingType: 'national',
            deliveryType: 'NextDay',
        }
    ],
    100: [
        {
            carrierName: 'ESTAFETA',
            shippingType: 'national',
            deliveryType: 'Ground',
        }
    ],
    133: [ // Tlanepantla
        {
            carrierName: 'ESTAFETA',
            shippingType: 'national',
            deliveryType: 'Ground',
        },
        {
            carrierName: 'DHL',
            shippingType: 'national',
            deliveryType: 'Ground',
        },
        {
            carrierName: 'DHL',
            shippingType: 'national',
            deliveryType: 'NextDay',
        }
    ],
    166: [ // Minas Gerais - Extrema*   
        { 
            carrierName: 'JT Express',
            shippingType: 'national',
            deliveryType: 'Ground',
        },
        {
            carrierName: 'CORREIOS',
            shippingType: 'national',
            deliveryType: 'NextDay',
        },
        {
            carrierName: 'CORREIOS',
            shippingType: 'national',
            deliveryType: 'Ground',
        }
    ]
}

export const SHIPPING_TYPES = {
    INTERNATIONAL: "international",
    NATIONAL: "national",
    PICKUP: "pickup",
}
