import { useMemo } from "react"

export const TimelineItem = ({ className = "", iconClassName = "", children, iconComponent = <DotIcon className="w-4 h-4 text-gray-300" />, lineType = LINE_TYPE_MIDDLE }) => {
    return (
        <div className={`flex ${className}`}>
            <TimelineIndicator iconComponent={iconComponent} lineType={lineType} iconClassName={iconClassName} />
            <div>{children}</div>
        </div>
    )
}

const TimelineIndicator = ({ lineType = LINE_TYPE_MIDDLE, iconComponent = null, iconClassName = "" }) => {
    return (
        <div className="flex flex-col min-h-1 w-12 flex-shrink-0">
            <IconContainer iconComponent={iconComponent} lineType={lineType} className={iconClassName} />
            <div className="relative flex-grow">
                <Line type={[LINE_TYPE_START, LINE_TYPE_MIDDLE].includes(lineType) ? LINE_TYPE_MIDDLE : LINE_TYPE_NONE} />
            </div>
        </div>
    )
}

const IconContainer = ({ iconComponent = null, lineType = LINE_TYPE_MIDDLE, className = "" }) => {
    return (
        <div className={`relative w-full z-1 ${className}`}>
            <div>&nbsp;</div>
            <Line type={lineType} />
            <div className="absolute inset-0">
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pt-0.5">{iconComponent}</div>
            </div>
        </div>
    )
}

export const DotIcon = ({ className = "" }) => {
    return (
        <svg className={className} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="20" stroke="none" strokeWidth="0" fill="currentColor" />
        </svg>
    )
}

export const LINE_TYPE_START = "START"
export const LINE_TYPE_MIDDLE = "MIDDLE"
export const LINE_TYPE_END = "END"
export const LINE_TYPE_NONE = "NONE"

const Line = ({ type = LINE_TYPE_MIDDLE, colorClass = "bg-gray-300" }) => {
    const lineClass = useMemo(() => {
        let lineClass = `absolute w-full ${colorClass}`
        switch (type) {
            case LINE_TYPE_START:
                lineClass += " h-1/2 bottom-0"
                break
            case LINE_TYPE_MIDDLE:
                lineClass += " h-full"

                break
            case LINE_TYPE_END:
                lineClass += " h-1/2 top-0"
                break
            default:
            case LINE_TYPE_NONE:
                lineClass += " h-0"
                break
        }
        return lineClass
    }, [type, colorClass])

    return (
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 h-full w-px">
            <div className={lineClass}></div>
        </div>
    )
}
