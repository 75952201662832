import {useHistory} from "react-router-dom";
import {useState, useEffect, useContext, useRef} from 'react';
import {useQuery} from "react-query";
import {completeReplenishment, addProductToReplenishment, fetchFreeLocationsForReplenishment, fetchReplenishment, searchProductInReplenishment, receiveProduct, receiveReplenishment, damagedProduct as updateDamagedProduct, correctProduct, updateQualityCheck, updateReplenishmentStatus} from "../../services";
import {
    Button,
    ButtonLoader,
    Checkbox,
    FilterMenu,
    SmallTable,
    SearchInput,
    InputQuantity,
    PageView,
    ConfirmDialog,
    DialogView,
    Notification,
    BasicTable
} from "../../components";
import {getSkus} from "../../components/utils/productUtils";
import { useParams } from "react-router-dom";
import {UserContext} from "../../hooks/UserContext";
import { InputNumber, Tooltip } from "antd"
import { CreateLotsDialog } from "./CreateLotsDialog";
import moment from 'moment';
import { fetchReplenishmentPaginated } from "../../services/replenishmentServices";
import { useDispatch, useSelector } from "react-redux";
import { setReplenishmentsProductsListPageSize } from "../../redux/pageSizeSlice";
import { useTranslation } from "react-i18next";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

export const ShowReplenishment = () => {
    const history = useHistory()
    const { i18n } = useTranslation()


    const [textToSearch, setTextToSearch] = useState("")
    const [messageProductsNotFound, setProductsNotFound] = useState(false)
    const [exists, setExists] = useState(false)
    const [productSearchIsLoading, setproductSearchIsLoading] = useState(false)
    const [foundProducts, setFoundProducts] = useState([])
    const [foundLots, setFoundLots] = useState([])
    const [correctedProduct, setCorrectedProduct] = useState(null)
    const [selectLocationId, setSelectLocationId] = useState(null)
    const [insertQuantity, setInsertQuantity] = useState(1)
    const [correctQuantity, setCorrectQuantity] = useState(0)
    const [insertNumberOfBoxes, setInsertNumberOfBoxes] = useState(1)
    const [loadingButton, setLoadingButton] = useState(false)
    const [loadingCompleteButton, setLoadingCompleteButton] = useState(false)
    const [loadingCorrectionButton, setLoadingCorrectionButton] = useState(false)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [damagedProduct, setDamagedProduct] = useState({})
    const [damagedDialogText, setDamagedDialogText] = useState(false)
    const [completeDialogText, setCompleteDialogText] = useState(false)
    const [openDialogCorrection, setOpenDialogCorrection] = useState(false)
    const [openDialogComplete, setOpenDialogComplete] = useState(false)
    const [messageDuplicateProductId, setMessageDuplicateProductId] = useState(false)
    const lotsDialogRef = useRef(null)
    const searchInputRef = useRef(null)
    const tableRef = useRef()
    const dispatch = useDispatch()

    // Quality check values.
    const [qualityCheckData, setQualityCheckData] = useState({
        productIsSegregated: false,
        productIsLabeled: false,
        clientAttendedAppointment: false
    })
    const [qualityCheckUpdateNotificationState, setQualityCheckUpdateNotificationState] = useState(false)
    const [qualityCheckDisabled, setQualityCheckDisabled] = useState(true)
    const [isReceiving, setIsReceiving] = useState(null)

    const { user } = useContext(UserContext);

    let { id } = useParams()
    let { warehouse_id } = useParams()
    const [params, setParams] = useState({warehouse_id: warehouse_id})
    const [activeLot, setActiveLot] = useState(null)
    const [animating, setAnimating] = useState(false)
    
    const [lastScannedProduct, setLastScannedProduct] = useState({id: 0})
    const lastScannedProductRef = useRef(null)
    const pageSize = useSelector((state) => state.pageSize.replenishmentProductsList);
    const [pageParams, setPageParams] = useState({
        page: 1,
        per_page: pageSize
    })

    const {
        isLoading,
        isError,
        error,
        data,
        refetch,
        isFetching,
        isPreviousData
    } = useQuery(['replenishment', id, pageParams], () => fetchReplenishmentPaginated(id, pageParams), {
        keepPreviousData: true,
    })

    const hasQualityCheck = replenishment => replenishment.segregated_product != null && replenishment.labeled_product != null

    const locationsQuery = useQuery(['locations',params], () => fetchFreeLocationsForReplenishment(params), {
        enabled: data && data.replenishment && data.replenishment.location !== undefined
    })

    const animate = () => {
        //setAnimating(true)
        //setTimeout(() => setAnimating(false), 500)
    }

    useEffect(() => {
        // update default location ID when data is loaded
        if (locationsQuery.data && locationsQuery.data.length > 0) {
            setSelectLocationId(locationsQuery.data[0].id)
        }       
        // console.log('da', data)
    }, [locationsQuery])

    useEffect(() => {
        if (data?.warehouse_location_id !== null) {
            const focusTimer = setTimeout(() => {
                searchInputRef.current?.focus()
            }, 50)
            return () => clearTimeout(focusTimer)
          }
    }, [data])

    useEffect(() => {
        if (data) {
            setQualityCheckData({
                productIsSegregated: !!data.replenishment.segregated_product,
                productIsLabeled: !!data.replenishment.labeled_product,
                clientAttendedAppointment: !!data.replenishment.attended_appointment
            })
            setQualityCheckDisabled(false)
        }
    }, [data])

    useEffect(() => {
        if(lastScannedProductRef.current){
            lastScannedProductRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    },[lastScannedProduct])

    const checkHasMoreStockThanExpected = (scannedProduct, productQuantity) => {
        const replenishmentProduct = data.replenishment.replenishment_products.find(({product_id}) => product_id === scannedProduct.id)
        const product = data.replenishment.products.find(({id}) => id === scannedProduct.id)

        if(!replenishmentProduct || !product){
            return true
        }

        if(parseInt(productQuantity) === 0){
            return false
        }

        const totalReceived = product.stock_by_lot.reduce((currentTotal, stockByLot) => currentTotal + parseInt(stockByLot.stock), 0)
        const newTotalReceived = totalReceived + parseInt(productQuantity)

        const hasMoreStockThanExpected = newTotalReceived > parseInt(replenishmentProduct.quantity) ? true : false
        return hasMoreStockThanExpected
    }

    const searchProductInReplenishmentLocally = (sku) => {
        const foundProduct = foundProducts.filter(product => product?.is_kit === false).find(product => product.bar_code == sku || product.product_codes.map(code => code.sku).includes(sku))
        if(foundProduct) return {product: foundProduct, exists: true}
        else return null
    }

    const handleSearchChange = async (val) => {
        if(isReceiving !== null){
            return false
        }
        //TODO BUG WHEN PRODUCT NOT FOUND & DOESNT EXIST
        setProductsNotFound(false)
        setproductSearchIsLoading(true)
        let search_res = null
        if (val !== ""){
            //If product is already in counting process, don't look for it again in the API
            search_res = searchProductInReplenishmentLocally(val)
            if(search_res === null) search_res = await searchProductInReplenishment(val, id)
            console.log("search_res")
            console.log(search_res)
            setproductSearchIsLoading(false)
            if (search_res === null) {
                //setFoundProduct(null)
                setExists(false)
                if (val === ""){
                    setProductsNotFound(false)
                }
                console.log("val", val)
                console.log(val)
                setProductsNotFound(true)
                setLastScannedProduct({id: 0})
                
            } else {
                
                if(lastScannedProduct.id != search_res.product.id) {
                    let scannedProductLots = foundLots.filter(lot => lot.product_id === search_res.product.id)
                    if(!scannedProductLots.map(lot => lot.id).includes(activeLot))
                        setActiveLot(null)
                }
                setLastScannedProduct(search_res.product)
                if (search_res.exists && search_res.exists === true) {
                    setExists(true)
                } else {
                    setExists(false)
                }
                if(foundProducts.findIndex(product => product.id == search_res.product.id) == -1){
                    if(!search_res.product.has_expiration && isInReplenishment(search_res.product)){
                        search_res.product.insertQuantity = 1
                        search_res.product.lot = null
                        setFoundProducts(existingItems => {return [search_res.product, ...existingItems]})
                    }else{
                        setFoundLots(existingItems => {
                            return existingItems.concat(search_res.lots)
                        })
                        const foundProductLots = search_res.lots.filter(lot => lot.product_id === search_res.product.id)
                        
                        foundProductLots.map(lot => {
                            const newProduct = { ...search_res.product}
                            newProduct.lot = lot
                            if(foundProductLots.length > 1){
                                newProduct.insertQuantity = 0
                            }else{
                                newProduct.insertQuantity = 1
                                setActiveLot(lot.id)
                                animate()
                            }
                            setFoundProducts(existingItems => {return [newProduct, ...existingItems]})
                        })
                    }
                    
                }else{
                    let availableLots = foundLots.filter(lot => lot.product_id === search_res.product.id)
                    let newActiveLot = activeLot
                    
                    if(availableLots.length == 1){
                        newActiveLot = availableLots[0].id
                        setActiveLot(newActiveLot)
                    }
                    let foundProductIndex = foundProducts.findIndex(product => product.id === search_res.product.id && newActiveLot == product.lot?.id)
                    if(foundProductIndex == -1){
                        alert("Éste producto tiene más de un lote, selecciona uno para continuar")
                    }else{
                        setFoundProducts(existingItems => {
                            return existingItems.map((product, index) => {
                                return index != foundProductIndex ? product : {...product, insertQuantity: product.insertQuantity + 1}
                            })
                        })
                        animate()
                    }
                }
                setProductsNotFound(false)
                //setFoundProducts(existingItems => {return [...existingItems, newFoundProducts]})
                searchInputRef.current?.resetInput()
            }
            setproductSearchIsLoading(false)
        }
    }

    const handleUpdateReplenishmentStatus = async (status) => {
        setProductsNotFound(false)
        setLoadingCompleteButton(true)
        await updateReplenishmentStatus(id, status)
        await refetch()
        setLoadingCompleteButton(false)
    }

    const handleCompleteReplenishment = async (status) => {
        setProductsNotFound(false)

        const productsWithStockInReception = data.replenishment.products.filter(product => { return product.in_reception !== null }).length
        
        if (productsWithStockInReception > 0) {
            openCompleteDialog(productsWithStockInReception)     
        } else {
            const res = await completeReplenishment(id)
            refetch()
        }

    }

    const acceptCompleteReplenishment = async () => {
        setLoadingCompleteButton(true)
        const res = await completeReplenishment(id)
        refetch()
        setLoadingCompleteButton(false)
        setOpenDialogComplete(false)
    }

    const handleReceiveReplenishment = async status => {
        setLoadingButton(true)
        await receiveReplenishment(
            id,
            status,
            insertNumberOfBoxes
        )
        await refetch()
        setLoadingButton(false)
    }

    const handleUpdateQualityCheck = async ({
            clientAttendedAppointment,
            productIsSegregated,
            productIsLabeled
        }) => {
        setQualityCheckDisabled(true)

        const updatedQualityData = {
            ...qualityCheckData,
            ...(clientAttendedAppointment != null && { clientAttendedAppointment }),
            ...(productIsSegregated != null && { productIsSegregated }),
            ...(productIsLabeled != null && { productIsLabeled })
        }

        setQualityCheckData(updatedQualityData)

        await updateQualityCheck(
            id,
            updatedQualityData
        )

        setQualityCheckDisabled(false)
        setQualityCheckUpdateNotificationState(true)
    }

    const handleReceiveProducts = async (product) => {
        console.log("Receiving product...")
        const res = await receiveProduct(product, product.insertQuantity, data.replenishment.warehouse_location_id, id, user.id)
        console.log("Ended Receiving product...")
        refetch()
        removeProductFromFounds(product)
        //setExists(false)
        searchInputRef.current?.resetInput()
        setproductSearchIsLoading(false)
        // setTextToSearch("")
        //setInsertQuantity(1)
        setProductsNotFound(false)
        setIsReceiving(null)
    }

    // TODO Merge handleUpdateReplenishmentStatus and this into a single method.
    const handleProcessReplenishment = async () => {
        setLoadingButton(true)
        try {
            await updateReplenishmentStatus(id, "PROCESSING")
        } catch (error) {
            if (error.response?.data?.code === "BILLING_CONFIG_MISSING") {
                window.alert("Esta tienda no ha sido configurada para poder iniciar el recibo. Por favor, contacta al representante de ventas de la tienda.")
            }
            else {
                console.log(error)
                window.alert(error)
            }
        }
        await refetch()
        setLoadingButton(false)
    }

    const handlAddProductToReplenishment = async (product) => {
        try {
            setproductSearchIsLoading(true)
            const res = await addProductToReplenishment(product, id)
            setProductsNotFound(false)
            setLastScannedProduct({id: 0})
            setExists(true)
            await refetch()
            setproductSearchIsLoading(false)
            if(product.has_expiration){
                setLastScannedProduct(product)
            }else{
                product.insertQuantity = 1
                product.lot = null
                setFoundProducts(existingItems => {return [product, ...existingItems]})
            }   
            } catch (error) {
            if (error.response?.data?.errorType === "RecordInvalid") {
                setMessageDuplicateProductId(true)
                return
            }
        }
    }

    const openCorrectReception  = async (product) => {
        // const res = await addProductToReplenishment(product, id)
        // refetch()
        // searchInputRef.current?.resetInput()
        // setProductsNotFound(false)
        let in_reception = product.in_reception || 0
        setCorrectQuantity(in_reception)
        setCorrectedProduct(product)
        console.log(correctedProduct)
        setOpenDialogCorrection(true)
        console.log(product)
    }

    const removeProductFromFounds = (product) => {
        console.log("Removing product", product)
        setFoundProducts(existingItems => {
            const receivedIndex = existingItems.findIndex(foundProduct => foundProduct == product)
            if(!product.has_expiration) return [...existingItems.slice(0, receivedIndex), ...existingItems.slice(receivedIndex+ 1)]
            else return existingItems.map((product, index) => {
                return index === receivedIndex ? {...product, insertQuantity: 0} : product
            })
        })
    }

    const handleCorrectReception= async (product) => {
        try{
            setLoadingCorrectionButton(true)
            const res = await correctProduct(product, correctQuantity, id, product.lot_id)
            refetch()
            setOpenDialogCorrection(false)
            setLoadingCorrectionButton(false)
        } catch (error) {
            if (error.response?.data?.errorType === "RecordInvalid") {
                setMessageDuplicateProductId(true)
                return
            }
        }
        
    }

    const openDamagedDialog = async (product) => {
        setDamagedDialogText("Mueve "+product.insertQuantity+" piezas a la localización: D-1-1-1 y clicka continuar")
        setDamagedProduct(product)
        setOpenConfirmDialog(true)
        setConfirmLoading(false)
    }


    const openCompleteDialog = async (productsWithStockInReception) => {
        setCompleteDialogText("Tienes "+productsWithStockInReception+" productos con piezas en recibo, ¿aceptas completar el recibo y que esas piezas se eliminen?")
        setOpenDialogComplete(true)
    }


    const handleDamageProducts = async () => {
        const product = damagedProduct
        setConfirmLoading(true)
        console.log("prid")
        console.log(product)
        const res = await updateDamagedProduct(product, product.insertQuantity, id, product.has_expiration ? product.lot.id : null)
        await refetch()
        setConfirmLoading(false)
        setOpenConfirmDialog(false)
        //setFoundProducts([])
        setProductsNotFound(false)
        //setExists(false)
        //setTextToSearch("")
        //setInsertQuantity(1)

        removeProductFromFounds(product)
        setDamagedProduct({})
        setIsReceiving(null)
    }

    const resetSearch = async () => {
        setProductsNotFound(false)
        setTextToSearch("")
    }

    const getLot = (product) => {
        if(product.lot_id){
            const lot = product.lots.find(lot => lot.id === product.lot_id)
            if(lot){
                return <>{lot.unique_code}<br/>{<i>{lot?.expiration_date_moment?.format('DD/MM/Y')}</i>}</>
            }
        }
        return product.has_expiration ? 'No tiene lote' : 'No requiere lote'
    }

    const getInReception = (product) => product.lot_reception

    const getProcessed = (product) => product.lot_stock

    function getName(product) {
        let name = ""
        if (product.parent && product.parent.name) {
            name = name + product.parent.name + " - "
        }
        name = name + product.name

        return (
        <div>
            {product.is_scannable === false &&
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full font-medium bg-yellow-100 text-yellow-800 mx-1`}>
                    {i18n.t('general.product.insert')}
                </span>
            }
            {name}
        </div>
        )
    }

    const searchStock = (product_id) => {
        // console.log('da', data)
        let product = data.replenishment.products.find(function(product, index) {
            if(product.id === product_id)
                return true;
        });
        // console.log(product)
        if (product) {
            return product.stock_for_replenishment
        } else {
            return 0
        }
        
    }


    const product_table_columns = [
        {
            Header: 'Lote',
            accessor: 'lot'
        },
        {
            Header: 'Nombre',
            accessor: 'name'
        },
        {
            Header: <div className="flex justify-center">SKU</div>,
            accessor: 'sku'
        },
        {
            Header: <div className="flex justify-center">UPC</div>,
            accessor: 'upc'
        },
        {
            Header: <div className="flex justify-center">Contadas</div>,
            accessor: 'quantity',
            Cell: InputQuantity,
        },
        {
            Header: <div className="flex justify-center">Acciones</div>,
            accessor: 'actions'
        }
    ]

    const exptected_products_table_columns = [
        {
            Header: 'Lote',
            accessor: 'lot',
            disableFilters: false,
            disableSortBy: false,
        },
        {
            Header: 'Nombre',
            accessor: 'name',
            disableFilters: false,
            disableSortBy: true,
        },
        {
            Header: <div className="flex justify-center">SKU</div>,
            accessor: 'sku',
            disableFilters: false,
            disableSortBy: true,
        },
        {
            Header: <div className="flex justify-center">UPC</div>,
            accessor: 'upc',
            disableFilters: false,
            disableSortBy: true,
        },
        {
            Header: <div className="flex justify-center">Esperadas</div>,
            accessor: 'quantity',
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: <div className="flex justify-center">Procesadas</div>,
            accessor: 'processed_quantity',
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: <div className="flex justify-center">En recepción</div>,
            accessor: 'in_reception',
            disableFilters: true,
            disableSortBy: true,
        }
    ]

    const [showCreateLotsDialog, setShowCreateLotsDialog] = useState(false)

    const hasExpirations = () => getExpirableProducts().length > 0

    const hasWarehouseLocation = () => data.replenishment.warehouse_location_id !== null

    const getExpirableProducts = () => data.replenishment.products.filter(product => product.has_expiration)

    const isMissingAllLots = () => {
        return false
        //if(!hasExpirations()) return false;
        //return data.replenishment.lots.length == 0
    }

    const isInReplenishment = (product) => {
        return data.replenishment.replenishment_products.findIndex(rp => rp.product_id == product.id) > -1
    }

    const hasMissingLots = () => {
        if(!hasExpirations() || !lastScannedProduct.has_expiration) return false;
        if(!isInReplenishment(lastScannedProduct)) return false;
        return foundLots.filter(lot => lot.product_id == lastScannedProduct.id).length == 0
    }

    const processedLots = (product) => {
        let processedLots = foundLots
        processedLots.forEach(lot => {
            lot.expiration_date_moment = lot.expiration_date == '' ? '' : moment(lot.expiration_date, "YYYY-MM-DD")
            const product = data.replenishment.replenishment_products.find(rp => rp.product_id == lot.product_id)
            if (product)  lot.replenishment_product_id = product.id
        });
        return processedLots.filter(lot => lot.product_id == product.id)
    }

    const onLotsSaved = (lots) => {
        const oldLotIds = foundLots.map(lot => lot.id)
        const newFoundLots = lots.filter(lot => !oldLotIds.includes(lot.id) )
        const newLotIds = lots.map(lot => lot.id)
        const missingLotsIds = foundLots.filter(lot => lot.product_id == lastScannedProduct.id).filter(lot => !newLotIds.includes(lot.id)).map(lot => lot.id)
        
        newFoundLots.map(lot => {
            setFoundProducts(existingItems => {
                return [...existingItems, {...lastScannedProduct,
                    insertQuantity: 0,
                    lot: {...lot, expiration_date_moment: lot.expiration_date == '' ? '' : moment(lot.expiration_date, "YYYY-MM-DD")}
                }].sort((a, b) => a.name > b.name ? 1 : -1)
            })
            
        })
        setFoundLots(existingItems => {
            return [...existingItems.filter(lot => lot.product_id != lastScannedProduct.id).concat(lots)]
        })
        setFoundProducts(existingItems => {
            return existingItems.filter(product => !missingLotsIds.includes(product.lot.id))
        })
        refetch()
        setShowCreateLotsDialog(false)
        //handleSearchChange(lastScannedProduct.bar_code)
    }

    const onFilterChange = (activeFilters) => {
        exptected_products_table_columns.forEach((col) => {
            if (pageParams[col.accessor]) {
            delete pageParams[col.accessor]
            }
        })
        let filters = []
        activeFilters.forEach((filter) => {
            filters[filter.id] = filter.value
        })
        setPageParams({ ...pageParams, ...filters, page: 1 })
    }

    const recievedProductsData = (products) => {
        let productsData = []
        products.forEach(product => {
            if(!product.has_expiration) productsData.push({...product,
                lot_id: null,
                lot_reception: product.in_reception_by_lot.find(in_reception => in_reception.lot_id == null)?.stock || 0,
                lot_stock: product.stock_by_lot.find(stock => stock.lot_id == null)?.stock || 0
            })
            else{
                const lots = product.lots.filter(lot => lot.product_id == product.id)
                if(lots.length > 0)
                    lots.forEach(lot => {
                        productsData.push({...product,
                            lot_id: lot.id,
                            lot_reception: product.in_reception_by_lot.find(in_reception => in_reception.lot_id == lot.id)?.stock || 0,
                            lot_stock: product.stock_by_lot.find(stock => stock.lot_id == lot.id)?.stock || 0
                        })
                    })
                else{
                    productsData.push({...product,
                        lot_id: null,
                        lot_reception: 0,
                        lot_stock: 0
                    })
                }
            }
        })
        return productsData
    }

    const handleSaveLots = () => lotsDialogRef.current?.handleSaveLots()

    const hasScannableProductsMoreStockThanExpected = foundProducts.some(foundProduct => {
        const hasMoreStockThanExpected = checkHasMoreStockThanExpected(foundProduct, foundProduct.insertQuantity)
        return foundProduct.is_scannable && hasMoreStockThanExpected ? true : false
    })

    return (<>
        <Notification
            show={qualityCheckUpdateNotificationState}
            setShow={setQualityCheckUpdateNotificationState}
            type="success"
            title="Quality check guardado"
        />
        <Notification
            show={messageDuplicateProductId}
            setShow={setMessageDuplicateProductId}
            type="error"
            title="Este producto ya ha sido añadido al recibo anteriormente"
        />
        <PageView 
        topMenu={
            <FilterMenu>
                {isLoading ?
                " "
                :

                <div>
                    <h1 className="text-lg leading-4 font-medium text-gray-900"> {"Cita de Recibo "+ data.replenishment.id +" - "+data.replenishment.store}</h1>
                    <h3 className="text-sm leading-3 font-normal text-gray-500">{data.replenishment.sell_ahead ? "Preventa" : "No-Preventa"}</h3>
                </div>
                
                }

                {data && data.replenishment && (data.replenishment.status === 'COMPLETED' || data.replenishment.status === 'IN_PUTAWAY') ?
                    <>
                        {data.replenishment.status !== 'COMPLETED' ? <ButtonLoader className="mr-5" type="secondary" loading={loadingCompleteButton} onClick={() => handleUpdateReplenishmentStatus("PROCESSING")} disabled={loadingCompleteButton}>Volver a abrir recibo</ButtonLoader> : <></>}
                        {!data.replenishment.is_from_return && data.replenishment.status !== 'COMPLETED' ? <ButtonLoader loading={loadingCompleteButton} onClick={() => handleCompleteReplenishment()} disabled={!user.permissions.complete_replenishment}>Completar Recibo</ButtonLoader> : <></> }
                    </>
                :
                data && data.replenishment && data.replenishment.status === 'PROCESSING' ?
                    <>
                        {!hasMissingLots() && hasExpirations() && <Tooltip title="Modifica los lotes del último producto escaneado"><ButtonLoader loading={loadingButton} onClick={() => setShowCreateLotsDialog(true)} disabled={loadingButton || !lastScannedProduct?.has_expiration}>Editar lotes</ButtonLoader></Tooltip>}
                        {!hasMissingLots() && <ButtonLoader className="ml-5" loading={loadingCompleteButton} onClick={() => handleUpdateReplenishmentStatus("IN_PUTAWAY")} disabled={loadingCompleteButton || (!data.replenishment.can_receive_more_stock_than_expected && hasScannableProductsMoreStockThanExpected)}>Terminar check-in</ButtonLoader>}
                        {hasMissingLots() && <ButtonLoader loading={loadingCompleteButton} onClick={() => handleSaveLots()} disabled={loadingCompleteButton}>Continuar</ButtonLoader>}
                    </>
                :
                    <></>
                }
                </FilterMenu>
        }
        >

            <DialogView setOpen={setOpenDialogCorrection} open={openDialogCorrection} >

            {correctedProduct !== null && correctedProduct.lot_reception > 0 ? 
            
                (
                    <>
                        <h1 className="text-lg leading-6 font-medium text-gray-900">Correción de recibo</h1>
                        <div>
                        <label className="block text-sm mb-2 font-medium text-gray-700">Nombre:</label> {correctedProduct.name} <br></br>
                        <label className="block text-sm mt-2 font-medium text-gray-700">SKUs:</label> {getSkus(correctedProduct.product_codes)} <br></br>
                        <label className="block text-sm mt-2 font-medium text-gray-700">UPC:</label> {correctedProduct.bar_code}
                        </div>

                        <div className="mb-5">
                        <label className="block text-sm mt-2 font-medium text-gray-700">Nuevo número de piezas en recepción:</label>
                            <InputQuantity onlyMinus={true} initialValue={correctedProduct.lot_reception} updateData={(value) => {setCorrectQuantity(value)}}></InputQuantity>
                        </div>

                        <div>
                        
                        
                        <button
                            type="button"
                            className="bg-white mr-2 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => setOpenDialogCorrection(false)}
                        >
                            Cancelar
                        </button>
                            <ButtonLoader loading={loadingCorrectionButton} onClick={() => handleCorrectReception(correctedProduct)} disabled={loadingCorrectionButton}>Corregir</ButtonLoader>
                        </div>
                    </>
                )
                
                :

                (
                    <>
                        <div>
                            <h1 className="text-lg leading-6 font-medium text-gray-900">No hay ninguna pieza en recibo</h1>
                            <button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => setOpenDialogCorrection(false)}
                           >
                                Cancelar
                            </button>
                        </div>
                    </>
                )
            }
                

                
            </DialogView>

            <DialogView setOpen={setOpenDialogComplete} open={openDialogComplete} >
                <div className="p-5">

                
                <h1 className="text-lg leading-6 font-medium text-gray-900">Atención</h1>

                <p className="text-gray-500 text-base">{completeDialogText}</p>

                <div>
                <button
                    type="button"
                    className="mr-5 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => setOpenDialogComplete(false)}
                >
                    Cancelar
                </button>
                <ButtonLoader loading={loadingCompleteButton} onClick={() => acceptCompleteReplenishment()} disabled={loadingCompleteButton}>Aceptar</ButtonLoader>
                </div>
                </div>
            </DialogView>


            {isLoading && foundProducts.length == 0 ? (
                <>Loading...</>
            ) : isError ? (
                <>Error: {error.message}</>
            ) : (
            <div className="flex-1 flex items-stretch overflow-hidden">
                {!isMissingAllLots() || !hasWarehouseLocation() ? 
                <>
                    <main className="flex-1 bg-white">
                        <section
                        aria-labelledby="primary-heading"
                        className="min-w-0 flex-1 h-full flex flex-col overflow-hidden min-h-full lg:order-last"
                        style={{minHeight: '75vh'}}
                        >
                        
                        <div className="m-10">
                        
                            {
                                data.replenishment && data.replenishment.status === 'COMPLETED' ? (
                                    <>
                                        <h1 className="text-lg my-5 leading-6 font-medium text-gray-900-">Recibo Completado</h1>
                                        { !data.replenishment.is_from_return && (
                                            <>
                                                <label className="block text-base font-medium text-gray-700" id="headlessui-listbox-label-3">Quality check</label>
                                                <div className="mt-1">
                                                    <Checkbox
                                                        label={"El producto viene correctamente segregado."}
                                                        onChange={(isChecked) =>
                                                            handleUpdateQualityCheck({ productIsSegregated: isChecked })
                                                        }
                                                        checked={qualityCheckData.productIsSegregated}
                                                        disabled={qualityCheckDisabled}
                                                    />
                                                    <Checkbox
                                                        label={"El producto viene correctamente etiquetado."}
                                                        onChange={(isChecked) =>
                                                            handleUpdateQualityCheck({ productIsLabeled: isChecked })
                                                        }
                                                        checked={qualityCheckData.productIsLabeled}
                                                        disabled={qualityCheckDisabled}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) :
                                data && data.replenishment && data.replenishment.status === 'IN_PUTAWAY' ? (
                                    <>
                                        <h1 className="text-lg my-5 leading-6 font-medium text-gray-900">
                                            Stage de Recibo Completado pero todavía quedan piezas por localizar en Put Away.
                                        </h1>
                                        { !data.replenishment.is_from_return && (
                                            <>
                                                <label className="block text-base font-medium text-gray-700" id="headlessui-listbox-label-3">Quality check</label>
                                                <div className="mt-1">
                                                    <Checkbox
                                                        label={"El producto viene correctamente segregado."}
                                                        onChange={(isChecked) =>
                                                            handleUpdateQualityCheck({ productIsSegregated: isChecked })
                                                        }
                                                        checked={!!qualityCheckData.productIsSegregated}
                                                        disabled={qualityCheckDisabled}
                                                    />
                                                    <Checkbox
                                                        label={"El producto viene correctamente etiquetado."}
                                                        onChange={(isChecked) =>
                                                            handleUpdateQualityCheck({ productIsLabeled: isChecked })
                                                        }
                                                        checked={!!qualityCheckData.productIsLabeled}
                                                        disabled={qualityCheckDisabled}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) :
                                data.replenishment && data.replenishment.status === 'REQUESTED' || data.replenishment.status === 'WITHOUT_SCHEDULING' ? (
                                    <>
                                        <h1 className="text-lg mt-5 mb-5 leading-6 font-medium text-gray-900">Recibo de inventario</h1>

                                        <label className="block text-base font-medium text-gray-700" id="headlessui-listbox-label-3">¿Cuantos bultos/cajas vienen en este recibo?</label>
                                        <div className="w-24 my-5">
                                            <InputQuantity initialValue={insertNumberOfBoxes} updateData={(value) => {setInsertNumberOfBoxes(value)}}></InputQuantity>
                                        </div>

                                        <label className="block text-base font-medium text-gray-700" id="headlessui-listbox-label-3">Quality check</label>
                                        <div className="mt-1">
                                            <Checkbox
                                                label={"Cumplió con cita."}
                                                onChange={(isChecked) =>
                                                    handleUpdateQualityCheck({ clientAttendedAppointment: isChecked })
                                                }
                                                checked={qualityCheckData.clientAttendedAppointment}
                                                disabled={qualityCheckDisabled}
                                            />
                                        </div>

                                        <ButtonLoader className="mt-5 mr-1" loading={loadingButton} onClick={() => handleReceiveReplenishment()} disabled={loadingButton}>
                                            Marcar cómo Recibido
                                        </ButtonLoader>
                                    </>
                                ) :
                                hasWarehouseLocation() ?
                                    (<>
                                        <div className="flex items-stretch">
                                            <div className="flex-grow">
                                                <div className="mt-3 grid grid-cols-4">
                                                    <div className="mx-2 col-span-3">
                                                        <label className="block text-base font-medium text-gray-700" id="headlessui-listbox-label-3">Cuenta piezas por SKU o UPC</label>
                                                        <SearchInput debounce="10" dependencies={[foundProducts, activeLot, foundLots, isReceiving]} inputValue={textToSearch} className="w-80 my-2" onChange={handleSearchChange} onReset={() => {resetSearch()}} ref={searchInputRef}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <CreateLotsDialog open={hasMissingLots() ||  showCreateLotsDialog} onSuccess={(lots) => onLotsSaved(lots)}
                                            onCancel={()=>setShowCreateLotsDialog(false)} lots={processedLots(lastScannedProduct)} products={[lastScannedProduct]}
                                            replenishment_products={data.replenishment.replenishment_products} replenishment_id={data.replenishment.id}
                                        ></CreateLotsDialog>
                                    </>)
                                    :
                                    <>
                                        <h1 className="text-lg mt-5 mb-5 leading-6 font-medium text-gray-900">Empezar check-in</h1>
                                        <ButtonLoader className="my-1" loading={loadingButton} onClick={() => handleProcessReplenishment()} disabled={loadingButton}>Empezar</ButtonLoader>
                                    </>
                            }
                            </div>
                        <div>
                        {
                            productSearchIsLoading ?<div className="ml-10 block text-sm font-medium text-gray-700" >Buscando...</div> : ""
                        }
                        {
                            messageProductsNotFound ?<div className="ml-10 block text-sm font-medium text-gray-700" >El SKU buscado no existe</div> : ""
                        }
                        {foundProducts.length > 0 && exists === true || messageProductsNotFound

                            ? 
                        <>
                            {!data.replenishment.can_receive_more_stock_than_expected && hasScannableProductsMoreStockThanExpected && (
                                <div className="bg-red-500 text-white py-2 px-6 flex gap-2">
                                    <ExclamationCircleIcon className="h-7 w-7"/>
                                    <span>
                                        {i18n.t('replenishment.error_unexpected_stock_start')}
                                        <span className="font-bold">{i18n.t('replenishment.error_unexpected_stock_middle')}</span>
                                        {i18n.t('replenishment.error_unexpected_stock_end')}
                                    </span>
                                </div>
                            )}

                            <table className="min-w-full divide-y divide-gray-200 overflow-y-auto border-collapse">
                            <thead className="bg-gray-50">
                                    <tr>
                                    {product_table_columns.map((column)=>(
                                    <th
                                        key={column.accessor}
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        {column['Header']}
                                    </th>
                                ))}
                                            
                                    </tr>
                            </thead>
                            <tbody>

                            { foundProducts.map(foundProduct => {
                                const isLastScannedProduct = foundProduct.id === lastScannedProduct.id
                                const hasMoreStockThanExpected = checkHasMoreStockThanExpected(foundProduct, foundProduct.insertQuantity)
                                const disableReceiveStock = foundProduct.is_scannable && hasMoreStockThanExpected && !data.replenishment.can_receive_more_stock_than_expected ? true : false
                                let rowClassName = isLastScannedProduct ? "border-2 border-blue-400 animate-pulse " : ""

                                const replenishmentProduct = data.replenishment.replenishment_products.find(({product_id}) => product_id === foundProduct.id)
                                const product = data.replenishment.products.find(({id}) => id === foundProduct.id)
                                if(!product) return null
                                const totalReceived = product.stock_by_lot.reduce((currentTotal, stockByLot) => currentTotal + parseInt(stockByLot.stock), 0)

                                const expectedRemainingStock = replenishmentProduct ? (replenishmentProduct.quantity - totalReceived) : 0

                                if(animating && foundProduct.lot?.id == activeLot){
                                    rowClassName += 'bg-gray-100 animate-pulse'
                                } else if (disableReceiveStock){
                                    rowClassName += 'bg-red-50'
                                } else{
                                    rowClassName += 'bg-gray-50'
                                }

                                // Remove the focused effect after some time
                                if(isLastScannedProduct){
                                    setTimeout(() => {
                                        lastScannedProductRef.current?.classList.remove("animate-pulse")
                                    }, 1000)
                                }

                                return <tr key={`${foundProduct.id}${foundProduct.lot?.id}`} className={rowClassName} ref={isLastScannedProduct ? lastScannedProductRef : null}>
                                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500 text-right">
                                        <label>
                                        {!foundProduct.has_expiration && "No requiere lote"}
                                        {foundLots.filter(lot => lot.product_id === foundProduct.id).length > 1 && foundProduct.lot && (<>
                                            <input
                                                type="radio"
                                                className="form-radio mr-1"
                                                value={foundProduct.lot.id}
                                                checked={activeLot == foundProduct.lot.id}
                                                onChange={(e) => {
                                                    setActiveLot(parseInt(e.target.value))
                                                    searchInputRef.current?.focus()
                                                }}
                                            /> 
                                        </> )} 
                                        {foundProduct.lot?.unique_code}<br/>
                                        <i>{foundProduct.lot?.expiration_date_moment.format('DD/MM/Y')}</i>

                                        </label>
                                    </td>
                                    <td className="px-2 py-2 max-w-xs text-sm text-gray-500 text-center">
                                    {getName(foundProduct)}
                                    </td>
                                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500 text-center">
                                    {getSkus(foundProduct.product_codes)}
                                    </td>
                                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500 text-center">
                                    {foundProduct.bar_code}
                                    </td>
                                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500 text-center">
                                        {/* <InputQuantity initialValue={insertQuantity} updateData={(value) => {setInsertQuantity(value)}}></InputQuantity> */}
                                        <div className={`max-w-max ${disableReceiveStock ? "border-red-500 border-2" : ""}`}>
                                        <InputNumber
                                            min={0}
                                            // size="large"
                                            value={foundProduct.insertQuantity}
                                            onChange={(value) =>{
                                                setFoundProducts(existingItems => {
                                                    let updatedProductIndex = existingItems.findIndex(updatedProduct => updatedProduct == foundProduct)
                                                    return existingItems.map((product, index) => {
                                                        return index != updatedProductIndex ? product : {...product, insertQuantity: value}
                                                    })
                                                })
                                                setActiveLot(null)
                                                setLastScannedProduct(foundProduct)
                                                //setInsertQuantity(value)
                                            }}
                                            keyboard={true}
                                        />
                                        </div>

                                        {disableReceiveStock && (
                                            <div className="text-red-400">
                                                {`${expectedRemainingStock} ${i18n.t('replenishment.expected_quantity')}`}
                                            </div>
                                        )}
                                    </td>
                                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500 text-center">
                                        <ButtonLoader
                                            type="primary"
                                            className="mr-2"
                                            loading={isReceiving == foundProduct.bar_code}
                                            disabled={foundProduct.insertQuantity < 1 || isReceiving !== null || disableReceiveStock}
                                            onClick={() => {
                                                setIsReceiving(foundProduct.bar_code)
                                                handleReceiveProducts(foundProduct)
                                            }}
                                        >
                                            Recibir
                                        </ButtonLoader>

                                        <Button
                                            type="secondary"
                                            className=""
                                            loading={isReceiving == ('-'+foundProduct.bar_code)}
                                            disabled={foundProduct.insertQuantity < 1 || isReceiving !== null || disableReceiveStock}
                                            onClick={() => openDamagedDialog(foundProduct)}
                                        >
                                            Dañado
                                        </Button>
                                        <ConfirmDialog
                                            open={openConfirmDialog}
                                            setOpen={setOpenConfirmDialog}
                                            title={damagedDialogText}
                                            description={""}
                                            confirmLabel="Continuar"
                                            onConfirm={() => {
                                                setIsReceiving('-'+foundProduct.bar_code)
                                                handleDamageProducts()
                                            }}
                                            loading={confirmLoading}
                                        />
                                    </td>
                                </tr>
                            }
                            )}
                                        
                        
                        
                            </tbody>
                            </table>
                        </>
                        
                        : 
                        lastScannedProduct.id !== 0 && !exists ? (
                            <>
                            <div className="ml-10">
                                <h1 className="text-lg mb-5 leading-6 font-medium text-gray-900"> Este producto existe en la tienda, pero no en el recibo</h1>
                                <div className="block text-sm font-medium text-gray-700 mb-5" >{lastScannedProduct.name} - {getSkus(lastScannedProduct.product_codes)} - {lastScannedProduct.bar_code}</div>
                                <Button
                                    type="primary"
                                    className="mr-2"
                                    onClick={() => handlAddProductToReplenishment(lastScannedProduct)}
                                >
                                    Añadir al recibo
                                </Button>
                            </div>
                            
                            </>
                        )
                        :
                        messageProductsNotFound && !productSearchIsLoading ?
                        (
                                // <div className="ml-10">
                                //     <div className="block text-sm font-medium text-gray-700" >Este producto no esta dado de alta ni en la tienda, ni en el recibo. Contacta un supervisor.</div>
                                // </div>
                                ""
                        )
                        : (
                            ""
                        )
                            
                        } 
                    
                    </div>

                    
                        </section>
                    </main>

                    <aside className="w-6/12 bg-white border-l border-gray-200 lg:block">
                        <div>
                            <h1 className="text-lg mt-5 ml-8 leading-6 font-medium text-gray-900"> Piezas recibidas</h1>
                            <BasicTable
                                columns={exptected_products_table_columns}
                                isFetching={isFetching}
                                showLoader={isFetching && isPreviousData}
                                showPaginator
                                showHeader
                                filterable
                                paginationMeta={data?.meta}
                                labelToPaginator="Productos"
                                pagesSize = {pageParams.per_page}
                                onFilterChange={onFilterChange}
                                onPaginationChange= { (requestedPage) => { setPageParams({...pageParams, page: requestedPage}) } }
                                onPageSizeChange= { (pageSize) => {
                                    setPageParams({...pageParams, per_page: pageSize, page: 1})
                                    dispatch(setReplenishmentsProductsListPageSize(pageSize))
                                    tableRef.current.resetPagination()
                                }}
                                data={recievedProductsData(data.replenishment.products).map(product => {
                                    return {
                                        lot: getLot(product),
                                        name: getName(product),
                                        sku: getSkus(product.product_codes),
                                        upc: product.bar_code,
                                        processed_quantity: getProcessed(product),
                                        in_reception: (
                                            <>
                                            <span>{getInReception(product)}</span>
                                            <br></br><a onClick={() => openCorrectReception(product)}>Corregir</a>
                                            </>
                                        ),
                                        quantity: product.quantity 
                                    }
                                })}
                                selectable={false}
                                editable={false}
                                ref={tableRef}
                            />
                        </div>
                    </aside>
                </>
                :
                <main className="flex-1 bg-white">
                    <section
                        aria-labelledby="primary-heading"
                        className="min-w-0 flex-1 h-full flex flex-col overflow-hidden min-h-full lg:order-last"
                        style={{minHeight: '75vh'}}
                        >
                        
                        <div className="m-10">
                            
                        </div>
                    </section>
                </main>
                }
            </div>
            )}
        </PageView>
    </>)
}