import {Button, Loader} from "../../../components";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {useState, useEffect} from "react";
import { getLabel } from "../../../services";
import {useQuery,useQueryClient} from "react-query";

export const LabelView = ({
    title,
    type,
    copies = 1,
    is_replenishment_label = false,
    file_path = null,
    onCancel,
    onPrint
}) => {
    const [path, setPath] = useState(null)
    const pdf2base64 = require('pdf-to-base64');
    const b64toBlob = require('b64-to-blob');
    useEffect ( ( )  =>  { 
        if(file_path){
            if (is_replenishment_label) {
                setPath(file_path)
            }else{
                let url =""
                url = process.env.REACT_APP_PLATFORM_URL+file_path;
                setPath(url)
            }
        }
    } ,  [title, file_path] )


    const handleClickOnPrint = () => {
        
        pdf2base64(path)
        .then(
            (response) => {
                for (let i = 0; i < copies; i++) {
                
                const contentType = 'application/pdf';
                const b64Data = response;
                const blob = b64toBlob(b64Data, contentType);
                const blobUrl = URL.createObjectURL(blob);
                const iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.src = blobUrl;
                document.body.appendChild(iframe);
                    // Imprimir la página
                    iframe.contentWindow.print();
                }
                setTimeout(() => {
                    onPrint()
                }, 500);
            }
        )        
        .catch(
            (error) => {
                console.log(error);
            }
        )
    }

    const handleClickOnCancel = () => {
        if(onCancel){
            onCancel()
        }
    }

    return (
        <div style={{width:"50vw"}} className="relative">
            <div className="space-y-3 divide-y divide-gray-200">
                <div className="grid grid-cols-1 gap-y-2 gap-x-4 sm:grid-cols-2">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
                    </div>
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900"> Tipo de  impresión <b>{type=="label" ? "Etiqueta":"Documento"}</b> </h3>
                    </div>
                </div>

                <div className="pt-4">
                    {!path ? (
                        <><Loader show={true}></Loader></>
                    ) : (
                        <div>
                            <embed id="label" 
                                src={path}
                                title="title" height="500" width="100%">
                            </embed>
                        </div>
                    )}
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <Button type="secondary" onClick={handleClickOnCancel}>Cancelar</Button>
                        <Button  onClick={handleClickOnPrint} >
                            Imprimir
                        </Button>

                    </div>
                </div>
            </div>

        </div>
    )
}