import axiosInstance from "../helpers/axiosInstance";

export const fetchProductsBySkyOrUpc = async (params = {}) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/products/find_by_sku_or_upc`, { params })
    return res.data
}

export const fetchProducts = async (params = {}) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/products/wms`, { params })
    return res.data
}

export const exportWarehouseInventory = async (warehouse_id) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/warehouses/${warehouse_id}/export-inventory.csv`, {
        responseType: 'blob', // Important
    })
    return res.data
}

export const fetchProductLocationCount = async (warehouse_id, product_id, params = {}) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/warehouses/${warehouse_id}/products/${product_id}`, { params })
    return res.data
}

export const fetchProductLots = async (product_id) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/products/${product_id}/product_lots`, {})
    return res.data
}