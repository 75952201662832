export const getSkus = (productCodes, textSize = 'text-xs') => {
  return (
    <>
      {productCodes.map((code, index) => (
        <span
          className={`inline-flex items-center px-2.5 py-0.5 rounded-full ${textSize} font-medium bg-blue-100 text-blue-800 mx-1`}
          key={index}
        >
          {code.sku}
        </span>
      ))}
    </>
  )
}

export const getVariants = (variants, parentName, searchSku, multiSearch) => {
  if (!variants)
    variants = []

  if (searchSku) {
    variants = variants.filter((v) =>
      v.product_codes.find((pc) => pc.sku.search(searchSku) >= 0)
    )
  } else if (multiSearch) {
    let matches = variants.filter(
      (v) =>
        v.product_codes.find((pc) => pc.sku.search(multiSearch) >= 0) ||
        v.name.search(multiSearch) >= 0
    )
    variants = matches.length > 0 ? matches : variants
  }
  return variants.map((product) => {
    return {
      ...product,
      id: product.id,
      variantId: product.variant_id,
      // name: getVariantName(product.name, parentName),
      stock: product.stock,
      name: product.name,
      sku: product.product_codes,
      parentName: parentName,
      bar_code: product.bar_code ? product.bar_code : <i>Sin UPC</i>,
    }
  })
}
