import axiosInstance from "../helpers/axiosInstance";

export const fetchWorkOrders = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/work-orders`, { params })
    return res.data
}

export const updateWorkOrderStatusInValidation = async (workOrderId, data) => {
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/work-orders/${workOrderId}/status/in-validation`, data)
    return res.data
}

export const updateWorkOrderStatusScheduled = async (workOrderId, data) => {
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/work-orders/${workOrderId}/status/scheduled`, data)
    return res.data
}

export const updateWorkOrderStatusInProgress = async (workOrderId, data) => {
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/work-orders/${workOrderId}/status/in-progress`, data)
    return res.data
}

export const updateWorkOrderStatusCompleted = async (workOrderId, data) => {
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/work-orders/${workOrderId}/status/completed`, data)
    return res.data
}

export const updateWorkOrderStatusRejected = async (workOrderId, data) => {
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/work-orders/${workOrderId}/status/rejected`, data)
    return res.data
}
