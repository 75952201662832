import { useTranslation } from "react-i18next";
import { useState } from 'react';
import { Select, Dropdown, Tag, Menu } from 'antd';

export const BulkFilter = ({
    filter,
    placeholder,
    onChange = () => {},
}) => {
    const { i18n } = useTranslation();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [items, setItems] = useState([]);

    const onFocusHandler = () => {
        setDropdownVisible(true);
    };
  
    const onBlurHandler = () => {
        setDropdownVisible(false);
    };

    const handleClose = (removedTag) => {
        const newItems = items.filter((tag) => tag !== removedTag);
        setItems(newItems)
        onChange(newItems, filter)
      };

    const onChangeHandler = (value) => {
        setDropdownVisible(false);
        let values = []
        let string_values = value.toString()
        if (string_values != '') {
        values = string_values.split(",");
        }
        setItems(values)
        onChange(values, filter)
    };

    const menu = (
        <Menu className="overflow-auto max-h-60">
            {items.map((item, index)=>{
                return (
                    <Menu.Item key={index}>
                        <Tag
                            closable
                            onClose={()=>handleClose(item)}>
                            {item}
                        </Tag>
                    </Menu.Item>
                )
            })}
        </Menu>
    );

    return (
        <Dropdown 
            overlay={menu} 
            onBlur={onBlurHandler}
            open={dropdownVisible}
            getPopupContainer={trigger => trigger.parentElement} // prevent scrolling with page
            >
            <Select
                mode="tags"
                placeholder={i18n.t(placeholder)}
                value={items}
                className="w-56"
                tokenSeparators={[" "]}
                maxTagCount="responsive"
                open={false}
                onFocus={onFocusHandler}
                onChange={onChangeHandler}
                allowClear
            />
        </Dropdown>
    )
}