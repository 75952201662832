import S3Client from "./s3Client"

const MIN_CHUNK_SIZE = 5242880

export default class VideoUploadClient extends S3Client {
    constructor({ region = 'us-east-2', accessKeyId, secretAccessKey, bucketName, blobType = { type: 'video/webm' } }) {
        super({ region: region, accessKeyId: accessKeyId, secretAccessKey: secretAccessKey, bucketName: bucketName })

        this.videoChunksCache = []
        this.latestSentVideoChunkIndex = null
        this.blobType = { ...blobType }
        this.isUploadingVideo = false
    }

    async createMultipartUpload(fileKey) {
        const result = await super.createMultipartUpload(fileKey)
        return result
    }

    async completeMultipartUpload(){
        if(this.isUploadingVideo){
            setTimeout(() => {
                this.completeMultipartUpload()
            }, 100)
            return null
        }

        try{
            await this.uploadVideoChunkCache()
        }catch(error){
            console.log(error)
        }

        if(this.currentMultipartUpload.parts.length === 0){
            console.log("No parts to complete")
            return null
        }

        this.latestSentVideoChunkIndex = null

        const response = await super.completeMultipartUpload()
        return response
    }

    async appendVideoChunk(videoChunk){
        if(this.isUploadingVideo){
            setTimeout(() => {
                this.appendVideoChunk(videoChunk)
            }, 100)
            return null
        }

        if(parseInt(videoChunk.size) === 0){
            console.log("Ignored appended chunk because contains a size of 0")
            return null
        }

        let response = null

        this.videoChunksCache.push(videoChunk)
        const videoChunksCacheSize = this.videoChunksCache.reduce((sizeAccumulator, videoChunk) => sizeAccumulator + parseInt(videoChunk.size), 0)

        if(videoChunksCacheSize > MIN_CHUNK_SIZE){
            response = await this.uploadVideoChunkCache()
        }

        return response
    }

    async uploadVideoChunkCache(){
        let response = null

        if(this.videoChunksCache.length > 0){
            this.isUploadingVideo = true
            const videoChunkCacheBlob = new Blob(this.videoChunksCache, this.blobType)

            const newLatestSentVideoChunkIndex = this.latestSentVideoChunkIndex === null ? 0 : (this.latestSentVideoChunkIndex + 1)

            console.log("Uploading chunk with index %s and with size of %s", newLatestSentVideoChunkIndex, videoChunkCacheBlob.size)

            try{
                response = await super.uploadPart(videoChunkCacheBlob, newLatestSentVideoChunkIndex)
            } catch(error){
                this.isUploadingVideo = false
                throw error
            }

            console.log("Uploaded chunk with index %s and with size of %s", newLatestSentVideoChunkIndex, videoChunkCacheBlob.size)

            this.videoChunksCache = []
            this.latestSentVideoChunkIndex = newLatestSentVideoChunkIndex
            this.isUploadingVideo = false
        }

        return response
    }
}
