import axiosInstance from "../helpers/axiosInstance";

export const createPickingOrder = async (data) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/picking-order`, data )
    return res.data
}

export const reassignPickingOrder = async (data) => {
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/picking-orders/reassign-picker`, data )
    return res.data
}

export const fetchPickingOrderWithToteCode = async (toteUniqueCode, warehouseId, sort_by = null) => {
    let params = {
        warehouse_id: warehouseId
    }
    if (sort_by) {
        params.sort_by = sort_by
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/picking-orders/tote/${toteUniqueCode}`, { params } )
    return res.data
}

export const fetchSipSiblingByProduct = async (pickingOrderId, productSkuUpc, exclude_picking_order_id = null) => {
    let params = {
        product_upc_sku: productSkuUpc,
        exclude_picking_order_id: exclude_picking_order_id
    } 
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/picking-orders/${pickingOrderId}/sip-sibling-by-product`, { params } )
    return res.data
}