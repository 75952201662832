import { useTranslation } from 'react-i18next';
import { FetchingIndicator } from '../../BasicTable/modules/FetchingIndicator';

export const NewTableHeader = ({headerGroups, isFetching = false, selectable = false}) => {
   const { i18n } = useTranslation();
   
   return (
    headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column, index) => (
            <th className={"bg-gray-50 px-5 py-1 text-left text-xs whitespace-nowrap tracking-wider" + (column.shrinkToContent || (selectable && index === 0) ? " w-0" : "")}
            {...column.getHeaderProps(column.getSortByToggleProps())}>
              <div className="flex items-center font-bold text-indigo-900 uppercase">
                {column.fetchingIndicator ? <FetchingIndicator isFetching={isFetching}/> : 
                <div>
                  {selectable && index === 0 ? column.render('Header') : i18n.t(column.render('Header'))}
                  {column.description &&
                  <div className="w-full text-gray-500 font-normal normal-case">
                    {i18n.t(column.render('description'))}
                  </div>
                  }
                </div>
                }
                {/* Add a sort direction indicator */}
                {column.canSort &&
                  <span className="pl-2 inline-block">
                      {column.isSorted
                      ? column.isSortedDesc
                          ? <svg className="h-4 w-4" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path></svg>
                          : <svg className="h-4 w-4" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path></svg>
                      : <svg className="h-4 w-4" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"></path></svg>
                      }
                  </span>
                }
              </div>
            </th>
          ))}
        </tr>
      ))
   )
}