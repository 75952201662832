import axiosInstance from "../helpers/axiosInstance";

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const getAuthUser = async (user, password) => {
    await sleep(3000)
    return {
        username: "juliantoro103"
    }
}

export const setUserPreferences = async (userId, preferences) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/users/${userId}/preferences`, preferences)
    return res.data
}

export const getOperatorUsers = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/users/operators`, {params})
    return res.data
}