import React, {useContext, useMemo} from "react";
import {Disclosure, Transition, Menu} from "@headlessui/react";
import {BellIcon, MenuIcon, XIcon} from "@heroicons/react/outline";
import {Fragment, useState, useCallback} from "react";
import {Link, useHistory, useRouteMatch, useParams} from "react-router-dom";
import cubboLogo from "../assets/cubbo-white.png";
import {
    userHasPermission,
    navigation
} from "../navigation/NavegationRoutes";
import {
    BILLING,
    CLAIMS,
    WAREHOUSES
} from "../navigation/constants";
import {UserContext} from "../hooks/UserContext";
import axiosInstance from "../helpers/axiosInstance";
import { ClaimsPendingCountBadge } from "../pages/Claims/ClaimsPendingCountBadge";
import { fetchWarehousesOpen } from "../../src/services/warehouseSevices";
import {useQuery} from "react-query";

const userNavigation = [
    { id: 4, name: 'Your Profile', href: '/' },
    { id: 5, name: 'Settings', href: '/' },
    { id: 6, name: 'Sign out', href: '/' },
]
 
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const TopMenu = () => {
    const context = useContext(UserContext);
    const history = useHistory()
    const url = window.location.pathname
    const urlElemnts = url.split('/');
    const {data} = useQuery(['warehouses'], () => fetchWarehousesOpen(), { keepPreviousData: true }) 

    const warehouse_id = useMemo(() => {

        if(context.user?.current_warehouse?.id) return context.user?.current_warehouse?.id
        if(urlElemnts[2]) {
            if(!data) return
            let foundIndex = data.findIndex(def => def.id === parseInt(urlElemnts[2]))
            if(foundIndex!=-1) {
                context.setUser({...context.user, current_warehouse: data[foundIndex]})
                return urlElemnts[2]
            }
            else {
                history.replace('/')
            }
        }
       
      }, [context, urlElemnts,data])

    const handleClickNavigation = (e, item) => {
        e.preventDefault()
        if(item.name==="Sign out"){
            axiosInstance.post("/api/v1/logout", {}, {withCredentials: true}).then(() => {
                context.setUser({})
                history.push("/login")
            }).catch(()=>{
                alert("Error al deslogearse")
            })
        }
    }


    const getUserAvatar = () => {
        return `https://ui-avatars.com/api/?name=${context.user?.first_name}+${context.user?.last_name}`
    }

    return (
        <Disclosure as="nav" className="bg-secondary-orange-50" aria-label="Global">
            {({ open }) => (
                <>
                {/**
                 * sm:px-4 lg:px-8
                 */}
                    <div className="max-w-7xl mx-auto px-2 ">
                        <div className="flex justify-between h-16">
                            <div className="flex items-center px-2 lg:px-0">
                                <div className="flex-shrink-0 flex items-center text-white">
                                    <Link
                                            to={WAREHOUSES}
                                            className="rounded-md py-2 px-3 text-sm font-medium text-white hover:bg-secondary-orange-100 hover:text-white"
                                            key={109}
                                            onClick={()=>console.log("click ")}
                                        >
                                        <img
                                            className="h-8 w-auto"
                                            src={cubboLogo}
                                            alt="Workflow"
                                        />WMS
                                    </Link>
                            
                                </div>
                                <div className="hidden lg:ml-8 lg:flex lg:space-x-4">
                                 <Link
                                    to={WAREHOUSES}
                                    className="rounded-md py-2 px-3 text-sm font-medium text-white hover:bg-secondary-orange-100 hover:text-white"
                                    key={"web_Tiendas"}
                                    // onClick={()=>console.log("click " + STORES)}
                                 >
                                    Almacenes
                                 </Link>
                                 {false && <Link
                                    to={BILLING}
                                    className="rounded-md py-2 px-3 text-sm font-medium text-white hover:bg-secondary-orange-100 hover:text-white"
                                    key={"billing"}
                                 >
                                    Billing
                                 </Link>}            
                                
                                { (warehouse_id && context?.user)&&
                                <>
                                    {navigation.map((item) => 
                                        <React.Fragment key={`top-menu-navigation-${item.id}`}>
                                            { userHasPermission(context.user, item.permission_key) &&
                                                <Link
                                                    to={item.href.replace(':warehouse_id',  warehouse_id)}
                                                    className="rounded-md py-2 px-1 text-sm font-medium text-white hover:bg-secondary-orange-100 hover:text-white"
                                                    key={"web"+item.id}
                                                >
                                                    {item.name}
                                                    { item.href === CLAIMS &&
                                                        <ClaimsPendingCountBadge warehouseId={warehouse_id}/>
                                                    }
                                                </Link>
                                            }
                                        </React.Fragment>
                                    )}
                                </>
                                }

                                </div>
                            </div>

                            <div className="flex items-center lg:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-light-blue-200 hover:text-white hover:bg-light-blue-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open menu</span>
                                    {open ? (
                                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="hidden lg:ml-4 lg:flex lg:items-center">
                                {/*<button className="flex-shrink-0 bg-light-blue-500 p-1 text-light-blue-200 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-light-blue-500 focus:ring-white">
                                    <span className="sr-only">Notificaitons</span>
                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                </button>*/}

                                {/* Profile dropdown */}
                                <Menu as="div" className="ml-4 relative flex-shrink-0">
                                    {({ open }) => (
                                        <>
                                            <div>
                                                <Menu.Button className="bg-light-blue-500 rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-light-blue-500 focus:ring-white">
                                                    {<>
                                                        <span className="sr-only">Open user menu</span>
                                                        <img className="h-8 w-8 rounded-full" src={getUserAvatar()} alt="" />
                                                    </>}
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items
                                                    static
                                                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                                                    style={{zIndex:999}}
                                                >
                                                    { userNavigation.map((item) => (
                                                        <Menu.Item key={item.id}>
                                                            {({ active }) => (
                                                                <a
                                                                    href={item.href.replace(':warehouse_id', context.user.current_warehouse?.id)}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100' : '',
                                                                        'block px-4 py-2 text-sm text-gray-700'
                                                                    )}
                                                                    onClick={(e) => handleClickNavigation(e, item)}
                                                                >
                                                                    {item.name}
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                    ))}
                                                </Menu.Items>
                                            </Transition>
                                        </>
                                    )}
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="lg:hidden">
                    { (warehouse_id && context?.user) && (
                        <div className="pt-2 pb-3 px-2 space-y-1">
                            {navigation.map((item) => (
                                <React.Fragment key={`top-menu-navigation-${item.id}`}>
                                 { userHasPermission(context.user, item.permission_key) &&
                                    <Link
                                        to={item.href.replace(':warehouse_id',  warehouse_id)}
                                        className="block rounded-md py-2 px-1 text-base font-medium text-white hover:text-white hover:bg-light-blue-400"
                                        key={`movile_${item.id}`}
                                    >
                                        {item.name}
                                        { item.href === CLAIMS &&
                                            <ClaimsPendingCountBadge warehouseId={warehouse_id}/>
                                        }
                                    </Link>
                                 }
                                 </React.Fragment>
                            ))}
                        </div>
                                 )
                    }
                        <div className="pt-4 pb-3 border-t border-light-blue-500">
                            <div className="flex items-center px-4">
                                <div className="flex-shrink-0">
                                    <img className="h-10 w-10 rounded-full" src={getUserAvatar()} alt="" />
                                </div>
                                <div className="ml-3">
                                    <div className="text-base font-medium text-white">{context.user?.name}</div>
                                    <div className="text-sm font-medium text-light-blue-200">{context.user?.email}</div>
                                </div>
                                <button className="ml-auto flex-shrink-0 bg-light-blue-500 rounded-full p-1 text-light-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-light-blue-500 focus:ring-white">
                                    <span className="sr-only">View notifications</span>
                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="mt-3 px-2">
                                {userNavigation.map((item) => (
                                    <a
                                        key={`userNavigationMovile${item.id}`}
                                        href={item.href}
                                        className="block rounded-md py-2 px-3 text-base font-medium text-light-blue-200 hover:text-white hover:bg-light-blue-400"
                                        onClick={(e) => handleClickNavigation(e, item)}
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}