import FullNameData from './FullNameData';
import AddressData from './AddressData';
import ContactData from './ContactData';
import CopyAddress from './CopyAddress';
import { CollapseContainer } from '../../../../../components';

const ShippingAddressSection = ({shippingAddress, countyOptions, onShippingAddressChange = (updatedShippingAddress)=>{}, isEditDisabled = false, isHidden = false}) => {
    if(isHidden) {
        return <></>
    }

    return (
        <section>
            <CollapseContainer
                defaultCollapsed={true}
                headerComponent={
                    <h2 className="mb-0 text-2xl font-bold">{'Detalle del pedido'}</h2>
                }
                onCollapsedText={"Ver detalle"}
                onNotCollapsedText={"Ocultar detalle"}
            >
                <div className='pt-4 flex flex-col gap-4'>
                    <FullNameData
                        shippingAddress={shippingAddress}
                        onShippingAddressChange={onShippingAddressChange}
                        isEditDisabled={isEditDisabled}
                    />

                    <ContactData
                        shippingAddress={shippingAddress}
                        onShippingAddressChange={onShippingAddressChange}
                        isEditDisabled={isEditDisabled}
                    />

                    <AddressData
                        shippingAddress={shippingAddress}
                        onShippingAddressChange={onShippingAddressChange}
                        isEditDisabled={isEditDisabled}
                        countyOptions={countyOptions}
                    />

                    <CopyAddress
                        shippingAddress={shippingAddress}
                    />
                </div>
            </CollapseContainer>
        </section>
    )
}

export default ShippingAddressSection