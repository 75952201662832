export const BILLING_CONFIG_TABS = {
    BILLING_DATE: "billing_date",
    STORAGE: "storage",
    PICK_AND_PACK: "pick_and_pack",
    SHIPPING_LABEL: "shipping_label",
    INTERNATIONAL_SHIPPING_LABEL: "international_shipping_label",
    REPLENISHMENT: "replenishment",
    RETURNS: "returns",
    WORK_ORDERS: "work_orders",
    DIFAL: "difal",
    SHIPPING_INSURANCE: "shipping_insurance",
    PACKING_MATERIAL: "packing_material"
}
