export const TABLE_COLUMNS = [
    {
        Header: <div className="flex justify-center">Método de envío</div>,
        accessor: 'shipping_method'
    },
    {
        Header: <div className="flex justify-center">Número de envío</div>,
        accessor: 'shipping_number'
    },
    {
        Header: <div className="flex justify-center">Fecha</div>,
        accessor: 'create_at'
    },
    {
        Header: <div className="flex justify-center">Guía generada</div>,
        accessor: 'actions'
    }
]

export const SHIPPING_LABEL_STATUSES = {
    VALID: "VALID",
    INVALID: "INVALID"
}
