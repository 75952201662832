import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline"

const CollapseToggle = ({isCollapsed, setIsCollapsed, onCollapsedText, onNotCollapsedText, isUpIcon}) => {
    return (
        <div className="cursor-pointer text-blue-600 font-medium text-lg flex flex-col justify-center">
            <div className="flex gap-2" onClick={() => setIsCollapsed(!isCollapsed)}>
                <span className="flex flex-col justify-center">{ isCollapsed ? onCollapsedText: onNotCollapsedText }</span>

                { isUpIcon ? <ChevronUpIcon className="w-6"/> : <ChevronDownIcon className="w-6"/> }
            </div>
        </div>
    )
}

export default CollapseToggle
