import BillingPriceConfigRow from "./BillingPriceConfigRow"

const BillingPriceConfigTable = ({rows, headers, className="", headerClassName="", cellClassName=""}) => {
    return (
        <table className={className}>
            <thead>
                <tr>
                    {headers.map(header => (
                        <th key={header.label} className={headerClassName}>{header.label}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, index) => (
                    <BillingPriceConfigRow key={index} headers={headers} row={row} className={cellClassName} />
                ))}
            </tbody>
        </table>
    )
}

export default BillingPriceConfigTable