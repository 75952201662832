import { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isMobile } from 'react-device-detect';
import { useRouteMatch } from 'react-router-dom';
import { getVideoMediaStream, stopTracks, getVideoDevices } from '../helpers/WebcamHelper'
import { fileIdentifier, isRecording } from '../redux/webcamRecorderSlice'
import VideoUploadClient from '../helpers/videoUploadClient'
import { PACKING_PROOF_CONFIG } from '../services/packingProofService'
import { stopRecording as stopRecordingAction } from '../redux/webcamRecorderSlice';
import { GENERATE_LABELS, GENERATE_ORDER_LABEL } from '../navigation/constants';

const VIDEO_MIME = 'video/webm'
const VIDEO_CODECS = 'vp9'
const VIDEO_TIMESLICE = 2000
const VIDEO_MAXIMUM_RECORDED_SECONDS = 300

const useWebcamRecorder = () => {
  const dispatch = useDispatch()
  const shouldRecord = useSelector(isRecording)
  const fileKey = useSelector(fileIdentifier)

  const mediaStream = useRef(null)
  const mediaRecorder = useRef(null)
  const videoUploadClient = useRef(null)
  const isEnabled = useRef(null)
  const startTime = useRef(null)

  const isRouteToKeepRecording = useRouteMatch([GENERATE_LABELS, GENERATE_ORDER_LABEL])

  const recordingEnabled = async () => {
    if(isMobile){
      isEnabled.current = false
      return
    }
    const videoDevices = await getVideoDevices()
    isEnabled.current = videoDevices.length > 0 ? true : false
  }

  useEffect(() => {
    recordingEnabled()
  },[])

  useEffect(() => {
    if (shouldRecord === null || shouldRecord === undefined) return
    if (!isEnabled.current) return
    if (shouldRecord) {
      startRecording()
    } else {
      stopRecording()
    }
  }, [shouldRecord, isEnabled.current])

  useEffect(() => {
    if(shouldRecord && !isRouteToKeepRecording){
      console.log("Stopping record because route changed")
      dispatch(stopRecordingAction())
    }
  },[isRouteToKeepRecording])

  const startRecording = async () => {
    if (mediaRecorder.current != null || mediaStream.current != null) {
      console.log('startRecording aborted, an instance of MediaRecorder or MediaStream is already running')
      return
    }
    console.log('startRecording')

    videoUploadClient.current = new VideoUploadClient(PACKING_PROOF_CONFIG)
    await videoUploadClient.current.createMultipartUpload(fileKey)

    mediaStream.current = await getVideoMediaStream()
    mediaRecorder.current = new MediaRecorder(mediaStream.current, {
      mimeType: `${VIDEO_MIME}; codecs=${VIDEO_CODECS}`,
      audioBitsPerSecond: 0,
      // videoBitsPerSecond: 3000000,
    })
    mediaRecorder.current.addEventListener('dataavailable', onRecordingDataAvailable)
    mediaRecorder.current.addEventListener('error', onRecordingError)
    mediaRecorder.current.start(VIDEO_TIMESLICE)

    startTime.current = new Date()
  }

  const stopRecording = () => {
    if (mediaRecorder.current == null || mediaStream.current == null) {
      console.log('stopRecording aborted, no instance of MediaRecorder or MediaStream')

      if(videoUploadClient.current !== null){
        setTimeout(() => {
          stopRecording()
        }, 100)
      }
      return
    }

    console.log('stopRecording')
    mediaRecorder.current.stop()
    mediaRecorder.current.removeEventListener('dataavailable', onRecordingDataAvailable)
    mediaRecorder.current.removeEventListener('error', onRecordingError)
    stopTracks(mediaStream.current)
    mediaRecorder.current = null
    mediaStream.current = null
  }

  const onRecordingDataAvailable = async event => {
    console.log('onRecordingDataAvailable', event)
    if (event.data?.size > 0) {
      await videoUploadClient.current.appendVideoChunk(event.data)
    }
    if (mediaRecorder.current == null) {
      try{
        await videoUploadClient.current.completeMultipartUpload()
      }catch(error){
        console.log(error)
      }
      //delete videoUploadClient.current
      videoUploadClient.current = null
    }

    const currentRecordedSeconds = (new Date() - startTime.current) / 1000

    if(shouldRecord === true && currentRecordedSeconds > VIDEO_MAXIMUM_RECORDED_SECONDS){
      //console.log("shouldRecord", shouldRecord)
      console.log("stopping recording, recording was exceeding the maximum recording seconds: %s", VIDEO_MAXIMUM_RECORDED_SECONDS)
      dispatch(stopRecordingAction())
    }
  }

  const onRecordingError = event => {
    console.error('Error recording video', event)
  }
  
}


export default useWebcamRecorder