import { useEffect, useState } from "react";

import CollapseHeader from './CollapseHeader';
import CollapseFooter from "./CollapseFooter";

const CollapseContainer = ({
    defaultCollapsed=false,
    headerComponent,
    footerComponent,
    onCollapsedText,
    onNotCollapsedText,
    isTogglePositionTop=true,
    destroyOnHide=true,
    containerStyles,
    headerStyles,
    footerStyles,
    options,
    children
}) => {
    const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed)

    useEffect(() => {
        if(options?.hasOwnProperty("collapse")){
            setIsCollapsed(options.collapse)
        }
    }, [options])

    return (
        <div className={`${containerStyles ? containerStyles : "bg-white rounded-lg p-4"}`}>
            <CollapseHeader
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
                onCollapsedText={onCollapsedText}
                onNotCollapsedText={onNotCollapsedText}
                headerComponent={headerComponent}
                showToggle={isTogglePositionTop}
                customStyles={headerStyles}
            />

            {destroyOnHide && !isCollapsed && (<div> {children} </div>)}

            {!destroyOnHide && (
                <div className={`${isCollapsed ? "hidden" : ""}`}> {children} </div>
            )}

            <CollapseFooter
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
                onCollapsedText={onCollapsedText}
                onNotCollapsedText={onNotCollapsedText}
                footerComponent={footerComponent}
                showToggle={!isTogglePositionTop}
                customStyles={footerStyles}
            />
        </div>
    )
}

export default CollapseContainer
