import { useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { cloneDeep } from "lodash"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { NewTable, Loader, Notification } from "../../components"
import { fetchCustomServicesWithUsages } from "../../services"

const ListCustomServicesContainer = () => {
    const { warehouse_id, store_id } = useParams()
    const initialSearchParams = {
        page: 1,
        per_page: 10,
        warehouse_id: warehouse_id,
        store_id: store_id
    }

    const {i18n} = useTranslation()
    const [notificationMessage, setNotificationMessage] = useState({show: false, type: "error", title: ""})
    const [searchParams, setSearchParams] = useState({...initialSearchParams})
    const [filtersData, setFiltersData] = useState([
        {
            key: "title",
            placeholder: i18n.t("billing.custom_services.list_custom_services.table_filters.title_placeholder"),
            label: i18n.t("billing.custom_services.list_custom_services.table_filters.title"),
            data: {
                visible: true,
                type: 'text',
                value: ''
            },
        },
        {
            key: "billed_total_price",
            label: i18n.t("billing.custom_services.list_custom_services.table_filters.billed_total_price"),
            data: {
                visible: true,
                type: 'text',
                value: ''
            },
        },
        {
            key: "greater_or_equal_than_billed_total_price",
            label: i18n.t("billing.custom_services.list_custom_services.table_filters.greater_or_equal_than_billed_total_price"),
            data: {
                visible: false,
                type: 'text',
                value: ''
            },
        },
        {
            key: "less_or_equal_than_billed_total_price",
            label: i18n.t("billing.custom_services.list_custom_services.table_filters.less_or_equal_than_billed_total_price"),
            data: {
                visible: false,
                type: 'text',
                value: ''
            },
        },
        {
            key: "is_discount",
            label: i18n.t("billing.custom_services.list_custom_services.table_filters.is_discount"),
            data: {
                visible: false,
                type: 'boolean',
                onClickValue: true
            },
        },
        {
            key: "is_charge",
            label: i18n.t("billing.custom_services.list_custom_services.table_filters.is_charge"),
            data: {
                visible: false,
                type: 'boolean',
                onClickValue: true
            },
        },
        {
            key: "description",
            label: i18n.t("billing.custom_services.list_custom_services.table_filters.description"),
            data: {
                visible: false,
                type: 'text',
                value: ''
            },
        }
    ])

    const {
        isLoading: customServicesIsLoading,
        isFetching: customServicesIsFetching,
        isError: customServicesIsError,
        data: customServices,
        isPreviousData: customServicesIsPreviousData
    } = useQuery(['store-billing-configuration', searchParams], ()=>fetchCustomServicesWithUsages(searchParams), {
        keepPreviousData: true,
        refetchOnWindowFocus: false
    })

    useEffect(() => {
        if(customServicesIsError){
            setNotificationMessage({show: true, type: "error", title: i18n.t("billing.custom_services.list_custom_services.init_error")})
        }
    }, [customServicesIsError, i18n])

    const customServicesTableColumns = [
        {
            Header: i18n.t("billing.custom_services.list_custom_services.table_columns.id"),
            accessor: 'id',
            disableSortBy: true
        },
        {
            Header: i18n.t("billing.custom_services.list_custom_services.table_columns.billed_total_price"),
            accessor: 'billed_total_price',
            disableSortBy: true
        },
        {
            Header: i18n.t("billing.custom_services.list_custom_services.table_columns.type"),
            accessor: 'type',
            disableSortBy: true
        },
        {
            Header: i18n.t("billing.custom_services.list_custom_services.table_columns.title"),
            accessor: 'title',
            disableSortBy: true
        },
        {
            Header: i18n.t("billing.custom_services.list_custom_services.table_columns.description"),
            accessor: 'description',
            disableSortBy: true,
        },
        {
            Header: "",
            accessor: 'actions',
            disableSortBy: true,
            fetchingIndicator : true,
            shrinkToContent: true
        },
    ]

    const buildTableData = () => {
        if(!customServices?.store_billing_services || customServices.store_billing_services.length === 0){
            return []
        }

        return customServices.store_billing_services.map(serviceWithUsage => {
            let typeClassName = null
            let typeTranslation = null

            if(parseFloat(serviceWithUsage.store_billing_service_usages[0].billed_total_price) > 0){
                typeClassName = "bg-red-200 text-red-500"
                typeTranslation = i18n.t("billing.custom_services.types.charge")
            } else{
                typeClassName = "bg-green-200 text-green-500"
                typeTranslation = i18n.t("billing.custom_services.types.discount")
            }

            return {
                object: cloneDeep(serviceWithUsage.store_billing_service_usages[0]),
                id: serviceWithUsage.store_billing_service_usages[0].id,
                title: serviceWithUsage.custom_service_details.title,
                description: serviceWithUsage.custom_service_details.description,
                billed_total_price: serviceWithUsage.store_billing_service_usages[0].billed_total_price,
                type: (
                    <span className={`px-2 inline-flex text-xs leading-5 rounded-full ${typeClassName}`}>
                    {typeTranslation}
                    </span>
                ),
            }
        })
    }

    const updateFiltersData = (updatedData, filter) => {
        // Update filters with new data
        setFiltersData((prev) => {
            return prev.map((item) => {
                if (item.key == filter) 
                    return {
                        ...item,
                        data: updatedData
                    }
                else return item
            }).sort((a, b) => a.data.visible > b.data.visible ? -1 : 1) // Sort to preserve the order of the filters
        })
    
        // Set search params only if needed
        const filterItem = filtersData.find(item => item.key == filter)
        if ((Array.isArray(filterItem.data.value) && filterItem.data.value.length === 0 && updatedData.value.length == 0)) return
        if (!['boolean','text'].includes(filterItem.data.type) && filterItem.data.value === updatedData.value) return
        let value = updatedData.value
        const filter_name = filterItem.key_alias || filterItem.key

        let paramName = null
        let paramValue = null
        let isFilterApplied = updatedData.visible

        switch(filter_name){
            case "is_charge":
                {
                    paramName = "is_discount"
                    paramValue = false
                    break
                }

            case "billed_total_price":
            case "greater_or_equal_than_billed_total_price":
            case "less_or_equal_than_billed_total_price":
                {
                    paramName = filter_name
                    paramValue = parseFloat(updatedData.value)
                    if(isNaN(paramValue)){
                        isFilterApplied = false
                    }
                    break
                }

            default:
                {
                    paramName = filter_name
                    paramValue = updatedData.value
                    break
                }
            }

        let newSearchParams = {...searchParams, page: 1}

        if(!isFilterApplied && newSearchParams.hasOwnProperty(paramName)){
            delete newSearchParams[paramName]
        } else if(isFilterApplied){
            newSearchParams[paramName] = paramValue
        }

        setSearchParams(newSearchParams)
    }

    return (
        <div>
            {customServices && (
                <NewTable
                    showHeader
                    showLoader={customServicesIsFetching && customServicesIsPreviousData}
                    columns={customServicesTableColumns}
                    showPaginator
                    data = {buildTableData()}
                    paginationMeta={customServices?.meta || {}}
                    onPaginationChange= { page => setSearchParams((prev) => ({...prev, page: page}))}
                    onPageSizeChange = { page => setSearchParams((prev) => ({...prev, per_page: page.id, page: 1}))}
                    showDataStatus
                    isFetching={customServicesIsFetching}
                    emptyTableText={i18n.t(`billing.custom_services.no_services`)}
                    selectable={false}
                    cursorPointer={false}
                    onSearchHandler={updateFiltersData}
                    handleResetFilters={() => setSearchParams({...initialSearchParams})}
                    filtersData={filtersData}
                />
            )}

            <Notification
                show={notificationMessage.show}
                title={notificationMessage.title}
                type={notificationMessage.type}
                setShow={showNotification => setNotificationMessage({...notificationMessage, show: showNotification})}
            />

            <Loader show={customServicesIsLoading && !customServicesIsPreviousData}></Loader>
        </div>
    )
}

export default ListCustomServicesContainer