import { useQuery, useQueryClient } from "react-query"
import { useHistory, useParams } from "react-router-dom"
import { Button, ConfirmDialog } from "../../components"
import { Loader } from "../../components/Loader"
import { useTranslation } from "react-i18next";
import { SHOW_ORDER_RETURNS } from "../../navigation/constants"
import { useEffect, useState } from "react"
import {
  cancelReturn,
  duplicateReturnedOrder,
  fetchReturn,
} from "../../services/returnServices"
import { StatusHighlighted } from '../../components/NewTable/StatusHighlighted';
import { RETURNS_STATUSES} from '../../components/NewTable/StatusHighlightedDefinitions';
import { OrderLineItem } from "../Orders/OrderLineItem"
import { getReturnTypeDefinition } from "./ReturnTypes"
import { getReturnNewOrderLabel } from "./ReturnNewOrderLabels"

export const ShowReturnContainer = () => {
  const { i18n } = useTranslation();
  let { id } = useParams()
  const history = useHistory()
  const {warehouse_id} = useParams()
  const queryClient = useQueryClient()
  const { isLoading, isFetching, isError, error, data, refetch } = useQuery(
    "return_detail",
    () => fetchReturn(id)
  )

  const [returnedStockUnits, setReturnedStockUnits] = useState([])

  useEffect(() => {
    if (!data) {
      return
    }
    // console.log(data)
    let returnedStocks = []
    data.returned_stock_units.forEach((stock) => {
      let foundIndex = returnedStocks.findIndex((rs) => {
        if (stock.status === "DAMAGED") {
          return rs.product.id === stock.product.id && rs.damaged
        }
        return rs.product.id === stock.product.id && rs.damaged === false
      })
      if (foundIndex === -1) {
        returnedStocks.push({
          product: { ...stock.product },
          quantity: 1,
          damaged: stock.status === "DAMAGED" ? true : false,
        })
      } else {
        returnedStocks[foundIndex].quantity += 1
      }
    })
    setReturnedStockUnits(returnedStocks)
    // console.log(returnedStocks)
  }, [data])

  useEffect(() => {
    if (!data) {
      return
    }
    // console.log(data)
    let returnedStocks = []
    data.returned_stock_units.forEach((stock) => {
      let foundIndex = returnedStocks.findIndex((rs) => {
        if (stock.status === "DAMAGED") {
          return rs.product.id === stock.product.id && rs.damaged
        }
        return rs.product.id === stock.product.id && rs.damaged === false
      })
      if (foundIndex === -1) {
        returnedStocks.push({
          product: { ...stock.product },
          quantity: 1,
          damaged: stock.status === "DAMAGED" ? true : false,
        })
      } else {
        returnedStocks[foundIndex].quantity += 1
      }
    })
    setReturnedStockUnits(returnedStocks)
    // console.log(returnedStocks)
  }, [data])

  const showOrderDetail = (order) => {
    history.push(SHOW_ORDER_RETURNS.replace(":id", order.id).replace(':warehouse_id', warehouse_id))
  }

  return (
    <>
      {isError ? (
        <>Error: {error.message}</>
      ) : (
        <div className="relative">
          <Loader show={isFetching} />
          <div className="">
            <dl>
              <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Número de orden retornada
                </dt>
                <dd
                  className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2  hover:underline cursor-pointer"
                  onClick={() => showOrderDetail(data?.returned_order)}
                >
                  {data?.returned_order?.order_number ?
                 ( <>
                    #{data?.returned_order?.order_number}
                  </>)
                  : 
                  <>
                    <i>{i18n.t('returns.unidentified')}</i>
                  </>
                  }
                </dd>
              </div>
              <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Tienda</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data?.returned_order?.store.name || data?.store?.name}
                </dd>
              </div>
              <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Estado</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <StatusHighlighted
                    status={data?.status}
                    statuses={RETURNS_STATUSES}
                  />
                </dd>
              </div>
              <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Tipo de Retorno</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {getReturnTypeDefinition(data?.return_type).label}
                </dd>
              </div>
              <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Fecha de retorno
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data &&
                    new Intl.DateTimeFormat("es", {
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                      hour: "numeric",
                      minute: "numeric",
                    }).format(new Date(data?.created_at))}
                </dd>
              </div>
              <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Notas</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data?.notes}
                </dd>
              </div>
              {data?.return_type != "SIMPLE_RETURN" && (
                <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Orden creada por retorno
                  </dt>
                  {data?.created_order ? (
                    <dd
                      className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2  hover:underline cursor-pointer"
                      onClick={() => showOrderDetail(data?.created_order)}
                    >
                      #{data?.created_order.order_number}
                    </dd>
                  ) : (
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <i>{getReturnNewOrderLabel(data?.return_type, data?.status)}</i>
                    </dd>
                  )}
                </div>
              )}
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Productos retornados
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data?.status === "REQUESTED" ? (
                    <div>
                      <i>La orden no ha regresado.</i>
                    </div>
                  ) : (
                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                      {returnedStockUnits.map((stockUnitGroup) => (
                        <li
                          className="pl-3 pr-4 py-3 text-md"
                          key={
                            String(stockUnitGroup.product.id) +
                            (stockUnitGroup.damaged ? "D" : "")
                          }
                        >
                          {console.log(stockUnitGroup)}
                          <OrderLineItem orderLine={stockUnitGroup} />
                        </li>
                      ))}
                    </ul>
                  )}
                </dd>
              </div>
              <div className="pt-5 pb-5">
                <div className="flex justify-end">
                  {data?.rma_file && (
                    <Button
                      type="secondary"
                      className="w-full"
                      onClick={() => {
                        window.open(
                          process.env.REACT_APP_PLATFORM_URL +
                            data?.rma_file.path
                        )
                      }}
                    >
                      Ver RMA
                    </Button>
                  )}
                  {data?.returned_order?.return_label?.label_file && (
                    <Button
                      className="ml-3 w-full"
                      onClick={() => {
                        window.open(
                          process.env.REACT_APP_PLATFORM_URL +
                            data.returned_order?.return_label.label_file.path
                        )
                      }}
                    >
                      Ver Guía de Retorno
                    </Button>
                  )}
                </div>
              </div>
            </dl>
          </div>
        </div>
      )}
    </>
  )
}
