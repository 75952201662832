export const PRODUCT_TABLE_COLUMNS = [
    {
        Header: <div className="flex justify-center">SKU</div>,
        accessor: 'sku'
    },
    {
        Header: <div className="flex justify-center">UPC</div>,
        accessor: 'UPC'
    },
    {
        Header: <div className="flex justify-center">Piezas requeridas</div>,
        accessor: 'quantity',
    },
    {
        Header: <div className="flex justify-center">Piezas revisadas</div>,
        accessor: 'processed_quantity'
    },
    {
        Header: <div className="flex justify-center">Estado de revisión</div>,
        accessor: 'actions'
    }
]
