import {
  ActionMenu,
  BasicTable,
  getChildsOutofStock,
  PageTopBar,
  PageView,
  // SearchInput,
  // SelectMenu,
  SlidePanel,
} from "../../components"
import { useQuery } from "react-query"
import {
  fetchProducts,
  exportWarehouseInventory,
} from "../../services/productServices"
import { Route, Switch, useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useRef, useState } from "react"
import { Loader } from "../../components/Loader"
import { ButtonLoader } from "../../components"
import { ExpandibleRow } from "../../components/BasicTable/ExpandibleRow"
import { getSkus, getStockContent, getVariants } from "../../utils/productUtils"
import { ProductLocationsDialog } from "./ProductLocationsDialog"

const columns = [
  {
    Header: "Tienda",
    accessor: "store",
    disableFilters: false,
    disableSortBy: true,
  },
  {
    // Build our expander column
    id: "name", // Make sure it has an ID
    accessor: "name",
    Header: "Nombre",
    disableFilters: false,
    disableSortBy: false,
    Cell: ({ value, row }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand ? (
        <ExpandibleRow
          text={value}
          expanded={row.isExpanded}
          {...row.getToggleRowExpandedProps()}
        />
      ) : (
        value
      ),
  },
  {
    Header: "SKU",
    accessor: "sku",
    disableFilters: false,
    disableSortBy: true,
  },
  {
    Header: "En almacén",
    accessor: "stock.IN_WAREHOUSE",
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "Disponible para vender",
    accessor: "stock.AVAILABLE",
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "En órdenes",
    accessor: "stock.RESERVED",
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "Dañado",
    accessor: "stock.DAMAGED",
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "",
    fetchingIndicator: true,
    accessor: "actions",
    disableFilters: true,
    disableSortBy: true,
    shrinkToContent: true,
  },
]

function getName(product) {
  //console.log("product")
  if (product.is_dropshipping) {
    return (
      <>
        {product.name}
        <br></br>
        <span
          className={
            "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mt-1 bg-red-100 text-red-800"
          }
        >
          Dropshipping
        </span>
      </>
    )
  } else if (product.is_kit) {
    return (
      <>
        {product.name}
        <br></br>
        <span
          className={
            "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mt-1 bg-blue-100 text-blue-800"
          }
        >
          Kit
        </span>
      </>
    )
  } else {
    return product.name
  }
}

export const ListProductsContainer = () => {
  const history = useHistory()
  const [loadingButton, setLoadingButton] = useState(false)
  const [showProductLocationsDialog, setShowProductLocationsDialog] =
    useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [searchParams, setSearchParams] = useState({
    page: 1,
    parents_only: true,
  })
  const tableRef = useRef()

  const { isLoading, isError, error, data, isFetching, isPreviousData } =
    useQuery(["products", searchParams], () => fetchProducts(searchParams), {
      keepPreviousData: true,
    })

  const onSortChange = (orderBy) => {
    // console.log("ON_ORDER_BY",orderBy)
    if (orderBy.length > 0) {
      setSearchParams({
        ...searchParams,
        order_by: orderBy[0].id,
        order: orderBy[0].desc ? "desc" : "asc",
      })
    } else {
      if ("order_by" in searchParams) {
        delete searchParams.order_by
        delete searchParams.order
        setSearchParams({ ...searchParams })
      }
    }
  }

  const handleExportInventory = async () => {
    // setLoadingButton(true)
    // try {
    //   let res = await exportWarehouseInventory(1)
    //   let today = new Date()
    //   // let's download the file we got from the API
    //   let data = new Blob([res], { type: "text/csv" })
    //   let csvURL = window.URL.createObjectURL(data)
    //   let tempLink = document.createElement("a")
    //   tempLink.href = csvURL
    //   // set filename
    //   tempLink.setAttribute(
    //     "download",
    //     "inventory" +
    //       today.getFullYear() +
    //       "-" +
    //       (today.getMonth() + 1) +
    //       "-" +
    //       today.getDate() +
    //       ".csv"
    //   )
    //   tempLink.click()
    // } catch (error) {
    //   console.log(error)
    //   window.alert(error.message)
    // }
    // setLoadingButton(false)

    // window.URL.revokeObjectURL(url);
  }

  const onFilterChange = (activeFilters) => {
    // console.log("ON_CHANGE_FILTER", activeFilters)
    columns.forEach((col) => {
      if (searchParams[col.accessor]) {
        delete searchParams[col.accessor]
      }
    })
    let filters = []
    activeFilters.forEach((filter) => {
      filters[filter.id] = filter.value
    })
    tableRef.current.resetPagination()
    setSearchParams({ ...searchParams, ...filters, page: 1 })
    // console.log("searchParams",searchParams)
  }

  const getMenuOptions = (product) => {
    const options = []
    if (!product.is_kit && !product.variants?.length > 0) {
      options.push({
        title: "Ver ubicaciones",
        clickHandler: () => {
          setSelectedProduct(product)
          setShowProductLocationsDialog(true)
        },
      })
    }
    return options
  }

  const getTableData = () => {
    return data.products.map((product) => {
      return {
        ...product,
        store: <div>{product.store?.name}</div>,
        name: getName(product),
        sku: getSkus(product.product_codes),
        isParent: true,
        stock: getStockContent(product),
        subRows: getVariants(
          product.variants,
          product.name,
          searchParams.sku ? searchParams.sku : null
        ),
        actions: (
          <ActionMenu className="float-right" items={getMenuOptions(product)} />
        ),
      }
    })
  }

  return (
    <>
      <PageView
        topMenu={
          <PageTopBar>
            <div className="text-lg font-semibold">Inventario</div>
            <>
              <span className="mr-5">
                <ButtonLoader
                  className="whitespace-nowrap"
                  type={"secondary"}
                  loading={loadingButton}
                  disabled={loadingButton}
                  onClick={() => handleExportInventory()}
                >
                  Descargar Inventario
                </ButtonLoader>
              </span>
            </>
          </PageTopBar>
        }
      >
        {!isPreviousData && isLoading ? (
          <>
            <Loader show={true}></Loader>
          </>
        ) : isError ? (
          <>Error: {error.message}</>
        ) : (
          <BasicTable
            onFilterChange={onFilterChange}
            onSortChange={onSortChange}
            columns={columns}
            showPaginator
            showHeader
            filterable
            sortable
            paginationMeta={data?.meta}
            showCount={false}
            onPaginationChange={(requestedPage) => {
              setSearchParams({ ...searchParams, page: requestedPage })
            }}
            showLoader={isFetching && isPreviousData}
            isFetching={isFetching}
            data={getTableData()}
            emptyTableText="No hay productos que mostrar."
            ref={tableRef}
          />
        )}
        <ProductLocationsDialog
          show={showProductLocationsDialog}
          close={() => setShowProductLocationsDialog(false)}
          product={selectedProduct}
        />
      </PageView>
      <Switch>
        {/* <Route exact path={ADD_PRODUCT}>
                    <SlidePanel title="Crear Producto">
                        <AddProductContainer type="kit" edit={true}/>
                    </SlidePanel>
                </Route> */}
      </Switch>
    </>
  )
}
