const BillingPriceConfigRow = ({headers, row, className=""}) => {

    const buildCellClassName = (cell, className) => {
        let cellClassName = "border-2 border-gray-200 p-0"
        cellClassName += cell.isDisabled ? " bg-gray-100" : " bg-white"

        if(className){
            cellClassName += ` ${className}`
        }
        if(cell.className){
            cellClassName += ` ${cell.className}`
        }

        return cellClassName
    }

    const findCell = cellId => {
        return row.find(cell => cell.id === cellId)
    }

    return (
        <tr className="">
            {headers.map((header, index, {length}) => {
                const cell = findCell(header.id)

                return (
                    <td
                        key={index}
                        className={buildCellClassName(cell, className)}
                    >
                        <div className={`${cell.isInvalid ? "border-2 border-red-500" : ""}`}>
                            {cell.cell}

                            {cell.errorMessage && cell.isInvalid && (
                                <div className="text-red-500 italic text-xs p-1">
                                    {cell.errorMessage}
                                </div>
                            )}
                        </div>
                    </td>
                )
            })}
        </tr>
    )
}

export default BillingPriceConfigRow