import axiosInstance from "../helpers/axiosInstance";

export const fetchStoreConfig = async (storeId, configKey) => {
    if(!storeId || !configKey){
        return null
    }
    
    const data = (async() => {
        try {
            const result = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/configurations?key=${configKey}`)
            return result.data
        } catch(error) {
            return null
        }
    })()

    return data
}

export const fetchStorePackingOptions = async(id, includeGlobalOptions = true) => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${id}/packing_options`, {params: {global_options: includeGlobalOptions}})

    return response.data
}

export const fetchStoreBillingConfigurations = async(params) => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1//store-billing-configurations`, { params: params })
    return response.data
}

export const fetchStores = async (params = {}) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores`, { params })
    return res.data
}

export const fetchStoresWithBillingConfigurations = async (params = {}) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1//store-billing-configurations/stores`, { params: params })
    return res.data
}

export const createStoreBillingConfiguration = async (storeId, warehouseId, storeBillingConfiguration, storeBillingServices) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store-billing-configurations`, {
        ...storeBillingConfiguration,
        store_id: storeId,
        store_billing_services: storeBillingServices,
        warehouse_id: warehouseId
    })
    return res.data
}

export const disableBillingAtCutoff = async function (storeId) {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/disable-billing-at-cutoff`)
    return res.data
}

export const fetchStore = async (id, params = {}) => {
    if(id==null)
        return null
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${id}`, {params})
    return res.data
}

export const fetchStoreShippingPackages = async (storeId, params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/stores/${storeId}/shipping-packages`, { params: params })
    return res.data
}

export const fetchStoreBillingServices = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store-billing-services`, { params: params })
    return res.data
}

export const createCustomServiceWithUsage = async (params) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store-billing-services/custom-service-with-usage`, { ...params
    })
    return res.data
}

export const fetchCustomServicesWithUsages = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/store-billing-services/current-custom-services-with-usages`, { params: params })
    return res.data
}
