import React from "react"
import { useQuery } from "react-query"
import { BadgeText } from "../../components/BadgeText"
import { countPendingClaims } from "../../services/claimServices"

export const ClaimsPendingCountBadge = ({warehouseId = null}) => {
  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    isPreviousData,
    refetch,
  } = useQuery(["claims_pending_count", warehouseId], () => countPendingClaims(warehouseId), {
    // keepPreviousData: true,
    // refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: 60000,
  })

  return <>{data && !isError && data.count > 0 &&<BadgeText>{data.count}</BadgeText>}</>
}
