import React, {useEffect, useState} from 'react';

export const Checkbox = (
    {
        label,
        onChange = () => {},
        size = 4,
        checked = false,
        disabled = false,
        key= false
    }) => {

    const [isChecked, setIsChecked] = useState(checked)

    useEffect(() => {
       setIsChecked(checked)
    }, [checked])

    const toggleCheckboxChange = () => {
        setIsChecked(!isChecked)

        onChange(!isChecked);
    }

    return(
            <div className="checkbox" key={key}>
                <label className="text-medium">
                    <input
                        type="checkbox"
                        value={label}
                        checked={isChecked}
                        onChange={toggleCheckboxChange}
                        className={`focus:ring-indigo-500 h-${size} w-${size} text-indigo-600 border-gray-300 rounded my-2 mr-2 disabled:opacity-50`}
                        disabled={disabled}
                    />

                    {label}
                </label>
            </div>
    )
}
