import {Route, Redirect} from "react-router-dom";
import {useContext} from "react";
import {UserContext} from "../../hooks/UserContext";
import {
    userHasPermission,
    navigation
} from "../../navigation/NavegationRoutes";

const PrivateRoute = (props) => {

    const { user, isLoading } = useContext(UserContext);
    const { component: Component, ...rest } = props;

    if(isLoading) {
        return <>Loading</>
    }
    
    if(user?.isLogged){

        let navegation_menu = navigation.find(item => item.href == props.path && item.validate)
        if(!navegation_menu){
            return ( <Route {...rest} render={(props) =>
                (<Component {...props}/>)
            }
            />)
        }

        if( userHasPermission(user, navegation_menu.permission_key) ){
            return ( <Route {...rest} render={(props) =>
                    (<Component {...props}/>)
                }
                />
            )
        }else{
            return <Redirect to='/access_denied' />
        } 

        
        
    }
        return <Redirect to='/login' />


};

export default PrivateRoute